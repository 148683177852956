import {t} from 'ttag';
import * as React from 'react';
import {DateTime as dt} from 'luxon';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {AddParticipantFormSchema} from './add-participant-form/schemas/add-participant-form-schema';
import {PPGRCDsApiResponse} from '../../../../entities/entity/deparment/ppgrcds/PPGRCDsList';
import AddParticipantFormDetails from './add-participant-form/AddParticipantFormDetails';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import {DATETIME_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import useDirectusQuery from '../../../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import FormSkeleton from '../../../../common/forms/FormSkeleton';
import BasicCard from '../../../../common/cards/BasicCard';
import {useStore} from '../../../../../store/valtio';

interface Props {
    ppgrcdId: number
}

export const AddParticipantForm = ({ppgrcdId}: Props) => {
    const {app: {user}} = useStore();

    const {
        actions: {POST: addParticipant},
        response: {
            error: errorAddParticipant,
            isLoading: isLoadingAddParticipant
        }
    } = useDirectusMutation({path: '/items/prevention_plans_email_invites'});

    const methods = useForm({
        resolver: yupResolver(AddParticipantFormSchema),
        defaultValues: {
            email: '',
            role: ''
        }
    });

    const {
        data: preventionPlansUsers,
        error: errorGetPreventionPlansUsers,
        isLoading: isLoadingGetPreventionPlansUsers
    } = useDirectusQuery<PPGRCDsApiResponse>({
        path: '/items/prevention_plans_directus_users',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'directus_users_id': {
                        "_eq": user?.id
                    }
                }
            },
            refetchOnChange: user
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        addParticipant({
            email: values.email,
            role: values.role,
            prevention_plan_id: ppgrcdId,
            valid_date: dt.now().plus({days: 30}).toFormat(DATETIME_FORMAT)
        });
    });

    if (!preventionPlansUsers) return null;

    return (
        <BasicCard headerContent={<></>}>
            <>
                <FormProvider {...methods}>
                    <FormSkeleton
                        mainGroupContent={<AddParticipantFormDetails authenticatedUser={preventionPlansUsers[0]}/>}
                        submitBtnText={t`Invite`}
                        onSubmit={submitHandler}
                        cancelAction={false}
                        innerForm
                    />
                </FormProvider>
                <DirectusFeedback
                    error={errorAddParticipant || errorGetPreventionPlansUsers}
                    isLoading={isLoadingAddParticipant || isLoadingGetPreventionPlansUsers}
                />
            </>
        </BasicCard>

    );
};

export default AddParticipantForm;