import {t} from 'ttag';
import React from 'react';
import {Grid, TextField} from '@mui/material';
import {Controller, useFormContext} from 'react-hook-form';
import {commonStyles} from '../../../common/styles/commonStyles';

interface Props {
    editable?: boolean
}

export const EntityFormDetails = ({editable}: Props) => {
    const {watch} = useFormContext();

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Name`+'*'}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                {watch('is_company') === 'false' ?
                    <Controller
                        name={'nif'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                type={'number'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`NIF`+'*'}
                                variant={"outlined"}
                                disabled={!editable}
                                fullWidth
                            />
                        )}
                    />
                    :
                    <Controller
                        name={'nipc'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                type={'number'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`NIPC`+'*'}
                                sx={commonStyles.textField}
                                variant={"outlined"}
                                disabled={!editable}
                                fullWidth
                            />
                        )}
                    />
                }
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'cae'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Cae`}
                            sx={commonStyles.textField}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'license'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`License`}
                            sx={commonStyles.textField}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

EntityFormDetails.defaultProps = {
    editable: true
}

export default EntityFormDetails;