import React from 'react';
import useDirectusQuery from '../custom-hooks/useDirectusQuery';
import {ResiduesApiResponse} from '../../pages/search/Search';
import ResiduesGrid from '../common/residues/ResiduesGrid';
import DirectusFeedback from '../common/DirectusFeedback';
import {Residue} from '../../types';

interface AddressFilter {
    island: string,
    county: string,
    parish: string
}

interface Props {
    residues: Residue[]
    addressFilter: AddressFilter,
    lerCodeFilter: number | string
}

export const PublicResiduesGrid = ({residues, addressFilter, lerCodeFilter}: Props) => {

    let filteredResidues = residues;

    if (addressFilter?.island) filteredResidues = residues?.filter((residue) => residue.address_id.island === addressFilter?.island);
    if (addressFilter?.county) filteredResidues = filteredResidues?.filter((residue) => residue.address_id.county === addressFilter?.county);
    if (addressFilter?.parish) filteredResidues = filteredResidues?.filter((residue) => residue.address_id.parish === addressFilter?.parish);
    if (lerCodeFilter) filteredResidues = filteredResidues?.filter((residue) => residue.ler_code.code === lerCodeFilter);

    return <ResiduesGrid residues={filteredResidues}/>
};

export default PublicResiduesGrid;