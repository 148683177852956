import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../common/grid/SubMenu';
import {toTitleCase} from '../../../../../utils/formatters/formatters';


export const columns = ({ editHandler, deleteHandler }) => [
  {
    field: 'name',
    headerName: t`NAME`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => row.directus_users_id.first_name
  },
  {
    field: 'email',
    headerName: t`EMAIL`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => row.directus_users_id.email
  },
  {
    field: 'role',
    headerName: t`ROLE`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => (
        row.role === 'MANAGER'
            ? 'Gestor'
            : row.role === 'COORDINATOR'
                ? 'Coordenador'
                : row.role === 'MEMBER'
                    ? 'Membro' : '-'

    )
  },
  {
    field: 'actions',
    headerName: '',
    type: 'number',
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => {
      return (
          <SubMenu
              viewAction
              editAction={false}
              onView={() => editHandler(row)}
              onDelete={() => deleteHandler(row)}
          />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
