import {t} from 'ttag';
import * as React from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import {AddCommentFormSchema} from './schemas/add-comment-form-schema';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import FormSkeleton from '../../../../common/forms/FormSkeleton';
import AddCommentFormDetails from './AddCommentFormDetails';
import {User} from '../../../../../types';

interface Props {
    user: User
    ppgrcdId: string
    onCreated: () => void
}

export const AddCommentForm = ({user, ppgrcdId: prevention_plan_id, onCreated}: Props) => {
    const {
        actions: {POST: addComment},
        response: {error, isLoading}
    } = useDirectusMutation({path: '/items/prevention_plans_comments'});

    const methods = useForm({
        resolver: yupResolver(AddCommentFormSchema),
        defaultValues: {
            title: '',
            comment: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        addComment({
            ...values,
            prevention_plan_id,
            user_id: user.id
        }, onCreated);
    });

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<AddCommentFormDetails/>}
                    submitBtnText={t`Send`}
                    onSubmit={submitHandler}
                    cancelAction={false}
                    innerForm
                />
            </FormProvider>
            <DirectusFeedback error={error} isLoading={isLoading}/>
        </>
    );
};

export default AddCommentForm;