import {t} from 'ttag';
import React from 'react';
import {Stack, Typography} from '@mui/material';
import {createDatetime, DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import SubMenu from '../../../../common/grid/SubMenu';


export const columns = ({ lerCodes, isEditing, editHandler, deleteHandler, readOnly }) => [
  {
    field: 'document_code',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`DOCUMENT`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`CODE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({row}) => row.document_code
  },
  {
    field: 'verification_code',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`VERIFICATION`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`CODE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2.5,
    renderCell: ({row}) => row.verification_code
  },
  {
    field: 'ler_code',
    headerName: t`LER`,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) =>
      isNaN(row.ler_code)
        ? row.ler_code.code
          : !isNaN(row.ler_code)
            && JSON.parse(JSON.stringify(lerCodes?.filter((lerCode) => lerCode.id === row.ler_code)[0]))?.code
  },
  {
    field: 'amount',
    headerName: t`QNT.`,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'base_unit',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`BASE`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`UNIT`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'transport',
    headerName: t`TRANSPORT`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'operator',
    headerName: t`OPERATOR`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'operation',
    headerName: t`OPERATION`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'insertion_date',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    flex: .5,
    renderCell: ({ row }) => {
      return (
        <SubMenu
            disabled={isEditing || readOnly}
            onDelete={() => deleteHandler(row)}
            onEdit={() => editHandler(row)}
        />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
