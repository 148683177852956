import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {TabContext, TabPanel} from '@mui/lab';
import {Box, Button, Stack, Tab, Typography} from '@mui/material';
import {NavigationBreadcrumbs} from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import PatternSearch from '../../components/common/pattern-search/PatternSearch';
import commonStyles from '../../components/common/styles/commonStyles';
import AuthorizationGuard from '../common/guards/AuthorizationGuard';
import EntitiesList from '../../components/entities/EntitiesList';
import BasicCard from '../../components/common/cards/BasicCard';
import {APP_ROLES} from '../../utils/user/user-utils';
import {CustomizedTabs} from './CustomizedTabs';
import {useStore} from '../../store/valtio';


export const Entities = () => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [tab, setTab] = useState('1');
    const [searchPattern, setSearchPattern] = useState(null);

    if(!user) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={commonStyles.pageTitle}>{t`ENTITIES`}</Typography>
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={() => navigate('/add-entity')}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Entity`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <NavigationBreadcrumbs
                list={[
                    {name: 'Home', link: '/'},
                    {name: t`Entities`, link: '/entities'}
                ]}
            />
            <BasicCard
                headerContent={
                    <CustomizedTabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                    >
                        <Tab disableRipple label={t`All`} value={'1'}/>
                        <Tab disableRipple label={t`Individual`} value={'2'}/>
                        <Tab disableRipple label={t`Collective`} value={'3'}/>
                    </CustomizedTabs>
                }
            >
                <TabContext value={tab}>
                    <Stack spacing={2}>
                        <PatternSearch
                            placeholder={t`Search by name...`}
                            onChange={(searchPattern) => setSearchPattern(searchPattern)}
                        />
                        <TabPanel sx={styles.tabPanel} value={'1'}>
                            <EntitiesList filterByUser={user} pattern={searchPattern}/>
                        </TabPanel>
                        <TabPanel sx={styles.tabPanel} value={'2'}>
                            <EntitiesList filterByUser={user} pattern={searchPattern} onlyIndividuals/>
                        </TabPanel>
                        <TabPanel sx={styles.tabPanel} value={'3'}>
                            <EntitiesList filterByUser={user} pattern={searchPattern} onlyCompanies/>
                        </TabPanel>
                    </Stack>
                </TabContext>
            </BasicCard>
        </Stack>
    );
};

const styles = {
    card: {
        backgroundColor: 'transparent'
    },
    cardHeader: {
        px: 0,
        '.MuiCardHeader-action': {mr: 0}
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    tabPanel: {
        p: 0
    }
}

export default Entities;