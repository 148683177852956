import {t} from 'ttag';
import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

interface Props {
    open: boolean,
    variant: 'primary' | 'success' | 'warning' | 'error',
    title: string,
    confirmText: string,
    cancelText: string,
    onConfirm: React.MouseEventHandler<HTMLButtonElement>,
    onCancel: React.MouseEventHandler<HTMLButtonElement>,
    maxWidth?: 'xs' | 'xl' | 'md' | 'sm' | 'lg'
    children: React.ReactElement,
    confirmDisabled?: boolean
    reverse: boolean
}

export const ConfirmDialog = ({
                                  onConfirm,
                                  onCancel,
                                  title,
                                  children,
                                  confirmText,
                                  cancelText,
                                  open,
                                  maxWidth,
                                  variant,
                                  confirmDisabled,
                                  reverse,
                                  ...rest
                              }: Props) => {

    if (!open) return null;

    return (
        <Dialog title={title} open={open} onClose={onCancel} maxWidth={maxWidth || 'xs'} fullWidth {...rest}>
            {title &&
                <DialogTitle>
                    <Typography color={variant} fontSize={'1.4rem'}>{title}</Typography>
                </DialogTitle>
            }
            <DialogContent dividers>
                <Box py={3} px={1}>
                    {children}
                </Box>
            </DialogContent>
            <DialogActions sx={{p: 2}}>
                <Box sx={{display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row'}}>
                    <Box mx={1}>
                        <Button color={'secondary'} variant={'outlined'} onClick={onCancel}>{cancelText}</Button>
                    </Box>
                    <Box mx={1}>
                        <Button color={variant} variant={'contained'} onClick={onConfirm} disabled={confirmDisabled}>{confirmText}</Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.defaultProps = {
    open: true,
    variant: 'error',
    title: t`Confirmation`,
    children: t`Are you sure?`,
    confirmText: t`Confirm`,
    cancelText: t`Cancel`,
    onConfirm: () => null,
    onCancel: () => null,
    reverse: false
};

export default ConfirmDialog;
