import {t} from 'ttag';
import {TabContext, TabPanel} from '@mui/lab';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Box, Button, Stack, Tab, Typography} from '@mui/material';
import {Delete, Visibility, VisibilityOff} from '@mui/icons-material';
import {UpdateEntityFormSchema} from '../../components/entities/forms/helpers/update-entity-form-schema';
import EntityFormDetails from '../../components/entities/forms/create-entity-form/EntityFormDetails';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import useDirectusMutation from '../../components/custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../components/custom-hooks/useDirectusQuery';
import Departments from '../../components/entities/departments/Departments';
import DirectusFeedback from '../../components/common/DirectusFeedback';
import commonStyles from '../../components/common/styles/commonStyles';
import FormSkeleton from '../../components/common/forms/FormSkeleton';
import AuthorizationGuard from '../common/guards/AuthorizationGuard';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import BasicCard from '../../components/common/cards/BasicCard';
import Collaborators from './collaborators/Collaborators';
import {APP_ROLES} from '../../utils/user/user-utils';
import {Entity as EntityType} from '../../types';
import {CustomizedTabs} from './CustomizedTabs';
import {useStore} from '../../store/valtio';

interface EntityApiResponse {
    data: EntityType[],
    error: Error,
    isLoading: boolean
}

export const Entity = () => {
    const {app: {user}} = useStore();
    const {entityId} = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState('1');
    const [editable, setEditable] = useState(false);
    const [invalidatedEntity, setInvalidatedEntity] = useState<EntityType>();
    const [showDeleteEntityDialog, setShowDeleteEntityDialog] = useState(false);
    const [showSuspendEntityDialog, setShowSuspendEntityDialog] = useState(false);
    const [showResumeEntityDialog, setShowResumeActivityDialog] = useState(false);

    const {data: entity, error: errorGetEntity, isLoading: isLoadingGetEntity} = useDirectusQuery<EntityApiResponse>({
        path: `/items/entities/${entityId}`,
        options: {
            params: {
                fields: '*.*.*'
            },
            refetchOnChange: invalidatedEntity
        }
    });

    const {
        actions: {DELETE: deleteEntity},
        response: {
            error: errorDeleteEntity,
            isLoading: isLoadingDeleteEntity
        }
    } = useDirectusMutation({path: `/items/entities/${entityId}`});

    const {
        actions: {PATCH: updateEntity},
        response: {
            error: errorUpdateEntity,
            isLoading: isLoadingUpdateEntity
        }
    } = useDirectusMutation({path: `/items/entities/${entityId}`});

    const defaultValues = useMemo(
        () => ({
            is_company: entity?.is_company.toString() || '',
            name: entity?.name || '',
            nif: entity?.nif || '',
            nipc: entity?.nipc || '',
            cae: entity?.cae || '',
            license: entity?.license || ''
        }),
        [entity]
    );

    const methods = useForm({
        resolver: yupResolver(UpdateEntityFormSchema),
        defaultValues
    });

    useEffect(() => {
        if (entity) {
            methods.reset(defaultValues);
        }
    }, [entity, methods, defaultValues]);

    const invalidateEntity = () => {
        setInvalidatedEntity(entity);
        setEditable(false);
    }

    const updateHandler = methods.handleSubmit((values) =>
        updateEntity({
            ...values,
            cae: parseInt(values.cae),
            nif: values.nif ? values.nif : null,
            nipc: values.nipc ? values.nipc : null
        }, invalidateEntity)
    );

    const deleteHandler = (callback = () => null) => deleteEntity(callback);

    const suspendHandler = (callback = () => null) => {
        updateEntity({
            is_active: false
        }, callback)
    };

    const resumeHandler = (callback = () => null) => {
        updateEntity({
            is_active: true
        }, callback)
    };

    if (!entity || !user) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={commonStyles.pageTitle}>{entity?.name}</Typography>
                    <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                        <Box display={'flex'} gap={2}>
                            {!entity.is_active &&
                                <Button
                                    size={'small'}
                                    variant={'contained'}
                                    onClick={() => setShowResumeActivityDialog(true)}
                                >
                                    <Visibility sx={styles.btnIcon}/>
                                    {t`Resume Activity`}
                                </Button>
                            }
                            {entity.is_active &&
                                <Button
                                    size={'small'}
                                    variant={'contained'}
                                    onClick={() => setShowSuspendEntityDialog(true)}
                                >
                                    <VisibilityOff sx={styles.btnIcon}/>
                                    {t`Suspend Activity`}
                                </Button>
                            }
                            <Button
                                size={'small'}
                                sx={styles.removeBtn}
                                variant={'contained'}
                                onClick={() => setShowDeleteEntityDialog(true)}
                            >
                                <Delete sx={styles.btnIcon}/>
                                {t`Delete`}
                            </Button>
                        </Box>
                    </AuthorizationGuard>
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Entities`, link: '/entities'},
                        {name: entity?.name, link: `/entities/${entity?.name}/${entity?.id}`}
                    ]}
                />
                <BasicCard
                    headerContent={
                        <CustomizedTabs value={tab} onChange={(_, value) => setTab(value)}>
                            <Tab disableRipple label={t`Info`} value={'1'}/>
                            <Tab
                                value={'2'}
                                label={t`Departments`}
                                disableRipple
                            />
                            <Tab
                                value={'3'}
                                label={t`Collaborators`}
                                disableRipple
                            />
                        </CustomizedTabs>
                    }
                >
                    <TabContext value={tab}>
                        <TabPanel sx={styles.tabPanel} value={'1'}>
                            <FormProvider {...methods}>
                                <FormSkeleton
                                    mainGroupContent={<EntityFormDetails editable={editable}/>}
                                    onSubmit={editable ? updateHandler : () => setEditable(true)}
                                    submitBtnText={editable ? t`SAVE` : t`EDIT`}
                                    cancelAction={false}
                                    innerForm
                                />
                            </FormProvider>
                        </TabPanel>
                        <TabPanel sx={styles.tabPanel} value={'2'}>
                            <Departments
                                departments={entity?.departments}
                                onInvalidatedDepartments={() => setInvalidatedEntity(entity)}
                            />
                        </TabPanel>
                        <TabPanel sx={styles.tabPanel} value={'3'}>
                            <Collaborators
                                collaborators={entity?.users}
                                onInvalidatedCollaborators={() => setInvalidatedEntity(entity)}
                            />
                        </TabPanel>
                    </TabContext>
                </BasicCard>
            </Stack>
            <ConfirmDialog
                open={showDeleteEntityDialog}
                title={t`Delete Entity`}
                onCancel={() => setShowDeleteEntityDialog(false)}
                onConfirm={() => deleteHandler(() => {
                    setShowDeleteEntityDialog(false);
                    navigate('/entities');
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete` + ` ${entity?.name}?`}
                </Typography>
            </ConfirmDialog>
            <ConfirmDialog
                open={showSuspendEntityDialog}
                variant={'primary'}
                title={t`Suspend Entity`}
                onCancel={() => setShowSuspendEntityDialog(false)}
                onConfirm={() => suspendHandler(() => {
                    setShowSuspendEntityDialog(false);
                    invalidateEntity();
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to suspend` + ` ${entity?.name}?`}
                </Typography>
            </ConfirmDialog>
            <ConfirmDialog
                open={showResumeEntityDialog}
                variant={'primary'}
                title={t`Resume Activity`}
                onCancel={() => setShowResumeActivityDialog(false)}
                onConfirm={() => resumeHandler(() => {
                    setShowResumeActivityDialog(false);
                    invalidateEntity();
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to resume activity on ` + ` ${entity?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetEntity || errorDeleteEntity || errorUpdateEntity}
                isLoading={isLoadingGetEntity || isLoadingDeleteEntity || isLoadingUpdateEntity}
            />
        </>
    );
};

const styles = {
    removeBtn: {
        backgroundColor: 'error.main',
        "&.MuiButtonBase-root:hover": {
            bgcolor: "error.main"
        }
    },
    btnIcon: {
        pr: 1,
        width: 17
    },
    tabPanel: {
        p: 1
    }
};

export default Entity;