import React from 'react';
import {Dialog, Typography} from '@mui/material';
import BasicCard from './cards/BasicCard';

interface Props {
    open: boolean,
    title: string,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    onClose: React.MouseEventHandler<HTMLButtonElement>,
    children: React.ReactElement,
}

export const CardDialog = ({
      open,
      title,
      maxWidth,
      onClose,
      children,
}: Props) => {

    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={maxWidth || 'md'} fullWidth>
            <BasicCard headerContent={<Typography px={3}>{title}</Typography>}>
                {children}
            </BasicCard>
        </Dialog>
    );
};

export default CardDialog;
