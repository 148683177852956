import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import CollaboratorsList from '../../../components/entities/entity/collaborators/CollaboratorsList';
import EntityInvitesList from '../../../components/entities/entity/invites/EntityInvitesList';
import PatternSearch from '../../../components/common/pattern-search/PatternSearch';
import AuthorizationGuard from '../../common/guards/AuthorizationGuard';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';
import {Collaborator} from '../../../types';

interface Props {
    collaborators: Collaborator[]
    onInvalidatedCollaborators: () => any
}

export const Collaborators = ({collaborators, onInvalidatedCollaborators}: Props) => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const {entityName, entityId} = useParams();
    const [searchPattern, setSearchPattern] = useState(null);
    const onAddCollaborator = () => navigate(`/entities/${entityName}/${entityId}/add-collaborator`);

    if (!user) return null;

    return (
        <Stack spacing={3}>
            <Box sx={styles.box}>
                <PatternSearch
                    placeholder={t`Search by name...`}
                    onChange={(searchPattern) => setSearchPattern(searchPattern)}
                />
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={onAddCollaborator}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Collaborator`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <CollaboratorsList
                collaborators={collaborators}
                pattern={searchPattern}
                onInvalidatedCollaborators={onInvalidatedCollaborators}
            />
            <EntityInvitesList/>
        </Stack>
    );
};

const styles = {
    card: {
        backgroundColor: 'transparent'
    },
    cardHeader: {
        px: 0,
        '.MuiCardHeader-action': {mr: 0}
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    tabPanel: {
        p: 0
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

Collaborators.defaultProps = {
    collaborators: []
};

export default Collaborators;