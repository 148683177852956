import {t} from 'ttag';
import * as Yup from 'yup';


export const RouteValorizationFormSchema = Yup.object().shape(
    {
        step0_pickup_transportation_service_to_create: Yup.object().typeError(t`Required field.`).required(t`Required field.`),
        step1_pretreatment_service: Yup.object().typeError(t`Required field.`).required(t`Required field.`),
        step1_2_treatment_service: Yup.object().typeError(t`Required field.`).required(t`Required field.`),
        step2_processing_service: Yup.object().typeError(t`Required field.`).required(t`Required field.`),
        step3_product_service: Yup.object().typeError(t`Required field.`).required(t`Required field.`),
        valorization_routes_platform_id: Yup.number().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
