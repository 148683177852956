import {t} from 'ttag';
import React from 'react';
import {Radio, Stack, Typography} from '@mui/material';
import DepartmentsActionsSubMenu from '../departments/available-departments-list/DepartmentsActionsSubMenu';

export const columns = ({contactHandler, radioChecked}) => [
  {
    field: "radiobutton",
    headerName: "",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
        <Radio checked={radioChecked[0] === params.id} value={params.id} />
    )
  },
  {
    field: 'entity_id.name',
    headerName: t`Entity`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.department_id.entity_id.name
  },
  {
    field: 'name',
    headerName: t`Department`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.department_id.name
  },
  {
    field: 'address_id',
    headerName: t`Location`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => `${row.department_id.address_id.county}, ${row.department_id.address_id.island}`
  },
  {
    field: 'services',
    headerName: t`Services`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.services_platform_id.subtype_title
  },
  // {
  //   field: 'actions',
  //   headerName: '',
  //   disableColumnMenu: true,
  //   flex: 1,
  //   renderCell: ({ row }) => {
  //     return (<DepartmentsActionsSubMenu onContact={() => contactHandler(row)}/>);
  //   }
  // }
];

export const helper = {
  columns
};

export default helper;
