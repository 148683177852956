import {t} from 'ttag';
import {Controller, useFormContext} from 'react-hook-form';
import React, {SyntheticEvent, useState} from 'react';
import {AddCircle, RemoveCircle} from '@mui/icons-material';
import {Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import commonStyles from '../../../common/styles/commonStyles';
import TextCollapsable from '../../../common/TextCollapsable';

interface Props {
    readOnly: boolean
}

export const SoilContaminationFormDetails = ({readOnly}: Props) => {
    const {watch} = useFormContext();
    const [openHistoricContext, setOpenHistoricContext] = useState(true);
    const [openContaminationPotential, setOpenContaminationPotential] = useState(true);

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <Grid spacing={2} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openHistoricContext}
                    title={t`Historic analysis and context`}
                    content={t`Refer to the existence of possible contaminating activities on the site.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenHistoricContext(!openHistoricContext))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'historic_analysis'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            rows={3}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Describe the historic analysis and its context` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            multiline
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} mt={5} item>
                <TextCollapsable
                    open={openContaminationPotential}
                    title={t`Contamination potential`}
                    content={t`Evaluate whether or not there is potential for contamination and respective data. If so, identify the study developed or to be developed, as well as the rationale for the estimation of contaminated soils and waste.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenContaminationPotential(!openContaminationPotential))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'evaluation_description'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            rows={3}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Evalutation description` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            multiline
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'potential_contamination'}
                    render={({field}) => (
                        <Box sx={commonStyles.spaceBetween}>
                            <Typography>{t`Is there a potential for contamination or not?`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>} disabled={readOnly}/>
                                <FormControlLabel value={'false'} label={t`No`} control={<Radio/>} disabled={readOnly}/>
                            </RadioGroup>
                        </Box>
                    )}
                />
            </Grid>
            {watch('potential_contamination') === 'true' &&
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'contaminated_soil_description'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                rows={3}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Contaminated soil description` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
            }
        </Grid>
    );
};

export default SoilContaminationFormDetails;