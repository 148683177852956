import {t} from 'ttag';
import StickyBox from "react-sticky-box";
import {useDirectus} from 'react-directus';
import {useNavigate} from 'react-router-dom';
import React, {SyntheticEvent, useState} from 'react';
import {Dashboard, KeyboardArrowRight, KeyboardReturn, Login, Logout, Person} from '@mui/icons-material';
import {Box, ListItemButton, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, Typography} from '@mui/material';
import AuthorizationGuard from '../../pages/common/guards/AuthorizationGuard';
import ResiduesMenuItem from './sidebar/ResiduesMenuItem';
import EntitiesMenuItem from './sidebar/EntitiesMenuItem';
import DirectusFeedback from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import {APP_ROLES} from '../../utils/user/user-utils';
import {APP_NAME} from '../../config/settings';
import {useStore} from '../../store/valtio';

const appLogo = require('../../assets/icons/gra-logo-white.png');
const copyLogo = require('../../assets/icons/Copy.png');

export const SideBar = () => {
    const {app: {user, isDesktop}} = useStore();
    const {directus} = useDirectus();
    const navigate = useNavigate();
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const [openEntities, setOpenEntities] = useState(false);
    const [openResidues, setOpenResidues] = useState(false);

    const handleLogout = () => {
        setIsLoadingLogout(true);
        directus.auth.logout()
            .then(() => window.location.reload())
            .finally(() => setIsLoadingLogout(false))
    }

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <Stack sx={styles.sideBar}>
            <StickyBox offsetTop={24} offsetBottom={24}>
                <Stack sx={styles.sideBarInner}>
                    <Box sx={styles.appBranding}>
                        <>
                            {isDesktop &&
                                <img
                                    src={appLogo}
                                    alt={'app-logo'}
                                    style={{cursor: 'pointer', width: 80}}
                                    onClick={() => navigate('/')}
                                />
                            }
                            {APP_NAME === 'PLATAFORMA REBUILD 17' ?
                                <Stack sx={commonStyles.centerContentAndItems}>
                                    <Typography sx={styles.appNameStyleProd}>
                                        PLATAFORMA
                                    </Typography>
                                    <Typography sx={styles.appNameStyleProd}>
                                        REBUILD 17
                                    </Typography>
                                </Stack>
                                : <Typography sx={styles.appNameStyle}>
                                    {APP_NAME}
                                </Typography>
                            }
                        </>
                    </Box>
                    <Stack spacing={3}>
                        {user ?
                            <>
                                <AuthorizationGuard
                                    userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                    <Box sx={styles.menu}>
                                        <Typography sx={styles.menuTitle}>
                                            {t`General`}
                                        </Typography>
                                        <MenuList sx={styles.menuList}>
                                            <MenuItem sx={{px: 0}} onClick={() => navigate('/home')}>
                                                <ListItemIcon>
                                                    <Dashboard sx={styles.color}/>
                                                </ListItemIcon>
                                                <ListItemText sx={styles.color}>{t`Platform`}</ListItemText>
                                                <ListItemIcon sx={styles.color}>
                                                    <KeyboardArrowRight/>
                                                </ListItemIcon>
                                            </MenuItem>
                                        </MenuList>
                                    </Box>
                                </AuthorizationGuard>
                                <Box sx={styles.menu}>
                                    <Typography sx={styles.menuTitle}>
                                        {t`Management`}
                                    </Typography>
                                    <MenuList sx={styles.menuList}>
                                        <AuthorizationGuard
                                            userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                            <>
                                                <EntitiesMenuItem
                                                    open={openEntities}
                                                    onToggle={e => handleMenuItemClick(e, () => setOpenEntities(!openEntities))}
                                                />
                                                <ResiduesMenuItem
                                                    open={openResidues}
                                                    onToggle={e => handleMenuItemClick(e, () => setOpenResidues(!openResidues))}
                                                />
                                                <MenuItem sx={{px: 0}} onClick={() => navigate('/ppgrcds')}>
                                                    <ListItemButton sx={styles.listItemBtn}>
                                                        <ListItemIcon sx={styles.color}>
                                                            <img alt={'copy-logo'} src={copyLogo} width={24}
                                                                 height={24}/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={t`PPGRCDs`}/>
                                                        <ListItemIcon sx={styles.color}>
                                                            <KeyboardArrowRight/>
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </MenuItem>
                                            </>
                                        </AuthorizationGuard>
                                        <MenuItem sx={{px: 0}} onClick={() => navigate('/profile')}>
                                            <ListItemButton sx={styles.listItemBtn}>
                                                <ListItemIcon sx={styles.color}>
                                                    <Person/>
                                                </ListItemIcon>
                                                <ListItemText primary={t`Profile`}/>
                                                <ListItemIcon sx={styles.color}>
                                                    <KeyboardArrowRight/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </MenuItem>
                                    </MenuList>
                                </Box>
                                <AuthorizationGuard
                                    userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                    <Box sx={{...styles.menu, marginTop: '50px !important'}}>
                                        <MenuList sx={styles.menuList}>
                                            <MenuItem sx={{px: 0}}
                                                      onClick={() => window.location.assign('https://rebuild17.obraspublicas.azores.gov.pt')}>
                                                <ListItemIcon>
                                                    <KeyboardReturn sx={styles.color}/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={styles.color}>{t`Return to website`}</ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Box>
                                </AuthorizationGuard>
                            </>
                            :
                            <>
                                <Box sx={styles.menu}>
                                    <Typography sx={styles.menuTitle}>
                                        {t`Authentication`}
                                    </Typography>
                                    <Box marginTop={.5}>
                                        <MenuList sx={styles.menuList}>
                                            <MenuItem sx={{px: 0}} onClick={() => navigate('/auth')}>
                                                <ListItemButton sx={styles.listItemBtn}>
                                                    <ListItemIcon sx={styles.color}>
                                                        <Login/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t`Sign In`}/>
                                                </ListItemButton>
                                            </MenuItem>
                                        </MenuList>
                                        <MenuList sx={styles.menuList}>
                                            <MenuItem sx={{px: 0}}
                                                      onClick={() => window.location.assign('https://rebuild17.obraspublicas.azores.gov.pt')}>
                                                <ListItemIcon>
                                                    <KeyboardReturn sx={styles.color}/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={styles.color}>{t`Return to website`}</ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Box>
                                </Box>
                            </>
                        }
                    </Stack>
                </Stack>
            </StickyBox>
            <DirectusFeedback isLoading={isLoadingLogout}/>
        </Stack>
    );
};

const styles = {
    sideBar: {
        width: '390px',
        minHeight: '100vh',
        backgroundColor: 'primary.main',
        zIndex: 1000
    },
    sideBarInner: {
        px: 4,
        gap: 10
    },
    appNameStyle: {
        fontWeight: 700,
        fontSize: '1.7rem',
        whiteSpace: 'nowrap',
        color: 'common.white'
    },
    appNameStyleProd: {
        fontWeight: 700,
        fontSize: '1.5rem',
        whiteSpace: 'nowrap',
        color: 'common.white'
    },
    appBranding: {
        gap: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    menu: {
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    menuList: {
        px: 0,
        width: '100%'
    },
    menuTitle: {
        fontWeight: 700,
        fontSize: '1.1rem',
        color: 'common.white',
        textDecoration: 'unset'
    },
    listItemBtn: {
        px: 0,
        color: 'common.white'
    },
    color: {
        color: 'common.white'
    },
    flagsWrapper: {
        gap: 2,
        width: '100%',
        ...commonStyles.centerContentAndItems
    }
};

export default SideBar;