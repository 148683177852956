import {t} from 'ttag';
import React, {useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import AvailableDepartmentsList from '../../entities/departments/AvailableDepartmentsList';
import PatternSearch from '../../common/pattern-search/PatternSearch';
import StepsLinearProgress from '../custom/StepsLinearProgress';
import commonStyles from '../../common/styles/commonStyles';
import FormActions from '../../common/forms/FormActions';
import BasicCard from '../../common/cards/BasicCard';

interface Props {
    onFinish: () => void,
    onReturn: () => void
}

export const AddedValueValorizationProcessing = ({onReturn, onFinish}: Props) => {
    const {watch, setValue, getValues} = useFormContext();
    const [entitiesSearchPattern, setEntitiesSearchPattern] = useState(null);

    const handleSelectDepartment = (selectedDepartment) => {
        setValue('selected_department', selectedDepartment);
    };

    return (
        <>
            <Stack spacing={3}>
                <BasicCard
                    headerContent={
                        <Box px={3}>
                            <Typography>{t`STEP 2 - PROCESSING`}</Typography>
                        </Box>
                    }
                >
                    <Stack spacing={3}>
                        <Typography pt={2}>{t`The second step consists of product processing and development.`}</Typography>
                        <Stack spacing={1}>
                            <Typography color={'common.gray'}>{t`ENOUGH INSTALLED CAPACITY`}</Typography>
                            <Controller
                                name={'capacity'}
                                render={({field}) => (
                                    <RadioGroup {...field}>
                                        <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>}/>
                                        <FormControlLabel value={'false'} label={t`No`} control={<Radio/>}/>
                                    </RadioGroup>
                                )}
                            />
                        </Stack>
                    </Stack>
                </BasicCard>
                {watch('capacity') === 'false' &&
                    <BasicCard headerContent={<Typography px={3}>{t`AVAILABLE DEPARTMENTS LIST`}</Typography>}>
                        <Stack spacing={2}>
                            <Box sx={commonStyles.spaceBetween}>
                                <PatternSearch
                                    onChange={(searchPattern) => setEntitiesSearchPattern(searchPattern)}/>
                                {(!watch('selected_department') && watch('capacity')) &&
                                    <Typography color={'error'}>{t`Please select one department.`}</Typography>
                                }
                            </Box>
                            <AvailableDepartmentsList
                                pattern={entitiesSearchPattern}
                                onSelectDepartment={handleSelectDepartment}
                            />
                        </Stack>
                    </BasicCard>
                }
            </Stack>
            <Box display={'flex'} justifyContent={'space-between'}>
                <StepsLinearProgress currentStep={2} totalSteps={3}/>
                <FormActions
                    submitBtnText={t`COMPLETE STEP`}
                    cancelBtnText={t`RETURN`}
                    onSubmit={onFinish}
                    onCancel={onReturn}
                />
            </Box>
        </>
    );
};

export default AddedValueValorizationProcessing;