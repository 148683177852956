import * as React from 'react';
import {CircularProgress, Dialog, DialogContent} from '@mui/material';

interface Props {
    loading?: boolean,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    rest?: React.ReactNode
}

export const LoadingScreen = ({loading, onClick, ...rest}: Props) => {

    if (!loading) return null;

    return (
        <Dialog
            PaperProps={styles.paperPropsStyle}
            onClick={onClick}
            open={loading}
            {...rest}
        >
            <DialogContent>
                <CircularProgress color={'inherit'}/>
            </DialogContent>
        </Dialog>
    );
};

const styles = {
    paperPropsStyle: {
        style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
    }
}

LoadingScreen.defaultProps = {
    loading: true,
    onClick: () => null
};

export default LoadingScreen;
