import {t} from 'ttag';
import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

interface Props {
    open: boolean,
    variant: 'primary' | 'success' | 'warning' | 'error',
    title: string
    confirmText: string,
    onConfirm: React.MouseEventHandler<HTMLButtonElement>,
    children: React.ReactElement,
    reverse: boolean
}

export const AlertDialog = ({
    onConfirm,
    children,
    title,
    confirmText,
    open,
    variant,
    reverse,
    ...rest
}: Props) => {

    if (!open) return null;

    return (
        <Dialog title={title} open={open} onClose={onConfirm} maxWidth={'sm'}  fullWidth {...rest}>
            <DialogTitle>
                <Typography color={variant} fontSize={'1.4rem'}>{title}</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box py={3} px={1}>
                    {children}
                </Box>
            </DialogContent>
            <DialogActions sx={{p: 2}}>
                <Box sx={{display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row'}}>
                    <Box mx={1}>
                        <Button color={variant} variant={'contained'} onClick={onConfirm}>{confirmText}</Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

AlertDialog.defaultProps = {
    open: true,
    title: t`ALERT`,
    variant: 'error',
    confirmText: t`Close`,
    onConfirm: () => null,
    reverse: false
};

export default AlertDialog;
