import {RESIDUE} from '../../components/residues/settings';
import {LOCALES} from '../../config/i18n';

export const initialState = {
  app: {
    configs: false,
    isDesktop: false,
    isMobile: false,
    isDesktopLg: false,
    language: LOCALES.PT,
    lerCodes: false,
    valorizationOperations: false,
    eliminationOperations: false,
    user: false,
    invalidatedUser: false,
    apiError: false,
    desiredResidueType: RESIDUE,
    uploadedLocationFiles: [],
    resetPasswordToken: false
  },
};

export default initialState;
