import {t} from 'ttag';

export const config = {
    "api_url": "https://directus.test.lrec.tetrapi.org",
    "app_version": "v1.0.0",
    "app_auth_service_url": "https://auth.test.lrec.tetrapi.org"
};

export const configLocal = {
    "api_url": "https://directus.test.lrec.tetrapi.org",
    "app_version": "v1.0.0",
    "app_auth_service_url": "https://auth.test.lrec.tetrapi.org"
};

export const PpgrcdRoles = [
    {
        name: t`Owner`,
        value: 'OWNER'
    },
    {
        name: t`Owner Representative`,
        value: 'OWNER_REPRESENTATIVE'
    },
    {
        name: t`Draftsman`,
        value: 'DRAFTSMAN'
    },
    {
        name: t`Contractor`,
        value: 'CONTRACTOR'
    },
    {
        name: t`Fiscal`,
        value: 'FISCAL'
    },
]

export const holders = [
    {
        name: t`Productor`,
        value: 'PRODUCTOR'
    },
    {
        name: t`Residue Operator`,
        value: 'CONSTRUCTION_RESIDUE'
    },
    {
        name: t`Service Provider`,
        value: 'SERVICE_PROVIDER'
    }
];

export const treatmentServiceTypes = [
    {
        name: t`Waste separation/decontamination`,
        value: 'WASTE_SEPARATION_DECONTAMINATION'
    },
    {
        name: t`Wood chipping`,
        value: 'WOOD_CHIPPING'
    },
    {
        name: t`Glass grinding`,
        value: 'GLASS_GRINDING'
    },
    {
        name: t`Plastic shredding`,
        value: 'PLASTIC_SHREDDING'
    },
    {
        name: t`Crushing and sorting`,
        value: 'CRUSHING_AND_SORTING'
    },
    {
        name: t`Wood Drying`,
        value: 'WOOD_DRYING'
    },
];

export const advancedServiceTypes = [
    {
        name: t`Molding`,
        value: 'MOLDING'
    },
    {
        name: t`Compression molding`,
        value: 'COMPRESSION_MOLDING'
    },
    {
        name: t`Plastic extrusion`,
        value: 'PLASTIC_EXTRUSION'
    },
    {
        name: t`Technical laboratory support`,
        value: 'TECHNICAL_LABORATORY_SUPPORT'
    }
];

export const transportServiceTypes = [
    {
        name: t`Road dispatch`,
        value: 'ROAD_DISPATCH'
    },
    {
        name: t`Maritime dispatch`,
        value: 'MARITIME_DISPATCH'
    }
];

export const consultingServiceTypes = [
    {
        name: t`Design`,
        value: 'DESIGN'
    },
    {
        name: t`Sustainability`,
        value: 'SUSTAINABILITY'
    },
    {
        name: t`Project management`,
        value: 'PROJECT_MANAGEMENT'
    },
    {
        name: t`Proofs of concept`,
        value: 'PROOF_OF_CONCEPT'
    },
    {
        name: t`Quality certifications`,
        value: 'QUALITY_CERTIFICATIONS'
    }
];

export const contaminationLevels = [
    {
        description: t`Not contaminated`,
        value: 'NOT_CONTAMINATED'
    },
    {
        description: t`Very litte contaminated (Not dangerous)`,
        value: 'VERY_LITTLE_CONTAMINATED_NOT_DANGEROUS'
    },
    {
        description: t`Contaminated (Not dangerous)`,
        value: 'CONTAMINATED_NOT_DANGEROUS'
    },
    {
        description: t`Contaminated (Dangerous)`,
        value: 'CONTAMINATED_DANGEROUS'
    }
] ;

export const formats = [
    {
        name: t`Dust`,
        value: 'DUST'
    },
    {
        name: t`Particles`,
        value: 'PARTICLES'
    },
    {
        name: t`Granulated`,
        value: 'GRANULATED'
    },
    {
        name: t`Board`,
        value: 'BOARD'
    },
    {
        name: t`Big dimensions`,
        value: 'BIG_DIMENSIONS'
    },
    {
        name: t`Varies`,
        value: 'VARIES'
    },
    {
        name: t`Other`,
        value: 'OTHER'
    },
]