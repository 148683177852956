import * as Yup from 'yup';
import {t} from 'ttag';
import {validateNif} from '../../../../../../utils/forms/helpers';


export const EntityResponsibleFormSchema = Yup.object().shape(
    {
        fiscal_number: Yup.number().nullable().test({
            test: (value) => validateNif(value),
            message: t`Should be a valid fiscal number.`
        }).required(t`Required field.`).typeError(t`Required field.`),
        license: Yup.string(),
        cae: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
    }
).required();
