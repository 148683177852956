import {t} from 'ttag';
import React from 'react';
import ValorizationProcessesListSubMenu from '../valorization-processes-list/ValorizationProcessesListSubMenu';


export const columns = ({completeHandler, cancelHandler, deleteHandler}) => [
    {
        field: 'name',
        headerName: t`ROUTE`,
        sortable: true,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({row}) => row.valorization_routes_platform_id.name
    },
    {
        field: 'output',
        headerName: t`FINAL PRODUCT(S)`,
        sortable: true,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({row}) => row.step3_product_service.services_platform_id.output_description
    },
    {
        field: 'status',
        headerName: t`STATUS`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => (
            row.status === 'ONGOING'
                ? 'Em Curso'
                : row.status === 'COMPLETED'
                    ? 'Completado'
                    : row.status === 'CANCELED'
                        ? 'Cancelado'
                        : '-'
        )
    },
    {
        field: 'actions',
        headerName: '',
        type: 'number',
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => {
            return (
                <ValorizationProcessesListSubMenu
                    onComplete={() => completeHandler(row)}
                    onCancel={() => cancelHandler(row)}
                    onDelete={() => deleteHandler(row)}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
