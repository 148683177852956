import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {DateTime as dt} from 'luxon';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Stack, Typography} from '@mui/material';
import {FiberManualRecord} from '@mui/icons-material';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import AddCollaboratorFormDetails from './add-collaborator-form/AddCollaboratorFormDetails';
import useDirectusMutation from '../../../../../custom-hooks/useDirectusMutation';
import {AddCollaboratorFormSchema} from './schemas/add-collaborator-form-schema';
import {DATETIME_FORMAT} from '../../../../../../utils/datetime/datetime-utils';
import DirectusFeedback from '../../../../../common/DirectusFeedback';
import FormSkeleton from '../../../../../common/forms/FormSkeleton';
import BasicCard from '../../../../../common/cards/BasicCard';


export const AddCollaboratorForm = () => {
    const navigate = useNavigate();
    const {entityName, entityId} = useParams();
    const [inviteCreated, setInviteCreated] = useState(false);
    const [inviteAlreadyExistsError, setInviteAlreadyExistsError] = useState(false);

    const {
        actions: {POST: inviteCollaborator},
        response: {
            error: errorInviteCollaborator,
            isLoading: isLoadingInviteCollaborator
        }
    } = useDirectusMutation({path: '/items/entities_email_invites'});

    const methods = useForm({
        resolver: yupResolver(AddCollaboratorFormSchema),
        defaultValues: {
            role: 'MEMBER',
            email: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        inviteCollaborator({
            email: values.email,
            role: values.role,
            entity_id: entityId,
            valid_date: dt.now().plus({days: 30}).toFormat(DATETIME_FORMAT)
        }, (inviteCreated) => {
            setInviteCreated(inviteCreated);
            navigate(`/entities/${entityName}/${entityId}`);
        });
    });

    return (
        <>
            <Stack spacing={8}>
                <BasicCard headerContent={<></>}>
                    <FormProvider {...methods}>
                        <FormSkeleton
                            submitBtnText={t`Invite`}
                            mainGroupContent={<AddCollaboratorFormDetails/>}
                            onCancel={() => methods.reset()}
                            onSubmit={submitHandler}
                            innerForm
                        />
                    </FormProvider>
                </BasicCard>
                <Stack spacing={3}>
                    <Typography sx={styles.permissionsTitle}>{t`Role Permissions`}</Typography>
                    <Stack spacing={2}>
                        <Box sx={styles.rolesDescriptionWrapper}>
                            <Typography>{t`Member: `}</Typography>
                            <Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Visualize other collaborators`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Manage Residues`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Manage Services`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Manage Valorization Plans`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Manage PPGRCDs`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={styles.rolesDescriptionWrapper}>
                            <Typography>{t`Coordinator: `}</Typography>
                            <Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`(Member permissions)`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`Manage collaborators`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={styles.rolesDescriptionWrapper}>
                            <Typography>{t`Manager: `}</Typography>
                            <Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography sx={styles.permissions}>{t`(Coordinator permissions)`}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} pl={2}>
                                    <FiberManualRecord sx={{width: 10}}/>
                                    <Typography
                                        sx={styles.permissions}>{t`Manage Entity and respective Departments`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <DirectusFeedback
                error={errorInviteCollaborator || inviteAlreadyExistsError}
                isLoading={isLoadingInviteCollaborator}
                isSuccess={Boolean(inviteCreated)}
                successMessage={t`Invite sent.`}
            />
        </>
    );
};

const styles = {
    permissionsTitle: {
        fontSize: '1.1rem',
        fontWeight: 'bold'
    },
    rolesDescriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1
    },
    permissions: {
        fontSize: '0.9rem'
    }
};

export default AddCollaboratorForm;