import {t} from 'ttag';
import React from 'react';
import {Stack, Typography} from '@mui/material';
import {DateTime as dt} from 'luxon';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {FieldValues, FormProvider, useFieldArray} from 'react-hook-form';
import {WasteManagementFormDetails} from './waste-management-form/WasteManagementFormDetails';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import FormSkeleton from '../../common/forms/FormSkeleton';
import BasicCard from '../../common/cards/BasicCard';

interface Props {

}

interface Props {
    currentForm: string
    wasteManagementFormMethods: UseFormReturn<FieldValues | any>
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const WasteManagementForm = ({
    currentForm,
    wasteManagementFormMethods,
    onSubmit,
    onReturn,
    readOnly
}: Props) => {
    const {getValues, resetField, setValue} = wasteManagementFormMethods;
    const {fields: residues, append, replace} = useFieldArray({
        control: wasteManagementFormMethods.control,
        name: 'residues'
    });

    const handleAddResidue = () => {
        append({
            description: getValues('description'),
            transportation: getValues('transportation'),
            operator: getValues('operator'),
            deposition: getValues('deposition'),
            other_costs: getValues('other_costs') ? getValues('other_costs') : 0,
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('description');
        resetField('transportation');
        resetField('operator');
        resetField('deposition');
        resetField('other_costs');
        resetField('insertion_date');
    }

    const handleEditResidue = (residueId) => {
        const wantedResidueToEdit = residues.filter((residue) => residue.id === residueId)[0];
        setValue('description', wantedResidueToEdit.description);
        setValue('transportation', wantedResidueToEdit.transportation);
        setValue('operator', wantedResidueToEdit.operator);
        setValue('deposition', wantedResidueToEdit.deposition);
        setValue('other_costs', wantedResidueToEdit.other_costs);
        setValue('insertion_date', wantedResidueToEdit.insertion_date);
        handleRemoveResidue(residueId);
    }

    const handleSaveEditResidue = (callback: () => null) => {
        append({
            description: getValues('description'),
            transportation: getValues('transportation'),
            operator: getValues('operator'),
            deposition: getValues('deposition'),
            other_costs: getValues('other_costs') ? getValues('other_costs') : 0,
            insertion_date: getValues('insertion_date')
        })
        resetField('description');
        resetField('transportation');
        resetField('operator');
        resetField('deposition');
        resetField('other_costs');
        resetField('insertion_date');
        callback();
    };

    const handleRemoveResidue = (residueId) => {
        let filteredResidues = residues.filter((residue) => residue.id !== residueId);
        replace(filteredResidues);
    }

    return (
        <>
            {(currentForm === 'All') || (currentForm === 'WasteManagement') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`7. WASTE MANAGEMENT COST ESTIMATION`}
                        </Typography>
                    }
                >
                    <FormProvider {...wasteManagementFormMethods}>
                        <Stack gap={3} py={2}>
                            <FormSkeleton
                                mainGroupContent={
                                    <WasteManagementFormDetails
                                        residues={residues}
                                        onAddResidue={handleAddResidue}
                                        onEditResidue={handleEditResidue}
                                        onRemoveResidue={handleRemoveResidue}
                                        onSaveEditResidue={handleSaveEditResidue}
                                        readOnly={readOnly}
                                    />
                                }
                                submitBtnText={t`Next`}
                                cancelBtnText={t`Return`}
                                onSubmit={onSubmit}
                                onCancel={onReturn}
                                actions={!readOnly}
                                innerForm
                            />
                        </Stack>
                    </FormProvider>
                </BasicCard>
            }
        </>
    );
};

export default WasteManagementForm;