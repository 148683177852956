import {t} from 'ttag';
import axios from 'axios';
import {useState} from 'react';
import {DirectusQueryResponse} from '../../types';
import {useStore} from '../../store/valtio';

// eslint-disable-next-line
export const useEgarsQuery = <Type extends DirectusQueryResponse>() => {
    const {app: {configs}} = useStore();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const POST = (body: object, callback = (data?: any) => null) => {
        setIsLoading(true);
        axios.post(`${configs.app_auth_service_url}/egars`, {
            workCode: body.workCode ?? '',
            nif: body.nif ?? '',
        }).then(({data: egars}) => {
            if (egars.length === 0) {
                setError(new Error(t`There are no available e-gars.`));
                setIsLoading(false);
                return;
            }
            setData(egars.guiaDetalhe);
            callback(egars.guiaDetalhe);
            setIsLoading(false);
        }).catch((e) => {
            setError(e);
            setIsLoading(false);
        });
    };

    return {actions: {POST}, response: {data: data || null, error: error || null, isLoading}};
};

export default useEgarsQuery;