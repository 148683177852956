import {t} from 'ttag';
import React from 'react';
import {DateTime as dt} from 'luxon';
import SubMenu from '../../../../common/grid/SubMenu';
import {createDatetime, DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import {Stack, Typography} from '@mui/material';


export const columns = ({isEditing, editHandler, deleteHandler, lerCodes, readOnly}) => [
    {
        field: 'code',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`LER`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`CODE`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 1.5,
        renderCell: ({row}) => JSON.parse(JSON.stringify(lerCodes?.filter((lerCode) => lerCode.id === (row.ler_code?.id || row.ler_code))[0])).code
    },
    {
        field: 'description',
        headerName: t`DESIGNATION`,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2.5,
        renderCell: ({row}) => JSON.parse(JSON.stringify(lerCodes?.filter((lerCode) => lerCode.id === (row.ler_code?.id || row.ler_code))[0])).description
    },
    {
        field: 'final_estimated_amount',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`ESTIMATED`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`AMOUNT`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2
    },
    {
        field: 'base_unit',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`BASE`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`UNIT`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 1.5,
        renderCell: ({row}) => (
            row.base_unit === 'CUBIC_METER'
                ? 'm3'
                : row.base_unit === 'TON'
                    ? 'tons' : '-'
        )
    },
    {
        field: 'valorization_amount',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`VALORIZATION`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`AMOUNT`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2.5
    },
    {
        field: 'valorization_operation',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`VALORIZATION`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`OPERATION`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2.5,
    },
    {
        field: 'disposal_amount',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`DISPOSAL`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`AMOUNT`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2
    },
    {
        field: 'disposal_operation',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`DISPOSAL`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`OPERATION`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2
    },
    {
        field: 'insertion_date',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2.5,
        renderCell: ({row}) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
    },
    {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        editable: !readOnly,
        flex: .5,
        renderCell: ({row}) => {
            return (
                <SubMenu
                    disabled={isEditing || readOnly}
                    onDelete={() => deleteHandler(row)}
                    onEdit={() => editHandler(row)}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
