import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {EntityDepartmentSelectorPpgrcdFormSchema} from './schemas/entity-department-selector-ppgrcd-form-schema';
import EntityDepartmentSelectorPpgrcdFormDetails from './EntityDepartmentSelectorPpgrcdFormDetails';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import DirectusFeedback from '../../common/DirectusFeedback';
import {ENTITY_ROLES} from '../../../utils/user/user-utils';
import FormSkeleton from '../../common/forms/FormSkeleton';
import {useStore} from '../../../store/valtio';
import {Typography} from '@mui/material';
import AlertDialog from '../../common/AlertDialog';


export const EntityDepartmentSelectorPpgrcdForm = () => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [showNoPermissionDialog, setShowNoPermissionDialog] = useState(false);

    const {
        actions: {POST: createPPGRCD},
        response: {
            error: errorCreatePPGRCD,
            isLoading: isLoadingCreatePPGRCD
        }
    } = useDirectusMutation({path: '/items/prevention_plans'});

    const methods = useForm({
        resolver: yupResolver(EntityDepartmentSelectorPpgrcdFormSchema),
        defaultValues: {
            entity_id: '',
            department_id: '',
            entity: {},
            department: {}
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        if (values.entity.user.role === ENTITY_ROLES.MEMBER) {
            setShowNoPermissionDialog(true);
        }

        if (values.entity.user.role === ENTITY_ROLES.MANAGER || values.entity.user.role === ENTITY_ROLES.COORDINATOR) {
            createPPGRCD({
                name: values.name,
                department_id: values.department.id,
                status: "DRAFT"
            }, ({id: ppgrcdId}) => navigate(`/entities/${values.entity.name}/${values.entity.id}/departments/${values.department.name}/${values.department.id}/add-ppgrcd/${ppgrcdId}`));
        }

    });

    if (!user) return null;

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<EntityDepartmentSelectorPpgrcdFormDetails authenticatedUser={user}/>}
                    submitBtnText={t`Add PPGRCD`}
                    onSubmit={submitHandler}
                    cancelAction={false}
                    innerForm
                />
            </FormProvider>
            <AlertDialog
                open={showNoPermissionDialog}
                onConfirm={() => setShowNoPermissionDialog(false)}
            >
                <Typography color={'text.secondary'}>
                    {t`You don't have permissions on this entity to be able to create prevention plans. Please select other entity.`}
                </Typography>
            </AlertDialog>
            <DirectusFeedback error={errorCreatePPGRCD} isLoading={isLoadingCreatePPGRCD}/>
        </>
    );
};

export default EntityDepartmentSelectorPpgrcdForm;