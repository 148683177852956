import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Grid, TextField} from '@mui/material';
import commonStyles from '../../../../../common/styles/commonStyles';
import FormSelectorsRAA from '../../../../../common/forms/FormSelectorsRAA';

interface Props {
    editable?: boolean
}

export const DepartmentFormDetails = ({editable}: Props) => {

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Name`+'*'}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'address_id.address'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Address`+'*'}
                            sx={commonStyles.textField}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'address_id.email'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`E-mail`+'*'}
                            sx={commonStyles.textField}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'address_id.phone'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            sx={{...commonStyles.textField}}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Phone Number`}
                            disabled={!editable}
                            variant={"outlined"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <FormSelectorsRAA editable={editable}/>
        </Grid>
    );
};

DepartmentFormDetails.defaultProps = {
    editable: true
}

export default DepartmentFormDetails;