import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import {EntitiesApiResponse} from '../../entities/EntitiesList';
import DirectusFeedback from '../../common/DirectusFeedback';
import {User} from '../../../types';
import commonStyles from '../../common/styles/commonStyles';

interface Props {
    authenticatedUser: User
}

export const EntityDepartmentSelectorPpgrcdFormDetails = ({authenticatedUser}: Props) => {
    const {watch, setValue, getValues, resetField} = useFormContext();

    const {
        data: entities,
        error: errorGetEntities,
        isLoading: isLoadingGetEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    'users': {
                        "directus_users_id": {
                            "id": {
                                "_eq": authenticatedUser?.id
                            }
                        }
                    }
                }
            }
        }
    });

    useEffect(() => {
        const entity = entities?.filter((entity) => entity.id === watch('entity_id'))[0];
        if(entity){
            setValue('entity', {
                ...entity,
                user: entity.users.filter((user) => user.directus_users_id.id === authenticatedUser.id)[0]
            })
        }
    }, [watch('entity_id')]);

    useEffect(() => {
        const department = watch('entity')?.departments?.filter((department) => department.id === watch('department_id'))[0];
        if(department){
            setValue('department', department);
        }
    }, [watch('department_id')]);

    if (!entities) return null;

    return (
        <>
            <Stack spacing={5}>
                <Grid spacing={3} container>
                    <Grid xs={12} md={12} item>
                        <Typography>{t`Please insert the prevention plan name below:`}</Typography>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder={t`Name` + '*'}
                                    sx={commonStyles.textField}
                                    variant={"outlined"}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6}/>
                    <Grid xs={12} md={12} item>
                        <Typography>{t`Please insert the entity and department that the ppgrcd will be associated with:`}</Typography>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'entity_id'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel sx={{color: 'text.primary'}}
                                                id={'entity-label'}>{t`Entity`}*</InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Entity`}
                                        labelId={'entity-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            resetField('department');
                                        }}
                                        variant={'outlined'}
                                        fullWidth
                                        required
                                    >
                                        {entities?.map((entity, key) => (
                                            <MenuItem key={key} value={entity.id}>
                                                {entity.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'department_id'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl disabled={!watch('entity')} fullWidth>
                                    <InputLabel sx={{color: 'text.primary'}} id={'department-label'}>
                                        {t`Department`}*
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Department`}
                                        labelId={'department-label'}
                                        disabled={!watch('entity')}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        fullWidth
                                        required
                                    >
                                        {getValues('entity')?.departments?.map((department, key) => (
                                            <MenuItem key={key} value={department.id}>
                                                {department.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText
                                        error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <DirectusFeedback error={errorGetEntities} isLoading={isLoadingGetEntities}/>
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    }
    ,
    inputLabel: {
        fontWeight: 'bold'
    }
};

export default EntityDepartmentSelectorPpgrcdFormDetails;