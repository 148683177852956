import {Stack} from '@mui/material';
import {t} from 'ttag';
import * as React from 'react';

export const dataGridComponents = {
    NoRowsOverlay: () => (
        <Stack sx={styles.dataOverlay}>
            {t`No data`}
        </Stack>
    ),
    NoResultsOverlay: () => (
        <Stack sx={styles.dataOverlay}>
            {t`No data`}
        </Stack>
    )
};

const styles = {
    dataOverlay: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}