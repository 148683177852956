
export const substancesAndObjectsFormMapper = (substances) => {
    const formattedSubstances = [];

    substances?.forEach((substance) => {
        formattedSubstances.push({
            name: substance.name,
            base_unit: substance.base_unit,
            destination: substance.destination,
            insertion_date: substance.insertion_date,
            reuse_quantity: substance.reuse_quantity,
            reuse_quantity_percentage: substance.reuse_quantity_percentage
        });
    });

    return formattedSubstances;
};

export const usageMethodologyFormMapper = (residues) => {
    const formattedResidues = [];

    residues?.forEach((residue) => {
        formattedResidues.push({
            base_unit: residue.base_unit,
            ler_code: residue.ler_code,
            reuse_quantity: residue.reuse_quantity,
            reuse_quantity_percentage: residue.reuse_quantity_percentage,
            insertion_date: residue.insertion_date
        });
    });

    return formattedResidues;
};

export const incorporationMethodologyFormMapper = (residues) => {
    const formattedResidues = [];

    residues?.forEach((residue) => {
        formattedResidues.push({
            amount: residue.amount,
            amount_percentage: residue.amount_percentage,
            base_unit: residue.base_unit,
            name: residue.name,
            insertion_date: residue.insertion_date
        });
    });

    return formattedResidues;
};

export const productionAndOperationFormMapper = (residues) => {
    const formattedResidues = [];

    residues?.forEach((residue) => {
        formattedResidues.push({
            base_unit: residue.base_unit,
            disposal_amount: residue.disposal_amount,
            disposal_operation: residue.disposal_operation,
            ler_code: residue.ler_code,
            valorization_amount: residue.valorization_amount,
            valorization_operation: residue.valorization_operation,
            final_estimated_amount: residue.final_estimated_amount,
            insertion_date: residue.insertion_date
        });
    });

    return formattedResidues;
};

export const wasteManagementFormMapper = (residues) => {
    const formattedResidues = [];

    residues?.forEach((residue) => {
        formattedResidues.push({
            deposition: residue.deposition,
            description: residue.description,
            operator: residue.operator,
            other_costs: residue.other_costs,
            transportation: residue.transportation,
            insertion_date: residue.insertion_date
        });
    });

    return formattedResidues;
};

export const planClosureFormMapper = (egars) => {
    const formattedEgars = [];

    egars?.forEach((egar) => {
        formattedEgars.push({
            base_unit: egar.base_unit,
            amount: egar.amount,
            document_code: egar.document_code,
            ler_code: egar.ler_code,
            fiscal_number: egar.fiscal_number,
            operation: egar.operation,
            operator: egar.operator,
            transport: egar.transport,
            verification_code: egar.verification_code,
            insertion_date: egar.insertion_date
        });
    });

    return formattedEgars;
};