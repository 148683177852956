import React from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Box, Collapse, ListItemIcon, ListItemText, MenuItem, Typography} from '@mui/material';
import commonStyles from './common/styles/commonStyles';


interface Props {
    title: string,
    open: boolean,
    customIconLess?: React.ReactElement,
    customIconMore?: React.ReactElement
    onToggle: React.MouseEventHandler<HTMLDivElement>
    titleElement?: React.ReactElement
    children: React.ReactElement
}

export const Collapsable = ({open, title, titleElement, customIconLess, customIconMore, onToggle, children}: Props) => {
    return (
        <>
            <MenuItem sx={styles.menuItem} disableRipple>
                <Box sx={commonStyles.spaceBetween}>
                    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} onClick={onToggle}
                         gap={1}>
                        <ListItemText>
                            <Typography>{title}</Typography>
                        </ListItemText>
                        <ListItemIcon>
                            {open
                                ? customIconLess || <ExpandLess sx={styles.color}/>
                                : customIconMore || <ExpandMore sx={styles.color}/>
                            }
                        </ListItemIcon>
                    </Box>
                    {titleElement || <></>}
                </Box>
            </MenuItem>
            <Collapse in={open} timeout={'auto'} unmountOnExit>
                {children}
            </Collapse>
        </>
    );
};

const styles = {
    menuItem: {
        p: 0,
        pt: 1,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    listItemBtn: {
        color: 'text.secondary',
        px: 0
    },
    color: {
        color: 'text.secondary'
    }
};

export default Collapsable;