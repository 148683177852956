import {t} from 'ttag';
import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {KeyboardArrowRight} from '@mui/icons-material';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import commonStyles from '../../../components/common/styles/commonStyles';
import FormActions from '../../../components/common/forms/FormActions';
import {Service} from '../../../types';

interface Props {
    valorizationMatrixResults: Array<any>
    onReturn: () => void
    onSelectService: (service: Service) => void
}

export const ValorizationSuggestions = ({valorizationMatrixResults, onSelectService, onReturn}: Props) => {

    const {
        data: suggestedServicesPlatform,
        error: errorGetSuggestedServicesPlatform,
        isLoading: isLoadingGetSuggestedServicesPlatform
    } = useDirectusQuery({
        path: '/items/services_platform',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    'valorization_matrix_type': {'_in': valorizationMatrixResults || ['']}
                }
            }
        }
    });

    return (
        <>
            <Stack spacing={7} py={3} px={1}>
                <Stack spacing={4}>
                    <Typography fontWeight={'bold'}
                                fontSize={'1.1rem'}>{t`RECOMMENDED SERVICES FOR YOUR RESIDUE`}</Typography>
                    {Boolean(suggestedServicesPlatform?.length) ?
                        <Stack spacing={2}>
                            {suggestedServicesPlatform?.map((service, key) => (
                                <Box
                                    key={key} sx={{...commonStyles.card(), ...styles.typeWrapper}}
                                    onClick={() => onSelectService(service)}
                                >
                                    <Typography key={key} sx={styles.text}>{service.subtype_title}</Typography>
                                    <KeyboardArrowRight key={key}/>
                                </Box>
                            ))}
                        </Stack>
                        : <Typography>{t`There are no recommended services for your residue at the moment.`}</Typography>
                    }
                </Stack>
            </Stack>
            <Box display={'flex'} justifyContent={'flex-end'} pt={3}>
                <FormActions
                    cancelBtnText={t`RETURN`}
                    onCancel={onReturn}
                    submitAction={false}
                />
            </Box>
            <DirectusFeedback
                error={errorGetSuggestedServicesPlatform}
                isLoading={isLoadingGetSuggestedServicesPlatform}
            />
        </>
    );
};

const styles = {
    text: {
        color: 'text.secondary'
    },
    typeWrapper: {
        py: 1,
        px: 2,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '20rem',
        cursor: 'pointer'
    }
};

export default ValorizationSuggestions;