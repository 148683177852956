import {t} from 'ttag';
import React from 'react';


export const columns = () => [
    {
        field: 'description',
        headerName: t`DESIGNATION`,
        sortable: true,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({ row }) => row.ler_code
            ? `${row.ler_code?.code} - ${row.ler_code?.description}`
            : t`Byproduct` + ` - ${row.derived_designation}`
    }
];

export const helper = {
    columns
};

export default helper;
