import {t} from 'ttag';
import {Clear, Delete, PhotoCamera} from '@mui/icons-material';
import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Avatar, Box, Button, FormHelperText, Grid, IconButton, Stack, TextField, Typography} from '@mui/material';
import {UpdateUserFormSchema} from './forms/helpers/update-user-form-schema';
import {createFileFormData, fileToBase64} from '../../utils/forms/helpers';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import ProfileUserAvatar from '../../pages/user/ProfileUserAvatar';
import FormSelectorsRAA from '../common/forms/FormSelectorsRAA';
import DirectusFeedback from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import {actions, useStore} from '../../store/valtio';
import {User} from '../../types';

interface Props {
    user: User
}

export const UpdateUserForm = ({user}: Props) => {
    const {app: {configs}} = useStore();
    const [errorFileSize, setErrorFileSize] = useState(null);
    const [editable, setEditable] = useState(false);
    const [previewAvatar, setPreviewAvatar] = useState('');

    const {
        actions: {PATCH: updateUser},
        response: {error: errorUpdateUser, isLoading: isLoadingUpdateUser}
    } = useDirectusMutation({path: `users/me`});

    const {
        actions: {POST: uploadAvatar},
        response: {error: errorUploadAvatar, isLoading: isLoadingUploadAvatar}
    } = useDirectusMutation({path: '/files'});

    const defaultValues = useMemo(
        () => ({
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email: user?.email || '',
            address_id: {
                phone: user?.address_id?.phone || '',
                address: user?.address_id?.address || '',
                island: user?.address_id?.island || '',
                county: user?.address_id?.county || '',
                parish: user?.address_id?.parish || '',
            },
            avatar: user?.avatar || null
        }),
        [user]
    );

    const methods = useForm({
        resolver: yupResolver(UpdateUserFormSchema),
        defaultValues
    });

    useEffect(() => {
        if (user) {
            methods.reset(defaultValues);
        }
    }, [user, methods, defaultValues]);

    const invalidateUser = () => {
        actions.app.setInvalidatedUser(user);
        setPreviewAvatar('');
        setEditable(false);
    }

    const submitHandler = methods.handleSubmit((values) => {
        (values.avatar && !values.avatar.id)
            ? createFileFormData([values.avatar], (formData) =>
                uploadAvatar(formData, (avatar) => {
                    updateUser({
                        first_name: values.first_name,
                        last_name: values.last_name,
                        address_id: values.address_id,
                        avatar
                    }, invalidateUser)
            }), (error) => setErrorFileSize(error))
            : updateUser({
                first_name: values.first_name,
                last_name: values.last_name,
                address_id: values.address_id,
                avatar: null
            }, invalidateUser)
    });

    const removeAvatarHandler = () => {
        methods.setValue('avatar', null);
    };

    if (!configs) return null;

    return (
        <>
            <Stack alignItems={'flex-end'} spacing={2}>
                <FormProvider {...methods}>
                    <Grid justifyContent={'space-between'} gap={3} container>
                        <Grid sx={styles.photoGridItem} xs={12} md={12} lg={4} item>
                            <Stack alignItems={'center'} spacing={2}>
                                <Grid container>
                                    <Grid xs={12} md={2} item/>
                                    <Grid sx={commonStyles.centerContentAndItems} xs={12} md={8} item>
                                        {previewAvatar && methods.watch('avatar') ?
                                            <Avatar sx={styles.avatar} alt={"preview-avatar"} src={previewAvatar}/>
                                            : user.avatar && methods.watch('avatar')
                                                ? <ProfileUserAvatar avatar={user.avatar}/>
                                                : <Avatar sx={styles.avatar} alt={"default_avatar"}/>
                                        }
                                    </Grid>
                                    <Grid sx={commonStyles.centerContent} xs={12} md={2}>
                                        {methods.watch('avatar') && editable &&
                                            <IconButton sx={styles.removeAvatarBtn} disabled={!editable}>
                                                <Clear sx={styles.removeImgIcon} onClick={removeAvatarHandler}/>
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                                <Controller
                                    name={'avatar'}
                                    render={({field, fieldState: {error}}) => (
                                        <>
                                            <label htmlFor={'avatar'}>
                                                <input
                                                    id={'avatar'}
                                                    type={'file'}
                                                    disabled={!editable}
                                                    style={{position: 'fixed', top: '-100em'}}
                                                    onChange={({target: {files}}) => {
                                                        field.onChange(files[0]);
                                                        fileToBase64(files[0], (base64) =>
                                                            setPreviewAvatar(base64)
                                                        )
                                                    }}
                                                />
                                                <Stack display={'flex'} alignItems={'center'}>
                                                    <IconButton component={'span'} disabled={!editable}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <PhotoCamera/>
                                                            <Typography>{t`Upload photo`}</Typography>
                                                        </Box>
                                                    </IconButton>
                                                </Stack>
                                            </label>
                                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                        </>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={12} lg={7} display={'flex'} justifyContent={'flex-end'} item>
                            <Grid spacing={2} container>
                                <Grid xs={12} md={6} item>
                                    <Controller
                                        name={'first_name'}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                placeholder={t`First Name` + '*'}
                                                sx={commonStyles.textField}
                                                variant={"outlined"}
                                                disabled={!editable}
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Controller
                                        name={'last_name'}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                placeholder={t`Last Name` + '*'}
                                                sx={commonStyles.textField}
                                                variant={"outlined"}
                                                disabled={!editable}
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Controller
                                        name={'email'}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                placeholder={t`E-mail` + '*'}
                                                sx={commonStyles.textField}
                                                variant={"outlined"}
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Controller
                                        name={'address_id.phone'}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                type={'number'}
                                                error={Boolean(error)}
                                                sx={{...commonStyles.textField}}
                                                placeholder={t`Phone Number`}
                                                helperText={error?.message}
                                                variant={"outlined"}
                                                disabled={!editable}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={12} item>
                                    <Controller
                                        name={'address_id.address'}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                placeholder={t`Address` + '*'}
                                                sx={commonStyles.textField}
                                                variant={"outlined"}
                                                disabled={!editable}
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <FormSelectorsRAA editable={editable}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{width: 120}}
                        variant={'contained'}
                        onClick={editable ? submitHandler : () => setEditable(true)}
                    >
                        {editable ? t`SAVE` : t`EDIT`}
                    </Button>
                </FormProvider>
            </Stack>
            <DirectusFeedback
                error={errorUpdateUser || errorUploadAvatar || errorFileSize}
                isLoading={isLoadingUpdateUser || isLoadingUploadAvatar}
            />
        </>
    );
};

const styles = {
    avatar: {
        width: 110,
        height: 110,
        cursor: 'pointer'
    },
    photoGridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    uploadBtn: {
        "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
        }
    },
    removeAvatarBtn: {
        color: 'error.main',
        width: 24,
        height: 24
    },
    removeImgIcon: {
        cursor: 'pointer'
    }
};

export default UpdateUserForm;