import {t} from 'ttag';
import React from 'react';
import {CheckCircle} from '@mui/icons-material';
import {Box, Button, Stack, Typography} from '@mui/material';
import FormActions from '../../common/forms/FormActions';
import BasicCard from '../../common/cards/BasicCard';

interface Props {
    onAccept: React.MouseEventHandler<HTMLButtonElement>
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const AddedValueValorizationSteps = ({onReturn, onAccept}: Props) => {

    return (
        <Stack spacing={3}>
            <Stack spacing={3}>
                <BasicCard
                    headerContent={
                        <Box px={3}>
                            <Typography>{t`STEP 1 - PRE-TREATMENT`}</Typography>
                        </Box>
                    }
                >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} py={3}>
                        <Typography>{t`Reducing particle size`}</Typography>
                    </Box>
                </BasicCard>
                <BasicCard
                    headerContent={
                        <Box px={3}>
                            <Typography>{t`STEP 2 - PROCESSING`}</Typography>
                        </Box>
                    }
                >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} py={3}>
                        <Typography>{t`Mixture development`}</Typography>
                    </Box>
                </BasicCard>
                <BasicCard
                    headerContent={
                        <Box display={'flex'} justifyContent={'space-between'} px={3}>
                            <Typography>{t`STEP 3 - PRODUCT`}</Typography>
                        </Box>
                    }
                >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} py={3}>
                        <Typography>{t`Finished product`}</Typography>
                    </Box>
                </BasicCard>
            </Stack>
            <Stack spacing={2}>
                <Typography>{t`GENERAL INFO`}</Typography>
                <Box>
                    <Typography sx={styles.infoText}>{t`- Coating of vertical and horizontal surfaces on new substrates or renovation of existing substrates, including ceramic, tile or tile`}</Typography>
                    <Typography sx={styles.infoText}>{t`- Decoration with microcement for stores, offices, receptions, exhibition areas, homes, theme parks, entrances, bed headboards, individual walls or any place where a High Decoration effect is desired`}</Typography>
                    <Typography sx={styles.infoText}>{t`- Microcement coating of building furniture, bookcases, cabinets, special pieces`}</Typography>
                    <Typography sx={styles.infoText}>{t`- Ceramic coatings in bathrooms and kitchens`}</Typography>
                    <Typography sx={styles.infoText}>{t`- Not suitable for swimming pool or tank interiors without subsequent coating or application of waterproofing varnish to protect it and allow permanent damming of the water.`}</Typography>
                </Box>
            </Stack>
            <FormActions
                submitBtnText={t`ACCEPT PLAN`}
                cancelBtnText={t`RETURN`}
                onSubmit={onAccept}
                onCancel={onReturn}
            />
        </Stack>
    );
};

const styles = {
    box: {
        gap: 2,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    checkCircle: {
        fontSize: 25,
        color: 'success.main'
    },
    infoText: {
        fontSize: '.7rem'
    }
};

export default AddedValueValorizationSteps;