import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {RequestResetPasswordFormSchema} from './helpers/request-reset-password-form-schema';
import commonStyles from '../../common/styles/commonStyles';
import {emailIsValid} from '../../../utils/forms/helpers';

interface Props {
    onSubmitRequest: ({email: string}) => void
}

export const RequestResetPasswordForm = ({onSubmitRequest}: Props) => {

    const methods = useForm({
        resolver: yupResolver(RequestResetPasswordFormSchema),
        defaultValues: {
            email: ''
        }
    });

    const handleSubmitRequest = methods.handleSubmit((values) => {
        onSubmitRequest(values.email)
    });

    return (
        <FormProvider {...methods}>
            <Stack width={'100%'} spacing={3}>
                <Typography fontSize={'0.875rem'}>
                    {t`Please provide the email of the account you want to recover.`}
                </Typography>
                <Controller
                    name={'email'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Email` + ' *'}
                            sx={commonStyles.textField}
                            variant={'outlined'}
                            fullWidth
                            required
                        />
                    )}
                />
                <Button
                    variant={'contained'}
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmitRequest}
                    disabled={!emailIsValid(methods.watch('email'))}
                    fullWidth
                >
                    {t`Request Reset Password`}
                </Button>
            </Stack>
        </FormProvider>
    );
};

export default RequestResetPasswordForm;