import {t} from 'ttag';
import * as Yup from 'yup';


export const AddValueValorizationProcessStagesSchema = Yup.object().shape(
    {
        status: Yup.string().required(t`Required field.`),
        capacity: Yup.string().required(t`Required field.`),
        start_date: Yup.string().required(t`Required field.`),
        selected_department: Yup.object().when('capacity', {
            is: (capacity) => capacity === 'false',
            then: Yup.object().typeError(t`Required field.`).required(t`Required field.`)
        })
    }
).required();
