import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Controller, useFormContext} from 'react-hook-form';
import {Button, Grid, TextField, Typography} from '@mui/material';
import WasteManagementResiduesListForm from './WasteManagementResiduesListForm';
import commonStyles from '../../../common/styles/commonStyles';

interface ResidueDetails {
    id?: string
    description: string
    transportation: number,
    operator: number,
    deposition: number
    other_costs: number
}

interface Props {
    residues: ResidueDetails[]
    onAddResidue: () => void
    onEditResidue: (residueId: number) => void
    onSaveEditResidue: (callback: () => void) => void
    onRemoveResidue: (idx: number) => void
    readOnly: boolean
}

export const WasteManagementFormDetails = ({
   residues,
   onAddResidue,
   onEditResidue,
   onSaveEditResidue,
   onRemoveResidue,
   readOnly
}: Props) => {
    const {watch, resetField} = useFormContext();
    const [editingResidue, setEditingResidue] = useState(null);

    const handleFinishEditing = () => {
        setEditingResidue(null);
        resetField('ler_code');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('insertion_date');
    };

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <Typography>{t`Estimate of the financial costs of construction and demolition waste management, including transportation and delivery to a licensed operator or disposal at an authorized site.`}</Typography>
            </Grid>
            <Grid xs={12} md={12} item>
                <WasteManagementResiduesListForm
                    residues={residues}
                    onEditResidue={(residueId) => {
                        setEditingResidue(residueId);
                        onEditResidue(residueId);
                    }}
                    onDeleteResidue={onRemoveResidue}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'description'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Residue Description/Designation` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'transportation'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Transportation (€)` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Typography>{t`On-Destination Delivery`}</Typography>
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'operator'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Licensed Operator (€)` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'deposition'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Deposition (€)` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Typography>{t`Other Costs`}</Typography>
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'other_costs'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Other Costs (€)`}
                            error={Boolean(error)}
                            variant={"outlined"}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            disabled={readOnly}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingResidue
                            ? () => onSaveEditResidue(handleFinishEditing)
                            : () => onAddResidue()
                    }
                    disabled={
                        readOnly || (
                            !watch('description') ||
                            !watch('transportation') ||
                            !watch('operator') ||
                            !watch('deposition')
                        )
                    }
                    fullWidth
                >
                    {!editingResidue && <Add sx={styles.headerAddIcon}/>}
                    {editingResidue ? t`SAVE CHANGES` : t`Add Cost`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default WasteManagementFormDetails;