import * as React from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {AddDepartmentFormSchema} from './helpers/add-department-form-schema';
import DepartmentFormDetails from './create-department-form/DepartmentFormDetails';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import FormSkeleton from '../../../../common/forms/FormSkeleton';
import BasicCard from '../../../../common/cards/BasicCard';

interface Props {
    entityId: number,
    onSuccess: () => any
}

export const AddDepartmentForm = ({entityId, onSuccess}: Props) => {

    const {
        actions: {POST: addDepartment},
        response: {error, isLoading}
    } = useDirectusMutation({path: '/items/departments'});

    const methods = useForm({
        resolver: yupResolver(AddDepartmentFormSchema),
        defaultValues: {
            name: '',
            address_id: {
                island: '',
                parish: '',
                county: '',
                phone: '',
                email: ''
            },
            entity_id: entityId
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        addDepartment(values, onSuccess);
    });

    return (
        <BasicCard headerContent={<></>}>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<DepartmentFormDetails/>}
                    onCancel={() => methods.reset()}
                    onSubmit={submitHandler}
                    innerForm
                />
            </FormProvider>
            <DirectusFeedback error={error} isLoading={isLoading}/>
        </BasicCard>

    );
};

export default AddDepartmentForm;