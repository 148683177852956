import {t} from 'ttag';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormControlLabel, FormHelperText, Radio, RadioGroup} from '@mui/material';


export const EntityFormType = () => {
    const {resetField} = useFormContext();

    return (
        <Controller
            name={'is_company'}
            render={({field, fieldState: {error}}) => (
                <>
                    <RadioGroup
                        {...field}
                        onChange={(value) => {
                            field.onChange(value);
                            resetField('nipc');
                            resetField('nif');
                        }}
                        row
                    >
                        <FormControlLabel sx={styles.label} value={'true'} label={t`Yes`} control={<Radio/>}/>
                        <FormControlLabel sx={styles.label} value={'false'} label={t`No`} control={<Radio/>}/>
                    </RadioGroup>
                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                </>
            )}
        />
    );
};

const styles = {
    label: {
        color: 'text.primary'
    }
}

EntityFormType.propTypes = {};

export default EntityFormType;