import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import {columns} from './helpers/valorization-routes-list-helpers';
import DirectusFeedback from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import ConfirmDialog from '../common/ConfirmDialog';
import {dataGridComponents} from '../datagrid/dataGridComponents';

interface Props {
    valorizationRoutes: Array<any>
    onInvalidateValorizationRoutes: (valorizationRoutes: Array<any>) => void
}

export const ValorizationRoutesList = ({valorizationRoutes, onInvalidateValorizationRoutes}: Props) => {
    const [routeToDelete, setRouteToDelete] = useState(null);

    const {
        actions: {PATCH: updateValorizationRoute},
        response: {
            error: errorUpdateValorizationRoute,
            isLoading: isLoadingUpdateValorizationRoute
        }
    } = useDirectusMutation({path: '/items/valorization_routes'});

    const {
        actions: {DELETE: deleteValorizationRoute},
        response: {
            error: errorDeleteValorizationRoute,
            isLoading: isLoadingDeleteValorizationRoute
        }
    } = useDirectusMutation({path: `/items/valorization_routes/${routeToDelete?.id}`});

    const completeHandler = (route) => updateValorizationRoute(
        {status: 'COMPLETED'},
        () => onInvalidateValorizationRoutes(valorizationRoutes),
        route.id
    );

    const cancelHandler = (route) => updateValorizationRoute(
        {status: 'CANCELED'},
        () => onInvalidateValorizationRoutes(valorizationRoutes),
        route.id
    );

    const deleteHandler = (route) => setRouteToDelete(route);

    return (
        <>
            <DataGrid
                rows={valorizationRoutes || []}
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                components={dataGridComponents}
                rowCount={valorizationRoutes?.length || 0}
                columns={columns({completeHandler, cancelHandler, deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(routeToDelete)}
                title={t`Delete Valorization Plan`}
                onCancel={() => setRouteToDelete(null)}
                onConfirm={() => deleteValorizationRoute(() => {
                    onInvalidateValorizationRoutes(valorizationRoutes);
                    setRouteToDelete(null);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete valorization plan `+` ${routeToDelete?.id}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorDeleteValorizationRoute || errorUpdateValorizationRoute}
                isLoading={isLoadingDeleteValorizationRoute || isLoadingUpdateValorizationRoute}
            />
        </>
    );
};

export default ValorizationRoutesList;