import {t} from 'ttag';
import {useNavigate, useParams} from 'react-router-dom';
import React, {SyntheticEvent, useState} from 'react';
import {Box, Button, Divider, MenuList, Stack, Typography} from '@mui/material';
import ValorizationProcessesList from '../../../valorization-processes/ValorizationProcessesList';
import useDirectusQuery from '../../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../common/DirectusFeedback';
import TextCollapsable from '../../../common/TextCollapsable';
import {ValorizationProcess} from '../../../../types';
import ValorizationRoutesList from '../../../valorization-processes/ValorizationRoutesList';


export const ValorizationPlansTab = () => {
    const navigate = useNavigate();
    const {residueName, residueId} = useParams();
    const [openPlatformInfo, setOpenPlatformInfo] = useState(false);
    const [openWhatToExpect, setOpenWhatToExpect] = useState(false);
    const [openValorizationDefinition, setOpenValorizationDefinition] = useState(false);
    const [openValorizationAdvantages, setOpenValorizationAdvantages] = useState(false);
    const [invalidatedValorizationProcesses, setInvalidatedValorizationProcesses] = useState<ValorizationProcess[]>();
    const [invalidatedValorizationRoutes, setInvalidatedValorizationRoutes] = useState();
    const goToResidueValorizationPage = () => navigate(`/residues/${residueName}/${residueId}/simulate-valorization`);

    const {
        data: valorizationProcesses,
        error: errorGetValorizationProcesses,
        isLoading: isLoadingGetValorizationProcesses
    } = useDirectusQuery({
        path: '/items/valorization_process',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'residue_id': {'_eq': residueId}
                }
            },
            refetchOnChange: invalidatedValorizationProcesses
        }
    });
    const {
        data: valorizationRoutes,
        error: errorGetValorizationRoutes,
        isLoading: isLoadingGetValorizationRoutes
    } = useDirectusQuery({
        path: '/items/valorization_routes',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'residue_id': {'_eq': residueId}
                }
            },
            refetchOnChange: invalidatedValorizationRoutes
        }
    });

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <>
            <Stack spacing={3}>
                {valorizationProcesses?.length
                    ? <ValorizationProcessesList
                        valorizationProcesses={valorizationProcesses}
                        onInvalidateValorizationProcesses={(valorizationProcesses) => setInvalidatedValorizationProcesses(valorizationProcesses)}
                    />
                    : valorizationRoutes?.length
                        ? <ValorizationRoutesList
                            valorizationRoutes={valorizationRoutes}
                            onInvalidateValorizationRoutes={(valorizationRoutes) => setInvalidatedValorizationRoutes(valorizationRoutes)}
                        />
                        :
                        <>
                            <Typography color={'text.info'}>{t`No valorization plan associated.`}</Typography>
                            <Divider/>
                            <MenuList>
                                <Stack spacing={3}>
                                    <TextCollapsable
                                        open={openValorizationDefinition}
                                        title={t`What is residue valorization?`}
                                        content={t`"A waste is a raw material out of place". Waste recovery is the use of waste either for energy purposes or as a raw material to replace conventional materials.`}
                                        onToggle={e => handleMenuItemClick(
                                            e,
                                            () => setOpenValorizationDefinition(!openValorizationDefinition)
                                        )}
                                    />
                                    <TextCollapsable
                                        open={openValorizationAdvantages}
                                        title={t`What are the advantages of residue valorization?`}
                                        content={t`Waste recovery leads to several benefits, starting with saving resources by replacing more noble materials or less by reducing the costs associated with disposal. Additionally, recovering waste allows the life span of materials to be extended, contributing to circular economy processes. Last but not least, recovery, when well conducted, promotes the environmental sustainability of the sector, as it reduces the need for extraction of virgin raw materials.`}
                                        onToggle={e => handleMenuItemClick(
                                            e,
                                            () => setOpenValorizationAdvantages(!openValorizationAdvantages)
                                        )}
                                    />
                                    <TextCollapsable
                                        open={openPlatformInfo}
                                        title={t`What is the main goal of ReBuild 17?`}
                                        content={t`This platform intends to centralize the currently dispersed information related to construction and demolition waste and related by-products. In parallel, ReBuild 17 aims to foster synergistic relationships between the various stakeholders in the sector so that, together, innovative and sustainable solutions for the future of construction can be achieved.`}
                                        onToggle={e => handleMenuItemClick(
                                            e,
                                            () => setOpenPlatformInfo(!openPlatformInfo)
                                        )}
                                    />
                                    <TextCollapsable
                                        open={openWhatToExpect}
                                        title={t`What to expect from this simulation?`}
                                        content={t`The simulation will first determine the feasibility of establishing a recovery plan for your type of material. If this is possible, a recovery plan will then be calculated, showing the step-by-step and partners available for its fruitful realization. If it is determined that the ideal conditions for recovery are not met, the process for responsible disposal will be shown.`}
                                        onToggle={e => handleMenuItemClick(
                                            e,
                                            () => setOpenWhatToExpect(!openWhatToExpect)
                                        )}
                                    />
                                </Stack>
                            </MenuList>
                        </>
                }
                {(!valorizationProcesses?.length && !valorizationRoutes?.length) &&
                    <Box display={'flex'} justifyContent={'flex-end'} sx={{bottom: 0}}>
                        <Button sx={{maxWidth: '15rem'}} variant={'contained'}
                                onClick={goToResidueValorizationPage}>
                            {t`Simulate Valorization`}
                        </Button>
                    </Box>
                }
            </Stack>
            <DirectusFeedback
                error={errorGetValorizationProcesses || errorGetValorizationRoutes}
                isLoading={isLoadingGetValorizationProcesses || isLoadingGetValorizationRoutes}
            />
        </>
    );
};

export default ValorizationPlansTab;