import {t} from 'ttag';
import React from 'react';
import {DateTime as dt} from 'luxon';
import {Stack, Typography} from '@mui/material';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {FieldValues, FormProvider, useFieldArray} from 'react-hook-form';
import IncorporationMethodologyFormDetails
    from './incorportation-of-recyclates-forms/IncorporationMethodologyFormDetails';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import FormSkeleton from '../../common/forms/FormSkeleton';
import BasicCard from '../../common/cards/BasicCard';

interface Props {
    currentForm: string
    incorporationMethodologyFormMethods: UseFormReturn<FieldValues | any>
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const IncorporationOfRecyclatesForm = ({
  currentForm,
  incorporationMethodologyFormMethods,
  onSubmit,
  onReturn,
  readOnly
}: Props) => {
    const {getValues, resetField, setValue} = incorporationMethodologyFormMethods;
    const {fields: residues, append, replace} = useFieldArray({
        control: incorporationMethodologyFormMethods.control,
        name: 'residues'
    });

    const handleAddResidue = () => {
        append({
            name: getValues('name'),
            amount: getValues('amount'),
            base_unit: getValues('base_unit'),
            amount_percentage: getValues('amount_percentage'),
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('name');
        resetField('amount');
        resetField('base_unit');
        resetField('amount_percentage');
        resetField('insertion_date');
    }

    const handleEditResidue = (residueId) => {
        const wantedResidueToEdit = residues.filter((residue) => residue.id === residueId)[0];
        setValue('name', wantedResidueToEdit.name);
        setValue('amount', wantedResidueToEdit.amount);
        setValue('base_unit', wantedResidueToEdit.base_unit);
        setValue('amount_percentage', wantedResidueToEdit.amount_percentage);
        setValue('insertion_date', wantedResidueToEdit.insertion_date);
        handleRemoveResidue(residueId);
    }

    const handleSaveEditResidue = (callback: () => null) => {
        append({
            name: getValues('name'),
            amount: getValues('amount'),
            base_unit: getValues('base_unit'),
            amount_percentage: getValues('amount_percentage'),
            insertion_date: getValues('insertion_date')
        })
        callback();
    };

    const handleRemoveResidue = (residueId) => {
        let filteredResidues = residues.filter((residue) => residue.id !== residueId);
        replace(filteredResidues);
    }

    return (
        <>
            {(currentForm === 'All') || (currentForm === 'IncorporationMethodology') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`3. INCORPORATION OF RECYCLED MATERIALS`}
                        </Typography>
                    }
                >
                    <FormProvider {...incorporationMethodologyFormMethods}>
                        <Stack gap={3} py={2}>
                            <FormSkeleton
                                mainGroupContent={
                                    <IncorporationMethodologyFormDetails
                                        residues={residues}
                                        onAddResidue={handleAddResidue}
                                        onEditResidue={handleEditResidue}
                                        onRemoveResidue={handleRemoveResidue}
                                        onSaveEditResidue={handleSaveEditResidue}
                                        readOnly={readOnly}
                                    />
                                }
                                submitBtnText={t`Next`}
                                cancelBtnText={t`Return`}
                                onSubmit={onSubmit}
                                onCancel={onReturn}
                                actions={!readOnly}
                                innerForm
                            />
                        </Stack>
                    </FormProvider>
                </BasicCard>
            }</>
    );
};

export default IncorporationOfRecyclatesForm;