import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Stack, Typography} from '@mui/material';
import {AssignmentTurnedIn, Cancel, Comment, Download, Publish} from '@mui/icons-material';
import {PPGRCDsApiResponse} from '../../../components/entities/entity/deparment/ppgrcds/PPGRCDsList';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import {generatePpgrcdHtmlTemplate, PPGRCD_STATUS} from '../../../utils/ppgrcds/ppgrcds-utils';
import Participants from '../../../components/ppgrcds/ppgrcd/participants/Participants';
import useDirectusMutation from '../../../components/custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import commonStyles from '../../../components/common/styles/commonStyles';
import AuthorizationGuard from '../../common/guards/AuthorizationGuard';
import FormActions from '../../../components/common/forms/FormActions';
import BasicCard from '../../../components/common/cards/BasicCard';
import {PPGRCD_ROLES} from '../../../utils/user/user-utils';
import {PPGRCD as PpgrcdType} from '../../../types';
import {useStore} from '../../../store/valtio';
import ResumePPGRCD from './ResumePPGRCD';
import Compressor from 'compressorjs';

//TODO SEE IF LOCATIONS TAB GETS REMOVED PERMANENTLY
export const PPGRCD = () => {
    const {app: {user, configs}} = useStore();
    const navigate = useNavigate();
    const [invalidatedPpgrcd, setInvalidatedPpgrcd] = useState<PpgrcdType>();
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();

    const handleViewAllForms = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/All`);

    const {
        data: ppgrcd,
        error: errorGetPpgrcd,
        isLoading: isLoadingGetPpgrcd
    } = useDirectusQuery({
            path: `/items/prevention_plans/${ppgrcdId}`,
            options: {
                params: {
                    fields: '*.*.*.*.*'
                },
                refetchOnChange: invalidatedPpgrcd
            }
        }
    );

    const {
        data: preventionPlansUsers,
        error: errorGetPreventionPlansUsers,
        isLoading: isLoadingGetPreventionPlansUsers
    } = useDirectusQuery<PPGRCDsApiResponse>({
        path: '/items/prevention_plans_directus_users',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'directus_users_id': {
                        "_eq": user?.id
                    }
                }
            },
            refetchOnChange: user
        }
    });

    const {
        actions: {PATCH: updatePpgrcd},
        response: {
            error: errorUpdatePPGRCD,
            isLoading: isLoadingUpdatePPGRCD
        }
    } = useDirectusMutation({path: `/items/prevention_plans/${ppgrcdId}`});

    const {
        actions: {GET_ASSET: getImage},
        response: {error: errorUploadAvatar, isLoading: isLoadingUploadAvatar}
    } = useDirectusMutation({path: '/files'});

    const handlePublishPpgrcd = () => {
        updatePpgrcd({
            status: PPGRCD_STATUS.PUBLISHED
        }, () => setInvalidatedPpgrcd(ppgrcd))
    };

    const handleCancelPpgrcd = () => {
        updatePpgrcd({
            status: PPGRCD_STATUS.CANCELLED
        }, () => setInvalidatedPpgrcd(ppgrcd))
    };

    const handleCompletePPGRCD = () => {
        updatePpgrcd({
            status: PPGRCD_STATUS.COMPLETED
        }, () => setInvalidatedPpgrcd(ppgrcd))
    };

    const getAllImagesFromLocation = (location) => {
        return new Promise((resolve, reject) => {
            let locationImages = [];
            let processedImages = 0;

            location.shapefiles.forEach((file, _, imagesArray) => getImage(file, (base64) => {
                locationImages.push({
                    locationId: location.id,
                    imgBase64: base64
                });
                processedImages++;
                if (processedImages === imagesArray.length) {
                    resolve(locationImages)
                }
            }))
        });
    };

    const getAllLocationsImages = () => {
        return new Promise((resolve, reject) => {
            let locationsWithImages = [];
            let processedLocations = 0;

            ppgrcd?.form_work_general[0]?.locations?.forEach((location, _, locationsArray) => {
                getAllImagesFromLocation(location).then((locationImages) => {
                    locationsWithImages.push(locationImages)
                    processedLocations++;
                    if (processedLocations === locationsArray.length) {
                        resolve(locationsWithImages)
                    }
                });
            });
        });
    };

    const handleGeneratePpgrcdPdf = () => {
        getAllLocationsImages().then((locationsWithImages) => {
            generatePpgrcdHtmlTemplate(configs.app_auth_service_url, ppgrcd, locationsWithImages)
        })
    };

    if (!preventionPlansUsers || !ppgrcd) return null;

    return (
        <>
            <Stack spacing={6}>
                <Stack sx={commonStyles.pageWrapper}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box sx={commonStyles.centerContentAndItems} gap={1}>
                            <Typography sx={commonStyles.pageTitle}>
                                {ppgrcd?.name}
                            </Typography>
                            {ppgrcd.status === PPGRCD_STATUS.CANCELLED &&
                                <Typography sx={{...commonStyles.pageSubTitles, color: 'error.main'}}>
                                    ({t`Cancelled`})
                                </Typography>
                            }
                        </Box>
                        <Box display={'flex'} gap={2}>
                            {(ppgrcd.status === PPGRCD_STATUS.DRAFT) &&
                                <Button
                                    variant={'contained'}
                                    disabled={
                                        !(
                                            Boolean(ppgrcd.form_entity_responsible.length) &&
                                            Boolean(ppgrcd.form_work_general.length) &&
                                            Boolean(ppgrcd.form_work_specifics.length) &&
                                            Boolean(ppgrcd.form_prevention_methodology.length) &&
                                            Boolean(ppgrcd.form_substances_objects.length) &&
                                            Boolean(ppgrcd.form_usage_methodology.length) &&
                                            Boolean(ppgrcd.form_incorporation_methodology.length) &&
                                            Boolean(ppgrcd.form_soil_contamination.length) &&
                                            Boolean(ppgrcd.form_conditioning_sorting.length) &&
                                            Boolean(ppgrcd.form_production_operation.length) &&
                                            Boolean(ppgrcd.form_waste_management.length)
                                        )
                                    }
                                    onClick={handlePublishPpgrcd}
                                >
                                    <Publish sx={styles.btnIcon}/>
                                    {t`Publish`}
                                </Button>
                            }
                            <>
                                {ppgrcd.status !== PPGRCD_STATUS.DRAFT &&
                                    <Button
                                        variant={'contained'}
                                        onClick={handleGeneratePpgrcdPdf}
                                    >
                                        <Download sx={styles.btnIcon}/>
                                        {t`PDF`}
                                    </Button>
                                }
                                {ppgrcd.status === PPGRCD_STATUS.PUBLISHED &&
                                    <Button
                                        variant={'contained'}
                                        onClick={handleCompletePPGRCD}
                                    >
                                        <AssignmentTurnedIn sx={styles.btnIcon}/>
                                        {t`Complete`}
                                    </Button>
                                }
                            </>
                            <Button
                                variant={'outlined'}
                                onClick={() => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcd?.id}/comments`)}
                            >
                                <Comment sx={styles.btnIcon}/>
                                {t`Comments`}
                            </Button>
                        </Box>
                    </Box>
                    <NavigationBreadcrumbs
                        list={[
                            {name: t`Home`, link: '/'},
                            {name: t`Entities`, link: `/entities`},
                            {name: entityName, link: `/entities/${entityName}/${entityId}`},
                            {
                                name: departmentName,
                                link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`
                            },
                            {
                                name: ppgrcd?.name,
                                link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`
                            },
                        ]}
                    />
                </Stack>
                <Stack spacing={4}>
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography sx={commonStyles.pageSubTitles}>{t`Forms`}</Typography>
                        <AuthorizationGuard userHasSomeOf={[preventionPlansUsers[0], [PPGRCD_ROLES.FISCAL]]}>
                            <Button variant={'outlined'} onClick={handleViewAllForms}>{t`VIEW ALL`}</Button>
                        </AuthorizationGuard>
                    </Box>
                    <ResumePPGRCD ppgrcd={ppgrcd} authenticatedUser={preventionPlansUsers[0]}/>
                </Stack>
                <Stack spacing={4}>
                    <Typography sx={commonStyles.pageSubTitles}>{t`Participants`}</Typography>
                    <BasicCard headerContent={<></>}>
                        <Participants users={ppgrcd.users}/>
                    </BasicCard>
                </Stack>
                <FormActions
                    submitBtnText={t`SAVE`}
                    cancelBtnText={t`RETURN`}
                    onSubmit={() => null}
                    onCancel={() => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)}
                >
                    {ppgrcd.status === PPGRCD_STATUS.PUBLISHED &&
                        <Button
                            color={'error'}
                            variant={'contained'}
                            onClick={handleCancelPpgrcd}
                        >
                            <Cancel sx={styles.btnIcon}/>
                            {t`Cancel`}
                        </Button>
                    }
                </FormActions>
            </Stack>
            <DirectusFeedback
                error={errorUpdatePPGRCD || errorGetPreventionPlansUsers || errorGetPpgrcd}
                isLoading={isLoadingUpdatePPGRCD || isLoadingGetPreventionPlansUsers || isLoadingGetPpgrcd}
            />
        </>
    );
};

const styles = {
    btnIcon: {
        pr: 1,
        width: 17
    }
};

export default PPGRCD;