import {t} from 'ttag';
import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import {useNavigate} from 'react-router-dom';
import {Logout, Person, SearchRounded} from '@mui/icons-material';
import {Avatar, Box, FormControl, IconButton, ListItemIcon, MenuItem, Select, Tooltip} from '@mui/material';
import NotificationsMenu from '../common/notifications/NotificationsMenu';
import {changeLanguage, LOCALE_DEFAULT, LOCALES} from '../../config/i18n';
import commonStyles from '../common/styles/commonStyles';
import {actions, useStore} from '../../store/valtio';
import UserBadge from './header/UserBadge';
import {useDirectus} from 'react-directus';

const englandFlag = require('../../assets/images/Flag_of_the_United_Kingdom.png');
const portugueseFlag = require('../../assets/images/Flag_of_Portugal.png');

export const Header = () => {
    const {app: {user}} = useStore();
    const {directus} = useDirectus();
    const navigate = useNavigate();
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setIsLoadingLogout(true);
        directus.auth.logout()
            .then(() => window.location.reload())
            .finally(() => setIsLoadingLogout(false))
    }

    const handleChangeLanguage = (event) => {
        actions.app.setLanguage(event.target.value);
        changeLanguage(event.target.value);
        navigate('/');
        window.location.reload();
    };

    return (
        <>
            <Box display={'flex'} justifyContent={user ? 'space-between' : 'flex-end'} width={'100%'} py={5}>
                {user &&
                    <IconButton
                        size={'large'}
                        color={'inherit'}
                        aria-label={'search'}
                        sx={{mr: 2, boxShadow: 2}}
                        onClick={() => navigate('/search')}
                    >
                        <SearchRounded/>
                    </IconButton>
                }
                <Box display={'flex'} alignItems={'center'} gap={3}>
                    <FormControl size={'small'} variant={'outlined'}>
                        <Select
                            size={'small'}
                            label={'language'}
                            variant={'standard'}
                            value={LOCALE_DEFAULT}
                            onChange={handleChangeLanguage}
                        >
                            <MenuItem value={LOCALES.PT}>
                                <Box sx={commonStyles.centerContentAndItems} gap={2}>
                                    <Box sx={styles.flag(portugueseFlag)}/>
                                    <em>PT</em>
                                </Box>
                            </MenuItem>
                            <MenuItem value={LOCALES.EN}>
                                <Box sx={commonStyles.centerContentAndItems} gap={2}>
                                    <Box sx={styles.flag(englandFlag)}/>
                                    <em>EN</em>
                                </Box>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {user &&
                        <>
                            <NotificationsMenu/>
                            <Tooltip title={t`User`}>
                                <IconButton
                                    onClick={handleClick}
                                    size={'small'}
                                    sx={{ml: 2}}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    {user.avatar
                                        ? <UserBadge user={user}/>
                                        : <Avatar
                                            sx={styles.avatar}
                                            alt={'user-avatar-default'}
                                        />
                                    }
                                </IconButton>
                            </Tooltip>
                            <Menu
                                open={open}
                                anchorEl={anchorEl}
                                id={'account-menu'}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={styles.paperProps}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                <MenuItem onClick={() => navigate('/profile')}>
                                    <ListItemIcon>
                                        <Person fontSize={'small'}/>
                                    </ListItemIcon>
                                    {t`Profile`}
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <Logout fontSize={'small'}/>
                                    </ListItemIcon>
                                    {t`Logout`}
                                </MenuItem>
                            </Menu>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
        ;
};

const styles = {
    flag: (backgroundImg) => ({
        width: 35,
        height: 20,
        borderRadius: 1,
        cursor: 'pointer',
        backgroundImage: `url(${backgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }),
    avatar: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    },
    paperProps: {
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
    }
}

export default Header;
