import {t} from 'ttag';
import * as Yup from 'yup';


export const AddWantedResidueFormSchema = Yup.object().shape(
    {
        ler_code: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        entity_id: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        amount: Yup.number().required(t`Required field.`).typeError(t`Must be a valid number.`),
        amount_base_unit: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        contamination: Yup.string(),
        format: Yup.string()
    }
).required();
