import {DirectusFile} from '../../../../../types';

export const compilleConstructionSiteFiles = (files: []) => {
    const compilledFiles = [];

    files.forEach((file: DirectusFile) => compilledFiles.push({
        'construction_sites_id' : '+',
        'directus_files_id' : {'id': file.id}
    }));

    return compilledFiles;
};

export const compilleLocationFiles = (files: []) => {
    const compilledFiles = [];

    files?.forEach((file: DirectusFile) => compilledFiles.push({
        'directus_files_id' : {'id': file.id}
    }));

    return compilledFiles;
};