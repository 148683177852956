import {ResidueBaseUnit} from '../../types';

const {KILOGRAM, GRAM, LITER, METER, SQUARE_METER} = ResidueBaseUnit;

export const baseUnitFormatter = (baseUnit: string) => {
  switch (baseUnit) {
      case 'KILOGRAM':
        return KILOGRAM;
      case 'GRAM':
        return GRAM;
      case 'LITER':
        return LITER;
      case 'METER':
        return METER;
      case 'SQUARE_METER':
        return SQUARE_METER;
      default:
          return KILOGRAM
  }
};

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}