import {t} from 'ttag';
import * as Yup from 'yup';


export const AddServiceSuggestionFormSchema = Yup.object().shape(
    {
        service_name: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        description: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
