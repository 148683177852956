import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Stack, Typography} from '@mui/material';
import AddCommentForm from '../../../components/ppgrcds/ppgrcd/comments/forms/AddCommentForm';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import commonStyles from '../../../components/common/styles/commonStyles';
import FormActions from '../../../components/common/forms/FormActions';
import BasicCard from '../../../components/common/cards/BasicCard';
import CardDialog from '../../../components/common/CardDialog';
import {Comment} from '../../../components/common/Comment';
import {Comment as CommentType} from '../../../types';
import {useStore} from '../../../store/valtio';

interface CommentsApiResponse {
    data?: CommentType[],
    error?: Error,
    isLoading?: boolean
}

export const CommentsPPGRCD = () => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [invalidatedComments, setInvalidatedComments] = useState<CommentType>();
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();
    const [showAddCommentDialog, setShowAddCommentDialog] = useState(false);

    const {data: comments, error, isLoading} = useDirectusQuery<CommentsApiResponse>({
        path: `/items/prevention_plans_comments`,
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'prevention_plan_id': {
                        "_eq": ppgrcdId
                    }
                }
            },
            refetchOnChange: invalidatedComments
        }
    });

    const handleCreatedComment = () => {
        setShowAddCommentDialog(false);
        setInvalidatedComments(comments);
    }

    if (!user || !ppgrcdId || !comments) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={commonStyles.pageTitle}>PPGRCD {`${ppgrcdId}`}</Typography>
                    <Box display={'flex'} gap={2}>
                        <Button
                            variant={'contained'}
                            onClick={() => setShowAddCommentDialog(true)}
                        >
                            {t`Comment`}
                        </Button>
                    </Box>
                </Box>
                <BasicCard headerContent={<Typography px={3}>{t`COMMENTS`}</Typography>}>
                    {comments.length
                        ? <Stack spacing={2} p={2}>
                            {comments?.map((comment, key) => (
                                <Comment
                                    key={key}
                                    title={comment.title || ''}
                                    author={comment.user_id}
                                    comment={comment.comment}
                                    date={comment.date_created}
                                    inverted={comment.user_id.id === user.id}
                                />
                            ))}
                        </Stack>
                        : <Typography>{t`No comments yet.`}</Typography>
                    }
                </BasicCard>
                <FormActions
                    onCancel={() => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`)}
                    cancelBtnText={t`Return`}
                    submitAction={false}
                />
            </Stack>
            <CardDialog
                open={showAddCommentDialog}
                title={t`ADD COMMENT`}
                onClose={() => setShowAddCommentDialog(false)}
            >
                <AddCommentForm user={user} ppgrcdId={ppgrcdId} onCreated={handleCreatedComment}/>
            </CardDialog>
            <DirectusFeedback error={error} isLoading={isLoading}/>
        </>
    );
};

export default CommentsPPGRCD;