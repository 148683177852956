import {t} from 'ttag';
import React, {SyntheticEvent, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {AddCircle, RemoveCircle} from '@mui/icons-material';
import {Box, FormControlLabel, Grid, Radio, RadioGroup, TextField} from '@mui/material';
import commonStyles from '../../../common/styles/commonStyles';
import TextCollapsable from '../../../common/TextCollapsable';

interface Props {
    readOnly: boolean
}

export const ConditioningAndSortingFormDetails = ({readOnly}: Props) => {
    const {watch} = useFormContext();
    const [openMethodologyUsage, setOpenMethodologyUsage] = useState(true);
    const [openJustification, setOpenJustification] = useState(true);

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openMethodologyUsage}
                    title={t`Methods for packaging and sorting CDW at the construction site or at a site assigned to the construction site `}
                    content={t`The residues will mandatorily be object of sorting at the work site or at a place assigned to it, duly stored, taking into consideration the work front, the characteristics and quantities of the residues produced, a residue storage place being created.
The storage of dangerous residues will be carried out in closed containers, in a waterproof and covered place. 
The preliminary storage sites will be duly identified with the name of the waste for which they are intended and the respective EWL code. 
Waste transportation should always be accompanied by an e-GAR, except for waste used on the site itself. 
`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenMethodologyUsage(!openMethodologyUsage))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'conditioning_description'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            rows={3}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Description of the conditioning and sorting methodology` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            multiline
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openJustification}
                    title={t`Is screening on site or elsewhere planned?`}
                    content={t`In cases where it is not possible to carry out the sorting of CDW at the construction site or at a location assigned to the same, a fact that must be duly substantiated in the works book and in the CDW Prevention and Management Plan, the respective producer is responsible for forwarding them to a waste treatment operator.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenJustification(!openJustification))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                    titleElement={
                        <Controller
                            name={'condition_and_sorting'}
                            render={({field}) => (
                                <Box sx={commonStyles.flexEndContent}>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>} disabled={readOnly}/>
                                        <FormControlLabel value={'false'} label={t`No`} control={<Radio/>} disabled={readOnly}/>
                                    </RadioGroup>
                                </Box>
                            )}
                        />
                    }
                />
            </Grid>
            {watch('condition_and_sorting') === 'false' &&
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'justification'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                rows={3}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Reasoned justification in cases where it is not possible to perform the CDW sorting` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
            }
        </Grid>
    );
};

export default ConditioningAndSortingFormDetails;