import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Card, Stack, Typography} from '@mui/material';
import AuthorizationGuard from '../../../pages/common/guards/AuthorizationGuard';
import commonStyles from '../../common/styles/commonStyles';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';

const landingPageImg1 = require('../../../assets/images/landing-page-image-1.png');
const landingPageBackground1 = require('../../../assets/images/landing-page-card-background-1.png');


export const ResiduesCard = () => {
    const navigate = useNavigate();
    const {app: {user, isDesktop}} = useStore();

    if (!user) return null;

    return (
        <Card sx={{...commonStyles.card(), ...styles.card}}>
            <Box sx={styles.contentWrapper}>
                <Stack sx={styles.textWrapper}>
                    <Typography color={'common.white'} sx={styles.residuesTitle}>
                        {t`Searching/Got Residues?`}
                    </Typography>
                    <Typography color={'common.white'} sx={styles.residuesDescription}>
                        {t`Convert your residues to money and make everyone happier.`}
                    </Typography>
                    <Box display={'flex'} gap={2}>
                        <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                            <Button
                                variant={'contained'}
                                sx={styles.addResidueBtn}
                                onClick={() => navigate('/add-residue')}
                            >
                                {t`Add`}
                            </Button>
                        </AuthorizationGuard>
                        <Button
                            variant={'contained'}
                            sx={styles.addResidueBtn}
                            onClick={() => navigate('/search')}
                        >
                            {t`Search`}
                        </Button>
                    </Box>
                </Stack>
                {isDesktop &&
                    <Box>
                        <img width={100} src={landingPageImg1} alt={'monetize-residues'}/>
                    </Box>
                }
            </Box>
        </Card>
    );
};

const styles = {
    card: {
        backgroundImage: `url(${landingPageBackground1})`,
        backgroundSize: 'cover',
    },
    contentWrapper: {
        p: 5,
        gap: 3,
        display: 'flex',
        minHeight: '12rem',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textWrapper: {
        p: 0,
        gap: 2
    },
    residuesTitle: {
        fontSize: '1.8rem',
        fontWeight: 'bold'
    },
    residuesDescription: {
        fontSize: '0.875rem'
    },
    addResidueBtn: {
        px: 2,
        fontWeight: 'bold',
        color: 'text.secondary',
        backgroundColor: 'common.white',
        '&:hover': {
            backgroundColor: 'success.dark',
            color: 'common.white',
        },
    }

};

export default ResiduesCard;