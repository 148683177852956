import {t} from 'ttag';
import React from 'react';
import {Stack} from '@mui/material';
import {DateTime as dt} from 'luxon';
import {useFieldArray, useFormContext} from 'react-hook-form';
import PreventionMethodologyFormDetails from './prevention-methodology-form/PreventionMethodologyFormDetails';
import {DATETIME_FORMAT} from '../../../../utils/datetime/datetime-utils';
import FormSkeleton from '../../../common/forms/FormSkeleton';

interface Props {
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    onReturn: React.MouseEventHandler<HTMLButtonElement>
    readOnly: boolean
}

export const PreventionMethodologyForm = ({onSubmit, onReturn, readOnly}: Props) => {
    const {getValues, setValue, resetField} = useFormContext();
    const {fields: residues, append, replace} = useFieldArray({
        name: 'residues'
    });

    const handleAddResidue = () => {
        append({
            name: getValues('name'),
            reuse_quantity: getValues('reuse_quantity'),
            base_unit: getValues('base_unit'),
            reuse_quantity_percentage: getValues('reuse_quantity_percentage'),
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('name');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('insertion_date');
    };

    const handleEditResidue = (residueId) => {
        const wantedResidueToEdit = residues.filter((residue) => residue.id === residueId)[0];
        setValue('name', wantedResidueToEdit.name);
        setValue('reuse_quantity', wantedResidueToEdit.reuse_quantity);
        setValue('base_unit', wantedResidueToEdit.base_unit);
        setValue('reuse_quantity_percentage', wantedResidueToEdit.reuse_quantity_percentage);
        setValue('insertion_date', wantedResidueToEdit.insertion_date);
        handleRemoveResidue(residueId);
    }

    const handleSaveEditResidue = (callback: () => null) => {
        append({
            name: getValues('name'),
            reuse_quantity: getValues('reuse_quantity'),
            base_unit: getValues('base_unit'),
            reuse_quantity_percentage: getValues('reuse_quantity_percentage'),
            insertion_date: getValues('insertion_date'),
        })
        callback();
    };

    const handleRemoveResidue = (residueId) => {
        let filteredResidues = residues.filter((residue) => residue.id !== residueId);
        replace(filteredResidues);
    };

    return (
        <Stack gap={3} py={2}>
            <FormSkeleton
                mainGroupContent={
                    <PreventionMethodologyFormDetails
                        residues={residues}
                        onAddResidue={handleAddResidue}
                        onEditResidue={handleEditResidue}
                        onRemoveResidue={handleRemoveResidue}
                        onSaveEditResidue={handleSaveEditResidue}
                        readOnly={readOnly}
                    />
                }
                submitBtnText={t`Next`}
                cancelBtnText={t`Return`}
                onSubmit={onSubmit}
                onCancel={onReturn}
                actions={!readOnly}
                innerForm
            />
        </Stack>

    );
};

export default PreventionMethodologyForm;