import {t} from 'ttag';
import React, {useState} from 'react';
import {Check, Close, FiberManualRecord, Notifications} from '@mui/icons-material';
import {Badge, Box, Divider, IconButton, Menu, MenuItem, Switch, Tooltip, Typography} from '@mui/material';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../DirectusFeedback';
import commonStyles from '../styles/commonStyles';
import {useStore} from '../../../store/valtio';

interface Props {
}

export const NotificationsMenu = ({}: Props) => {
    const {app: {user}} = useStore();
    const [invalidatedInviteNotifications, setInvalidatedInviteNotifications] = useState(false);
    const [allowEmailNotifications, setAllowEmailNotifications] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const {data: invitesNotifications} = useDirectusQuery({
            path: 'items/entities_email_invites', options: {
                params: {
                    fields: '*.*',
                    filter: {
                        'status': {'_eq': 'PENDING'},
                        'email': {'_eq': user?.email}
                    }
                },
                refetchOnChange: invalidatedInviteNotifications
            }
        }
    );

    const {
        actions: {PATCH: updateUser},
        response: {
            error: errorUpdateUser,
            isLoading: isLoadingUpdateUser
        }
    } = useDirectusMutation({path: 'users/me'});

    const {
        actions: {PATCH: rejectInvite},
        response: {
            error: errorRejectInvite,
            isLoading: isLoadingRejectInvite
        }
    } = useDirectusMutation({path: 'items/entities_email_invites'});

    const {
        actions: {POST: acceptInvite},
        response: {
            error: errorAcceptInvite,
            isLoading: isLoadingAcceptInvite
        }
    } = useDirectusMutation({path: 'rb17_api/entity_invite/accept'});

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRejectInvite = (inviteId: number) => {
        rejectInvite({status: 'REJECTED'}, () => {
            setInvalidatedInviteNotifications(invitesNotifications);
        }, inviteId);
    };

    const handleAcceptInvite = (invite: object) => {
        acceptInvite({
            email_user: user.email,
            directus_user_id: user.id,
            entity_id: invite.entity_id.id
        }, () => {
            setInvalidatedInviteNotifications(invitesNotifications);
        });
    };

    const handleChangeAllowEmailNotifications = ({target: {checked}}) => {
        updateUser({
            email_notifications: checked
        }, () => setAllowEmailNotifications(checked))
    };

    return (
        <>
            <Tooltip title={t`Notifications`}>
                <Badge
                    max={10}
                    color={'error'}
                    sx={{cursor: 'pointer'}}
                    badgeContent={invitesNotifications?.length}
                    onClick={handleClick}
                >
                    <Notifications sx={{width: 32, height: 32}}/>
                </Badge>
            </Tooltip>
            <Menu
                open={open}
                id={'account-menu'}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem sx={{cursor: 'unset'}} value={''}>
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography>{t`Notifications`}</Typography>
                        <Box sx={commonStyles.centerContentAndItems}>
                            <Typography color={allowEmailNotifications ? 'common.black' : 'text.disabled'}>
                                {t`Emails`}
                            </Typography>
                            <Switch
                                sx={styles.switch}
                                checked={allowEmailNotifications}
                                onChange={handleChangeAllowEmailNotifications}
                                inputProps={{ 'aria-label': 'controlled' }}
                                size={'small'}
                            />
                        </Box>
                    </Box>
                </MenuItem>
                <Divider/>
                {
                    invitesNotifications?.length ?
                        <>
                            {
                                invitesNotifications?.map((invite, key) => (
                                    <Box key={key} sx={commonStyles.spaceBetween}>
                                        <Box sx={{
                                            ...commonStyles.flexStartContent,
                                            alignItems: 'center',
                                            px: 2
                                        }}>
                                            <FiberManualRecord sx={{width: 10, height: 10}} color={'error'}/>
                                            <MenuItem sx={{cursor: 'unset'}} key={key} value={invite.id}>
                                                {`${invite?.user_created?.first_name} ${invite?.user_created?.last_name} ` + t`invited you to join` + ` ${invite?.entity_id?.name}`}
                                            </MenuItem>
                                        </Box>
                                        <Box sx={commonStyles.centerContentAndItems} gap={1} pr={1}>
                                            <IconButton color={'success'} onClick={() => handleAcceptInvite(invite)}>
                                                <Check sx={{width: 20, height: 20}}/>
                                            </IconButton>
                                            <IconButton color={'error'}
                                                        onClick={() => handleRejectInvite(invite.id)}>
                                                <Close sx={{width: 20, height: 20}}/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </>
                        :
                        <Typography p={2}>{t`No notifications at the moment.`}</Typography>
                }
            </Menu>
            <DirectusFeedback
                error={errorAcceptInvite || errorRejectInvite || errorUpdateUser}
                 isLoading={isLoadingAcceptInvite || isLoadingRejectInvite || isLoadingUpdateUser}
            />
        </>
    );
};

const styles = {
    switch: {
        ".Mui-checked .MuiSwitch-thumb": {
            backgroundColor: 'black'
        },
        ".css-1qe1eln-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: 'black'
        }
    }
}

export default NotificationsMenu;