import React from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Box, Collapse, List, ListItemIcon, ListItemText, MenuItem, Typography} from '@mui/material';
import commonStyles from './styles/commonStyles';

interface Props {
    title: string,
    content: string,
    hyperlink?: string
    open: boolean,
    customIconLess?: React.ReactElement,
    customIconMore?: React.ReactElement
    onToggle: React.MouseEventHandler<HTMLDivElement>
    titleElement?: React.ReactElement
}

export const TextCollapsable = ({open, title, titleElement, content, hyperlink, customIconLess, customIconMore, onToggle}: Props) => {
    return (
        <>
            <MenuItem sx={styles.menuItem} disableRipple>
                <Box sx={commonStyles.spaceBetween}>
                    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} onClick={onToggle}
                         gap={1}>
                        <ListItemText>
                            <Typography fontSize={'1.1rem'}>{title}</Typography>
                        </ListItemText>
                        <ListItemIcon>
                            {open
                                ? customIconLess || <ExpandLess sx={styles.color}/>
                                : customIconMore || <ExpandMore sx={styles.color}/>
                            }
                        </ListItemIcon>
                    </Box>
                    {titleElement || <></>}
                </Box>
            </MenuItem>
            <Collapse in={open} timeout={'auto'} unmountOnExit>
                <List>
                    <ListItemText>
                        <Typography align={'justify'} fontSize={'0.9rem'}>{
                            content.split("\n").map((i, key) => {
                                return <p key={key}>{i}</p>;
                            })
                        }</Typography>
                        {hyperlink && <a href={hyperlink} target={'_blank'}>{hyperlink}</a>}
                    </ListItemText>
                </List>
            </Collapse>
        </>
    );
};

const styles = {
    menuItem: {
        p: 0,
        pt: 1,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    listItemBtn: {
        color: 'text.secondary',
        px: 0
    },
    color: {
        color: 'text.secondary'
    }
};

export default TextCollapsable;