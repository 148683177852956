import {t} from 'ttag';
import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import {Box, Typography} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import commonStyles from '../../../common/styles/commonStyles';
import {Cancel, CheckCircle, ContactMail, Delete} from '@mui/icons-material';

interface Props {
    onComplete: () => void
    onCancel: () => void
    onDelete: () => void
    disabled?: boolean
}

export const ValorizationProcessesListSubMenu = ({onComplete, onCancel, onDelete, disabled = false}: Props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id={"long-button"}
                aria-label={"more"}
                aria-haspopup={"true"}
                aria-expanded={open ? 'true' : undefined}
                aria-controls={open ? 'long-menu' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                open={open}
                id={"long-menu"}
                PaperProps={styles.paperProps}
                MenuListProps={{'aria-labelledby': 'long-button'}}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem disabled={disabled} onClick={onComplete}>
                    <Box display={'flex'} gap={1}>
                        <CheckCircle color={'success'} sx={{paddingLeft: '3px', width: 20}}/>
                        <Typography color={'text.secondary'}>{t`Complete`}</Typography>
                    </Box>
                </MenuItem>
                <MenuItem disabled={disabled} onClick={onCancel}>
                    <Box display={'flex'} gap={1}>
                        <Cancel color={'error'} sx={{paddingLeft: '3px', width: 20}}/>
                        <Typography color={'text.secondary'}>{t`Cancel`}</Typography>
                    </Box>
                </MenuItem>
                <MenuItem onClick={onDelete}>
                    <Box display={'flex'} gap={1}>
                        <Delete color={'error'}/>
                        <Typography color={'error.main'}>{t`Delete`}</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </div>
    );
}

const styles = {
    paperProps: {
        style: {
            minWidth: '5rem',
            borderRadius: 10
        }
    },
}

export default ValorizationProcessesListSubMenu;