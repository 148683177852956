import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate, useParams} from 'react-router-dom';
import useDirectusMutation from '../../../custom-hooks/useDirectusMutation';
import {commonStyles} from '../../../common/styles/commonStyles';
import DirectusFeedback from '../../../common/DirectusFeedback';
import {columns} from './helpers/collaborators-list-helpers';
import ConfirmDialog from '../../../common/ConfirmDialog';
import {Collaborator} from '../../../../types';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface Props {
    collaborators: Collaborator[]
    pattern?: string
    onInvalidatedCollaborators: () => any
}

export const CollaboratorsList = ({collaborators, pattern, onInvalidatedCollaborators}: Props) => {
    const navigate = useNavigate();
    const {entityName, entityId} = useParams();
    const [collaboratorToDelete, setCollaboratorToDelete] = useState<Collaborator>(null);

    const collaboratorUrl = ({directus_users_id: {first_name, last_name}, id: collaboratorId}) =>
        `/entities/${entityName}/${entityId}/collaborators/${first_name} ${last_name}/${collaboratorId}`;

    const editHandler = (collaborator) => navigate(collaboratorUrl(collaborator));
    const viewHandler = (collaborator) => navigate(collaboratorUrl(collaborator));
    const deleteHandler = (collaborator) => setCollaboratorToDelete(collaborator);

    const {
        actions: {DELETE: deleteCollaborator},
        response: {
            error: errorDeleteDepartment,
            isLoading: isLoadingDeleteDepartment
        }
    } = useDirectusMutation({path: `/items/collaborators/${collaboratorToDelete?.id}`});

    const filteredCollaborators = pattern
        ? collaborators?.filter((collaborator) =>
            (collaborator.directus_users_id.first_name.includes(pattern) || collaborator.directus_users_id.last_name.includes(pattern))
        )
        : collaborators;

    if (!filteredCollaborators) return null;

    return (
        <>
            <DataGrid
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                components={dataGridComponents}
                rows={filteredCollaborators || []}
                rowCount={filteredCollaborators?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(collaboratorToDelete)}
                title={t`Delete Collaborator`}
                onCancel={() => setCollaboratorToDelete(null)}
                onConfirm={() => {
                    deleteCollaborator(onInvalidatedCollaborators);
                    setCollaboratorToDelete(null);
                }}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${collaboratorToDelete?.directus_users_id.first_name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback error={errorDeleteDepartment} isLoading={isLoadingDeleteDepartment}/>
        </>
    );
};

CollaboratorsList.defaultProps = {
    filterBy: 'all'
};

export default CollaboratorsList;