import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {Checkbox, FormControl, FormHelperText, Grid, InputLabel, ListItemIcon, MenuItem, Select} from '@mui/material';
import {AddResidualOperatorServiceFormSchema} from './schemas/add-residual-operator-service-form-schema';
import FormActions from '../../../../../../common/forms/FormActions';
import {useStore} from '../../../../../../../store/valtio';

interface Props {
    onSubmit: (values: object) => any,
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const ResidualOperatorServiceForm = ({onSubmit, onReturn}: Props) => {
    const {app: {lerCodes, valorizationOperations, eliminationOperations}} = useStore();

    const methods = useForm({
        resolver: yupResolver(AddResidualOperatorServiceFormSchema),
        defaultValues: {
            ler_code: '',
            d_operations: [],
            r_operations: []
        }
    });

    const submitHandler = methods.handleSubmit((values) => onSubmit(values));

    const ITEM_HEIGHT = 46;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        variant: "menu"
    };

    if (!lerCodes || !valorizationOperations || !eliminationOperations) return null;

    return (
        <FormProvider {...methods}>
            <Grid spacing={4} container>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'ler_code'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={styles.inputLabel} id={'lerCode-label'}
                                            required>{t`Residue Type (Ler Code)`}</InputLabel>
                                <Select
                                    {...field}
                                    label={t`Residue Type (Ler Code)`}
                                    labelId={'lerCode-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {lerCodes?.map((ler, key) => (
                                        ler.is_header
                                            ?
                                            <MenuItem
                                                key={key}
                                                value={ler.id}
                                                sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                                disabled
                                            >
                                                {`${ler.code} - ${ler.description}`}
                                            </MenuItem>
                                            :
                                            <MenuItem key={key} value={ler.id}>
                                                {`${ler.code} - ${ler.description}`}
                                            </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item/>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'d_operations'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={{color: 'text.primary'}} id={'disposal-operation-label'}>
                                    {t`Disposal Operations (D's)`}
                                </InputLabel>
                                <Select
                                    {...field}
                                    label={t`Disposal Operations (D's)`}
                                    labelId={'disposal-operation-label'}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={({target: {value}}) => methods.setValue('d_operations', value)}
                                    error={Boolean(error)}
                                    MenuProps={MenuProps}
                                    multiple
                                >
                                    {eliminationOperations.map((op, key) => (
                                        <MenuItem key={key} value={op.id}>
                                            <ListItemIcon>
                                                <Checkbox checked={methods.getValues('d_operations').includes(op.id)}/>
                                            </ListItemIcon>
                                            <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                {`${op.code} - ${op.description}`}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'r_operations'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={{color: 'text.primary'}} id={'valorization-operation-label'}>
                                    {t`Valorization Operations (R's)`}
                                </InputLabel>
                                <Select
                                    {...field}
                                    label={t`Valorization Operations (R's)`}
                                    labelId={'valorization-operation-label'}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={({target: {value}}) => methods.setValue('r_operations', value)}
                                    error={Boolean(error)}
                                    MenuProps={MenuProps}
                                    multiple
                                >
                                    {valorizationOperations.map((op, key) => (
                                        <MenuItem key={key} value={op.id}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={methods.getValues('r_operations').includes(op.id)}/>
                                            </ListItemIcon>
                                            <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                {`${op.code} - ${op.description}`}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
            </Grid>
            <FormActions
                submitBtnText={t`Add`}
                cancelBtnText={t`Return`}
                onSubmit={submitHandler}
                onCancel={onReturn}
            />
        </FormProvider>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    subGroup: {
        py: 1,
        px: 3,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'background.gray'
    },
    subGroupTilte: {
        color: 'text.primary'
    },
    text: {
        color: 'text.secondary'
    },
    formControl: {
        // ellipsis overflow
        textOverflow: 'ellipsis'
    },
    indeterminateColor: {
        color: 'success.main'
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
};

export default ResidualOperatorServiceForm;