import {t} from 'ttag';
import * as React from 'react';
import {Stack, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddWantedResidueForm from '../../components/residues/forms/AddWantedResidueForm';
import commonStyles from '../../components/common/styles/commonStyles';


export const AddWantedResidue = () => {
    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD WANTED RESIDUE`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Residues`, link: '/residues'},
                    {name: t`Add Wanted Residue`, link: '/add-wanted-residue'}
                ]}
            />
            <AddWantedResidueForm/>
        </Stack>
    );
};

export default AddWantedResidue;