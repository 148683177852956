import {t} from 'ttag';
import {DateTime as dt} from 'luxon';
import React, {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Chip, Stack, Typography} from '@mui/material';
import AddedValueValorizationTreatment
    from '../../../components/valorization-processes/added-value-valorization/AddedValueValorizationTreatment';
import AddedValueValorizationSteps
    from '../../../components/valorization-processes/added-value-valorization/AddedValueValorizationSteps';
import AddedValueValorizationProcessing
    from '../../../components/valorization-processes/added-value-valorization/AddedValueValorizationProcessing';
import AddedValueValorizationProduct
    from '../../../components/valorization-processes/added-value-valorization/AddedValueValorizationProduct';
import AddedValueValorizationResume
    from '../../../components/valorization-processes/added-value-valorization/AddedValueValorizationResume';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import commonStyles from '../../../components/common/styles/commonStyles';
import {
    AddValueValorizationProcessStagesSchema
} from '../../../components/valorization-processes/added-value-valorization/schemas/add-value-valorization-process-stages-schema';
import useDirectusMutation from '../../../components/custom-hooks/useDirectusMutation';
import {DATE_FORMAT, DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import ValorizationType from './ValorizationType';


export const SimulateValorization = () => {
    const navigate = useNavigate();
    const {residueName, residueId, currentStepParam, valorizationProcessIdParam} = useParams();
    const [valorizationProcessId, setValorizationProcessId] = useState(valorizationProcessIdParam || '');
    const [currentStep, setCurrentStep] = useState(currentStepParam || '');

    const valorizationTreatmentMethods = useForm({
        resolver: yupResolver(AddValueValorizationProcessStagesSchema),
        defaultValues: {
            status: 'ONGOING',
            capacity: 'true',
            start_date: dt.now().toFormat(DATETIME_FORMAT),
            selected_department: {}
        }
    });

    const valorizationProcessingMethods = useForm({
        resolver: yupResolver(AddValueValorizationProcessStagesSchema),
        defaultValues: {
            status: 'ONGOING',
            capacity: 'true',
            start_date: dt.now().toFormat(DATETIME_FORMAT),
            selected_department: {}
        }
    });

    const valorizationProductMethods = useForm({
        resolver: yupResolver(AddValueValorizationProcessStagesSchema),
        defaultValues: {
            status: 'ONGOING',
            capacity: 'true',
            start_date: dt.now().toFormat(DATETIME_FORMAT),
            selected_department: {}
        }
    });

    const {
        actions: {POST: createValorizationProcess},
        response: {
            error: errorCreateValorizationProcess,
            isLoading: isLoadingCreateValorizationProcess
        }
    } = useDirectusMutation({path: '/items/valorization_processes'});

    const {
        actions: {POST: submitValorizationStage},
        response: {
            error: errorSubmitValorizationStage,
            isLoading: isLoadingSubmitValorizationStage
        }
    } = useDirectusMutation({path: '/items/valorization_processes_stages'});

    const handleCreateValorizationProcess = () => {
        createValorizationProcess({
            status: 'ONGOING',
            product_type: 'whatever',
            start_date: dt.now().toFormat(DATETIME_FORMAT),
            current_step: '1',
            total_steps: '3',
            residues: [residueId]
        }, ({id}) => {
            setValorizationProcessId(id);
            setCurrentStep('1');
        });
    };

    const submitValorizationTreatmentHandler = valorizationTreatmentMethods.handleSubmit((values) => {
        setCurrentStep('2');
    });

    const submitValorizationProcessingHandler = valorizationProcessingMethods.handleSubmit((values) => {
        setCurrentStep('3');
    });

    const submitValorizationProductHandler = valorizationProductMethods.handleSubmit((values) => {
        submitValorizationProcessStages();
    });

    const submitValorizationProcessStages = () => {
        submitValorizationStage({
                ...valorizationTreatmentMethods.getValues(),
                selected_department_id: valorizationTreatmentMethods.getValues('capacity') === 'true'
                    ? null
                    : valorizationTreatmentMethods.getValues('selected_department').id,
                valorization_process_id: valorizationProcessId
            },
            () => submitValorizationStage({
                    ...valorizationProcessingMethods.getValues(),
                    selected_department_id: valorizationProcessingMethods.getValues('capacity') === 'true'
                        ? null
                        : valorizationProcessingMethods.getValues('selected_department').id,
                    valorization_process_id: valorizationProcessId
                }, () => submitValorizationStage({
                        ...valorizationProductMethods.getValues(),
                        selected_department_id: valorizationProductMethods.getValues('capacity') === 'true'
                            ? null
                            : valorizationProductMethods.getValues('selected_department').id,
                        valorization_process_id: valorizationProcessId
                    },
                    () => setCurrentStep('completed')
                )
            ));
    };

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Typography sx={commonStyles.pageTitle}>{t`Valorization Plan`}</Typography>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Residues`, link: `/residues`},
                        {name: residueName, link: `/residues/${residueName}/${residueId}`},
                        {
                            name: t`Simulate Valorization`,
                            link: `/residues/${residueName}/${residueId}/simulate-valorization`
                        }
                    ]}
                />
                {!currentStep &&
                    <ValorizationType/>
                }
                {currentStep === 'stepsList' &&
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} gap={3}>
                            <Box display={'flex'} gap={1}>
                                <Typography fontWeight={'bold'}>{`START`}</Typography>
                                <Typography>{dt.now().toFormat(DATE_FORMAT)}</Typography>
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <Typography fontWeight={'bold'}>{`END`}</Typography>
                                <Typography>{'(' + t`Ongoing` + ')'}</Typography>
                            </Box>
                        </Box>
                        <Chip size={'medium'} label={t`Ongoing`}/>
                    </Box>
                }
                {currentStep === 'stepsList' &&
                    <AddedValueValorizationSteps
                        onReturn={() => navigate(`/residues/${residueName}/${residueId}/simulate-valorization`)}
                        onAccept={handleCreateValorizationProcess}/>
                }
                {currentStep === '1' &&
                    <FormProvider {...valorizationTreatmentMethods}>
                        <AddedValueValorizationTreatment
                            onReturn={() => setCurrentStep('')}
                            onFinish={submitValorizationTreatmentHandler}
                        />
                    </FormProvider>
                }
                {currentStep === '2' &&
                    <FormProvider {...valorizationProcessingMethods}>
                        <AddedValueValorizationProcessing
                            onReturn={() => setCurrentStep('1')}
                            onFinish={submitValorizationProcessingHandler}
                        />
                    </FormProvider>
                }
                {currentStep === '3' &&
                    <FormProvider {...valorizationProductMethods}>
                        <AddedValueValorizationProduct
                            onReturn={() => setCurrentStep('2')}
                            onFinish={submitValorizationProductHandler}
                        />
                    </FormProvider>
                }
                {currentStep === 'completed' && <AddedValueValorizationResume valorizationProcessId={valorizationProcessId}/>}
            </Stack>
            <DirectusFeedback
                error={errorCreateValorizationProcess || errorSubmitValorizationStage}
                isLoading={isLoadingCreateValorizationProcess || isLoadingSubmitValorizationStage}
            />
        </>
    );
};


export default SimulateValorization;