import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import ServicesList from '../../../../../components/entities/entity/deparment/services/ServicesList';
import PatternSearch from '../../../../../components/common/pattern-search/PatternSearch';
import AuthorizationGuard from '../../../../common/guards/AuthorizationGuard';
import {APP_ROLES} from '../../../../../utils/user/user-utils';
import {useStore} from '../../../../../store/valtio';
import {Service} from '../../../../../types';

interface Props {
    services: Service[]
    onInvalidateServices: () => void
}

export const Services = ({services, onInvalidateServices}: Props) => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const {entityName, entityId, departmentName, departmentId} = useParams();
    const [searchPattern, setSearchPattern] = useState(null);
    const onAddService = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/services/add-service`);

    return (
        <Stack spacing={2}>
            <Box sx={styles.box}>
                <PatternSearch onChange={(searchPattern) => setSearchPattern(searchPattern)}/>
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={onAddService}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Service`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <ServicesList services={services} pattern={searchPattern} onInvalidateServices={onInvalidateServices}/>
        </Stack>

    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export default Services;