import {t} from 'ttag';
import * as Yup from 'yup';


export const AddResidualOperatorServiceFormSchema = Yup.object().shape(
    {
        ler_code: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        r_operations: Yup.array().typeError(t`Required field.`).required(t`Required field.`),
        d_operations: Yup.array().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
