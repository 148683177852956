import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate, useParams} from 'react-router-dom';
import {columns} from './helpers/services-list-helpers';
import commonStyles from '../../../../common/styles/commonStyles';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import {Service} from '../../../../../types';
import {dataGridComponents} from '../../../../datagrid/dataGridComponents';

interface Props {
    services?: Service[]
    pattern?: string
    onInvalidateServices: () => void
}

export const ServicesList = ({services, pattern, onInvalidateServices}: Props) => {
    const navigate = useNavigate();
    const {entityName, entityId, departmentName, departmentId} = useParams();
    const [serviceToDelete, setServiceToDelete] = useState(null);

    const serviceUrl = (service) =>
        `\`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/services/{${service?.name}/${service?.id}`;

    const editHandler = (service) => navigate(serviceUrl(service));
    const viewHandler = (service) => navigate(serviceUrl(service));
    const deleteHandler = (service) => setServiceToDelete(service);

    const {
        actions: {DELETE: deleteService},
        response: {
            error: errorDeleteService,
            isLoading: isLoadingDeleteService
        }
    } = useDirectusMutation({path: `/items/services/${serviceToDelete?.id}`});

    return (
        <>
            <DataGrid
                rows={services}
                sx={commonStyles.datagrid}
                components={dataGridComponents}
                getRowId={(row) => row.id}
                rowCount={services?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(serviceToDelete)}
                title={t`Delete Service`}
                onCancel={() => setServiceToDelete(null)}
                onConfirm={() => deleteService(() => {
                    setServiceToDelete(null);
                    onInvalidateServices();
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${serviceToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback error={errorDeleteService} isLoading={isLoadingDeleteService}/>
        </>
    );
};

export default ServicesList;