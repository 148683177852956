import {t} from 'ttag';
import {Controller, useFormContext} from 'react-hook-form';
import React, {SyntheticEvent, useState} from 'react';
import {
    Box,
    Button,
    FormControl, FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {Add, AddCircle, RemoveCircle} from '@mui/icons-material';
import commonStyles from '../../../../common/styles/commonStyles';
import UsageResiduesListForm from './UsageResiduesListForm';
import TextCollapsable from '../../../../common/TextCollapsable';
import {useStore} from '../../../../../store/valtio';
import {LerCode} from '../../../../../types';

interface ResidueDetails {
    id?: string
    ler_code: LerCode
    reuse_quantity: string
    base_unit: string
    reuse_quantity_percentage: string
}

interface Props {
    residues: ResidueDetails[]
    onAddResidue: () => void
    onEditResidue: (residueId: number) => void
    onSaveEditResidue: (callback: () => void) => void
    onRemoveResidue: (idx: number) => void
    readOnly: boolean
}

export const UsageMethodologyFormDetails = ({
                                                residues,
                                                onAddResidue,
                                                onEditResidue,
                                                onSaveEditResidue,
                                                onRemoveResidue,
                                                readOnly
                                            }: Props) => {
    const {app: {lerCodes}} = useStore();
    const {watch, getValues, resetField} = useFormContext();
    const [editingResidue, setEditingResidue] = useState(null);
    const [openUsedWaste, setOpenUsedWaste] = useState(true);
    const [openMethodologyUsage, setOpenMethodologyUsage] = useState(true);
    const reuseQuantityPercentageCondition = watch('reuse_quantity_percentage') && (parseInt(getValues('reuse_quantity_percentage')) <= 100);

    const handleFinishEditing = () => {
        setEditingResidue(null);
        resetField('ler_code');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('insertion_date');
    };

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    };

    if (!lerCodes) return null;

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openMethodologyUsage}
                    title={t`Methodology for the use of CDW`}
                    content={t`Explain which wastes are to be used in the project and work or in others according to the general rules in force. Refer to the general rules applicable, for which purpose they will be used and which operations apply.  The general rules published are at `}
                    hyperlink={'https://www.apambiente.pt/index.php/residuos/regras-gerais'}
                    onToggle={e => handleMenuItemClick(e, () => setOpenMethodologyUsage(!openMethodologyUsage))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'methodology_description'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            rows={3}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Description of the usage methodology` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            multiline
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openUsedWaste}
                    title={t`Waste used in project / work`}
                    content={t`Include the target waste in the general rules to be used in the project / work in question or others. Identify the work of origin - name and address.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenUsedWaste(!openUsedWaste))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <UsageResiduesListForm
                    residues={residues}
                    onEditResidue={(residueId) => {
                        setEditingResidue(residueId);
                        onEditResidue(residueId);
                    }}
                    onDeleteResidue={onRemoveResidue}
                    lerCodes={lerCodes}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'ler_code'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel sx={styles.inputLabel} id={'lerCode-label'} disabled={readOnly}
                                        required>{t`Ler Code`}</InputLabel>
                            <Select
                                {...field}
                                label={t`Ler Code`}
                                labelId={'lerCode-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={readOnly}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {lerCodes?.map((ler, key) => (
                                    ler.is_header
                                        ?
                                        <MenuItem
                                            key={key}
                                            value={ler.id}
                                            sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                            disabled
                                        >
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                        :
                                        <MenuItem key={key} value={ler.id}>
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item/>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'reuse_quantity'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Quantity to be reused` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                <FormControlLabel value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'reuse_quantity_percentage'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <>
                                <TextField
                                    {...field}
                                    sx={commonStyles.textField}
                                    helperText={error?.message}
                                    placeholder={t`Quantity to be reused in relation to the total (%)` + '*'}
                                    error={Boolean(error)}
                                    variant={'outlined'}
                                    disabled={readOnly}
                                    fullWidth
                                    required
                                />
                                {!reuseQuantityPercentageCondition &&
                                    <FormHelperText error={!reuseQuantityPercentageCondition}>
                                        {t`The quantity to be reused must be 100% or less`}
                                    </FormHelperText>
                                }
                            </>
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingResidue
                            ? () => onSaveEditResidue(handleFinishEditing)
                            : () => onAddResidue()
                    }
                    disabled={
                        readOnly || (
                            !watch('ler_code') ||
                            !watch('reuse_quantity') ||
                            !watch('base_unit') ||
                            (!watch('reuse_quantity_percentage') ||
                                parseInt(getValues('reuse_quantity_percentage')) > 100 ||
                                parseInt(getValues('reuse_quantity_percentage')) <= 0
                            )
                        )
                    }
                    fullWidth
                >
                    {!editingResidue && <Add sx={styles.headerAddIcon}/>}
                    {editingResidue ? t`SAVE CHANGES` : t`Add Residue`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default UsageMethodologyFormDetails;