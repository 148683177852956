import * as React from 'react';
import {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './helpers/plan-closure-egars-form-list-helpers';
import commonStyles from '../../../common/styles/commonStyles';
import {Egar} from '../../../../types';
import {t} from 'ttag';
import {useStore} from '../../../../store/valtio';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface Props {
    egars?: Egar[]
    isEditing?: boolean
    onDeleteEgar: (egarId) => void
    onEditEgar: (egarId) => void
    readOnly: boolean
}

export const PlanClosureEgarsListForm = ({egars, isEditing, onEditEgar, onDeleteEgar, readOnly}: Props) => {
    const {app: {lerCodes}} = useStore();
    const [pageSize, setPageSize] = useState(5);
    const deleteHandler = (egar) => onDeleteEgar(egar.document_code);
    const editHandler = (egar) => onEditEgar(egar.id);

    if (!egars || !lerCodes) return null;

    return (
        <DataGrid
            rows={egars}
            pageSize={pageSize}
            sx={commonStyles.datagrid}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5, 15, 25]}
            components={dataGridComponents}
            rowCount={egars?.length || 0}
            columns={columns({lerCodes, isEditing, editHandler, deleteHandler, readOnly})}
            onPageSizeChange={(size) => setPageSize(size)}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: 'insertion_date',
                            sort: 'desc',
                        },
                    ],
                },
            }}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t`Rows per page`,
                }
            }}
            disableExtendRowFullWidth
            autoHeight
        />
    );
};

export default PlanClosureEgarsListForm;