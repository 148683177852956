import {t} from 'ttag';
import React from 'react';
import {Stack, Typography} from '@mui/material';
import {createDatetime, DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import SubMenu from '../../../../common/grid/SubMenu';


export const columns = ({ editHandler, deleteHandler, readOnly }) => [
  {
    field: 'address',
    headerName: t`ADDRESS`,
    disableColumnMenu: true,
    flex: 4,
    renderCell: ({ row }) => row.address_id.address
  },
  {
    field: 'island',
    headerName: t`ISLAND`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id.island
  },
  {
    field: 'county',
    headerName: t`COUNTY`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id.county
  },
  {
    field: 'parish',
    headerName: t`PARISH`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id.parish
  },
  {
    field: 'insertion_date',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <SubMenu
            disabled={readOnly}
            onDelete={() => deleteHandler(row)}
            onEdit={() => editHandler(row)}
        />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
