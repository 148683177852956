import axios from 'axios';
import React, {useEffect} from 'react';
import {actions, useStore} from './store/valtio';
import {configLocal} from './mocks';

interface Props {
    children: React.ReactElement
}

export const ConfigsProvider = ({children}: Props) => {
    const {app: {configs}} = useStore();

    useEffect(() => {
        if (!configs) {
            axios.get(`${window.location.href}config.json`).then(res => {
                actions.app.setConfigs(res.data);
            });
            // actions.app.setConfigs(configLocal);
        }
    }, [configs]);

    if(!configs) return null;

    return children;
};

export default ConfigsProvider;