import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../../common/grid/SubMenu';


export const columns = ({ editHandler, deleteHandler }) => [
  {
    field: 'name',
    headerName: t`DESIGNATION`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'user_created',
    headerName: t`WORK OWNER`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => `${row.user_created.first_name} ${row.user_created.last_name}`
  },
  {
    field: 'status',
    headerName: t`STATUS`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => (
        row.status === 'PUBLISHED'
            ? t`Published`
            : row.status === 'DRAFT'
                ? t`Draft`
                : row.status === 'CANCELLED'
                  ? t`Cancelled`
                    : row.status === 'COMPLETED'
                    ? t`Completed` : '-'

    )
  },
  {
    field: 'actions',
    headerName: '',
    type: 'number',
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => {
      return (
        <SubMenu
            onEdit={() => editHandler(row)}
            onDelete={() => deleteHandler(row)}
        />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
