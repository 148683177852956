import {t} from 'ttag';
import React from 'react';


export const columns = () => [
    {
        field: 'designation',
        headerName: t`DESIGNATION`,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({row}) =>
            row?.form_work_general[0]?.name
                ? row?.form_work_general[0]?.name
                : '-'
    }
];

export const helper = {
    columns
};

export default helper;
