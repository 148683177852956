import {t} from 'ttag';
import React, {useEffect, useMemo, useState} from 'react';
import {Delete} from '@mui/icons-material';
import {TabContext, TabPanel} from '@mui/lab';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Box, Button, Stack, Tab, Typography} from '@mui/material';
import {
    UpdateDepartmentFormSchema
} from '../../../../components/entities/entity/deparment/forms/helpers/update-department-form-schema';
import DepartmentFormDetails
    from '../../../../components/entities/entity/deparment/forms/create-department-form/DepartmentFormDetails';
import NavigationBreadcrumbs from '../../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import useDirectusMutation from '../../../../components/custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../../components/common/DirectusFeedback';
import commonStyles from '../../../../components/common/styles/commonStyles';
import FormSkeleton from '../../../../components/common/forms/FormSkeleton';
import ConfirmDialog from '../../../../components/common/ConfirmDialog';
import BasicCard from '../../../../components/common/cards/BasicCard';
import {Department as DepartmentType} from '../../../../types';
import {CustomizedTabs} from '../../CustomizedTabs';
import {useStore} from '../../../../store/valtio';
import Services from './services/Services';
import PPGRCDs from './ppgrcds/PPGRCDs';

interface DepartmentsApiResponse {
    data?: DepartmentType[]
    error?: Error
    isLoading?: boolean
}

export const Department = () => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [tab, setTab] = useState('1');
    const [editable, setEditable] = useState(false);
    const [invalidatedDepartment, setInvalidatedDepartment] = useState<DepartmentType>();
    const [showDeleteDepartmentDialog, setShowDeleteDepartmentDialog] = useState(false);
    const {entityName, entityId, departmentName, departmentId} = useParams();

    const {
        data: department,
        error: errorGetDepartment,
        isLoading: isLoadingGetDepartment
    } = useDirectusQuery<DepartmentsApiResponse>({
        path: `/items/departments/${departmentId}`,
        options: {
            params: {
                fields: '*.*.*.*'
            },
            refetchOnChange: invalidatedDepartment
        }
    });

    const {
        actions: {DELETE: deleteDepartment},
        response: {
            error: errorDeleteDepartment,
            isLoading: isLoadingDeleteDepartment
        }
    } = useDirectusMutation({path: `/items/departments/${departmentId}`});

    const {
        actions: {PATCH: updateDepartment},
        response: {
            error: errorUpdateDepartment,
            isLoading: isLoadingUpdateDepartment
        }
    } = useDirectusMutation({path: `/items/departments/${departmentId}`});

    const defaultValues = useMemo(
        () => ({
            name: department?.name || '',
            address_id: {
                address: department?.address_id?.address || '',
                island: department?.address_id?.island || '',
                parish: department?.address_id?.parish || '',
                county: department?.address_id?.county || '',
                phone: department?.address_id?.phone || '',
                email: department?.address_id?.email || ''
            }
        }),
        [department]
    );

    const methods = useForm({
        resolver: yupResolver(UpdateDepartmentFormSchema),
        defaultValues
    });

    useEffect(() => {
        if (department) {
            methods.reset(defaultValues);
        }
    }, [department, methods, defaultValues]);

    const invalidateDepartment = () => {
        setInvalidatedDepartment(department);
        setEditable(false);
    }

    const updateHandler = methods.handleSubmit((values) => {
        updateDepartment(values, invalidateDepartment)
    });

    if (!department || !user) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={commonStyles.pageTitle}>{department?.name}</Typography>
                    <Box display={'flex'} gap={2}>
                        <Button
                            size={'small'}
                            sx={styles.removeBtn}
                            variant={'contained'}
                            onClick={() => setShowDeleteDepartmentDialog(true)}
                        >
                            <Delete sx={styles.btnIcon}/>
                            {t`Delete`}
                        </Button>
                    </Box>
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Entities`, link: '/entities'},
                        {name: entityName, link: `/entities/${entityName}/${entityId}`},
                        {
                            name: departmentName,
                            link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`
                        }
                    ]}
                />
                <BasicCard
                    headerContent={
                        <CustomizedTabs value={tab} onChange={(_, value) => setTab(value)}>
                            <Tab disableRipple label={t`Info`} value={'1'}/>
                            <Tab disableRipple label={t`PPGRCDs`} value={'2'}/>
                            <Tab disableRipple label={t`Services`} value={'3'}/>
                        </CustomizedTabs>
                    }
                >
                    <TabContext value={tab}>
                        <FormProvider {...methods}>
                            <TabContext value={tab}>
                                <TabPanel sx={{p: 1}} value={'1'}>
                                    <FormSkeleton
                                        mainGroupContent={<DepartmentFormDetails editable={editable}/>}
                                        onSubmit={editable ? updateHandler : () => setEditable(true)}
                                        submitBtnText={editable ? t`SAVE` : t`EDIT`}
                                        cancelAction={false}
                                        innerForm
                                    />
                                </TabPanel>
                                <TabPanel sx={{p: 1}} value={'2'}>
                                    <Stack spacing={3}>
                                        <PPGRCDs department={department} authenticatedUser={user}/>
                                    </Stack>
                                </TabPanel>
                                <TabPanel sx={{p: 1}} value={'3'}>
                                    <Stack spacing={3}>
                                        <Services
                                            services={department?.services}
                                            onInvalidateServices={() => setInvalidatedDepartment(department)}
                                        />
                                    </Stack>
                                </TabPanel>
                            </TabContext>
                        </FormProvider>
                    </TabContext>
                </BasicCard>
            </Stack>
            <ConfirmDialog
                open={showDeleteDepartmentDialog}
                title={t`Delete Department`}
                onCancel={() => setShowDeleteDepartmentDialog(false)}
                onConfirm={() => deleteDepartment(() => {
                    setShowDeleteDepartmentDialog(false);
                    navigate(`/entities/${entityName}/${entityId}`);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${department?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetDepartment || errorDeleteDepartment || errorUpdateDepartment}
                isLoading={isLoadingGetDepartment || isLoadingDeleteDepartment || isLoadingUpdateDepartment}
            />
        </>
    );
};

const styles = {
    removeBtn: {
        backgroundColor: 'error.main',
        "&.MuiButtonBase-root:hover": {
            bgcolor: "error.main"
        }
    },
    btnIcon: {
        pr: 1,
        width: 17
    }
};

export default Department;