import React from 'react';
import {Avatar} from '@mui/material';
import useDirectusAssets from '../../custom-hooks/useDirectusAssets';
import DirectusFeedback from '../../common/DirectusFeedback';
import {ImageApiResponse, User} from '../../../types';

interface Props {
    user: User
}

export const UserBadge = ({user}: Props) => {

    const {
        data: userAvatar,
        error: errorGetUserAvatar,
        isLoading: isLoadingGetUserAvatar
    } = useDirectusAssets<ImageApiResponse>({
        path: `/assets/${user?.avatar?.id}`,
    });

    return (
        <>
            {userAvatar ?
                <Avatar
                    src={userAvatar}
                    sx={styles.avatar}
                    alt={'user-avatar'}
                />
                : <Avatar
                    sx={styles.avatar}
                    alt={'user-avatar'}
                />
            }
            <DirectusFeedback error={errorGetUserAvatar} isLoading={isLoadingGetUserAvatar}/>
        </>
    );
};

const styles = {
    avatar: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    }
}

export default UserBadge;