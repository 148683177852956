import React from 'react';
import {Typography} from '@mui/material';

interface Props {
    children: string
}

export const JustifiedTypography = ({children}: Props) => {

    return (
        <Typography align={'justify'} fontSize={'0.9rem'}>{
            children.split("\n").map((i, key) => {
                return <p key={key}>{i}</p>;
            })
        }</Typography>
    );
};

export default JustifiedTypography;