import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './substances-list-helpers';
import commonStyles from '../../../../../common/styles/commonStyles';
import {t} from 'ttag';
import {dataGridComponents} from '../../../../../datagrid/dataGridComponents';

interface Substance {

}

interface Props {
    substances?: Substance[]
    isEditing: boolean
    onEditSubstance: (substanceId) => void
    onDeleteSubstance: (substanceId) => void
    readOnly: boolean
}

export const SubstancesListForm = ({substances, isEditing, onEditSubstance, onDeleteSubstance, readOnly}: Props) => {
    const deleteHandler = (substance) => onDeleteSubstance(substance.id);
    const editHandler = (substance) => onEditSubstance(substance.id);

    if (!substances) return null;

    return (
        <DataGrid
            rows={substances}
            sx={commonStyles.datagrid}
            getRowId={(row) => row.id}
            components={dataGridComponents}
            rowCount={substances?.length || 0}
            columns={columns({isEditing, editHandler, deleteHandler, readOnly})}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: 'insertion_date',
                            sort: 'desc',
                        },
                    ],
                },
            }}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t`Rows per page`
                }
            }}
            disableExtendRowFullWidth
            autoHeight
        />
    );
};

export default SubstancesListForm;