import {t} from 'ttag';
import React from 'react';
import {CheckCircle} from '@mui/icons-material';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Stack, Typography} from '@mui/material';
import commonStyles from '../../../components/common/styles/commonStyles';
import AuthorizationGuard from '../../common/guards/AuthorizationGuard';
import AccordionCard from '../../../components/common/AccordionCard';
import {PPGRCD_STATUS} from '../../../utils/ppgrcds/ppgrcds-utils';
import {PPGRCD_ROLES} from '../../../utils/user/user-utils';
import {PPGRCD, User} from '../../../types';

interface Props {
    ppgrcd: PPGRCD
    authenticatedUser: User
}

export const ResumePPGRCD = ({ppgrcd, authenticatedUser}: Props) => {
    const navigate = useNavigate();
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();
    const ppgrcdFinalizedStatus = [PPGRCD_STATUS.COMPLETED, PPGRCD_STATUS.CANCELLED];

    const capther1Completed = ppgrcd.form_entity_responsible.length && ppgrcd.form_work_general.length && ppgrcd.form_work_specifics.length;
    const capther2Completed = ppgrcd.form_prevention_methodology.length && ppgrcd.form_substances_objects.length && ppgrcd.form_usage_methodology.length;
    const allPpgrcdForms = [
        ppgrcd.form_entity_responsible,
        ppgrcd.form_work_general,
        ppgrcd.form_work_specifics,
        ppgrcd.form_prevention_methodology,
        ppgrcd.form_substances_objects,
        ppgrcd.form_usage_methodology,
        ppgrcd.form_incorporation_methodology,
        ppgrcd.form_soil_contamination,
        ppgrcd.form_conditioning_sorting,
        ppgrcd.form_production_operation,
        ppgrcd.form_waste_management,
        ppgrcd.form_plan_closure
    ];

    const nextFormIndex = allPpgrcdForms.findIndex(element => !element.length);

    const handleGoToEntityResponsibleForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/EntityResponsibleForm`);
    const handleGoToWorkGeneralForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/WorkGeneralForm`);
    const handleGoToWorkSpecificsForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/WorkSpecificsForm`);
    const handleGoToPreventionMethodologyForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/PreventionMethodology`);
    const handleGoToSubstancesAndObjectsForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/SubstancesAndObjects`);
    const handleGoToUsageMethodologyForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/UsageMethodology`);
    const handleGoToIncorporationMethodologyForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/IncorporationMethodology`);
    const handleGoToSoilContaminationForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/SoilContamination`);
    const handleGoToConditioningAndSortingForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/ConditioningAndSorting`);
    const handleGoToProductionAndOperationForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/ProductionAndOperation`);
    const handleGoToWasteManagementForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/WasteManagement`);
    const handleGoToPlanClosureForm = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}/PlanClosure`);

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <AccordionCard
                    title={t`1 - GENERAL ENTITY/WORK DATA`}
                    summaryChildren={Boolean(capther1Completed) ? <CheckCircle sx={styles.checkCircle}/> : <></>}
                >
                    <Stack spacing={2}>
                        <Box sx={commonStyles.spaceBetween}>
                            <Typography sx={styles.topics}>{t`1.1 - ENTITY GENERAL DATA`}</Typography>
                            <AuthorizationGuard
                                userHasSomeOf={[authenticatedUser, [
                                    PPGRCD_ROLES.OWNER,
                                    PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                    PPGRCD_ROLES.DRAFTSMAN,
                                    PPGRCD_ROLES.CONTRACTOR
                                ]]}>
                                <>
                                    {Boolean(ppgrcd.form_entity_responsible.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                        <Button variant={'outlined'} onClick={handleGoToEntityResponsibleForm}>
                                            {t`EDIT`}
                                        </Button>
                                    }
                                    {nextFormIndex === 0 &&
                                        <Button variant={'outlined'} onClick={handleGoToEntityResponsibleForm}>
                                            {t`FILL IN`}
                                        </Button>
                                    }
                                </>
                            </AuthorizationGuard>
                        </Box>
                        <Box sx={commonStyles.spaceBetween}>
                            <Typography sx={styles.topics}>{t`1.2 - WORK GENERAL DATA`}</Typography>
                            <AuthorizationGuard
                                userHasSomeOf={[authenticatedUser, [
                                    PPGRCD_ROLES.OWNER,
                                    PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                    PPGRCD_ROLES.DRAFTSMAN,
                                    PPGRCD_ROLES.CONTRACTOR
                                ]]}>
                                <>
                                    {Boolean(ppgrcd.form_work_general.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                        <Button variant={'outlined'} onClick={handleGoToWorkGeneralForm}>
                                            {t`EDIT`}
                                        </Button>
                                    }
                                    {nextFormIndex === 1 &&
                                        <Button variant={'outlined'} onClick={handleGoToWorkGeneralForm}>
                                            {t`FILL IN`}
                                        </Button>
                                    }
                                </>
                            </AuthorizationGuard>
                        </Box>
                        <Box sx={commonStyles.spaceBetween}>
                            <Typography sx={styles.topics}>{t`1.3 - WORK SPECIFICS`}</Typography>
                            <AuthorizationGuard
                                userHasSomeOf={[authenticatedUser, [
                                    PPGRCD_ROLES.OWNER,
                                    PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                    PPGRCD_ROLES.DRAFTSMAN,
                                    PPGRCD_ROLES.CONTRACTOR
                                ]]}>
                                <>
                                    {Boolean(ppgrcd.form_work_specifics.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                        <Button variant={'outlined'} onClick={handleGoToWorkSpecificsForm}>
                                            {t`EDIT`}
                                        </Button>
                                    }
                                    {nextFormIndex === 2 &&
                                        <Button variant={'outlined'} onClick={handleGoToWorkSpecificsForm}>
                                            {t`FILL IN`}
                                        </Button>
                                    }
                                </>
                            </AuthorizationGuard>
                        </Box>
                    </Stack>
                </AccordionCard>
                <AccordionCard
                    title={t`2 - WASTE PREVENTION AND REUSE`}
                    summaryChildren={Boolean(capther2Completed) ? <CheckCircle sx={styles.checkCircle}/> : <></>}
                >
                    <Stack spacing={1}>
                        <Stack spacing={2}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography sx={styles.topics}>{t`2.1 - PROJECT DATA`}</Typography>
                                <AuthorizationGuard
                                    userHasSomeOf={[authenticatedUser, [
                                        PPGRCD_ROLES.OWNER,
                                        PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                        PPGRCD_ROLES.DRAFTSMAN,
                                        PPGRCD_ROLES.CONTRACTOR
                                    ]]}>
                                    <>
                                        {Boolean(ppgrcd.form_prevention_methodology.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                            <Button variant={'outlined'} onClick={handleGoToPreventionMethodologyForm}>
                                                {t`EDIT`}
                                            </Button>
                                        }
                                        {nextFormIndex === 3 &&
                                            <Button variant={'outlined'} onClick={handleGoToPreventionMethodologyForm}>
                                                {t`FILL IN`}
                                            </Button>
                                        }
                                    </>
                                </AuthorizationGuard>
                            </Box>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography sx={styles.topics}>{t`2.2 - SUBSTANCES DATA`}</Typography>
                                <AuthorizationGuard
                                    userHasSomeOf={[authenticatedUser, [
                                        PPGRCD_ROLES.OWNER,
                                        PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                        PPGRCD_ROLES.DRAFTSMAN,
                                        PPGRCD_ROLES.CONTRACTOR
                                    ]]}>
                                    <>
                                        {Boolean(ppgrcd.form_substances_objects.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                            <Button variant={'outlined'} onClick={handleGoToSubstancesAndObjectsForm}>
                                                {t`EDIT`}
                                            </Button>
                                        }
                                        {nextFormIndex === 4 &&
                                            <Button variant={'outlined'} onClick={handleGoToSubstancesAndObjectsForm}>
                                                {t`FILL IN`}
                                            </Button>
                                        }
                                    </>
                                </AuthorizationGuard>
                            </Box>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography sx={styles.topics}>{t`2.3 - USAGE DATA`}</Typography>
                                <AuthorizationGuard
                                    userHasSomeOf={[authenticatedUser, [
                                        PPGRCD_ROLES.OWNER,
                                        PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                        PPGRCD_ROLES.DRAFTSMAN,
                                        PPGRCD_ROLES.CONTRACTOR
                                    ]]}>
                                    <>
                                        {Boolean(ppgrcd.form_usage_methodology.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                            <Button variant={'outlined'} onClick={handleGoToUsageMethodologyForm}>
                                                {t`EDIT`}
                                            </Button>
                                        }
                                        {nextFormIndex === 5 &&
                                            <Button variant={'outlined'} onClick={handleGoToUsageMethodologyForm}>
                                                {t`FILL IN`}
                                            </Button>
                                        }
                                    </>
                                </AuthorizationGuard>
                            </Box>
                        </Stack>
                    </Stack>
                </AccordionCard>
                <AccordionCard
                    title={t`3 - INCORPORATION OF RECYCLED MATERIALS`}
                    summaryChildren={
                        ppgrcd.form_incorporation_methodology.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Stack spacing={1}>
                            <Typography>- {t`Methodology for incorporating recycled CDW`}</Typography>
                            <Typography>- {t`Recycled CDW integrated into project/works `}</Typography>
                        </Stack>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_incorporation_methodology.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToIncorporationMethodologyForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 6 &&
                                    <Button variant={'outlined'} onClick={handleGoToIncorporationMethodologyForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
                <AccordionCard
                    title={t`4 - SOIL CONTAMINATION`}
                    summaryChildren={
                        ppgrcd.form_soil_contamination.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Stack spacing={1}>
                            <Typography>- {t`Historic analysis and context`}</Typography>
                            <Typography>- {t`Contamination potential`}</Typography>
                        </Stack>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_soil_contamination.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToSoilContaminationForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 7 &&
                                    <Button variant={'outlined'} onClick={handleGoToSoilContaminationForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
                <AccordionCard
                    title={t`5 - CONDITIONING AND SORTING`}
                    summaryChildren={
                        ppgrcd.form_conditioning_sorting.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography>- {t`Methods for packaging and sorting CDW`}</Typography>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_conditioning_sorting.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToConditioningAndSortingForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 8 &&
                                    <Button variant={'outlined'} onClick={handleGoToConditioningAndSortingForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
                <AccordionCard
                    title={t`6 - PRODUCTION AND OPERATION`}
                    summaryChildren={
                        ppgrcd.form_production_operation.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography>- {t`Remediation operations for soil`}</Typography>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_production_operation.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToProductionAndOperationForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 9 &&
                                    <Button variant={'outlined'} onClick={handleGoToProductionAndOperationForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
                <AccordionCard
                    title={t`7 - WASTE MANAGEMENT COST ESTIMATION`}
                    summaryChildren={
                        ppgrcd.form_waste_management.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography>- {t`Estimate of the financial costs of construction and demolition waste management`}</Typography>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_waste_management.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToWasteManagementForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 10 &&
                                    <Button variant={'outlined'} onClick={handleGoToWasteManagementForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
                <AccordionCard
                    title={t`8 - PLAN CLOSURE - E-GAR`}
                    summaryChildren={
                        ppgrcd.form_plan_closure.length
                            ? <CheckCircle sx={styles.checkCircle}/>
                            : <></>
                    }
                >
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography>- {t`e-Gar associated with the PPGRCD`}</Typography>
                        <AuthorizationGuard
                            userHasSomeOf={[authenticatedUser, [
                                PPGRCD_ROLES.OWNER,
                                PPGRCD_ROLES.OWNER_REPRESENTATIVE,
                                PPGRCD_ROLES.DRAFTSMAN,
                                PPGRCD_ROLES.CONTRACTOR
                            ]]}>
                            <>
                                {Boolean(ppgrcd.form_plan_closure.length) && (!ppgrcdFinalizedStatus.includes(ppgrcd.status)) &&
                                    <Button variant={'outlined'} onClick={handleGoToPlanClosureForm}>
                                        {t`EDIT`}
                                    </Button>
                                }
                                {nextFormIndex === 11 &&
                                    <Button variant={'outlined'} onClick={handleGoToPlanClosureForm}>
                                        {t`FILL IN`}
                                    </Button>
                                }
                            </>
                        </AuthorizationGuard>
                    </Box>
                </AccordionCard>
            </Stack>
        </>
    )
        ;
};

const styles = {
    btnIcon: {
        pr: 1,
        width
:
17
}
,
tabPanel: {
    p: 2
}
,
checkCircle: {
    fontSize: 25,
        color
:
    'success.main'
}
,
topics: {
    color: 'text.info'
}
}
;

export default ResumePPGRCD;