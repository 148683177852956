import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Grid, TextField} from '@mui/material';
import commonStyles from '../../../../common/styles/commonStyles';

interface Props {
    readOnly: boolean
}

export const EntityResponsibleFormDetails = ({readOnly}: Props) => {

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Name` + '*'}
                            variant={"outlined"}
                            disabled
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'fiscal_number'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Fiscal Number` + '*'}
                            variant={"outlined"}
                            disabled
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'license'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`License` + '*'}
                            variant={"outlined"}
                            disabled
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'cae'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Cae` + '*'}
                            variant={"outlined"}
                            disabled
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default EntityResponsibleFormDetails;