import {t} from 'ttag';
import React, {useState} from 'react';
import {FileUpload} from '@mui/icons-material';
import {Controller, useFormContext} from 'react-hook-form';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DatetimePicker from '../../../common/date-time-picker/DatetimePicker';
import {contaminationLevels, formats, holders} from '../../../../mocks';
import FormSelectorsRAA from '../../../common/forms/FormSelectorsRAA';
import {commonStyles} from '../../../common/styles/commonStyles';
import UpdateResidueFormImage from './UpdateResidueFormImage';
import {fileToBase64} from '../../../../utils/forms/helpers';
import {LerCode, Residue} from '../../../../types';
import {useStore} from '../../../../store/valtio';
import EntityDepartmentSelectorResidueFormDetails from '../../residue/EntityDepartmentSelectorResidueFormDetails';

interface Props {
    residue: Residue
    lerCodes: LerCode[],
    editable?: boolean
    readOnly?: boolean
}

export const UpdateResidueFormDetails = ({residue, lerCodes, editable, readOnly}: Props) => {
    const {app: {user, configs}} = useStore();
    const {getValues} = useFormContext();
    const [previewPhoto, setPreviewPhoto] = useState<string>();

    if (!configs) return null;

    return (
        <Grid spacing={4} container>
            <Grid xs={12} item>
                <Controller
                    name={'image'}
                    render={({field}) => (
                        <Stack spacing={3} alignItems={'center'}>
                            {previewPhoto ?
                                <img
                                    src={previewPhoto}
                                    alt={'preview-photo'}
                                    style={{maxWidth: 200, maxHeight: 200, borderRadius: 10}}
                                />
                                : residue.image
                                    ? <UpdateResidueFormImage image={residue.image}/>
                                    : <img
                                        alt={'default-photo'}
                                        src={getValues('photo') || getValues('default_photo')}
                                        style={{maxWidth: 200, maxHeight: 200, borderRadius: 10}}
                                    />
                            }
                            {editable &&
                                <>
                                    <input
                                        type={'file'}
                                        id={'photo-upload'}
                                        onChange={({target: {files}}) => {
                                            field.onChange(files[0]);
                                            fileToBase64(files[0], (base64) =>
                                                setPreviewPhoto(base64)
                                            )
                                        }}
                                        hidden
                                    />
                                    <label htmlFor={'photo-upload'}>
                                        <Button variant={'text'} component={'span'}>
                                            <FileUpload sx={{pr: 1}}/>
                                            {t`Upload photo`}
                                        </Button>
                                    </label>
                                </>
                            }
                        </Stack>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'holder'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel sx={{color: 'text.primary'}}
                                        id={"holder-type-label"}>{t`Holder`}*</InputLabel>
                            <Select
                                {...field}
                                label={t`Holder`}
                                labelId={'holder-type-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!editable}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {holders.map((holder, key) => (
                                    <MenuItem key={key} value={holder.value}>{holder.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'ler_code'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl disabled={!editable} fullWidth>
                            <InputLabel sx={styles.inputLabel} id={'lerCode-label'}
                                        required>{t`Ler Code`}</InputLabel>
                            <Select
                                {...field}
                                label={t`Ler Code`}
                                labelId={'lerCode-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!editable}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {lerCodes?.map((ler, key) => (
                                    ler.is_header
                                        ?
                                        <MenuItem
                                            key={key}
                                            value={ler.id}
                                            sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                            disabled
                                        >
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                        :
                                        <MenuItem key={key} value={ler.id}>
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <EntityDepartmentSelectorResidueFormDetails authenticatedUser={user} editable={editable} readOnly={readOnly}/>
            </Grid>

            <FormSelectorsRAA editable={editable}/>

            <Grid xs={12} md={6} item>
                <Controller
                    name={'contamination'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl disabled={!editable} fullWidth>
                            <InputLabel
                                sx={styles.inputLabel}
                                id={'contamination-label'}
                                required
                            >
                                {t`Contamination`}
                            </InputLabel>
                            <Select
                                {...field}
                                label={t`Contamination`}
                                labelId={'contamination-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!editable}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {contaminationLevels.map((contamination, key) => (
                                    <MenuItem key={key} value={contamination.value}>
                                        {contamination.description}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'format'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl disabled={!editable} fullWidth>
                            <InputLabel sx={styles.inputLabel} id={'format-label'} required>{t`Format`}</InputLabel>
                            <Select
                                {...field}
                                label={t`Format`}
                                labelId={'format-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!editable}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {formats.map((format, key) => (
                                    <MenuItem key={key} value={format.value}>{format.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'amount'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Quantity(t)`}
                            variant={"outlined"}
                            disabled={!editable}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'amount_base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel disabled={!editable} value={'TON'} label={t`Tons`}
                                                  control={<Radio/>}/>
                                <FormControlLabel disabled={!editable} value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'availability_date'}
                    render={({field, fieldState: {error}}) => (
                        <DatetimePicker
                            {...field}
                            variant={'DATE_ONLY'}
                            onChange={e => {
                                field.onChange(e);
                            }}
                            label={t`Availability Date`}
                            helperText={error?.message}
                            error={Boolean(error)}
                            disabled={!editable}
                            fullWidth
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    }
};

UpdateResidueFormDetails.defaultProps = {
    editable: false,
    readOnly: false
};

export default UpdateResidueFormDetails;