export const commonStyles = {
    datagrid: {
        '.MuiDataGrid-columnHeaders': {
            backgroundColor: 'background.gray',
            borderRadius: 2
        },
        '.MuiDataGrid-row': {
          cursor: 'pointer'
        },
        '.MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '&.MuiDataGrid-root': {
            border: 'none'
        }
    },
    datagridInner: {
        '&.MuiDataGrid-root': {
            border: 'none'
        },
        '&>.MuiDataGrid-main': {
            '&>.MuiDataGrid-columnHeaders': {
                borderBottom: 'none'
            },
            '& div div div div >.MuiDataGrid-cell': {
                borderBottom: 'none',
                borderRight: 'none'
            },
        }
    },
    dataGridHideHeader: {
        "& .MuiDataGrid-columnHeaders": { display: "none" },
        "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" }
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 2
        },
        input: {
            color: 'text.secondary'
        }
    },
    pageWrapper: {
        my: 2,
        gap: 3
    },
    pageTitle: {
        fontSize: '1.7rem',
        fontWeight: 'bold'
    },
    pageSubTitles: {
        fontSize: '1.6rem',
        fontWeight: '500'
    },
    card: (elevation = true) => ({
        width: '100%',
        borderRadius: 2,
        boxShadow: elevation ? 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px' : 'none'
    }),
    flexEndContent: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    flexStartContent: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center'
    },
    centerItems: {
        display: 'flex',
        alignItems: 'center',
    },
    centerContentAndItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    spaceBetween: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    spaceEvenly: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    }
};

export default commonStyles