import {t} from 'ttag';
import * as Yup from 'yup';


export const UpdateUserFormSchema = Yup.object().shape(
    {
        first_name: Yup.string().required(t`Required field.`),
        last_name: Yup.string().required(t`Required field.`),
        email: Yup.string()
            .required(t`Required field.`)
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, t`Should be a valid email.`),
        address_id: Yup.object().shape({
            address: Yup.string().required(t`Required field.`),
            island: Yup.string().required(t`Required field.`),
            parish: Yup.string().required(t`Required field.`),
            county: Yup.string().required(t`Required field.`),
            phone: Yup.string()
                .notRequired()
                .test('phone', t`Invalid phone number.`, function(value) {
                    if (!!value) {
                        const schema = Yup.string().matches(/^[1-9]\d{8}$/, t`Invalid phone number.`);
                        return schema.isValidSync(value);
                    }
                    return true;
                })
        }).required(t`Required field.`)
    }
).required();
