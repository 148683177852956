import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import useDirectusMutation from '../../../custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../custom-hooks/useDirectusQuery';
import {commonStyles} from '../../../common/styles/commonStyles';
import DirectusFeedback from '../../../common/DirectusFeedback';
import {columns} from './helpers/entity-invites-list-helpers';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';


export const EntityInvitesList = () => {
    const {entityId} = useParams();
    const [invalidatedInvites, setInvalidatedInvites] = useState();

    const {
        data: invites,
        error: errorGetInvites,
        isLoading: isLoadingGetInvites
    } = useDirectusQuery({
        path: `/items/entities_email_invites`,
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'entity_id': {'_eq': entityId},
                    'status': {
                        '_in': ['PENDING', 'NO_ACCOUNT']
                    }
                }
            },
            refetchOnChange: invalidatedInvites
        }
    });

    const {
        actions: {DELETE: deleteInvite},
        response: {
            error: errorDeleteInvite,
            isLoading: isLoadingDeleteInvite
        }
    } = useDirectusMutation({path: '/items/entities_email_invites'});

    const deleteHandler = (invite) => {
        deleteInvite(() => setInvalidatedInvites(invites), invite.id);
    };

    if (!invites) return null;

    return (
        <Stack spacing={3}>
            <Typography fontSize={'1.4rem'} fontWeight={'bold'}>{t`PENDING INVITES`}</Typography>
            <DataGrid
                rows={invites || []}
                sx={commonStyles.datagrid}
                components={dataGridComponents}
                getRowId={(row) => row.id}
                rowCount={invites?.length || 0}
                columns={columns({deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <DirectusFeedback
                error={errorGetInvites || errorDeleteInvite}
                isLoading={isLoadingGetInvites || isLoadingDeleteInvite}
            />
        </Stack>
    );
};

EntityInvitesList.defaultProps = {
    filterBy: 'all'
};

export default EntityInvitesList;