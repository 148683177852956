import React from 'react';
import PropTypes from 'prop-types';
import {hasEveryOf, hasSomeOf} from '../../../utils/user/user-utils';
import {User} from '../../../types';

interface UserHasSomeOfArguments {
    user: User,
    roles: Array<String>
}
interface UserHasEveryOfArguments {
    user: User,
    roles: Array<String>
}

interface Props {
    children: React.ReactElement
    userHasSomeOf?: Array<UserHasSomeOfArguments>
    userHasEveryOf?: Array<UserHasEveryOfArguments>
    redirectToUnauthorized?: boolean
}

export const AuthorizationGuard = ({
   children,
   userHasSomeOf,
   userHasEveryOf,
   redirectToUnauthorized
}: Props) => {

    if (userHasSomeOf && !hasSomeOf(userHasSomeOf[0], userHasSomeOf[1])) {
        return null;
    }

    if(userHasEveryOf && !hasEveryOf(userHasEveryOf[0], userHasEveryOf[1])){
        if (redirectToUnauthorized) window.location.href = '/401';
        return null;
    }

    return children;
};

AuthorizationGuard.propTypes = {
    userHasSomeOf: PropTypes.arrayOf(PropTypes.string),
    userHasEveryOf: PropTypes.arrayOf(PropTypes.string),
    redirectToUnauthorized: PropTypes.bool,
    showErrorView: PropTypes.bool
};

AuthorizationGuard.defaultProps = {
    userHasSomeOf: [],
    userHasEveryOf: [],
    redirectToUnauthorized: false,
    showErrorView: false,
};

export default AuthorizationGuard;
