import {useState} from 'react';
import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import commonStyles from '../../../common/styles/commonStyles';
import {columns} from './helpers/incorporation-residues-form-list-helpers';
import {t} from 'ttag';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface IncorporationResidue {
    id: number
    amount: number
    base_unit: string
    amount_percentage: number
    insertion_date: string
}

interface Props {
    residues?: IncorporationResidue[]
    isEditing?: boolean
    onDeleteResidue: (residueId) => void
    onEditResidue: (residueId) => void
    readOnly: boolean
}

export const IncorporationResiduesListForm = ({residues, isEditing, onEditResidue, onDeleteResidue, readOnly}: Props) => {
    const [pageSize, setPageSize] = useState(5);
    const deleteHandler = (residue) => onDeleteResidue(residue.id);
    const editHandler = (residue) => onEditResidue(residue.id);

    if (!residues) return null;

    return (
        <DataGrid
            rows={residues}
            pageSize={pageSize}
            sx={commonStyles.datagrid}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5, 15, 25]}
            components={dataGridComponents}
            rowCount={residues?.length || 0}
            columns={columns({isEditing, editHandler, deleteHandler, readOnly})}
            onPageSizeChange={(size) => setPageSize(size)}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: 'insertion_date',
                            sort: 'desc',
                        },
                    ],
                },
            }}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t`Rows per page`,
                }
            }}
            disableExtendRowFullWidth
            autoHeight
        />
    );
};

export default IncorporationResiduesListForm;