import {t} from 'ttag';
import React from 'react';
import {Stack} from '@mui/material';
import {DateTime as dt} from 'luxon';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {FieldValues, useFieldArray, useFormContext} from 'react-hook-form';
import WorkGeneralFormDetails from './work-general-form/WorkGeneralFormDetails';
import {DATETIME_FORMAT} from '../../../../utils/datetime/datetime-utils';
import FormSkeleton from '../../../common/forms/FormSkeleton';

interface Props {
    workGeneralFormMethods: UseFormReturn<FieldValues | any>
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    onReturn: React.MouseEventHandler<HTMLButtonElement>
    readOnly: boolean
}

export const WorkGeneralForm = ({workGeneralFormMethods, onSubmit, onReturn, readOnly}: Props) => {
    const {getValues, resetField, setValue} = useFormContext();

    const {fields: locations, append, replace} = useFieldArray({
        control: workGeneralFormMethods.control,
        name: 'locations'
    });

    const handleAddLocation = () => {
        append({
            address_id: getValues('address_id'),
            latitude: getValues('latitude'),
            longitude: getValues('longitude'),
            shapefiles: getValues('shapefiles'),
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('address_id');
        resetField('latitude');
        resetField('longitude');
        resetField('shapefiles');
        resetField('insertion_date');
    };

    const handleEditLocation = (locationId) => {
        const wantedLocationToEdit = locations.filter((location) => location.id === locationId)[0];
        setValue('address_id', wantedLocationToEdit.address_id);
        setValue('latitude', wantedLocationToEdit.latitude);
        setValue('longitude', wantedLocationToEdit.longitude);
        setValue('shapefiles', wantedLocationToEdit.shapefiles);
        setValue('insertion_date', wantedLocationToEdit.insertion_date);
        handleRemoveLocation(locationId);
    }

    const handleSaveEditLocation = (callback: () => null) => {
        append({
            address_id: getValues('address_id'),
            latitude: getValues('latitude'),
            longitude: getValues('longitude'),
            shapefiles: getValues('shapefiles'),
            insertion_date: getValues('insertion_date')
        })
        callback();
    };

    const handleRemoveLocation = (locationId) => {
        let filteredLocations = locations.filter((location) => location.id !== locationId);
        replace(filteredLocations);
    };

    return (
        <Stack gap={3} py={2}>
            <FormSkeleton
                mainGroupContent={
                    <WorkGeneralFormDetails
                        locations={locations}
                        onAddLocation={handleAddLocation}
                        onEditLocation={handleEditLocation}
                        onRemoveLocation={handleRemoveLocation}
                        onSaveEditLocation={handleSaveEditLocation}
                        readOnly={readOnly}
                    />
                }
                submitBtnText={t`Next`}
                cancelBtnText={t`Return`}
                onSubmit={onSubmit}
                onCancel={onReturn}
                actions={!readOnly}
                innerForm
            />
        </Stack>
    );
};

export default WorkGeneralForm;