import {t} from 'ttag';
import React, {useState} from 'react';
import {Grid, Stack} from '@mui/material';
import {Controller} from 'react-hook-form';
import FormSelectorsRAA from '../../../../../common/forms/FormSelectorsRAA';
import {Attachments} from '../../../../../common/attachments/Attachments.js';
import DirectusFeedback from '../../../../../common/DirectusFeedback';


export const AddLocationFormDetails = () => {
    const [errorFileSize, setErrorFileSize] = useState(null);

    return (
        <>
            <Grid spacing={4} container>
                <FormSelectorsRAA editable={true}/>
                <Grid xs={12} md={12} item>
                    <Stack spacing={1}>
                        <Controller
                            name={'files'}
                            render={({field, fieldState: {error}}) => (
                                <Attachments
                                    attachments={field.value}
                                    dropAttachmentsLabel={t`Insert picture with the location of the work including georeferencing (shapefile).`}
                                    allowedFileTypes={['.jpg, .jpeg, .png, .gis']}
                                    onChange={field.onChange}
                                    onFilesRejected={(filesRejected) => {
                                        if (filesRejected.length > 0) {
                                            setErrorFileSize({message: t`The file(s) size should be less than 1mb.`})
                                        }
                                    }}
                                    error={Boolean(error)}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <DirectusFeedback isLoading={false} error={errorFileSize}/>
        </>
    );
};


export default AddLocationFormDetails;