import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../common/grid/SubMenu';


export const columns = ({deleteHandler}) => [
  {
    field: 'email',
    headerName: t`EMAIL`,
    disableColumnMenu: true,
    flex: 3.5,
    renderCell: ({row}) => row.email
  },
  {
    field: 'role',
    headerName: t`ROLE`,
    disableColumnMenu: true,
    flex: 2.5,
    renderCell: ({row}) =>
        row.role === 'MANAGER'
          ? 'Gestor'
            : row.role === 'COORDINATOR'
              ? 'Coordenador'
                : row.role === 'MEMBER'
                  ? 'Membro' : '-'
  },
  {
    field: 'status',
    headerName: t`STATUS`,
    disableColumnMenu: true,
    flex: 2.5,
    renderCell: ({row}) => t`Pending`
  },
  {
    field: 'registered',
    headerName: t`IS REGISTERED`,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({row}) =>
        row.status === 'PENDING'
          ? 'Sim'
            : row.status === 'NO_ACCOUNT'
              && 'Não'
  },
  {
    headerName: '',
    field: 'actions',
    disableColumnMenu: true,
    flex: .5,
    renderCell: ({row}) => {
      return (
          <SubMenu
              onDelete={() => deleteHandler(row)}
              editAction={false}
          />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
