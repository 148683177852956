import React from 'react';
import {Card, CardActions, CardContent, CardHeader} from '@mui/material';
import commonStyles from '../styles/commonStyles';

interface Props {
    headerContent?: React.ReactElement,
    actions?: React.ReactElement,
    children: React.ReactElement,
    rest?: React.ReactNode
}

export const BasicCard = ({headerContent, actions, children, ...rest}: Props) => {

    return (
        <Card {...rest} sx={commonStyles.card()}>
            {headerContent && <CardHeader title={headerContent} sx={styles.cardHeader}/>}
            <CardContent sx={styles.cardContent}>
                {children}
            </CardContent>
            {actions &&
                <CardActions sx={styles.cardActions}>
                    {actions}
                </CardActions>
            }
        </Card>
    );
};

const styles = {
    cardHeader: {
        p: 0,
        minHeight: 40,
        backgroundColor: 'background.gray'
    },
    cardContent: {
        px: 3
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

export default BasicCard;