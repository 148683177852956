import {t} from 'ttag';
import * as React from 'react';
import {SyntheticEvent, useState} from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useNavigate} from 'react-router-dom';
import {APP_NAME} from '../../config/settings';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import commonStyles from '../common/styles/commonStyles';
import NotificationsMenu from '../common/notifications/NotificationsMenu';
import {
    Drawer,
    FormControl,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Select,
    Stack
} from '@mui/material';
import {useDirectus} from 'react-directus';
import {Close, Dashboard, KeyboardArrowRight, KeyboardReturn, Login, Logout, Person} from '@mui/icons-material';
import AuthorizationGuard from '../../pages/common/guards/AuthorizationGuard';
import {changeLanguage, LOCALE_DEFAULT, LOCALES} from '../../config/i18n';
import EntitiesMenuItem from './sidebar/EntitiesMenuItem';
import ResiduesMenuItem from './sidebar/ResiduesMenuItem';
import DirectusFeedback from '../common/DirectusFeedback';
import {APP_ROLES} from '../../utils/user/user-utils';
import actions from '../../store/valtio/actions';
import {useStore} from '../../store/valtio';

const englandFlag = require('../../assets/images/Flag_of_the_United_Kingdom.png');
const portugueseFlag = require('../../assets/images/Flag_of_Portugal.png');
const copyLogo = require('../../assets/icons/Copy.png');

export const NavBar = () => {
    const {app: {user, configs}} = useStore();
    const {directus} = useDirectus();
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openEntities, setOpenEntities] = useState(false);
    const [openResidues, setOpenResidues] = useState(false);
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);

    const handleChangeLanguage = (event) => {
        actions.app.setLanguage(event.target.value);
        changeLanguage(event.target.value);
        navigate('/');
        window.location.reload();
    };

    const handleLogout = () => {
        setIsLoadingLogout(true);
        directus.auth.logout()
            .then(() => window.location.reload())
            .finally(() => setIsLoadingLogout(false))
    }

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpenDrawer(open);
    };

    return (
        <Box>
            <AppBar position={'static'}>
                <Toolbar>
                    <Box sx={commonStyles.spaceBetween} p={2}>
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            onClick={toggleDrawer(!openDrawer)}
                        >
                            <MenuIcon fontSize={'large'}/>
                        </IconButton>
                        <Drawer
                            open={openDrawer}
                            anchor={'left'}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                        >
                            {/* The inside of the drawer */}
                            <Stack
                                sx={{
                                    p: 5,
                                    height: 1,
                                    backgroundColor: "primary.main"
                                }}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Stack alignItems={'flex-start'} spacing={10}>
                                    <IconButton sx={{padding: 0}}>
                                        <Close sx={{color: 'common.white'}} onClick={toggleDrawer(false)}/>
                                    </IconButton>
                                    <Stack spacing={3}>
                                        {user ?
                                            <>
                                                <AuthorizationGuard
                                                    userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                                    <Box sx={styles.menu}>
                                                        <Typography sx={styles.menuTitle}>
                                                            {t`General`}
                                                        </Typography>
                                                        <MenuList sx={styles.menuList}>
                                                            <MenuItem sx={{px: 0}} onClick={() => navigate('/home')}>
                                                                <ListItemIcon>
                                                                    <Dashboard sx={styles.color}/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={styles.color}>{t`Platform`}</ListItemText>
                                                                <ListItemIcon sx={styles.color}>
                                                                    <KeyboardArrowRight/>
                                                                </ListItemIcon>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Box>
                                                </AuthorizationGuard>
                                                <Box sx={styles.menu}>
                                                    <Typography sx={styles.menuTitle}>
                                                        {t`Management`}
                                                    </Typography>
                                                    <MenuList sx={styles.menuList}>
                                                        <AuthorizationGuard
                                                            userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                                            <>
                                                                <EntitiesMenuItem
                                                                    open={openEntities}
                                                                    onToggle={e => handleMenuItemClick(e, () => setOpenEntities(!openEntities))}
                                                                />
                                                                <ResiduesMenuItem
                                                                    open={openResidues}
                                                                    onToggle={e => handleMenuItemClick(e, () => setOpenResidues(!openResidues))}
                                                                />
                                                                <MenuItem sx={{px: 0}}
                                                                          onClick={() => navigate('/ppgrcds')}>
                                                                    <ListItemButton sx={styles.listItemBtn}>
                                                                        <ListItemIcon sx={styles.color}>
                                                                            <img alt={'copy-logo'} src={copyLogo}
                                                                                 width={24}
                                                                                 height={24}/>
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={t`PPGRCDs`}/>
                                                                        <ListItemIcon sx={styles.color}>
                                                                            <KeyboardArrowRight/>
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                </MenuItem>
                                                            </>
                                                        </AuthorizationGuard>
                                                        <MenuItem sx={{px: 0}} onClick={() => navigate('/profile')}>
                                                            <ListItemButton sx={styles.listItemBtn}>
                                                                <ListItemIcon sx={styles.color}>
                                                                    <Person/>
                                                                </ListItemIcon>
                                                                <ListItemText primary={t`Profile`}/>
                                                                <ListItemIcon sx={styles.color}>
                                                                    <KeyboardArrowRight/>
                                                                </ListItemIcon>
                                                            </ListItemButton>
                                                        </MenuItem>
                                                    </MenuList>
                                                </Box>
                                                <AuthorizationGuard
                                                    userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                                                    <Box sx={{...styles.menu, marginTop: '80px !important'}}>
                                                        <MenuList sx={styles.menuList}>
                                                            <MenuItem sx={{px: 0}}
                                                                      onClick={e => handleMenuItemClick(e, handleLogout)}>
                                                                <ListItemIcon>
                                                                    <Logout sx={styles.color}/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={styles.color}>{t`Logout`}</ListItemText>
                                                            </MenuItem>
                                                        </MenuList>
                                                        <MenuList sx={styles.menuList}>
                                                            <MenuItem sx={{px: 0}}
                                                                      onClick={() => window.location.assign('https://rebuild17.obraspublicas.azores.gov.pt')}>
                                                                <ListItemIcon>
                                                                    <KeyboardReturn sx={styles.color}/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={styles.color}>{t`Return to website`}</ListItemText>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Box>
                                                </AuthorizationGuard>
                                            </>
                                            :
                                            <>
                                                <Box sx={styles.menu}>
                                                    <Typography sx={styles.menuTitle}>
                                                        {t`Authentication`}
                                                    </Typography>
                                                    <Box marginTop={2}>
                                                        <MenuList sx={styles.menuList}>
                                                            <MenuItem sx={{px: 0}} onClick={() => navigate('/auth')}>
                                                                <ListItemButton sx={styles.listItemBtn}>
                                                                    <ListItemIcon sx={styles.color}>
                                                                        <Login/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={t`Sign In`}/>
                                                                </ListItemButton>
                                                            </MenuItem>
                                                        </MenuList>
                                                        <MenuList sx={styles.menuList}>
                                                            <MenuItem sx={{px: 0}}
                                                                      onClick={() => window.location.assign('https://rebuild17.obraspublicas.azores.gov.pt')}>
                                                                <ListItemIcon>
                                                                    <KeyboardReturn sx={styles.color}/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={styles.color}>{t`Return to website`}</ListItemText>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                    </Stack>
                                </Stack>
                                <FormControl size={'small'} variant={'outlined'}>
                                    <Select
                                        value={LOCALE_DEFAULT}
                                        size={'small'}
                                        label={'language'}
                                        variant={'standard'}
                                        onChange={handleChangeLanguage}
                                    >
                                        <MenuItem value={LOCALES.PT}>
                                            <Box sx={commonStyles.centerContentAndItems} gap={2}>
                                                <Box sx={styles.flag(portugueseFlag)}/>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={LOCALES.EN}>
                                            <Box sx={commonStyles.centerContentAndItems} gap={2}>
                                                <Box sx={styles.flag(englandFlag)}/>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Drawer>
                        <Typography fontSize={'1.6rem'} fontWeight={'bold'}>
                            {APP_NAME}
                        </Typography>
                        <NotificationsMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
            <DirectusFeedback isLoading={isLoadingLogout}/>
        </Box>
    );
}

const styles = {
    flag: (backgroundImg) => ({
        width: 50,
        height: 30,
        borderRadius: 1,
        cursor: 'pointer',
        backgroundImage: `url(${backgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }),
    sideBar: {
        width: '390px',
        minHeight: '100vh',
        backgroundColor: 'primary.main',
        zIndex: 1000
    },
    sideBarInner: {
        px: 4,
        gap: 10
    },
    appName: {
        fontWeight: 700,
        fontSize: '1.7rem',
        whiteSpace: 'nowrap',
        color: 'common.white'
    },
    appBranding: {
        gap: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    menu: {
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    menuList: {
        px: 0,
        width: '100%'
    },
    menuTitle: {
        fontWeight: 700,
        fontSize: '1.1rem',
        color: 'common.white',
        textDecoration: 'unset'
    },
    listItemBtn: {
        px: 0,
        color: 'common.white'
    },
    color: {
        color: 'common.white'
    },
    flagsWrapper: {
        gap: 2,
        width: '100%',
        ...commonStyles.centerContentAndItems
    }
};

export default NavBar;
