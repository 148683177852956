import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Grid, Stack, TextField, Typography} from '@mui/material';
import commonStyles from '../../../common/styles/commonStyles';


export const CreatePpgrcdFormDetails = () => {

    return (
        <>
            <Stack spacing={3}>
                <Grid spacing={3} container>
                    <Grid xs={12} md={12} item>
                        <Typography>{t`Please insert the prevention plan name below:`}</Typography>
                    </Grid>
                    <Grid xs={12} md={12} item>
                        <Controller
                            name={'name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder={t`Name` + '*'}
                                    sx={commonStyles.textField}
                                    variant={"outlined"}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};

export default CreatePpgrcdFormDetails;