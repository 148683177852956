import * as React from 'react';
import Stack from '@mui/material/Stack';
import {Lens} from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';

interface BreadcrumbsItem {
    name: string,
    link: string
}

interface Props {
    list: Array<BreadcrumbsItem>,
    rest?: React.ReactNode
}

export const NavigationBreadcrumbs = ({list, ...rest}: Props) => {
    const navigate = useNavigate();

    const items = list?.map((breadcrumbsItem: BreadcrumbsItem, key) => (
        <Typography
            key={key}
            sx={styles.item}
            onClick={() => navigate(breadcrumbsItem.link)}
        >
            {breadcrumbsItem.name}
        </Typography>
    ));

    return (
        <Stack spacing={2}>
            <Breadcrumbs
                {...rest}
                separator={<Lens sx={{color: 'common.gray', width: 10}}/>}
            >
                {items}
            </Breadcrumbs>
        </Stack>
    );
};

const styles = {
    item: {
        cursor: 'pointer',
        color: 'common.gray',
        textDecoration: 'none'
    }
}

export default NavigationBreadcrumbs;