import {t} from 'ttag';
import React, {useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {KeyboardArrowRight} from '@mui/icons-material';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import AvailableDepartmentsList from '../../../components/entities/departments/AvailableDepartmentsList';
import {RouteValorizationFormSchema} from './schemas/route-valorization-form-schema';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import commonStyles from '../../../components/common/styles/commonStyles';
import FormActions from '../../../components/common/forms/FormActions';
import {Residue} from '../../../types';

interface Props {
    residue: Residue
    valorizationRoutesResults: Array<any>
    onSelectValorizationRoutePlatform: (valorizationRoutePlatform: object) => void
    onSubmit: () => void
    onReturn: () => void
}

export const RouteValorization = ({
  residue,
  valorizationRoutesResults,
  onSelectValorizationRoutePlatform,
  onSubmit,
  onReturn
}: Props) => {
    const [selectedValorizationRoutesPlatform, setSelectedValorizationRoutesPlatform] = useState();

    const methods = useForm({
        resolver: yupResolver(RouteValorizationFormSchema),
        defaultValues: {
            step0_pickup_transportation_service: '',
            step0_pickup_transportation_service_to_create: '',
            step1_pretreatment_service: '',
            step1_2_treatment_service: '',
            step2_processing_service: '',
            step3_product_service: '',
            valorization_routes_platform_id: selectedValorizationRoutesPlatform?.id || ''
        }
    });

    const {
        data: valorizationRoutes,
        error: errorGetValorizationRoutes,
        isLoading: isLoadingGetValorizationRoutes
    } = useDirectusQuery({
        path: '/items/valorization_routes_platform',
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    const handleSubmitValorizationRoute = methods.handleSubmit((values) => {
        onSubmit(values);
    });

    const suggestedValorizationRoutes = valorizationRoutes?.filter(
        route => valorizationRoutesResults?.map((route) => route.id).includes(route.id)
    );

    return (
        <>
            {!selectedValorizationRoutesPlatform &&
                <>
                    <Stack spacing={7} py={3} px={1}>
                        <Stack spacing={4}>
                            <Typography fontWeight={'bold'}
                                        fontSize={'1.1rem'}>{t`RECOMMENDED SOLUTIONS FOR YOUR RESIDUE`}</Typography>
                            {Boolean(suggestedValorizationRoutes?.length) ?
                                <Stack spacing={2}>
                                    {suggestedValorizationRoutes?.map((route, key) => (
                                        <>
                                            <Box
                                                key={key}
                                                sx={{
                                                    ...commonStyles.card(),
                                                    ...styles.typeWrapper(Boolean(
                                                        !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_contamination
                                                        || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_format
                                                        || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_amount
                                                    ))
                                                }}
                                                onClick={() => {
                                                    if (!Boolean(
                                                        !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_contamination
                                                        || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_format
                                                        || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_amount
                                                    )) {
                                                        setSelectedValorizationRoutesPlatform(route)
                                                        methods.setValue('step0_pickup_transportation_service_to_create', route.step0_pickup_transportation_service_platform)
                                                        onSelectValorizationRoutePlatform(route);
                                                        methods.setValue('valorization_routes_platform_id', route.id)
                                                    } else return
                                                }}
                                            >
                                                <Typography
                                                    key={key}
                                                    sx={styles.text(
                                                        Boolean(
                                                            !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_contamination
                                                            || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_format
                                                            || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_amount
                                                        )
                                                    )}
                                                >
                                                    {route.name}
                                                </Typography>
                                                {!Boolean(
                                                    !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_contamination
                                                    || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_format
                                                    || !valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_amount
                                                ) && <KeyboardArrowRight key={key}/>
                                                }
                                            </Box>
                                            <>
                                                {!valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_contamination &&
                                                    <Typography
                                                        color={'error'}>{t`This service does not work with this type of residue contamination`}</Typography>
                                                }
                                                {!valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_format &&
                                                    <Typography
                                                        color={'error'}>{t`This service does not work with this format of residue`}</Typography>
                                                }
                                                {!valorizationRoutesResults?.filter((routeResult) => routeResult.id === route.id)[0].valid_amount &&
                                                    <Typography
                                                        color={'error'}>{t`This service does not work with this amount of residue`}</Typography>
                                                }
                                            </>
                                        </>
                                    ))}
                                </Stack>
                                :
                                <Typography>{t`There are no solutions available for your residue at the moment.`}</Typography>
                            }
                        </Stack>
                    </Stack>
                    <Box display={'flex'} justifyContent={'flex-end'} pt={3}>
                        <FormActions
                            cancelBtnText={t`RETURN`}
                            submitAction={false}
                            onCancel={onReturn}
                        />
                    </Box>
                </>
            }
            {selectedValorizationRoutesPlatform &&
                <FormProvider {...methods}>
                    <Stack spacing={7} py={3} px={1}>
                        <Stack spacing={3}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography fontWeight={'bold'} textTransform={'uppercase'}>
                                    {selectedValorizationRoutesPlatform.step0_pickup_transportation_service_platform.subtype_title}
                                </Typography>
                                <Typography sx={styles.outputText}>
                                    {selectedValorizationRoutesPlatform.step0_pickup_transportation_service_platform.output_description}
                                </Typography>
                            </Box>
                            <Stack spacing={3}>
                                <AvailableDepartmentsList
                                    filterByDepartmentId={residue?.department_id?.id}
                                    selectedService={selectedValorizationRoutesPlatform.step0_pickup_transportation_service_platform}
                                    onSelectService={(service) => methods.setValue('step0_pickup_transportation_service', service)}
                                    showOwnCapacity
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={3}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography fontWeight={'bold'} textTransform={'uppercase'}>
                                    {selectedValorizationRoutesPlatform.step1_pretreatment_service_platform.subtype_title}
                                </Typography>
                                <Typography sx={styles.outputText}>
                                    {t`Final Product: ` + selectedValorizationRoutesPlatform.step1_pretreatment_service_platform.output_description}
                                </Typography>
                            </Box>
                            <Stack spacing={3}>
                                <Box sx={commonStyles.spaceBetween}>
                                    <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                                    {(!methods.watch('step1_pretreatment_service')) &&
                                        <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                                    }
                                </Box>
                                <AvailableDepartmentsList
                                    filterByDepartmentId={residue?.department_id?.id}
                                    selectedService={selectedValorizationRoutesPlatform.step1_pretreatment_service_platform}
                                    onSelectService={(service) => methods.setValue('step1_pretreatment_service', service)}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={3}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography fontWeight={'bold'} textTransform={'uppercase'}>
                                    {selectedValorizationRoutesPlatform.step1_2_treatment_service_platform.subtype_title}
                                </Typography>
                                <Typography sx={styles.outputText}>
                                    {t`Final Product: ` + selectedValorizationRoutesPlatform.step1_2_treatment_service_platform.output_description}
                                </Typography>
                            </Box>
                            <Stack spacing={3}>
                                <Box sx={commonStyles.spaceBetween}>
                                    <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                                    {(!methods.watch('step1_2_treatment_service')) &&
                                        <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                                    }
                                </Box>
                                <AvailableDepartmentsList
                                    selectedService={selectedValorizationRoutesPlatform.step1_2_treatment_service_platform}
                                    onSelectService={(service) => methods.setValue('step1_2_treatment_service', service)}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={3}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography fontWeight={'bold'} textTransform={'uppercase'}>
                                    {selectedValorizationRoutesPlatform.step2_processing_service_platform.subtype_title}
                                </Typography>
                                <Typography sx={styles.outputText}>
                                    {t`Final Product: ` + selectedValorizationRoutesPlatform.step2_processing_service_platform.output_description}
                                </Typography>
                            </Box>
                            <Stack spacing={3}>
                                <Box sx={commonStyles.spaceBetween}>
                                    <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                                    {(!methods.watch('step2_processing_service')) &&
                                        <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                                    }
                                </Box>
                                <AvailableDepartmentsList
                                    selectedService={selectedValorizationRoutesPlatform.step2_processing_service_platform}
                                    onSelectService={(service) => methods.setValue('step2_processing_service', service)}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={3}>
                            <Box sx={commonStyles.spaceBetween}>
                                <Typography fontWeight={'bold'} textTransform={'uppercase'}>
                                    {selectedValorizationRoutesPlatform.step3_product_service_platform.subtype_title}
                                </Typography>
                                <Typography sx={styles.outputText}>
                                    {t`Final Product: ` + selectedValorizationRoutesPlatform.step3_product_service_platform.output_description}
                                </Typography>
                            </Box>
                            <Stack spacing={3}>
                                <Box sx={commonStyles.spaceBetween}>
                                    <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                                    {(!methods.watch('step3_product_service')) &&
                                        <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                                    }
                                </Box>
                                <AvailableDepartmentsList
                                    selectedService={selectedValorizationRoutesPlatform.step3_product_service_platform}
                                    onSelectService={(service) => methods.setValue('step3_product_service', service)}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box display={'flex'} justifyContent={'flex-end'} pt={3}>
                        <FormActions
                            cancelBtnText={t`RETURN`}
                            submitActionDisabled={
                                !methods.watch('step1_pretreatment_service') ||
                                !methods.watch('step2_processing_service') ||
                                !methods.watch('step3_product_service')
                            }
                            onSubmit={handleSubmitValorizationRoute}
                            onCancel={onReturn}
                        />
                    </Box>
                </FormProvider>
            }
            <DirectusFeedback error={errorGetValorizationRoutes} isLoading={isLoadingGetValorizationRoutes}/>
        </>
    );
};

const styles = {
    text: (isDisabled) => ({
        color: isDisabled ? 'text.disabled' : 'text.secondary'
    }),
    typeWrapper: (isDisabled) => ({
        py: 1,
        px: 2,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '20rem',
        cursor: isDisabled ? 'unset' : 'pointer'
    }),
    outputText: {
        textAlign: 'right',
        textTransform: 'uppercase'
    }
};

export default RouteValorization;