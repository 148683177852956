import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import commonStyles from './styles/commonStyles';

interface Props {
    title: string,
    children: React.ReactElement,
    summaryChildren?: React.ReactElement
}

export const AccordionCard = ({title, summaryChildren, children}: Props) => {


    return (
        <Accordion sx={{...styles.accordion, ...commonStyles.card()}} elevation={0}>
            <AccordionSummary sx={styles.accordionSummary}>
                <Box sx={commonStyles.spaceBetween}>
                    <Box display={'flex'}>
                        <Typography px={2}>{title}</Typography>
                        {title && <ExpandMore/>}
                    </Box>
                    {summaryChildren || <></>}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box p={3}>
                    {children}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

const styles = {
    accordion: {
        '&:before': {
            display: 'none',
        }
    },
    accordionSummary: {
        borderRadius: 2,
        backgroundColor: 'background.gray'
    }
};

export default AccordionCard;