import {useEffect, useState} from 'react';
import {useDirectus} from 'react-directus';
import {DirectusQueryResponse} from '../../types';
import actions from '../../store/valtio/actions';

interface Args {
    path: string,
    options?: {
        params?: {
            fields?: string,
            deep?: object,
            search?: string,
            alias?: object,
            filter?: object
        },
        headers?: object,
        noAuth: boolean,
        refetchOnChange?: any
    }
}

// eslint-disable-next-line
export const useDirectusQuery = <Type extends DirectusQueryResponse>(args: Args) => {
    const {directus} = useDirectus();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        const fetch = async () =>
            await directus.collections.transport.get(args.path, {
                ...args?.options,
                headers: args.options?.noAuth ? {...args?.options?.headers}
                    : {
                    ...args?.options?.headers,
                    Authorization: `Bearer ${directus.storage.auth_token}`
                }
            })

        fetch()
            .then((r) => r?.data ? setData(r.data) : setData(r))
            .catch((e) => {
                if (e.errors[0].extensions.code === 'TOKEN_EXPIRED'){
                    actions.app.setUser(null)
                }
                setError(e);
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line
    }, [directus, args.options?.refetchOnChange]);

    return {data: data || null, error: error || null, isLoading};
};

useDirectusQuery.defaultProps = {
    args: {
        options: {
            noAuth: false
        }
    }
};

export default useDirectusQuery;