import {t} from 'ttag';
import React from 'react';
import {DateTime as dt} from 'luxon';
import {Stack, Typography} from '@mui/material';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {FieldValues, FormProvider, useFieldArray} from 'react-hook-form';
import ProductionAndOperationFormDetails from './production-and-operation-form/ProductionAndOperationFormDetails';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import FormSkeleton from '../../common/forms/FormSkeleton';
import BasicCard from '../../common/cards/BasicCard';
import {useStore} from '../../../store/valtio';

interface Props {
    currentForm: string
    productionAndOperationFormMethods: UseFormReturn<FieldValues | any>
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const ProductionAndOperationForm = ({
   currentForm,
   productionAndOperationFormMethods,
   onSubmit,
   onReturn,
   readOnly
}: Props) => {
    const {app: {lerCodes}} = useStore();
    const {getValues, resetField, setValue} = productionAndOperationFormMethods;
    const {fields: residues, append, replace} = useFieldArray({
        control: productionAndOperationFormMethods.control,
        name: 'residues'
    });

    const handleAddResidue = () => {
        append({
            ler_code: getValues('ler_code'),
            final_estimated_amount: getValues('final_estimated_amount'),
            base_unit: getValues('base_unit'),
            valorization_amount: getValues('valorization_amount'),
            valorization_operation: getValues('valorization_operation'),
            disposal_amount: getValues('disposal_amount'),
            disposal_operation: getValues('disposal_operation'),
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('ler_code');
        resetField('final_estimated_amount');
        resetField('base_unit');
        resetField('valorization_amount');
        resetField('valorization_operation');
        resetField('disposal_amount');
        resetField('disposal_operation');
        resetField('insertion_date');
    }

    const handleEditResidue = (residueId) => {
        const wantedResidueToEdit = residues.filter((residue) => residue.id === residueId)[0];
        setValue('ler_code', wantedResidueToEdit.ler_code);
        setValue('final_estimated_amount', wantedResidueToEdit.final_estimated_amount);
        setValue('base_unit', wantedResidueToEdit.base_unit);
        setValue('valorization_amount', wantedResidueToEdit.valorization_amount);
        setValue('valorization_operation', wantedResidueToEdit.valorization_operation);
        setValue('disposal_amount', wantedResidueToEdit.disposal_amount);
        setValue('disposal_operation', wantedResidueToEdit.disposal_operation);
        setValue('insertion_date', wantedResidueToEdit.insertion_date);
        handleRemoveResidue(residueId);
    }

    const handleSaveEditResidue = (callback: () => null) => {
        append({
            ler_code: getValues('ler_code'),
            final_estimated_amount: getValues('final_estimated_amount'),
            base_unit: getValues('base_unit'),
            valorization_amount: getValues('valorization_amount'),
            valorization_operation: getValues('valorization_operation'),
            disposal_amount: getValues('disposal_amount'),
            disposal_operation: getValues('disposal_operation'),
            insertion_date: getValues('insertion_date')
        })
        callback();
    };

    const handleRemoveResidue = (residueId) => {
        let filteredResidues = residues.filter((residue) => residue.id !== residueId);
        replace(filteredResidues);
    }

    return (
        <>
            {(currentForm === 'All') || (currentForm === 'ProductionAndOperation') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`6. PRODUCTION AND OPERATION OF RECOVERY AND DISPOSAL OF CDW`}
                        </Typography>
                    }
                >
                    <FormProvider {...productionAndOperationFormMethods}>
                        <Stack gap={3} py={2}>
                            <FormSkeleton
                                mainGroupContent={
                                    <ProductionAndOperationFormDetails
                                        residues={residues}
                                        onAddResidue={handleAddResidue}
                                        onEditResidue={handleEditResidue}
                                        onRemoveResidue={handleRemoveResidue}
                                        onSaveEditResidue={handleSaveEditResidue}
                                        readOnly={readOnly}
                                    />
                                }
                                submitBtnText={t`Next`}
                                cancelBtnText={t`Return`}
                                onSubmit={onSubmit}
                                onCancel={onReturn}
                                actions={!readOnly}
                                innerForm
                            />
                        </Stack>
                    </FormProvider>
                </BasicCard>
            }
        </>
    );
};

export default ProductionAndOperationForm;