import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Delete} from '@mui/icons-material';
import {Box, Button, Stack, Typography} from '@mui/material';
import UpdateCollaboratorFormDetails
    from '../../../../components/entities/entity/collaborators/collaborator/forms/edit-collaborator-form/UpdateCollaboratorFormDetails';
import NavigationBreadcrumbs from '../../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import useDirectusMutation from '../../../../components/custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../../components/common/DirectusFeedback';
import commonStyles from '../../../../components/common/styles/commonStyles';
import ConfirmDialog from '../../../../components/common/ConfirmDialog';
import BasicCard from '../../../../components/common/cards/BasicCard';


export const Collaborator = ({}) => {
    const navigate = useNavigate();
    const {entityName, entityId, collaboratorName, collaboratorId} = useParams();
    const [showDeleteCollaboratorDialog, setShowDeleteCollaboratorDialog] = useState(false);

    const {
        data: collaborators,
        error: errorGetCollaborator,
        isLoading: isLoadingGetCollaborator
    } = useDirectusQuery({
        path: `/items/entities_directus_users`,
        options: {
            params: {
                fields: '*.*',
                filter: {
                    "id": {
                        "_eq": collaboratorId
                    }
                }
            }
        }
    });

    const {
        actions: {PATCH: updateCollaborator},
        response: {
            error: errorUpdateCollaborator,
            isLoading: isLoadingUpdateCollaborator
        }
    } = useDirectusMutation({path: `items/entities_directus_users/${collaboratorId}`});

    const {
        actions: {DELETE: deleteCollaborator},
        response: {
            error: errorDeleteCollaborator,
            isLoading: isLoadingDeleteCollaborator
        }
    } = useDirectusMutation({path: `items/entities_directus_users/${collaboratorId}`});

    const handleUpdateCollaborator = (values) => {
        updateCollaborator({
            ...values,
            entities_id: parseInt(entityId)
        })
    }
    const deleteHandler = (callback = () => null) => deleteCollaborator(callback);

    if (!collaborators) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                        sx={commonStyles.pageTitle}>{collaborators[0]?.directus_users_id?.first_name} {collaborators[0]?.directus_users_id?.last_name}</Typography>
                    {collaborators.length >= 2 &&
                        <Box display={'flex'} gap={2}>
                            <Button
                                size={'small'}
                                sx={styles.removeBtn}
                                variant={'contained'}
                                onClick={() => setShowDeleteCollaboratorDialog(true)}
                            >
                                <Delete sx={styles.btnIcon}/>
                                {t`Delete`}
                            </Button>
                        </Box>
                    }
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Entities`, link: '/entities'},
                        {name: entityName, link: `/entities/${entityName}/${entityId}`},
                        {
                            name: collaboratorName,
                            link: `/entities/${entityName}/${entityId}/collaborators/${collaboratorName}/${collaboratorId}`
                        }
                    ]}
                />
                <BasicCard headerContent={<></>}>
                    <Stack spacing={3} p={1}>
                        <UpdateCollaboratorFormDetails
                            collaborator={collaborators[0]}
                            updateCollaborator={handleUpdateCollaborator}
                        />
                    </Stack>
                </BasicCard>
            </Stack>
            <ConfirmDialog
                open={showDeleteCollaboratorDialog}
                title={t`Delete Collaborator`}
                onCancel={() => setShowDeleteCollaboratorDialog(false)}
                onConfirm={() => deleteHandler(() => {
                    setShowDeleteCollaboratorDialog(false);
                    navigate(`/entities/${entityName}/${entityId}`);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete` + ` ${collaborators[0]?.directus_users_id?.first_name} ${collaborators[0]?.directus_users_id?.last_name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetCollaborator || errorDeleteCollaborator || errorUpdateCollaborator}
                isLoading={isLoadingGetCollaborator || isLoadingDeleteCollaborator || isLoadingUpdateCollaborator}
            />
        </>
    );
};

const styles = {
    removeBtn: {
        backgroundColor: 'error.main',
        "&.MuiButtonBase-root:hover": {
            bgcolor: "error.main"
        }
    },
    btnIcon: {
        pr: 1,
        width: 17
    },
    header: {
        py: 1,
        px: 2,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'background.gray'
    },
    headerTitle: {
        fontWeight: 600,
        fontSize: '1rem',
        color: 'text.primary'
    },
};

export default Collaborator;