import {t} from 'ttag';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import commonStyles from '../../../components/common/styles/commonStyles';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddParticipantForm from '../../../components/ppgrcds/ppgrcd/participants/participant/AddParticipantForm';


export const AddParticipant = () => {
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD PARTICIPANT`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Entities`, link: `/entities`},
                    {name: entityName, link: `/entities/${entityName}/${entityId}`},
                    {
                        name: departmentName,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`
                    },
                    {
                        name: `PPGRCD ${ppgrcdId}`,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`
                    },
                    {
                        name: t`Add Participant`,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}/add-participant`
                    },
                ]}
            />
            <AddParticipantForm ppgrcdId={ppgrcdId}/>
        </Stack>
    );
};

export default AddParticipant;