import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {columns} from '../helpers/available-departments-list-helpers';
import {dataGridComponents} from '../../datagrid/dataGridComponents';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import {commonStyles} from '../../common/styles/commonStyles';
import DirectusFeedback from '../../common/DirectusFeedback';
import {Service} from '../../../types';

export interface AvailableDepartmentsApiResponse {
    data: {
        id: number,
        name: string,
        address: {
            island: string,
            county: string,
            parish: string
        }
        services: Array<Service>
    },
    error: Error,
    isLoading: boolean
}

interface Props {
    selectedService: Service
    onSelectService: (selectedService: object) => void
    showOwnCapacity?: boolean
    filterByDepartmentId?: number
}

let radioChecked;

export const AvailableDepartmentsList = ({
     selectedService,
     onSelectService,
     showOwnCapacity,
     filterByDepartmentId
 }: Props) => {
    const [pageSize, setPageSize] = useState(3);
    const [selectedDepartment, setSelectedDepartment] = useState<string>(null);
    const [selectionModel, setSelectionModel] = useState(['']);
    const contactHandler = (selectedDepartment) => setSelectedDepartment(selectedDepartment.name);

    const {
        data: availableServices,
        error: errorGetAvailableServices,
        isLoading: isLoadingGetAvailableServices
    } = useDirectusQuery<AvailableDepartmentsApiResponse>({
        path: '/items/services',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'services_platform_id': {'_eq': selectedService?.id}
                }
            }
        }
    });

    if (!availableServices?.length) {
        if (showOwnCapacity) {
            return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                <Typography>{t`OWN CAPACITY`}</Typography>
                <RadioGroup value={'true'} row>
                    <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>} disabled/>
                    <FormControlLabel value={'false'} label={t`No`} control={<Radio/>} disabled/>
                </RadioGroup>
            </Box>
        }
        return <Typography>{t`There are no available entities at the moment.`}</Typography>
    }

    let services = availableServices;

    if (filterByDepartmentId) {
        services = availableServices.filter((service) => service?.department_id?.id === filterByDepartmentId);

        if (!services?.length && showOwnCapacity) {
            return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                <Typography>{t`OWN CAPACITY`}</Typography>
                <RadioGroup value={'true'} row>
                    <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>} disabled/>
                    <FormControlLabel value={'false'} label={t`No`} control={<Radio/>} disabled/>
                </RadioGroup>
            </Box>
        }
    }

    const handleSelectedServiceChange = (newSelectionModel) => {
        onSelectService(availableServices?.filter((service) => service.id === newSelectionModel[0])[0]);
    };

    radioChecked = selectionModel;

    return (
        <>
            {showOwnCapacity &&
                <Box sx={commonStyles.spaceBetween}>
                    <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                    {!selectionModel[0] &&
                        <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                    }
                </Box>
            }
            <DataGrid
                pageSize={pageSize}
                rows={services || []}
                sx={commonStyles.datagrid}
                components={dataGridComponents}
                rowsPerPageOptions={[3, 5, 10]}
                rowCount={services?.length || 0}
                getRowHeight={() => 'auto'}
                columns={columns({contactHandler, radioChecked})}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    handleSelectedServiceChange(newSelectionModel);
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                selectionModel={selectionModel}
                disableExtendRowFullWidth
                autoHeight
            />
            {/*<ContactDialog*/}
            {/*    open={Boolean(selectedDepartment)}*/}
            {/*    entityToContact={selectedDepartment}*/}
            {/*    onSubmit={handleContactDepartment}*/}
            {/*    onClose={() => setSelectedDepartment(null)}*/}
            {/*/>*/}
            <DirectusFeedback
                error={errorGetAvailableServices}
                isLoading={isLoadingGetAvailableServices}
            />
        </>
    );
};

AvailableDepartmentsList.defaultProps = {
    showOwnCapacity: false
}

export default AvailableDepartmentsList;