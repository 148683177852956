import axios from 'axios';

const pdfHeaderImage = require('../../assets/images/background-header.jpg');


export const PPGRCD_STATUS = {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED'
}

export const generatePpgrcdHtmlTemplate = (auth_service_url, ppgrcd, locationsImages) => {

    const locationsWithUpdatedImages = ppgrcd?.form_work_general[0]?.locations?.map((location) =>
        location.shapefiles.length > 0
            ? {
                ...location,
                base64: `data:image/png;base64, ${locationsImages?.map((locationWithImage) => locationWithImage.filter((image) => image.locationId === location.id))[0][0]?.imgBase64}`
            }
            : location
    );

    axios.post(
        `${auth_service_url}/generate-ppgrcd-template`,
        {
            ppgrcd: {
                forms: {
                    form_conditioning_sorting: ppgrcd.form_conditioning_sorting[0],
                    form_entity_responsible: ppgrcd.form_entity_responsible[0],
                    form_incorporation_methodology: ppgrcd.form_incorporation_methodology[0],
                    form_plan_closure: ppgrcd.form_plan_closure[0],
                    form_prevention_methodology: ppgrcd.form_prevention_methodology[0],
                    form_production_operation: ppgrcd.form_production_operation[0],
                    form_soil_contamination: ppgrcd.form_soil_contamination[0],
                    form_substances_objects: ppgrcd.form_substances_objects[0],
                    form_usage_methodology: ppgrcd.form_usage_methodology[0],
                    form_waste_management: ppgrcd.form_waste_management[0],
                    form_work_general: {
                        ...ppgrcd.form_work_general[0],
                        locations: locationsWithUpdatedImages
                    },
                    form_work_specifics: ppgrcd.form_work_specifics
                },
                users: ppgrcd.users,
                image: pdfHeaderImage
            }
        }
    ).then(res => {
        const page = window.open('', '', 'height=600,width=1100');
        page.document.write('<style>@page{size:landscape;}</style><html><head><title></title>');
        page.document.write('</head><body >');
        page.document.write(res.data);
        page.document.write('</body></html>');

        setTimeout(() => {
            page.print();
        }, 500);
    });
}