import React from 'react';
import {Box, Card, CardActions, CardContent} from '@mui/material';
import commonStyles from '../styles/commonStyles';

interface Props {
    backgroundImg?: string,
    bottomLabel: React.ReactElement,
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const GraphicCard = ({backgroundImg, bottomLabel, onClick}: Props) => {

    return (
        <Card sx={{...commonStyles.card(), height: '100%', cursor: 'pointer'}} onClick={onClick}>
            <CardContent sx={styles.cardContent(backgroundImg)}>
                <Box
                    component={'img'}
                    sx={styles.cardContent(backgroundImg)}
                />
            </CardContent>
            <CardActions sx={{p: 0}}>
                {bottomLabel}
            </CardActions>
        </Card>
    );
};

const styles = {
  cardContent: (backgroundImg = null) => ({
      p: 0,
      height: '12rem',
      backgroundImage: backgroundImg ? `url(${backgroundImg})` : 'unset',
      backgroundPosition: backgroundImg ? 'center': 'unset',
      backgroundSize: backgroundImg ? 'cover' : 'unset'
  })
};

GraphicCard.defaultProps = {
    onClick: () => null
};

export default GraphicCard;