import {t} from 'ttag';
import {toTitleCase} from '../../../../utils/formatters/formatters';
import {sanitizeDatetime} from '../../../../utils/datetime/datetime-utils';


export const columns = () => [
  {
    field: 'action',
    headerName: t`ACTION`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => toTitleCase(row.action)
  },
  {
    field: 'collection',
    headerName: t`COLLECTION`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'timestamp',
    headerName: t`TIMESTAMP`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => sanitizeDatetime(row.timestamp)
  }
];

export const helper = {
  columns
};

export default helper;
