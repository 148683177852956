import * as Yup from 'yup';
import {t} from 'ttag';


export const WorkGeneralFormSchema = Yup.object().shape(
    {
        name: Yup.string().required(t`Required field.`),
        cpv_code: Yup.string(),
        environmental_impact: Yup.string(),
        estimated_start_date: Yup.string().required(t`Required field.`),
        estimated_duration: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        estimated_duration_base_unit: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        address_id: Yup.object().when('locations', {
            is: (locations) => !locations.length,
            then: Yup.object().shape({
                address: Yup.string().required(t`Required field.`),
                island: Yup.string().required(t`Required field.`),
                parish: Yup.string().required(t`Required field.`),
                county: Yup.string().required(t`Required field.`),
                insertion_date: Yup.string().required(t`Required field.`)
            })
        }).shape({
            address: Yup.string(),
            island: Yup.string(),
            parish: Yup.string(),
            county: Yup.string()
        })
    }
).required();
