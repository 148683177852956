import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Controller, useFormContext} from 'react-hook-form';
import {Box, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import DatetimePicker from '../../../../common/date-time-picker/DatetimePicker';
import LocationsListForm from '../../../ppgrcd/locations/LocationsListForm';
import {Attachments} from '../../../../common/attachments/Attachments.js';
import FormSelectorsRAA from '../../../../common/forms/FormSelectorsRAA';
import commonStyles from '../../../../common/styles/commonStyles';
import {Address} from '../../../../../types';
import DirectusFeedback from '../../../../common/DirectusFeedback';

interface Location {
    id?: string
    address_id: Address,
    shapefiles: [],
    insertion_date: string
}

interface Props {
    locations: Location[]
    onAddLocation: () => void
    onEditLocation: (locationId: number) => void
    onSaveEditLocation: (callback: () => void) => void
    onRemoveLocation: (idx: number) => void
    readOnly: boolean
}

export const WorkGeneralFormDetails = ({
                                           locations,
                                           onAddLocation,
                                           onEditLocation,
                                           onSaveEditLocation,
                                           onRemoveLocation,
                                           readOnly
                                       }: Props) => {
    const [errorFileSize, setErrorFileSize] = useState(null);
    const [editingLocation, setEditingLocation] = useState(null);
    const {watch, resetField, getValues} = useFormContext();

    const handleFinishEditing = () => {
        setEditingLocation(null);
        resetField('address_id');
        resetField('shapefiles');
        resetField('insertion_date');
    };

    const searchOnGoogleMapsHandler = () => {
        window.open(`https://www.google.com/maps/place/${getValues('latitude')},${getValues('longitude')}`, '_blank');
    };

    return (
        <>
            <Grid spacing={3} container>
                <Grid xs={12} md={12} item>
                    <Typography>{t`General data about work`}</Typography>
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'name'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Designation` + '*'}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'cpv_code'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`CPV Code`}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'environmental_impact'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Environmental impact analysis`}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'estimated_start_date'}
                        render={({field, fieldState: {error}}) => (
                            <DatetimePicker
                                {...field}
                                variant={'DATE_ONLY'}
                                label={t`Estimated start date` + '*'}
                                helperText={error?.message}
                                error={Boolean(error)}
                                disabled={readOnly}
                                disablePast
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'estimated_duration'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Estimated duration` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} sx={commonStyles.flexStartContent} item>
                    <Controller
                        name={'estimated_duration_base_unit'}
                        render={({field}) => (
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'months'} label={t`Months`} control={<Radio/>}
                                                  disabled={readOnly}/>
                                <FormControlLabel
                                    value={'days'}
                                    control={<Radio/>}
                                    label={<Box display={'flex'}><Typography>{t`Days`}</Typography></Box>}
                                    disabled={readOnly}
                                />
                            </RadioGroup>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Typography>{t`Identification of implantation sites`}</Typography>
                </Grid>
                <Grid xs={12} md={12} item>
                    <LocationsListForm
                        locations={locations}
                        onEditLocation={(locationId) => {
                            setEditingLocation(locationId);
                            onEditLocation(locationId);
                        }}
                        onDeleteLocation={onRemoveLocation}
                        readOnly={readOnly}
                    />
                </Grid>
                <FormSelectorsRAA editable={!readOnly}/>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'address_id.address'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Address` + '*'}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={4} item>
                    <Controller
                        name={'latitude'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Latitude`}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={4} item>
                    <Controller
                        name={'longitude'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Longitude`}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid sx={commonStyles.centerContentAndItems} xs={12} md={4} item>
                    <Button
                        sx={{width: '100%', height: '100%'}}
                        variant={'contained'}
                        disabled={!(watch('latitude') && watch('longitude'))}
                        onClick={searchOnGoogleMapsHandler}
                    >
                        {t`Search on Google Maps`}
                    </Button>
                </Grid>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'shapefiles'}
                        render={({field, fieldState: {error}}) => (
                            <Attachments
                                attachments={field.value}
                                dropAttachmentsLabel={t`Insert image to identify the work`}
                                onChange={field.onChange}
                                onFilesRejected={(filesRejected) => {
                                    if (filesRejected.length > 0) {
                                        setErrorFileSize({message: t`The file(s) size should be less than 1mb.`})
                                    }
                                }}
                                error={Boolean(error)}
                                disabled={readOnly}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Button
                        variant={'outlined'}
                        onClick={
                            editingLocation
                                ? () => onSaveEditLocation(handleFinishEditing)
                                : () => onAddLocation()
                        }
                        disabled={
                            readOnly || (
                                !watch('address_id.address') ||
                                !watch('address_id.island') ||
                                !watch('address_id.county') ||
                                !watch('address_id.parish')
                            )
                        }
                        fullWidth
                    >
                        {!editingLocation && <Add sx={styles.headerAddIcon}/>}
                        {editingLocation ? t`SAVE CHANGES` : t`Add Location`}
                    </Button>
                </Grid>
            </Grid>
            <DirectusFeedback isLoading={false} error={errorFileSize}/>
        </>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default WorkGeneralFormDetails;