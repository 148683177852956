import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {TabContext, TabPanel} from '@mui/lab';
import {Box, Stack, Tab, Typography} from '@mui/material';
import {NavigationBreadcrumbs} from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import NotificationsList from '../../components/user/notifications/NotificationsList';
import OperationsList from '../../components/user/operations/OperationsList';
import commonStyles from '../../components/common/styles/commonStyles';
import UpdateUserForm from '../../components/user/UpdateUserForm';
import BasicCard from '../../components/common/cards/BasicCard';
import {CustomizedTabs} from '../entities/CustomizedTabs';
import {useStore} from '../../store/valtio';

export const Profile = () => {
    const {app: {user}} = useStore();
    const [tab, setTab] = useState('1');

    if(!user) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`My Profile`}</Typography>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <NavigationBreadcrumbs
                    list={[
                        {name: 'Home', link: '/'},
                        {name: t`My Profile`, link: '/profile'}
                    ]}
                />
            </Box>
            <BasicCard
                headerContent={
                    <CustomizedTabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                    >
                        <Tab disableRipple label={t`All`} value={'1'}/>
                        <Tab disableRipple label={t`Operations History`} value={'2'}/>
                        <Tab disableRipple label={t`Notifications`} value={'3'}/>
                    </CustomizedTabs>
                }
            >
                <Stack sx={styles.tabContentWrapper}>
                    <TabContext value={tab}>
                        <TabPanel sx={{pb: 0}} value={'1'}>
                            <UpdateUserForm user={user}/>
                        </TabPanel>
                        <TabPanel sx={{p: 0}} value={'2'}>
                            <OperationsList height={500} userId={user?.id}/>
                        </TabPanel>
                        <TabPanel sx={{p: 0}} value={'3'}>
                            <NotificationsList height={500} notifications={user?.notifications}/>
                        </TabPanel>
                    </TabContext>
                </Stack>
            </BasicCard>
        </Stack>
    );
};

const styles = {
    tabContentWrapper: {
        padding: 1,
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center'
    }
};

export default Profile;