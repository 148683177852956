import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box, Button, FormHelperText, Grid, Stack, TextField, Typography} from '@mui/material';
import {emailIsValid, passwordIsValid} from '../../../utils/forms/helpers';
import {RegisterFormSchema} from './helpers/login-form-schema';
import commonStyles from '../../common/styles/commonStyles';

interface Props {
    onSubmitRegister: ({name: string, email: string, password: string}) => void
    errorRegister: boolean
}

export const RegisterForm = ({onSubmitRegister, errorRegister}: Props) => {

    const methods = useForm({
        resolver: yupResolver(RegisterFormSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        }
    });

    const handleSubmitLoginForm = methods.handleSubmit((values) => {
        onSubmitRegister(values)
    });

    return (
        <FormProvider {...methods}>
            <Stack width={'100%'} spacing={3}>
                <Grid spacing={3} container>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'first_name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder={t`First Name` + ' *'}
                                    sx={commonStyles.textField}
                                    variant={'outlined'}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'last_name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder={t`Last Name` + ' *'}
                                    sx={commonStyles.textField}
                                    variant={'outlined'}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Controller
                    name={'email'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Email` + ' *'}
                            sx={commonStyles.textField}
                            variant={'outlined'}
                            fullWidth
                            required
                        />
                    )}
                />
                {errorRegister &&
                    <Box sx={commonStyles.flexStartContent}>
                        <Typography color={'error'}>{t`This email is already in use.`}</Typography>
                    </Box>
                }
                <Controller
                    name={'password'}
                    render={({field, fieldState: {error}}) => (
                        <>
                            <TextField
                                {...field}
                                type={'password'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`Password` + ' *'}
                                sx={commonStyles.textField}
                                variant={'outlined'}
                                fullWidth
                                required
                            />
                            {!passwordIsValid(methods.watch('password')) &&
                                <FormHelperText error={Boolean(!passwordIsValid(methods.watch('password')))}>
                                    {t`The password must be a minimum of 8 characters.`}
                                </FormHelperText>
                            }
                        </>
                    )}
                />
                <Box sx={commonStyles.flexStartContent}>
                    <Typography fontSize={'0.875rem'}>
                        {t`By registering you agree to the platform`} <a href={'https://plataforma-rebuild17.obraspublicas.azores.gov.pt/terms-and-conditions'} target={'_blank'}>{t`terms and conditions`}.</a>
                    </Typography>
                </Box>
                <Button
                    variant={'contained'}
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmitLoginForm}
                    disabled={
                        !emailIsValid(methods.watch('email')) ||
                        !passwordIsValid(methods.watch('password')) ||
                        !methods.watch('first_name') ||
                        !methods.watch('last_name')
                    }
                    fullWidth
                >
                    {t`Register`}
                </Button>
            </Stack>
        </FormProvider>
    );
};

export default RegisterForm;