import {t} from 'ttag';
import * as React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import commonStyles from '../../../components/common/styles/commonStyles';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddLocationForm from '../../../components/ppgrcds/ppgrcd/locations/location/AddLocationForm';


export const AddLocation = () => {
    const navigate = useNavigate();
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();
    const navigateToPPGRCD = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`);

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD LOCATION`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Entities`, link: `/entities`},
                    {name: entityName, link: `/entities/${entityName}/${entityId}`},
                    {
                        name: departmentName,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`
                    },
                    {
                        name: `PPGRCD ${ppgrcdId}`,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`
                    },
                    {
                        name: t`Add Location`,
                        link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}/add-location`
                    },
                ]}
            />
            <AddLocationForm onSubmitCallback={navigateToPPGRCD}/>
        </Stack>
    );
};

export default AddLocation;