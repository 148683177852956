import {t} from 'ttag';
import * as Yup from 'yup';


export const AddCommentFormSchema = Yup.object().shape(
    {
        title: Yup.string().required(t`Required field.`),
        comment: Yup.string().required(t`Required field.`),
    }
).required();
