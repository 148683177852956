import axios from 'axios';
import {useEffect, useState} from 'react';
import {useDirectus} from 'react-directus';
import {DirectusQueryResponse} from '../../types';
import {useStore} from '../../store/valtio';
import {arrayBufferToBase64} from '../../utils/forms/helpers';

interface Args {
    path: string,
    callback?: () => void,
    options?: {
        refetchOnChange?: any
    }
}

// eslint-disable-next-line
export const useDirectusAssets = <Type extends DirectusQueryResponse>(args: Args) => {
    const {app: {configs}} = useStore();
    const {directus} = useDirectus();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        axios.get(`${configs.api_url}${args.path}`, {
            headers: {Authorization: `Bearer ${directus.storage.auth_token}`},
            responseType: 'arraybuffer'
        }).then((res) => {
                const base64 = arrayBufferToBase64(res.data);
                setData("data:image;base64," + base64);
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line
    }, [directus, args.options?.refetchOnChange]);

    return {data: data || null, error: error || null, isLoading};
};

export default useDirectusAssets;