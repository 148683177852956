import {t} from 'ttag';
import * as Yup from 'yup';


export const AddTransportResidualServiceFormSchema = Yup.object().shape(
    {
        service_platform_id: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        description: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
