import {t} from 'ttag';
import * as Yup from 'yup';


export const UpdateCollaboratorFormSchema = Yup.object().shape(
    {
        role: Yup.string().required(t`Required field.`),
        first_name: Yup.string().required(t`Required field.`),
        last_name: Yup.string().required(t`Required field.`),
        email: Yup.string()
            .required(t`Required field.`)
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, t`Should be a valid email.`)
    }
).required();
