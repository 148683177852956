import {t} from 'ttag';
import React from 'react';
import {DateTime as dt} from 'luxon';
import SubMenu from '../../../../common/grid/SubMenu';
import {createDatetime, DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import {Stack, Typography} from '@mui/material';


export const columns = ({isEditing, editHandler, deleteHandler, readOnly}) => [
    {
        field: 'name',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`RECYCLED`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`MATERIALS`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2
    },
    {
        field: 'amount',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`INCORPORATED`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`AMOUNT`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2
    },
    {
        field: 'base_unit',
        headerName: t`BASE UNIT`,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2,
        renderCell: ({row}) => (
            row.base_unit === 'CUBIC_METER'
                ? 'm3'
                : row.base_unit === 'TON'
                    ? 'tons' : '-'
        )
    },
    {
        field: 'amount_percentage',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`% INCORPORATED`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`FROM TOTAL`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        flex: 2
    },
    {
        field: 'insertion_date',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        editable: !readOnly,
        flex: 2,
        renderCell: ({row}) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
    },
    {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        editable: !readOnly,
        flex: .5,
        renderCell: ({row}) => {
            return (
                <SubMenu
                    disabled={isEditing || readOnly}
                    onDelete={() => deleteHandler(row)}
                    onEdit={() => editHandler(row)}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
