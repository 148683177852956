import store from './store';

export const actions = {
  app: {
    setConfigs: (value) => (store.app.configs = value),
    setIsDesktop: (value) => (store.app.isDesktop = Boolean(value)),
    setIsMobile: (value) => (store.app.isMobile = Boolean(value)),
    setIsDesktopLg: (value) => (store.app.isDesktopLg = Boolean(value)),
    setLanguage: (value) => (store.app.language = value),
    setLerCodes: (value) => (store.app.lerCodes = value),
    setValorizationOperations: (value) => (store.app.valorizationOperations = value),
    setEliminationOperations: (value) => (store.app.eliminationOperations = value),
    setUser: (value) => (store.app.user = value),
    setInvalidatedUser: (value) => (store.app.invalidatedUser = value),
    setApiError: (value) => (store.app.apiError = value),
    setDesiredResidueType: (value) => (store.app.desiredResidueType = value),
    setUploadedLocationFiles: (value) => (store.app.uploadedLocationFiles = value),
    setResetPasswordToken: (value) => (store.app.resetPasswordToken = value),
  },
};

export default actions;
