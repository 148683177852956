import {t} from 'ttag';
import React from 'react';
import {Box, Card, Typography} from '@mui/material';
import commonStyles from '../../common/styles/commonStyles';

const landingPageBackground2 = require('../../../assets/images/landing-page-card-background-2.png');


export const ValorizationProcessesCard = () => {
    return (
        <Card sx={{...commonStyles.card(), ...styles.card}}>
            <Box sx={styles.contentWrapper}>
                <Typography color={'common.white'} sx={styles.residuesTitle}>
                    {t`Valorization Processes`}
                </Typography>
                <Typography textAlign={'center'} color={'common.white'} sx={styles.residuesDescription}>
                    {t`Convert your residues to money and make everyone happier.`}
                </Typography>
            </Box>
        </Card>
    );
};

const styles = {
    card: {
        backgroundImage: `url(${landingPageBackground2})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    contentWrapper: {
        px: 1,
        py: 5,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    residuesTitle: {
        fontSize: '1.8rem',
        fontWeight: 'bold'
    },
    residuesDescription: {
        fontSize: '0.875rem'
    },
    btn: {
        px: 3,
        fontWeight: 'bold',
        color: 'common.white',
        backgroundColor: 'success.dark',
        '&:hover': {
            color: 'text.secondary',
            backgroundColor: 'common.white'
        },
    }
}

export default ValorizationProcessesCard;