import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../../common/grid/SubMenu';


export const columns = ({ editHandler, deleteHandler }) => [
  {
    field: 'type',
    headerName: t`TYPE`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => (
      row.services_platform_id?.type === 'ADVANCED'
        ? 'Serviços Avançados'
        : row.services_platform_id?.type === 'PRE_TREATMENT'
          ? 'Serviços de Pré-Tratamento'
            : row.services_platform_id?.type === 'TRANSPORTATION'
              ? 'Serviços de Transporte'
              : row.services_platform_id?.type === 'CONSULTING'
                ? 'Serviços de Consulta'
                : 'Operador de Resíduos'
    )
  },
  {
    field: 'subtype',
    headerName: t`SUBTYPE`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => row?.services_platform_id ? row?.services_platform_id?.subtype_title : 'N/A'
  },
  {
    field: 'actions',
    headerName: '',
    type: 'number',
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => {
      return (
        <SubMenu
            editAction={false}
            onEdit={() => editHandler(row)}
            onDelete={() => deleteHandler(row)}
        />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
