import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {hasSomeOf, PPGRCD_ROLES} from '../../../../../../utils/user/user-utils';
import commonStyles from '../../../../../common/styles/commonStyles';
import {PpgrcdRoles} from '../../../../../../mocks';
import {User} from '../../../../../../types';

interface Props {
    authenticatedUser: User
    editable?: boolean
}

export const AddParticipantFormDetails = ({authenticatedUser, editable}: Props) => {

    const allowedPpgrcdRoles = PpgrcdRoles.filter((role) => (
        hasSomeOf(authenticatedUser, [PPGRCD_ROLES.DRAFTSMAN, PPGRCD_ROLES.CONTRACTOR, PPGRCD_ROLES.FISCAL])
            ? role.value === authenticatedUser?.role
            : role
    ));

    if (!allowedPpgrcdRoles) return null;

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'email'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`E-mail` + '*'}
                            sx={commonStyles.textField}
                            variant={"outlined"}
                            disabled={!editable}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'role'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel sx={styles.inputLabel} id={'role-label'} required>{t`Role`}</InputLabel>
                            <Select
                                {...field}
                                label={t`Role`}
                                labelId={'role-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {allowedPpgrcdRoles?.map((role, key) => (
                                    <MenuItem key={key} value={role.value}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

AddParticipantFormDetails.defaultProps = {
    editable: true
}

export default AddParticipantFormDetails;