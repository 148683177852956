import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Card, Grid, Stack, Typography} from '@mui/material';
import ValorizationProcessesCard from '../../components/valorization-processes/custom/ValorizationProcessesCard';
import NotificationsList from '../../components/user/notifications/NotificationsList';
import ResiduesListSummarized from '../../components/residues/ResiduesListSummarized';
import EntitiesListSummarized from '../../components/entities/EntitiesListSummarized';
import PPGRCDsListSummarized from '../../components/residues/PPGRCDsListSummarized';
import useDirectusQuery from '../../components/custom-hooks/useDirectusQuery';
import {ResiduesApiResponse} from '../../components/residues/ResiduesList';
import ResiduesCard from '../../components/residues/custom/ResiduesCard';
import DirectusFeedback from '../../components/common/DirectusFeedback';
import commonStyles from '../../components/common/styles/commonStyles';
import ProgressCard from '../../components/common/cards/ProgressCard';
import BasicCard from '../../components/common/cards/BasicCard';
import {User} from '../../types';

interface Props {
    user: User
}

export const LandingPageContent = ({user}: Props) => {
    const navigate = useNavigate();

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": user.id
                                }
                            }
                        }
                    }
                }
            }
        }
    });

    const {
        data: valorizationProcesses,
        error: errorGetValorizationProcesses,
        isLoading: isLoadingGetValorizationProcesses
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/valorization_process',
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    if (!residues || !valorizationProcesses) return null;

    const completedValorizationProcesses = valorizationProcesses?.filter((valorizationProcess) =>
        (residues.filter(residue => valorizationProcess.residue_id === residue.id))
        && (valorizationProcess.status === 'COMPLETED')
    );

    return (
        <>
            <Grid spacing={4} pb={4} container>
                <Grid xs={12} lg={6} item>
                    <Stack sx={styles.stack} spacing={2}>
                        <ResiduesCard/>
                        <ProgressCard
                            title={t`Percentage of valorized residues`}
                            infoText={`${completedValorizationProcesses.length} ` + t`residues out of` + ` ${residues.length}`}
                            progressPercentage={residues.length === 0 ? 0 : ((completedValorizationProcesses.length / residues.length) * 100)}
                        />
                    </Stack>
                </Grid>
                <Grid xs={12} lg={6} item>
                    <Stack sx={styles.stack} spacing={2}>
                        <Card sx={commonStyles.card()}>
                            <Stack spacing={1} p={2}>
                                <Typography sx={styles.notificationsTitle}>{t`Nofitications`}</Typography>
                                <NotificationsList notifications={user?.notifications} height={240} hideFooter/>
                            </Stack>
                        </Card>
                        <ValorizationProcessesCard/>
                    </Stack>
                </Grid>
                <Grid xs={12} lg={4} item>
                    <BasicCard headerContent={<Typography px={2}>{t`Active Entities`}</Typography>}>
                        <Stack spacing={1}>
                            <EntitiesListSummarized height={250} filterByUser={user}/>
                            <Box sx={commonStyles.flexEndContent}>
                                <Button onClick={() => navigate('/entities')}>{t`SEE MORE`}</Button>
                            </Box>
                        </Stack>
                    </BasicCard>
                </Grid>
                <Grid xs={12} lg={4} item>
                    <BasicCard headerContent={<Typography px={2}>{t`Active Residues`}</Typography>}>
                        <Stack spacing={1}>
                            <ResiduesListSummarized height={250} filterByUser={user}/>
                            <Box sx={commonStyles.flexEndContent}>
                                <Button onClick={() => navigate('/residues')}>{t`SEE MORE`}</Button>
                            </Box>
                        </Stack>
                    </BasicCard>
                </Grid>
                <Grid xs={12} lg={4} item>
                    <BasicCard headerContent={<Typography px={2}>{t`Active PPGRCD's`}</Typography>}>
                        <Stack spacing={1}>
                            <PPGRCDsListSummarized height={250} filterByUser={user}/>
                            <Box sx={commonStyles.flexEndContent}>
                                <Button onClick={() => navigate('/ppgrcds')}>{t`SEE MORE`}</Button>
                            </Box>
                        </Stack>
                    </BasicCard>
                </Grid>
            </Grid>
            <DirectusFeedback
                error={errorGetResidues || errorGetValorizationProcesses}
                isLoading={isLoadingGetResidues || isLoadingGetValorizationProcesses}
            />
        </>
    );
};

const styles = {
    stack: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    notificationsTitle: {
        color: 'text.secondary',
        fontSize: '1.3rem',
        fontWeight: 'bold'
    }
}

export default LandingPageContent;