import {t} from 'ttag';
import React from 'react';
import {Stack} from '@mui/material';
import {DateTime as dt} from 'luxon';
import SubstancesAndObjectsFormDetails from './substances-and-objects-form/SubstancesAndObjectsFormDetails';
import FormSkeleton from '../../../common/forms/FormSkeleton';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {DATETIME_FORMAT} from '../../../../utils/datetime/datetime-utils';

interface Props {
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const SubstancesAndObjectsForm = ({onSubmit, onReturn, readOnly}: Props) => {
    const {getValues, resetField, setValue} = useFormContext();
    const {fields: substances, append, replace} = useFieldArray({
        name: 'substances'
    });

    const handleAddSubstance = () => {
        append({
            name: getValues('name'),
            reuse_quantity: getValues('reuse_quantity'),
            base_unit: getValues('base_unit'),
            reuse_quantity_percentage: getValues('reuse_quantity_percentage'),
            destination: getValues('destination'),
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('name');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('destination');
        resetField('insertion_date');
    };

    const handleEditSubstance = (substanceId) => {
        const wantedSubstanceToEdit = substances.filter((substance) => substance.id === substanceId)[0];
        setValue('name', wantedSubstanceToEdit.name);
        setValue('reuse_quantity', wantedSubstanceToEdit.reuse_quantity);
        setValue('base_unit', wantedSubstanceToEdit.base_unit);
        setValue('reuse_quantity_percentage', wantedSubstanceToEdit.reuse_quantity_percentage);
        setValue('destination', wantedSubstanceToEdit.destination);
        setValue('insertion_date', wantedSubstanceToEdit.insertion_date);
        handleRemoveSubstance(substanceId);
    }

    const handleSaveEditSubstance = (callback: () => null) => {
        append({
            name: getValues('name'),
            reuse_quantity: getValues('reuse_quantity'),
            base_unit: getValues('base_unit'),
            reuse_quantity_percentage: getValues('reuse_quantity_percentage'),
            destination: getValues('destination'),
            insertion_date: getValues('insertion_date')
        })
        callback();
    };

    const handleRemoveSubstance = (substanceId) => {
        let filteredSubstances = substances.filter((substance) => substance.id !== substanceId);
        replace(filteredSubstances);
    };

    return (
        <Stack gap={3} py={2}>
            <FormSkeleton
                mainGroupContent={
                    <SubstancesAndObjectsFormDetails
                        substances={substances}
                        onAddSubstance={handleAddSubstance}
                        onEditSubstance={handleEditSubstance}
                        onRemoveSubstance={handleRemoveSubstance}
                        onSaveEditSubstance={handleSaveEditSubstance}
                        readOnly={readOnly}
                    />
                }
                submitBtnText={t`Next`}
                cancelBtnText={t`Return`}
                onSubmit={onSubmit}
                onCancel={onReturn}
                actions={!readOnly}
                innerForm
            />
        </Stack>

    );
};

export default SubstancesAndObjectsForm;