import {useSnackbar} from 'notistack';
import React, {useEffect} from 'react';
import LoadingScreen from './LoadingScreen';

interface Props {
    data?: any,
    error?: {
        data: { description: string }
        message: string
    },
    isLoading: boolean,
    isSuccess?: boolean,
    successMessage?: string,
    snackbarOnErrorOptions?: object,
    snackbarOnSuccessOptions?: object
}

export const DirectusFeedback = ({
   data,
   error,
   isLoading,
   isSuccess,
   successMessage,
   snackbarOnErrorOptions,
   snackbarOnSuccessOptions
}: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (data || isSuccess) {
            enqueueSnackbar(successMessage, snackbarOnSuccessOptions);
        }
    }, [successMessage, data, isSuccess, enqueueSnackbar, snackbarOnSuccessOptions]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error.data?.description || error.message, snackbarOnErrorOptions);
        }
    }, [error, enqueueSnackbar, snackbarOnErrorOptions]);

    return isLoading ? <LoadingScreen/> : null;
};

DirectusFeedback.defaultProps = {
    data: null,
    errorGetEntity: null,
    isLoadingGetEntity: false,
    isSuccess: false,
    successMessage: 'Success',
    snackbarOnErrorOptions: {variant: 'error'},
    snackbarOnSuccessOptions: {variant: 'success'}
};

export default DirectusFeedback;
