export const APP_ROLES = {
  ADMIN: 'Administrator',
  ADMIN_GOLD: 'AdminGold',
  ADMIN_GENERAL: 'AdminGeneral',
  PUBLIC: 'Public',
  RESERVED: 'Reserved',
  STATISTICS: 'Statistics',
};

export const ENTITY_ROLES = {
  MEMBER: 'MEMBER',
  COORDINATOR: 'COORDINATOR',
  MANAGER: 'MANAGER'
};

export const PPGRCD_ROLES = {
  OWNER: 'OWNER',
  OWNER_REPRESENTATIVE: 'OWNER_REPRESENTATIVE',
  DRAFTSMAN: 'DRAFTSMAN',
  CONTRACTOR: 'CONTRACTOR',
  FISCAL: 'FISCAL'
};

export const hasSomeOf = (user, roles = []) =>
  !roles.length || roles.some((rName) => (user?.role?.name?.toLowerCase() || user?.role?.toLowerCase()) === rName?.toLowerCase());

export const hasEveryOf = (user, roles = []) =>
  !roles.length || roles.every((rName) => (user?.role?.name?.toLowerCase() || user?.role?.toLowerCase()) === rName?.toLowerCase());
