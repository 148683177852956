import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {ResetPasswordFormSchema} from './helpers/reset-password-form-schema';
import commonStyles from '../../common/styles/commonStyles';
import {passwordIsValid} from '../../../utils/forms/helpers';

interface Props {
    onSubmit: (password: string) => void
}

export const ResetPasswordForm = ({onSubmit}: Props) => {

    const methods = useForm({
        resolver: yupResolver(ResetPasswordFormSchema),
        defaultValues: {
            password: '',
            passwordConfirmation: ''
        }
    });

    const handleSubmitResetPasswordForm = methods.handleSubmit((values) => {
        onSubmit(values.password)
    });

    return (
        <FormProvider {...methods}>
            <Stack width={'100%'} spacing={3}>
                <Controller
                    name={'password'}
                    render={({field, fieldState: {error}}) => (
                        <>
                            <TextField
                                {...field}
                                type={'password'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`New password` + ' *'}
                                sx={commonStyles.textField}
                                variant={'outlined'}
                                fullWidth
                                required
                            />
                            {!passwordIsValid(methods.watch('password')) &&
                                <Box sx={commonStyles.flexStartContent}>
                                    <Typography fontSize={'0.875rem'} color={'error'}>
                                        {t`The password must be a minimum of 8 characters.`}
                                    </Typography>
                                </Box>
                            }
                        </>
                    )}
                />
                <Controller
                    name={'passwordConfirmation'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'password'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Reenter new password` + ' *'}
                            sx={commonStyles.textField}
                            variant={'outlined'}
                            fullWidth
                            required
                        />
                    )}
                />
                {
                    (methods.watch('passwordConfirmation') && methods.watch('password')) &&
                    (methods.watch('passwordConfirmation') !== methods.watch('password')) &&
                    <Box sx={commonStyles.flexStartContent}>
                        <Typography fontSize={'0.875rem'} color={'error'}>
                            {t`This password does not match the first one.`}
                        </Typography>
                    </Box>
                }
                <Button
                    variant={'contained'}
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmitResetPasswordForm}
                    disabled={
                        !methods.watch('passwordConfirmation') ||
                        !methods.watch('password') ||
                        (methods.watch('passwordConfirmation') !== methods.watch('password'))
                    }
                    fullWidth
                >
                    {t`Reset Password`}
                </Button>
            </Stack>
        </FormProvider>
    );
};

export default ResetPasswordForm;