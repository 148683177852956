import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {TabContext, TabPanel} from '@mui/lab';
import {Box, Button, Stack, Tab, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import WantedResiduesOthersList from '../../components/residues/WantedResiduesOthersList';
import PatternSearch from '../../components/common/pattern-search/PatternSearch';
import WantedResiduesList from '../../components/residues/WantedResiduesList';
import commonStyles from '../../components/common/styles/commonStyles';
import AuthorizationGuard from '../common/guards/AuthorizationGuard';
import BasicCard from '../../components/common/cards/BasicCard';
import {RESIDUE} from '../../components/residues/settings';
import {CustomizedTabs} from '../entities/CustomizedTabs';
import {APP_ROLES} from '../../utils/user/user-utils';
import {actions, useStore} from '../../store/valtio';


export const WantedResidues = () => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const [tab, setTab] = useState('1');
    const [filterByLerCode, setFilterByLerCode] = useState(null);

    const navigateHandler = () => {
        actions.app.setDesiredResidueType(RESIDUE);
        navigate('/add-wanted-residue');
    };

    if (!user) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Box sx={styles.box}>
                <Typography sx={commonStyles.pageTitle}>{t`WANTED RESIDUES`}</Typography>
                <AuthorizationGuard
                    userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={navigateHandler}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Wanted Residue`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Residues`, link: '/wanted-residues'}
                ]}
            />
            <BasicCard
                headerContent={
                    <CustomizedTabs value={tab} onChange={(_, value) => setTab(value)}>
                        <Tab
                            value={'1'}
                            label={t`My Residues`}
                            disableRipple
                        />
                        <Tab
                            value={'2'}
                            label={t`Searched By Others`}
                            disableRipple
                        />
                    </CustomizedTabs>
                }
            >
                <TabContext value={tab}>
                    <Stack spacing={2}>
                        <PatternSearch
                            placeholder={t`Search by ler code...`}
                            onChange={(filterByLerCode) => setFilterByLerCode(filterByLerCode)}
                        />
                        <TabPanel sx={styles.tabPanel} value={'1'}>
                            <WantedResiduesList filterByLerCode={filterByLerCode}/>
                        </TabPanel>
                        <TabPanel sx={styles.tabPanel} value={'2'}>
                            <WantedResiduesOthersList filterByLerCode={filterByLerCode}/>
                        </TabPanel>
                    </Stack>
                </TabContext>
            </BasicCard>
        </Stack>
    );
};

const styles = {
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    selectField: {
        borderRadius: 2
    },
    tabPanel: {
        p: 1
    }
};

export default WantedResidues;