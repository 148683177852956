import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate, useParams} from 'react-router-dom';
import useDirectusMutation from '../../../custom-hooks/useDirectusMutation';
import {commonStyles} from '../../../common/styles/commonStyles';
import DirectusFeedback from '../../../common/DirectusFeedback';
import {columns} from './helpers/departments-list-helpers';
import ConfirmDialog from '../../../common/ConfirmDialog';
import {Department, Entity} from '../../../../types';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface Props {
    departments: Department[]
    pattern?: string,
    onInvalidatedDepartments: () => any
}

export const DepartmentsList = ({departments, pattern, onInvalidatedDepartments}: Props) => {
    const navigate = useNavigate();
    const [departmentToDelete, setDepartmentToDelete] = useState<Department>(null);
    const departmentUrl = (department) => `/entities/${entityName}/${entityId}/departments/${department?.name}/${department?.id}`
    const {entityName, entityId} = useParams();

    const editHandler = (department) => navigate(departmentUrl(department));
    const viewHandler = (department) => navigate(departmentUrl(department));
    const deleteHandler = (department) => setDepartmentToDelete(department);

    const deleteDepartmentHandler = () => {
        deleteDepartment(onInvalidatedDepartments);
        setDepartmentToDelete(null);
    };

    const {
        actions: {DELETE: deleteDepartment},
        response: {
            error: errorDeleteDepartment,
            isLoading: isLoadingDeleteDepartment
        }
    } = useDirectusMutation({path: `/items/departments/${departmentToDelete?.id}`});

    const filteredDepartments = pattern ? departments?.filter((department) => department.name.includes(pattern)) : departments;

    if (!filteredDepartments) return null;

    return (
        <>
            <DataGrid
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                rows={filteredDepartments || []}
                components={dataGridComponents}
                rowCount={filteredDepartments?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                disableSelectionOnClick
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(departmentToDelete)}
                title={t`Delete Department`}
                onConfirm={deleteDepartmentHandler}
                onCancel={() => setDepartmentToDelete(null)}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${departmentToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorDeleteDepartment}
                isLoading={isLoadingDeleteDepartment}
            />
        </>
    );
};

export default DepartmentsList;