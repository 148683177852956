import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import PatternSearch from '../../components/common/pattern-search/PatternSearch';
import useDirectusQuery from '../../components/custom-hooks/useDirectusQuery';
import {EntitiesApiResponse} from '../../components/entities/EntitiesList';
import DirectusFeedback from '../../components/common/DirectusFeedback';
import commonStyles from '../../components/common/styles/commonStyles';
import AuthorizationGuard from '../common/guards/AuthorizationGuard';
import ResiduesList from '../../components/residues/ResiduesList';
import BasicCard from '../../components/common/cards/BasicCard';
import {APP_ROLES} from '../../utils/user/user-utils';
import {useStore} from '../../store/valtio';


export const Residues = () => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const [filterByLerCode, setFilterByLerCode] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState('all');
    const [selectedStatus, setSelectedStatus] = useState('all');

    const {
        data: entities,
        error: errorGetEntities,
        isLoading: isLoadingGetEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    "users": {
                        "directus_users_id": {"_eq": user?.id}
                    }
                }
            }
        }
    });

    if (!user) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box sx={styles.box}>
                    <Typography sx={commonStyles.pageTitle}>{t`RESIDUES`}</Typography>
                    <AuthorizationGuard
                        userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                        <Button variant={'contained'} onClick={() => navigate('/add-residue')}>
                            <Add sx={styles.headerAddIcon}/>
                            {t`Add Residue`}
                        </Button>
                    </AuthorizationGuard>
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Residues`, link: '/residues'}
                    ]}
                />
                <BasicCard headerContent={<></>}>
                    <Stack spacing={2}>
                        <Box sx={styles.box}>
                            <PatternSearch
                                placeholder={t`Search by ler code...`}
                                onChange={(pattern) => setFilterByLerCode(pattern)}
                            />
                            <Box display={'flex'} gap={1}>
                                <FormControl size={'small'} sx={{minWidth: '10rem'}}>
                                    <InputLabel id={'entities-label'}>{t`Status`}</InputLabel>
                                    <Select
                                        label={t`Status`}
                                        value={selectedStatus}
                                        sx={styles.selectField}
                                        labelId={'status-label'}
                                        onChange={({target: {value}}) => setSelectedStatus(value)}
                                    >
                                        <MenuItem value={'all'}>{t`All`}</MenuItem>
                                        <MenuItem value={'PUBLISHED'}>{t`Published`}</MenuItem>
                                        <MenuItem value={'UNPUBLISHED'}>{t`Unpublished`}</MenuItem>
                                        <MenuItem value={'CANCELLED'}>{t`Cancelled`}</MenuItem>
                                        <MenuItem value={'COMPLETED'}>{t`Completed`}</MenuItem>
                                        <MenuItem value={'VALORISATION'}>{t`Valorisation`}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl size={'small'} sx={{minWidth: '10rem'}}>
                                    <InputLabel id={'entities-label'}>{t`Entity`}</InputLabel>
                                    <Select
                                        label={t`Entity`}
                                        value={selectedEntity}
                                        sx={styles.selectField}
                                        labelId={'entities-label'}
                                        onChange={({target: {value}}) => setSelectedEntity(value)}
                                    >
                                        <MenuItem value={'all'}>{t`All Entities`}</MenuItem>
                                        {entities?.map((entity, key) => (
                                            <MenuItem key={key} value={entity.id}>
                                                {entity.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <ResiduesList user={user} filterByEntityId={selectedEntity} filterByStatus={selectedStatus}
                                      filterByLerCode={filterByLerCode}/>
                    </Stack>
                </BasicCard>
            </Stack>
            <DirectusFeedback error={errorGetEntities} isLoading={isLoadingGetEntities}/>
        </>
    );
};

const styles = {
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    selectField: {
        borderRadius: 2
    }
};

export default Residues;