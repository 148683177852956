export interface DirectusMutation {
    actions: [(body: object) => void]
    response: DirectusQueryResponse
}

export interface DirectusQueryResponse {
    data?: object | Array<any> | string
    error?: Error
    isLoading?: boolean
}

export interface ImageApiResponse {
    data: string,
    error: Error,
    isLoading: boolean
}

export interface DirectusFile {
    id: string
    storage: string
    filename_disk: string
    filename_download: string
    title: string
    type: string
    folder: string
    uploaded_by: string
    uploaded_on: string
    modified_by: string
    modified_on: string
    charset: string
    filesize: string
    width: string
    height: string
    duration: string
    embed: string
    description: string
    location: string
    tags: string
    metadata: object
}

export interface Collaborator {
    id: number,
    role: Role,
    directus_users_id: {
        first_name: string,
        last_name: string,
        email: string,
        avatar: string
    }
}

export interface User {
    id: number
    first_name: string
    last_name: string
    email: string
    password: string
    avatar: DirectusFile
    role: UserRole
    address_id: Address
    notifications: Notification[]
    status: string
}

export interface Entity {
    id: number
    name: string
    fiscal_number: number
    is_company: boolean
    cae?: CaeCode
    license?: string
    users: Collaborator[]
    departments: Department[]
}

export interface Department {
    id: number,
    name: string,
    is_hq: boolean,
    has_services: boolean
    entity_id: Entity
    address_id: Address
    services: Service[]
}

export interface Residue {
    id: number
    is_derived: boolean
    holder: ResidueHolder
    status: ResidueStatus
    contamination: ResidueContamination
    format: string
    availability_date: string
    amount: number
    amount_base_unit: ResidueBaseUnit
    origin_derived_id?: Residue
    derived_designation?: string
    prevention_plan_id?: PPGRCD
    entity_id: Entity
    residue_plan_id: ResiduePlan
    ler_code: LerCode
    address_id: Address
    valorization_process_id: ValorizationProcess
    department_id: Department
    image: DirectusFile
}

export interface Derived {
    id: number
    lerCode: string
    name: string
    quantity: number
    availability: string
    status: string
}

export interface Egar {
    id: number
    document_code: string
    verification_code: string
    ler_code: object
    amount: string
    base_unit: string
    transport: string
    fiscal_number: number
    operator: string
    operation: string
}

export interface Address {
    id?: number
    email?: string
    address: string
    country?: string
    region?: string
    island: string
    parish: string
    county: string
    phone?: string
}

export interface ValorizationProcess {
    id: number,
    status: ValorizationProcessStatus
    product_type: string
    start_date: string
    end_date?: string
    current_step: string
    total_steps: string
    output_id: ValorizationProcessOutput
    valorization_route_id: ValorizationRoute
}
export interface ValorizationProcessStage {
    id: number,
    status: ValorizationProcessStatus
    start_date: string
    end_date?: string
    valorization_process_id: ValorizationProcess
}

export interface ResiduePlan {
    id: number
    status: ResiduePlanStatus
    valorizationRoute: ValorizationRoute
}

export interface Service {
    id: number
    name: number
}

export interface ValorizationProcessOutput {
    id: number
    output_name: string
    valorization_process_id: ValorizationProcess
}

export interface ValorizationRoute {
    id: number
    typology: string
    operation: string
    step: string
    service_subtype_id: ServiceSubtype
    output: ValorizationProcessOutput
}

export interface ServiceSubtype {
    id: number
    name: string
    service_type_id: ServiceType
}

export interface ServiceType {
    id: number
    name: string
    is_active: boolean
}

export interface LerCode {
    id: number
    code: string
    description: string
    is_header: boolean
}

export interface CaeCode {
    id: number
    code: string
    description: string
}

export interface PPGRCD {
    id: number
    aia_score: string
    cpv_code: string
    department_id: Department
    description: string
    date_completion: string
    comments: Array<string>
    status: ResiduePlanStatus
    plan_attachment: DirectusFile
    prevention_methodology: string
    residue_reuse_methodology: string
    form_conditioning_sorting: Array<any>
    form_entity_responsible: Array<any>
    form_incorporation_methodology: Array<any>
    form_plan_closure: Array<any>
    form_prevention_methodology: Array<any>
    form_production_operation: Array<any>
    form_soil_contamination: Array<any>
    form_substances_objects: Array<any>
    form_usage_methodology: Array<any>
    form_waste_management: Array<any>
    form_work_general: Array<any>
    form_work_specifics: Array<any>
    notes: string
    users: User[]
}

export interface Comment {
    id: number
    title: string
    comment: string
    user_id: User
    prevention_plan_id: string
    date_created: string
}

export interface Participant {
    id: number
    name: string
    entity: Entity
    role: Role
    email: string
    status: string
}

export interface Location {
    id: number
    address_id: Address
    images: DirectusFile[]
    prevention_plan_id: PPGRCD
}

export interface Notification {
    id: number
    user_id: User
    title: string
    message: string
    date_read: string
    is_read: boolean
    action_link: string
}

export interface Operation {
    id: number
    action: string
    collection: string
    timestamp: string
    user: User
}

interface UserRole {
    id: number
    name: string
    admin_access: boolean
    app_access: boolean
}

export enum ResidueContamination {
    NOT_CONTAMINATED = 'Not contaminated',
    VERY_LITTLE_CONTAMINATED_NOT_DANGEROUS = 'Very litte contaminated (Not dangerous)',
    CONTAMINATED_NOT_DANGEROUS = 'Contaminated (Not dangerous)',
    CONTAMINATED_DANGEROUS = 'Contaminated (Dangerous)',
}

export enum ValorizationProcessStatus {
    ONGOING = 'Ongoing',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed'
}

export enum ResidueStatus {
    UNPUBLISHED = 'UNPUBLISHED',
    PUBLISHED = 'PUBLISHED',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    VALORISATION = 'VALORISATION'
}

export enum ResidueBaseUnit {
    KILOGRAM = 'Kgs',
    GRAM = 'g',
    LITER = 'L',
    METER = 'm',
    SQUARE_METER = 'm2'
}

enum ResiduePlanStatus {
    PUBLISHED = 'Published',
    DRAFT = 'Draft',
    ARCHIVED = 'Archived',
    CANCELLED = 'Cancelled'
}

export enum ResidueHolder {
    PRODUCTOR = 'Productor',
    CONSTRUCTION_RESIDUE = 'Construction Residue',
    SERVICE_PROVIDER = 'Service Provider'
}

enum Role {
    MEMBER = 'Member',
    MANAGER = 'Manager'
}