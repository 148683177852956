import React from 'react';
import LandingPageContent from './landing-page/LandingPageContent';
import PublicLandingPage from './PublicLandingPage';
import {useStore} from '../store/valtio';


export const LandingPage = () => {
    const {app: {user}} = useStore();

    if (!user) return <PublicLandingPage/>;

    return <LandingPageContent user={user}/>
};


export default LandingPage;
