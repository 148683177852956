import React from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {actions} from '../../../store/valtio';

interface Props {
    children: React.ReactElement,
}

export const RouteGuard = ({children}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    if (location.pathname.includes('/auth/reset-password') && token) {
        actions.app.setResetPasswordToken(token);
        navigate('/auth/reset-password')
    }

    return children;
};

export default RouteGuard;