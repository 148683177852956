import {t} from 'ttag';
import {DateTime as dt} from 'luxon';
import React, {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material';
import {UpdateValorizationProcessStageFormSchema} from './schemas/update-valorization-process-stage-form-schema';
import {AvailableDepartmentsApiResponse} from '../../entities/departments/AvailableDepartmentsList';
import {ValorizationProcess, ValorizationProcessStage} from '../../../types';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import {DATE_FORMAT} from '../../../utils/datetime/datetime-utils';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../common/DirectusFeedback';
import commonStyles from '../../common/styles/commonStyles';
import FormActions from '../../common/forms/FormActions';
import BasicCard from '../../common/cards/BasicCard';

interface ValorizationProcessApiResponse {
    data?: ValorizationProcess[],
    error?: Error,
    isLoading: boolean
}

interface ValorizationProcessStagesApiResponse {
    data?: ValorizationProcessStage[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    valorizationProcessId: number
}

export const AddedValueValorizationResume = ({valorizationProcessId}: Props) => {
    const {residueName, residueId} = useParams();
    const navigate = useNavigate();

    const {
        data: availableDepartments,
        error: errorGetAvailableDepartments,
        isLoading: isLoadingGetAvailableDepartments
    } = useDirectusQuery<AvailableDepartmentsApiResponse>({
        path: '/items/departments',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'entity_id': {
                        'is_active': {'_eq': true}
                    }
                }
            }
        }
    });

    const {
        data: valorizationProcess,
        error: errorGetValorizationProcess,
        isLoading: isLoadingGetValorizationProcess
    } = useDirectusQuery<ValorizationProcessApiResponse>({
        path: `/items/valorization_processes/${valorizationProcessId}`
    });

    const {
        data: valorizationProcessStages,
        error: errorGetValorizationProcessStages,
        isLoading: isLoadingGetValorizationProcessStages
    } = useDirectusQuery<ValorizationProcessStagesApiResponse>({
        path: '/items/valorization_processes_stages',
        options: {
            params: {
                filter: {
                    'valorization_process_id': {
                        'id': {
                            "_eq": valorizationProcessId
                        }
                    }
                }
            }
        }
    });

    const {
        actions: {PATCH: updateValorizationProcessStage},
        response: {
            error: errorUpdateValorizationProcessStage,
            isLoading: isLoadingUpdateValorizationProcessStage
        }
    } = useDirectusMutation({path: '/items/valorization_processes_stages'});

    const defaultValues = useMemo(
        () => ({
            stages: valorizationProcessStages
        }),
        [valorizationProcessStages]
    );

    const methods = useForm({
        resolver: yupResolver(UpdateValorizationProcessStageFormSchema),
        defaultValues: {
            stages: valorizationProcessStages
        }
    });

    const {fields: stages} = useFieldArray({
        control: methods.control,
        name: 'stages'
    });

    useEffect(() => {
        if (valorizationProcessStages) {
            methods.reset(defaultValues);
        }
    }, [valorizationProcessStages, methods, defaultValues]);

    const handleUpdateValorizationProcessStages = (callback = () => null) => methods.handleSubmit((values) => {
        let stagesUpdated = 0;

        values.stages.forEach((stage) => {
            stagesUpdated ++;
            updateValorizationProcessStage(stage, () => null, stage.id);
            if (stagesUpdated === values.stages.length) callback();
        });
    });

    if (!valorizationProcess || !valorizationProcessStages || !availableDepartments) return null;

    return (
        <>
            <Stack spacing={3}>
                <BasicCard
                    headerContent={
                        <Box px={3}>
                            <Typography>{t`FINAL RESUME`}</Typography>
                        </Box>
                    }
                >
                    <Stack spacing={3}>
                        <Typography>{t`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`}</Typography>
                        <Grid gap={4} container>
                            <Grid item>
                                <Typography>{t`FINAL RESIDUE`}</Typography>
                                <Typography>{t`FINAL DERIVED`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>RESIDUE X</Typography>
                                <Typography>DERIVED X</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </BasicCard>
                <BasicCard
                    headerContent={
                        <Box px={3}>
                            <Typography>{t`STEPS`}</Typography>
                        </Box>
                    }
                >
                    <Stack spacing={3}>
                        {stages.map((stage, key) => (
                            <Grid sx={commonStyles.centerItems} key={key} container>
                                <Grid xs={12} md={12} lg={3} item>
                                    <Stack spacing={1}>
                                        <Typography>{t`STEP 1 - PRE-TREATMENT`}</Typography>
                                        <Typography>{t`Particle size reduction`}</Typography>
                                        <Typography>{
                                            stage.selected_department_id
                                                ? availableDepartments?.filter((department) => department.id === stage.selected_department_id)[0]?.name
                                                : t`Own capacity`
                                        }</Typography>
                                    </Stack>
                                </Grid>
                                <Grid xs={4} md={6} lg={3} sx={commonStyles.centerContent} gap={1} item>
                                    <Typography fontWeight={'bold'}>{t`START`}</Typography>
                                    <Typography>{dt.now(stage.start_date).toFormat(DATE_FORMAT)}</Typography>
                                </Grid>
                                <Grid xs={4} md={6} lg={3} sx={commonStyles.centerContent} gap={1} item>
                                    <Typography>{t`END`}</Typography>
                                    <Typography>{stage.end_date ? dt.now(stage.end_date).toFormat(DATE_FORMAT) : '(' + t`Ongoing` + ')'}</Typography>
                                </Grid>
                                <Grid xs={4} md={12} lg={3} sx={commonStyles.flexEndContent} item>
                                    <FormProvider key={key} {...methods}>
                                        <Controller
                                            key={key}
                                            name={`stages[${key}].status`}
                                            render={({field, fieldState: {error}}) => (
                                                <FormControl sx={{width: '11rem'}}>
                                                    <InputLabel sx={styles.inputLabel} id={'status-label'}
                                                                required>{t`Status`}</InputLabel>
                                                    <Select
                                                        {...field}
                                                        label={t`Status`}
                                                        labelId={'status-label'}
                                                        sx={styles.selectField}
                                                        error={Boolean(error)}
                                                        required
                                                    >
                                                        <MenuItem value={'ONGOING'}>
                                                            {t`Ongoing`}
                                                        </MenuItem>
                                                        <MenuItem value={'COMPLETED'}>
                                                            {t`Completed`}
                                                        </MenuItem>
                                                        <MenuItem value={'CANCELLED'}>
                                                            {t`Cancelled`}
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText
                                                        error={Boolean(error)}>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </FormProvider>
                                </Grid>
                            </Grid>
                        ))}
                    </Stack>
                </BasicCard>
                <FormActions
                    submitBtnText={t`SAVE`}
                    onSubmit={() =>
                        handleUpdateValorizationProcessStages(() =>
                            navigate(`/residues/${residueName}/${residueId}`)
                        )()
                    }
                    cancelAction={false}
                />
            </Stack>
            <DirectusFeedback
                error={errorGetValorizationProcess || errorGetValorizationProcessStages || errorUpdateValorizationProcessStage}
                isLoading={isLoadingGetValorizationProcess || isLoadingGetValorizationProcessStages || isLoadingUpdateValorizationProcessStage}
            />
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    }
};

export default AddedValueValorizationResume;