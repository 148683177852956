import {axiosInstance} from './auth-axios-instance';
import AxiosError from './AxiosError';

export const axiosBaseQuery = ({baseUrl} = {baseUrl: ''}) => {
  return async ({url = '', method, body: data, ...rest}) => {
    try {
      return {data: await axiosInstance({url: baseUrl + url, method, data, ...rest})};
    } catch (e) {
      if(e?.response?.status === 401){
        return {data: e?.response?.data};
      }
      throw new AxiosError(e?.message, e?.response?.status, e?.response?.data);
    }
  };
};
