import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {AddLocationFormSchema} from './add-location-form/schemas/add-location-form-schema';
import {createFileFormData, getFileFromBase64} from '../../../../../utils/forms/helpers';
import AddLocationFormDetails from './add-location-form/AddLocationFormDetails';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import FormSkeleton from '../../../../common/forms/FormSkeleton';
import {compilleConstructionSiteFiles} from '../helpers/utils';
import BasicCard from '../../../../common/cards/BasicCard';
import {actions} from '../../../../../store/valtio';

interface Props {
    onSubmitCallback: () => void
}

export const AddLocationForm = ({onSubmitCallback}: Props) => {
    const {ppgrcdId} = useParams();
    const [errorFileSize, setErrorFileSize] = useState(null);

    const methods = useForm({
        resolver: yupResolver(AddLocationFormSchema),
        defaultValues: {
            prevention_plan_id: ppgrcdId,
            address_id: {
                island: '',
                county: '',
                parish: ''
            },
            files: []
        }
    });

    const {
        actions: {POST: addLocation},
        response: {error: errorAddLocation, isLoading: isLoadingAddLocation}
    } = useDirectusMutation({path: '/items/construction_sites'});

    const {
        actions: {POST: uploadPhoto},
        response: {error: errorUploadPhoto, isLoading: isLoadingUploadPhoto}
    } = useDirectusMutation({path: '/files'});

    const uploadFiles = (attachments, callback = (files: []) => null) => {
        createFileFormData(attachments.map(attachment => getFileFromBase64(attachment.content, attachment.name)), (formData) =>
            uploadPhoto(formData, (files) => {
                callback(files);
            }), (error) => setErrorFileSize(error))
    }

    const submitHandler = methods.handleSubmit((values) => {
        values.files
            ? uploadFiles(values.files, (files) => {
                addLocation({
                    prevention_plan_id: values.prevention_plan_id,
                    address_id: values.address_id,
                    images: {
                        create: compilleConstructionSiteFiles(files)
                    }
                }, onSubmitCallback)
            })
            : addLocation(values, () => {
                actions.app.setUploadedLocationFiles([]);
                onSubmitCallback();
            });
    });

    return (
        <BasicCard>
            <>
                <FormProvider {...methods}>
                    <FormSkeleton
                        mainGroupContent={<AddLocationFormDetails/>}
                        submitBtnText={t`Submit`}
                        onSubmit={submitHandler}
                        cancelAction={false}
                        innerForm
                    />
                </FormProvider>
                <DirectusFeedback
                    error={errorAddLocation || errorUploadPhoto || errorFileSize}
                    isLoading={isLoadingAddLocation || isLoadingUploadPhoto}
                />
            </>
        </BasicCard>

    );
};

export default AddLocationForm;