import {t} from 'ttag';
import PropTypes from 'prop-types';
import DatePicker from 'react-mobile-datepicker';
import {AccessTime, CalendarToday} from '@mui/icons-material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {forwardRef, useCallback, useMemo, useState} from 'react';
import {IconButton, Stack, TextField, useMediaQuery} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as DatePickerTime } from '@mui/x-date-pickers/DateTimePicker';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  dateToYYMMDDHHMMSS,
  TIME_FORMAT_NO_SEC,
  DATETIME_FORMAT_NO_SEC
} from '../../../utils/datetime/datetime-utils';


export const DatetimePicker = forwardRef(
  (
    {label, value, onChange, variant, size, openTo, helperText, error: errorGetEntity, disabled, wheelTimePickerOnMobile, required, ...rest},
    ref
  ) => {
    const isTablet = useMediaQuery((theme) => theme.breakpoints.between('xs', 'lg'));
    const [open, setOpen] = useState(false);

    const Icon = variant === VARIANTS.TIME_ONLY ? AccessTime : CalendarToday;

    /** @type string */
    const format = useMemo(() => {
      if (variant === VARIANTS.DATE_ONLY) return DATE_FORMAT;
      if (variant === VARIANTS.TIME_ONLY) return TIME_FORMAT_NO_SEC;
      return DATETIME_FORMAT_NO_SEC;
    }, [variant]);

    /** @type {("year" | "month" | "day" | "hours" | "minutes" | "seconds")[]} */
    const views = useMemo(() => {
      if (variant === VARIANTS.DATE_ONLY) return ['year', 'month', 'day'];
      if (variant === VARIANTS.TIME_ONLY) return ['hours', 'minutes'];
      return ['year', 'month', 'day', 'hours', 'minutes'];
    }, [variant]);

    /** @type {(dt: DateTime) => void} */
    const changeHandler = useCallback(
      (dt) => {
        if (dt?.isValid) {
          onChange(dt?.toFormat(DATETIME_FORMAT));
        }
      },
      [onChange]
    );

    return (
      <LocalizationProvider locale={'pt'} dateAdapter={AdapterLuxon}>
        <Stack ref={ref}>
          <DatePickerTime
            value={value ? value.replace(' ', 'T') : value}
            TextFieldProps={{onClick: () => setOpen(true), fullWidth: true}}
            openTo={openTo ? openTo : variant === VARIANTS.TIME_ONLY ? 'hours' : 'day'}
            DialogProps={{PaperProps: {sx: {'& *:focus': {outline: 'none'}}}}}
            inputProps={{style: {cursor: 'pointer'}, readOnly: true}}
            InputAdornmentProps={{sx: {display: 'none'}}}
            hideTabs={variant !== VARIANTS.DATETIME}
            onOpen={() => !disabled && setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={changeHandler}
            inputFormat={format}
            disabled={disabled}
            views={views}
            ampm={false}
            showToolbar={false}
            ampmInClock={false}
            showDaysOutsideCurrentMonth
            {...rest}
            open={open && (!isTablet || !wheelTimePickerOnMobile)}
            renderInput={(props) => (
              <TextField
                {...props}
                size={size}
                label={label}
                error={errorGetEntity}
                required={required}
                disabled={disabled}
                helperText={helperText}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <IconButton size={size} sx={{p: 1, mr: -1}}>
                      <Icon />
                    </IconButton>
                  )
                }}
              />
            )}
          />
          {open && wheelTimePickerOnMobile && isTablet && (
            <DatePicker
              theme={'ios'}
              confirmText={t`OK`}
              value={value ? new Date(value.replace(' ', 'T')) : new Date()}
              headerFormat={variant === VARIANTS.TIME_ONLY ? 'hh:mm' : 'YYYY-MM-DD hh:mm'}
              dateConfig={{
                hour: {format: 'hh', caption: t`Hour`, step: 1},
                minute: {format: 'mm', caption: t`Min`, step: 1}
              }}
              onChange={(v) => onChange(dateToYYMMDDHHMMSS(v))}
              onSelect={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              isOpen={open && isTablet}
              cancelText={false}
              showCaption
              isPopup
            />
          )}
        </Stack>
      </LocalizationProvider>
    );
  }
);

export const VARIANTS = {
  DATETIME: 'DATETIME',
  DATE_ONLY: 'DATE_ONLY',
  TIME_ONLY: 'TIME_ONLY'
};


DatetimePicker.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  size: PropTypes.oneOf(['small', 'medium']),
  openTo: PropTypes.oneOf(['year', 'day', 'month', 'hours', 'minutes', 'seconds']),
  errorGetEntity: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  wheelTimePickerOnMobile: PropTypes.bool
};

DatetimePicker.defaultProps = {
  openTo: null,
  label: null,
  value: null,
  variant: VARIANTS.DATETIME,
  helperText: '',
  size: 'medium',
  onChange: () => null,
  errorGetEntity: false,
  disabled: false,
  required: false,
  wheelTimePickerOnMobile: false
};

export default DatetimePicker;
