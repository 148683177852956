import {t} from 'ttag';
import {useState} from 'react';
import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import useDirectusQuery from '../custom-hooks/useDirectusQuery';
import {columns} from './helpers/by-products-list-helpers';
import DirectusFeedback from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import ConfirmDialog from '../common/ConfirmDialog';
import {ResiduesApiResponse} from './ResiduesList';
import {Typography} from '@mui/material';
import {User} from '../../types';

interface Props {
    user: User
    filterByDesignation?: string
    filterByEntityId?: number | string
    filterByStatus?: string
}

export const ByProductsList = ({user, filterByEntityId, filterByStatus, filterByDesignation}: Props) => {
    const navigate = useNavigate();
    const [residueToDelete, setResidueToDelete] = useState(null);
    const editHandler = (residue) => navigate(residueUrl(residue));
    const viewHandler = (residue) => navigate(residueUrl(residue));
    const deleteHandler = (residue) => setResidueToDelete(residue);
    const residueUrl = (residue) => `/by-products/${residue?.derived_designation}/${residue?.id}`;

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": user.id
                                }
                            }
                        }
                    },
                    'is_derived' : {'_eq': true}
                }
            }
        }
    });

    const {
        actions: {DELETE: deleteResidue},
        response: {
            error: errorDeleteResidue,
            isLoading: isLoadingDeleteResidue
        }
    } = useDirectusMutation({path: `/items/residues/${residueToDelete?.id}`});

    let filteredResidues = residues?.filter((residue) =>
        filterByEntityId === 'all' ? residue : residue.department_id.entity_id.id === filterByEntityId
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByStatus === 'all' ? residue : residue.status === filterByStatus
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByDesignation ? residue.derived_designation.includes(filterByDesignation) : residue
    );

    if (!filteredResidues) return null;

    return (
        <>
            <DataGrid
                rows={filteredResidues || []}
                getRowId={(row) => row.id}
                components={dataGridComponents}
                loading={isLoadingGetResidues}
                rowCount={filteredResidues?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                sx={commonStyles.datagrid}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(residueToDelete)}
                title={t`Delete By-Product`}
                onCancel={() => setResidueToDelete(null)}
                onConfirm={() => deleteResidue(() => setResidueToDelete(null))}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${residueToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetResidues || errorDeleteResidue}
                isLoading={isLoadingGetResidues || isLoadingDeleteResidue}
            />
        </>

    );
};

export default ByProductsList;