import {addLocale, setDefaultLang, useLocale as setLocale} from 'ttag';

export const LOCALE_KEY = 'LANG';

export const LOCALES = {
  EN: 'en',
  PT: 'pt'
};

export const LOCALE_DETECTED = LOCALES.PT;
// export const LOCALE_DETECTED = detectBrowserLanguage().substring(0, 2).toLowerCase();

export const LOCALES_TRANSLATIONS = {
  en: (s) => s,
  pt: (s) => s
};

export const LOCALE_CANDIDATE = String(localStorage.getItem(LOCALE_KEY) || LOCALE_DETECTED)
  .substring(0, 2)
  .toLowerCase();

export const LOCALE_DEFAULT = Object.values(LOCALES).includes(LOCALE_CANDIDATE) ? LOCALE_CANDIDATE : LOCALES.PT;

export let t = (str, locale = LOCALE_DEFAULT) => {
  if (LOCALES_TRANSLATIONS.hasOwnProperty(locale.toLowerCase())) {
    return LOCALES_TRANSLATIONS[locale](str);
  }

  return str;
};

export const loadLocales = (locales = []) => {
  const convertToTranslatableFn = (translationRaw) => {
    const {translations: {'': tObj = {}} = {}} = translationRaw || {};
    return (s) =>
      tObj.hasOwnProperty(s) &&
      tObj[s].hasOwnProperty('msgstr') &&
      tObj[s].msgstr.hasOwnProperty(0) &&
      tObj[s].msgstr[0]
        ? tObj[s].msgstr[0]
        : s;
  };

  locales.forEach((locale) => {
    const translation = require(`../i18n/${locale}.po.json`);
    LOCALES_TRANSLATIONS[locale] = convertToTranslatableFn(translation);
    addLocale(locale, translation);
  });
};

export const changeLanguage = (lang) => {
  localStorage.setItem(LOCALE_KEY, lang);
  setLocale(lang);
};

export const i18nInit = () => {
  loadLocales(Object.values(LOCALES));
  setDefaultLang(LOCALE_DEFAULT);
};

(() => {
  i18nInit();
  changeLanguage(LOCALE_DEFAULT);
})();
