import {t} from 'ttag';
import React, {useState} from 'react';
import {Search} from '@mui/icons-material';
import {useDebouncedCallback} from 'use-debounce';
import {Box, InputAdornment, TextField} from '@mui/material';
import {commonStyles} from '../styles/commonStyles';

interface Props {
    placeholder?: string,
    onChange: (value: string) => void,
    [rest:string]: any;
}

export const PatternSearch = ({placeholder, onChange, rest}: Props) => {
    const [patternInput, setPatternInput] = useState('');
    const handlePattern = useDebouncedCallback((value) => onChange(value), 750);

    return (
        <TextField
            {...rest}
            size={'small'}
            value={patternInput}
            placeholder={placeholder}
            sx={{...commonStyles.textField, width: '25rem'}}
            onChange={(e) => {
                setPatternInput(e.target.value);
                handlePattern(e.target.value);
            }}
            InputProps={{
                startAdornment: (
                    <Box>
                        <InputAdornment position={'start'}>
                            <Search/>
                        </InputAdornment>
                    </Box>
                )
            }}
        />
    );
};

PatternSearch.defaultProps = {
    placeholder: t`Search`,
    onChange: () => null
};

export default PatternSearch;
