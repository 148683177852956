import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../common/grid/SubMenu';


export const columns = ({ editHandler, deleteHandler }) => [
  {
    field: 'name',
    headerName: t`NAME`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    headerName: t`VAT/ICTN`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({row}) => row.nif || row.nipc
  },
  {
    field: 'actions',
    headerName: '',
    type: 'number',
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => {
      return (
          <SubMenu
              viewAction
              editAction={false}
              onView={() => editHandler(row)}
              onDelete={() => deleteHandler(row)}
          />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
