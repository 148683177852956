import {t} from 'ttag';
import * as React from 'react';
import {Stack, Typography} from '@mui/material';
import commonStyles from '../../components/common/styles/commonStyles';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddResidueForm from '../../components/residues/forms/AddResidueForm';
import {useStore} from '../../store/valtio';


export const AddResidue = () => {
    const {app: {user, lerCodes}} = useStore();

    if(!user || !lerCodes) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD RESIDUE`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Residues`, link: '/residues'},
                    {name: t`Add Residue`, link: '/add-residue'}
                ]}
            />
            <AddResidueForm user={user} lerCodes={lerCodes}/>
        </Stack>
    );
};

export default AddResidue;