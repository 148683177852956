import {t} from 'ttag';
import React from 'react';

export const columns = () => [
  {
    field: 'name',
    headerName: t`NAME`,
    disableColumnMenu: true,
    flex: 3
  }
];

export const helper = {
  columns
};

export default helper;
