import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {commonStyles} from '../../../common/styles/commonStyles';
import {columns} from './helpers/locations-list-form-helpers';
import {Location} from '../../../../types';
import {useState} from 'react';
import {t} from 'ttag';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface Props {
    locations?: Location[]
    pattern?: string
    onDeleteLocation: (locationId) => void
    onEditLocation: (locationId) => void
    readOnly: boolean
}

export const LocationsListForm = ({locations, onEditLocation, onDeleteLocation, readOnly}: Props) => {
    const [pageSize, setPageSize] = useState(5);
    const editHandler = (location) => onEditLocation(location.id);
    const deleteHandler = (location) => onDeleteLocation(location.id);

    if (!locations) return null;

    return (
        <DataGrid
            rows={locations}
            pageSize={pageSize}
            sx={commonStyles.datagrid}
            getRowId={(row) => row.id}
            components={dataGridComponents}
            rowsPerPageOptions={[5, 15, 25]}
            rowCount={locations?.length || 0}
            columns={columns({editHandler, deleteHandler, readOnly})}
            onPageSizeChange={(size) => setPageSize(size)}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: 'insertion_date',
                            sort: 'desc',
                        },
                    ],
                },
            }}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t`Rows per page`
                }
            }}
            disableExtendRowFullWidth
            autoHeight
        />
    );
};

export default LocationsListForm;