import {t} from 'ttag';
import React from 'react';
import {Stack} from '@mui/material';
import WorkSpecificsFormDetails from './work-specifics-form/WorkGeneralFormDetails';
import FormSkeleton from '../../../common/forms/FormSkeleton';

interface Props {
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    onReturn: React.MouseEventHandler<HTMLButtonElement>
    readOnly: boolean
}

export const WorkSpecificsForm = ({onSubmit, onReturn, readOnly}: Props) => {

    return (
        <Stack gap={3} py={2}>
            <FormSkeleton
                mainGroupContent={<WorkSpecificsFormDetails readOnly={readOnly}/>}
                submitBtnText={t`Next`}
                cancelBtnText={t`Return`}
                onSubmit={onSubmit}
                onCancel={onReturn}
                actions={!readOnly}
                innerForm
            />
        </Stack>

    );
};

export default WorkSpecificsForm;