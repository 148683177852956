import React from 'react';
import {Avatar, Box, Card, Stack, Typography} from '@mui/material';
import {createDatetime, DATE_FORMAT} from '../../utils/datetime/datetime-utils';
import ProfileUserAvatar from '../../pages/user/ProfileUserAvatar';
import commonStyles from './styles/commonStyles';
import {useStore} from '../../store/valtio';
import {User} from '../../types';


interface Props {
    title: string
    author: User
    comment: string
    date: string
    inverted: boolean
}

export const Comment = ({title, author, comment, date, inverted}: Props) => {
    const {app: {configs}} = useStore();

    if (!configs) return null;

    return (
        <>
            <Box display={'flex'} flexDirection={inverted ? 'row-reverse' : 'row'} gap={2}>
                {author.avatar
                    ? <ProfileUserAvatar avatar={author.avatar}/>
                    : <Avatar alt={'default_avatar'} sx={styles.avatar}/>
                }
                <Card sx={{...commonStyles.card(), width: '50%', height: '100%'}}>
                    <Stack height={'100%'} alignItems={inverted ? 'flex-end' : 'flex-start'} spacing={1} px={3} py={2}>
                        <Box sx={commonStyles.spaceBetween} flexDirection={inverted ? 'row-reverse' : 'row'}>
                            <Typography fontWeight={'bold'}>{title}</Typography>
                            <Typography>{createDatetime(date).toFormat(DATE_FORMAT)}</Typography>
                        </Box>
                        <Typography>{comment}</Typography>
                        <Typography fontSize={'.9rem'} fontWeight={'bold'}>
                            {author.first_name} {author.last_name}
                        </Typography>
                    </Stack>
                </Card>
            </Box>
        </>
    );
};

const styles = {
    avatar: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    }
}

export default Comment;