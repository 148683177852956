import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import AuthorizationGuard from '../../../pages/common/guards/AuthorizationGuard';
import PatternSearch from '../../common/pattern-search/PatternSearch';
import DepartmentsList from '../entity/deparment/DepartmentsList';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';
import {Department} from '../../../types';

interface Props {
    departments: Department[],
    onInvalidatedDepartments: () => any
}

export const Departments = ({departments, onInvalidatedDepartments}: Props) => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const {entityName, entityId} = useParams();
    const [searchPattern, setSearchPattern] = useState(null);
    const onAddDepartment = () => navigate(`/entities/${entityName}/${entityId}/add-department`);

    if(!user) return null;

    return (
        <Stack spacing={3}>
            <Box sx={styles.box}>
                <PatternSearch
                    placeholder={t`Search by name...`}
                    onChange={(searchPattern) => setSearchPattern(searchPattern)}
                />
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={onAddDepartment}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Department`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <DepartmentsList
                pattern={searchPattern}
                departments={departments}
                onInvalidatedDepartments={onInvalidatedDepartments}
            />
        </Stack>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

Departments.defaultProps = {
    departments: []
}

export default Departments;