import {t} from 'ttag';
import {Stack, Typography} from '@mui/material';
import AddDepartmentForm from '../../../components/entities/entity/deparment/forms/AddDepartmentForm';
import commonStyles from '../../../components/common/styles/commonStyles';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import * as React from 'react';
import {useNavigate, useParams} from 'react-router-dom';


export const AddDepartment = () => {
    const navigate = useNavigate();
    const {entityName, entityId} = useParams();

    const goToEntityPage = () => navigate(`/entities/${entityName}/${entityId}`);

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD DEPARTMENT`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Entities`, link: '/entities'},
                    {name: `${entityName}`, link: `/entities/${entityName}/${entityId}`},
                    {name: t`Add Department`, link: `/entities/${entityName}/${entityId}/add-department`}
                ]}
            />
            <AddDepartmentForm entityId={parseInt(entityId)} onSuccess={() => goToEntityPage()}/>
        </Stack>
    );
};

export default AddDepartment;