import {t} from 'ttag';
import {Controller, useFormContext} from 'react-hook-form';
import React, {SyntheticEvent, useState} from 'react';
import {Add, AddCircle, RemoveCircle} from '@mui/icons-material';
import {
    Box,
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import commonStyles from '../../../../common/styles/commonStyles';
import TextCollapsable from '../../../../common/TextCollapsable';
import ResiduesListForm from './ResiduesListForm';

interface ResidueDetails {
    id?: string
    name: string,
    reuse_quantity: string,
    base_unit: string,
    reuse_quantity_percentage: string
}

interface Props {
    residues: ResidueDetails[]
    onAddResidue: () => void
    onEditResidue: (residueId: number) => void
    onSaveEditResidue: (callback: () => void) => void
    onRemoveResidue: (idx: number) => void
    readOnly: boolean
}

export const PreventionMethodologyFormDetails = ({
     residues,
     onAddResidue,
     onEditResidue,
     onSaveEditResidue,
     onRemoveResidue,
     readOnly
 }: Props) => {
    const {watch, getValues, resetField} = useFormContext();
    const [editingResidue, setEditingResidue] = useState<number>(null);
    const [openMethodologyUsage, setOpenMethodologyUsage] = useState(true);
    const [openCharacterization, setOpenCharacterization] = useState(true);
    const reuseQuantityPercentageCondition = watch('reuse_quantity_percentage') && (parseInt(getValues('reuse_quantity_percentage')) <= 100);

    const handleFinishEditing = () => {
        setEditingResidue(null);
        resetField('name');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('insertion_date');
    }

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openCharacterization}
                    title={t`Project/work characterization and methods`}
                    content={t`The principle adopted in the project/works aims to reduce waste production, only being considered the treatment of waste that cannot be reused in this project/works or another destination. The responsibility for the correct segregation of waste is the responsibility of all employees, who are trained in waste management and aware of the importance of sorting. Indicate all measures to be taken in the scope of waste prevention (note: different concept from reuse), for example measures to reduce its production and harmfulness, training of workers, selective packaging of waste, packaging of hazardous waste, etc.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenCharacterization(!openCharacterization))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'methodology_description'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                rows={3}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Description of the prevention methodology` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openMethodologyUsage}
                    title={t`Materials reused in project/work`}
                    content={t`Includes Methodologies and reused materials: uncontaminated soils and rocks (Soils and rocks that do not contain hazardous substances from construction activities should preferably be reused in the original work site; or be used in other licensed operations provided by law). Materials can be classified as by-products in the Autonomous Region of the Azores are those classified as such at national level, as well as by-products qualified according to national criteria in other countries of the European Union, provided they meet the same criteria established at national or regional level. It is not foreseen, at this moment, the existence of a by-product license to be requested by the producer and kept for 5 years.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenMethodologyUsage(!openMethodologyUsage))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <ResiduesListForm
                    residues={residues}
                    isEditing={Boolean(editingResidue)}
                    onEditResidue={(residueId) => {
                        setEditingResidue(residueId);
                        onEditResidue(residueId);
                    }}
                    onDeleteResidue={onRemoveResidue}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Identification of the material` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'reuse_quantity'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Quantity to be reused` + '*'}
                                InputProps={{inputProps: {min: 0}}}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                <FormControlLabel value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'reuse_quantity_percentage'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                placeholder={t`Quantity to be reused in relation to the total (%)` + '*'}
                                InputProps={{inputProps: {min: 0}}}
                                error={Boolean(error)}
                                variant={'outlined'}
                                disabled={readOnly}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                fullWidth
                                required
                            />
                            {!reuseQuantityPercentageCondition &&
                                <FormHelperText error={!reuseQuantityPercentageCondition}>
                                    {t`The quantity to be reused must be 100% or less`}
                                </FormHelperText>
                            }
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingResidue
                            ? () => onSaveEditResidue(handleFinishEditing)
                            : () => onAddResidue()
                    }
                    disabled={
                        readOnly || (
                            !watch('reuse_quantity') ||
                            !watch('base_unit') ||
                            (!watch('reuse_quantity_percentage') ||
                                parseInt(getValues('reuse_quantity_percentage')) > 100 ||
                                parseInt(getValues('reuse_quantity_percentage')) <= 0
                            )
                        )
                    }
                    fullWidth
                >
                    {!editingResidue && <Add sx={styles.headerAddIcon}/>}
                    {editingResidue ? t`SAVE CHANGES` : t`Add Material`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default PreventionMethodologyFormDetails;