/**
 * Default theme for the app.
 * @constant {Theme}
 */

export const defaultTheme = {
  typography: {
    fontFamily: ['Poppins']
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      gray: '#79797E',
      lightGray: '#CDCDCD'
    },
    background: {
      paper: '#fff',
      gray: '#F1F1FC',
      default: '#F9FAFC'
    },
    primary: {
      light: '#5f5fc4',
      main: '#232164',
      dark: '#001064',
      contrastText: '#fff'
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff'
    },
    error: {
      main: '#d32f2f'
    },
    success: {
      main: '#038d16',
      dark: '#2A5841',
    },
    text: {
      primary: '#000',
      secondary: '#000',
      info: '#595959',
      disabled: '#949494'
    }
  }
};
