import {t} from 'ttag';
import * as Yup from 'yup';


export const UpdateValorizationProcessStageFormSchema = Yup.object().shape(
    {
        stages: Yup.array().of(
            Yup.object().shape({
                status: Yup.string().required(t`Required field.`)
            })
        ).required()
    }
).required();
