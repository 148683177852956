import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {AddTransportResidualServiceFormSchema} from './schemas/add-transport-residual-service-form-schema';
import CommonServiceFormDetails from './CommonServiceFormDetails';
import {advancedServiceTypes, transportServiceTypes} from '../../../../../../../mocks';

interface Props {
    servicesPlatform: Array<any>
    onSubmit: (values: object) => any,
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const ResidualTransportServiceForm = ({servicesPlatform, onSubmit, onReturn}: Props) => {
    const methods = useForm({
        resolver: yupResolver(AddTransportResidualServiceFormSchema),
        defaultValues: {
            service_platform_id: '',
            description: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => onSubmit(values));

    return (
        <FormProvider {...methods}>
            <CommonServiceFormDetails services={servicesPlatform} onSubmit={submitHandler} onReturn={onReturn}/>
        </FormProvider>
    );
};

export default ResidualTransportServiceForm;