import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import {columns} from '../entities/helpers/entities-list-helpers';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import useDirectusQuery from '../custom-hooks/useDirectusQuery';
import {commonStyles} from '../common/styles/commonStyles';
import DirectusFeedback from '../common/DirectusFeedback';
import ConfirmDialog from '../common/ConfirmDialog';
import {Entity} from '../../types';

export interface EntitiesApiResponse {
    data: Entity[],
    error: Error,
    isLoading: boolean
}

interface Props {
    filterByUser: any
    pattern?: string,
    onlyCompanies?: boolean,
    onlyIndividuals?: boolean
}

export const EntitiesList = ({filterByUser, pattern, onlyCompanies, onlyIndividuals}: Props) => {
    const navigate = useNavigate();
    const [entityToDelete, setEntityToDelete] = useState(null);
    const [invalidatedEntities, setInvalidatedEntities] = useState<Entity[]>();
    const entityUrl = (entity) => `/entities/${entity?.name}/${entity?.id}`;

    const editHandler = (entity) => navigate(entityUrl(entity));
    const viewHandler = (entity) => navigate(entityUrl(entity));
    const deleteHandler = (entity) => setEntityToDelete(entity);

    const {
        data: entities,
        error: errorGetEntities,
        isLoading: isLoadingGetEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    'users': {
                        "directus_users_id": {
                            "id": {
                                "_eq": filterByUser.id
                            }
                        }
                    },
                    ...(pattern ? {'name': {'_contains': pattern}} : {})
                }
            },
            refetchOnChange: invalidatedEntities || pattern
        }
    });

    const {
        actions: {DELETE: deleteEntity},
        response: {
            error: errorDeleteEntity,
            isLoading: isLoadingDeleteEntity
        }
    } = useDirectusMutation({path: `/items/entities/${entityToDelete?.id}`});

    if(!entities) return null;

    const filteredEntities = entities?.filter((entity) =>
        onlyCompanies
            ? entity.is_company === true
            : onlyIndividuals
                ? entity.is_company === false
                : entity
    );

    return (
        <>
            <DataGrid
                getRowId={(row) => row.id}
                sx={commonStyles.datagrid}
                rows={filteredEntities || []}
                components={dataGridComponents}
                rowCount={filteredEntities?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(entityToDelete)}
                title={t`Delete Entity`}
                onConfirm={() => deleteEntity(() => {
                    setInvalidatedEntities(entities);
                    setEntityToDelete(null);
                })}
                onCancel={() => setEntityToDelete(null)}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${entityToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetEntities || errorDeleteEntity}
                isLoading={isLoadingGetEntities || isLoadingDeleteEntity}
            />
        </>
    );
};

EntitiesList.defaultProps = {
    filterBy: 'all'
};

export default EntitiesList;