import * as Yup from 'yup';
import {t} from 'ttag';


export const AddResidualTreatmentServiceFormSchema = Yup.object().shape(
    {
        service_platform_id: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
        ler_code: Yup.number().when('type', {
            is: (type) => type === 'WASTE_SEPARATION_DECONTAMINATION',
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        }),
        equipment_type: Yup.string().when('type', {
            is: (type) => type !== 'WOOD_DRYING',
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
            otherwise: Yup.string()
        }),
        capacity: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        receiving_conditions: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        granulometry_min: Yup.number(),
        granulometry_max: Yup.number()
            .typeError('Invalid values.')
            .when('granulometry_min', (min, schema) => {
                return schema.test({
                    test: (value) => value && value > min,
                    message: t`Max should be greater than Min.`
                });
            }),
        granulometry_unit: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
