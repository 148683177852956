import {useEffect} from 'react';
import useDirectusQuery from '../../components/custom-hooks/useDirectusQuery';
import {actions, useStore} from '../../store/valtio';
import {i18nInit} from '../../config/i18n';


export const AppInitProvider = ({children}) => {
    const {app: {invalidatedUser}} = useStore();

    const {data: user} = useDirectusQuery({
            path: 'users/me', options: {
                params: {
                    fields: '*.*.*'
                },
                refetchOnChange: invalidatedUser
            }
        }
    );

    const {data: lerCodes} = useDirectusQuery({
        path: '/items/ler_codes', options: {
            params: {
                fields: '*.*'
            },
            noAuth: true
        }
    });

    const {data: operations} = useDirectusQuery({
        path: '/items/services_operations', options: {
            params: {
                fields: '*.*'
            }
        }
    });

    useEffect(() => i18nInit(), []);

    useEffect(() => {
        if (lerCodes?.length > 0) {
            actions.app.setLerCodes(lerCodes)
        }
    }, [lerCodes]);

    useEffect(() => {
        if (operations?.length > 0) {
            const valorizationOperations = operations?.filter((op) => op.type === 'VALORIZATION');
            const eliminationOperations = operations?.filter((op) => op.type === 'ELIMINATION');
            actions.app.setValorizationOperations(valorizationOperations);
            actions.app.setEliminationOperations(eliminationOperations);
        }
    }, [operations]);

    useEffect(() => {
        if (user) {
            actions.app.setUser(user);
        }
    }, [user]);

    return children;
};

export default AppInitProvider;
