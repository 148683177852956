import './index.css';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {createRoot} from 'react-dom/client';
import {createTheme, ThemeProvider} from '@mui/material';
import Store from './api/redux-store/redux/store';
import ConfigsProvider from './ConfigsProvider';
import AuthProviders from './AuthProviders';
import {defaultTheme} from "./Theme";
import App from './App';

const container = document.getElementById('root');
const Theme = createTheme(defaultTheme);
const root = createRoot(container);

root.render(
    <Provider store={Store}>
        <ConfigsProvider>
            <AuthProviders>
                <ThemeProvider theme={Theme}>
                    <SnackbarProvider>
                        <App/>
                    </SnackbarProvider>
                </ThemeProvider>
            </AuthProviders>
        </ConfigsProvider>
    </Provider>
);
