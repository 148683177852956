import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {LerCode} from '../../../types';

interface Props {
    lerCodes: LerCode[]
}

export const LerCodeSelectorFilter = ({lerCodes}: Props) => {

    return (
        <Grid xs={12} md={3.5} item>
            <Controller
                name={'ler_code'}
                render={({field, fieldState: {error}}) => (
                    <FormControl size={'small'} fullWidth>
                        <InputLabel sx={{color: 'text.primary'}}
                                    id={'lerCode-label'}
                        >{t`LER`}</InputLabel>
                        <Select
                            {...field}
                            size={'small'}
                            label={t`LER`}
                            labelId={'lerCode-label'}
                            sx={styles.selectField}
                            error={Boolean(error)}
                            required
                        >
                            <MenuItem value={'all'}>{t`All`}</MenuItem>
                            {lerCodes?.map((ler, key) => (
                                ler.is_header
                                    ?
                                    <MenuItem
                                        key={key}
                                        value={ler.code}
                                        sx={{
                                            opacity: '1 !important',
                                            backgroundColor: 'common.lightGray'
                                        }}
                                        disabled
                                    >
                                        {`${ler.code} - ${ler.description}`}
                                    </MenuItem>
                                    :
                                    <MenuItem key={key} value={ler.code}>
                                        {`${ler.code} - ${ler.description}`}
                                    </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                            error={Boolean(error)}>{error?.message}</FormHelperText>
                    </FormControl>
                )}
            />
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    }
};

export default LerCodeSelectorFilter;