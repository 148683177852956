import {t} from 'ttag';
import * as Yup from 'yup';


export const AddLocationFormSchema = Yup.object().shape(
    {
        prevention_plan_id: Yup.number().required(t`Required field.`),
        address_id: Yup.object().shape({
            island: Yup.string().required(t`Required field.`),
            parish: Yup.string().required(t`Required field.`),
            county: Yup.string().required(t`Required field.`)
        }).required(t`Required field.`)
    }
).required();
