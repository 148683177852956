import React from 'react';
import {FieldValues, FormProvider} from 'react-hook-form';
import EntityResponsibleForm from './general-entity-work-forms/EntityResponsibleForm';
import WorkSpecificsForm from './general-entity-work-forms/WorkSpecificsForm';
import WorkGeneralForm from './general-entity-work-forms/WorkGeneralForm';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {Typography} from '@mui/material';
import {t} from 'ttag';
import BasicCard from '../../common/cards/BasicCard';

interface Props {
    currentForm: string
    entityResponsibleFormMethods: UseFormReturn<FieldValues | any>
    workGeneralFormMethods: UseFormReturn<FieldValues | any>
    workSpecificsFormMethods: UseFormReturn<FieldValues | any>
    onSubmitEntityResponsibleForm: () => void
    onSubmitWorkGeneralForm: () => void
    onSubmitWorkSpecificsForm: () => void
    onReturnWorkGeneralForm: () => void
    onReturnWorkSpecificsForm: () => void
    readOnly: boolean
}

export const GeneralEntityWorkForms = ({
   currentForm,
   entityResponsibleFormMethods,
   workGeneralFormMethods,
   workSpecificsFormMethods,
   onSubmitEntityResponsibleForm,
   onSubmitWorkGeneralForm,
   onSubmitWorkSpecificsForm,
   onReturnWorkGeneralForm,
   onReturnWorkSpecificsForm,
   readOnly
}: Props) => {

    return (
        <>
            {(currentForm === 'All') || (currentForm === 'EntityResponsibleForm') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`1.1 GENERAL ENTITY/WORK DATA`} ({ t`ENTITY GENERAL DATA`})
                        </Typography>
                    }
                >
                    <FormProvider {...entityResponsibleFormMethods}>
                        <EntityResponsibleForm
                            onSubmit={onSubmitEntityResponsibleForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
            {(currentForm === 'All') || (currentForm === 'WorkGeneralForm') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`1.2 GENERAL ENTITY/WORK DATA`} ({ t`WORK GENERAL DATA`})
                        </Typography>
                    }
                >
                    <FormProvider {...workGeneralFormMethods}>
                        <WorkGeneralForm
                            workGeneralFormMethods={workGeneralFormMethods}
                            onSubmit={onSubmitWorkGeneralForm}
                            onReturn={onReturnWorkGeneralForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
            {(currentForm === 'All') || (currentForm === 'WorkSpecificsForm') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`1.3 GENERAL ENTITY/WORK DATA`} ({t`WORK SPECIFICS`})
                        </Typography>
                    }
                >
                    <FormProvider {...workSpecificsFormMethods}>
                        <WorkSpecificsForm
                            onSubmit={onSubmitWorkSpecificsForm}
                            onReturn={onReturnWorkSpecificsForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
        </>
    );
};

export default GeneralEntityWorkForms;