import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AccountBalance, ExpandLess, ExpandMore} from '@mui/icons-material';
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import AuthorizationGuard from '../../../pages/common/guards/AuthorizationGuard';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';
import EntityDepartmentSelectorPpgrcdForm from '../../ppgrcds/form-dialogs/EntityDepartmentSelectorPpgrcdForm';
import CardDialog from '../../common/CardDialog';
import EntityDepartmentSelectorForm from '../../common/form-dialogs/EntityDepartmentSelectorForm';

interface Props {
    open: boolean,
    onToggle: React.MouseEventHandler<HTMLDivElement>
}

export const EntitiesMenuItem = ({open, onToggle}: Props) => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const [showAddPpgrcdDialog, setShowAddPpgrcdDialog] = useState<boolean>();
    const [showAddServiceDialog, setShowAddServiceDialog] = useState<boolean>();

    if (!user) return null;

    return (
        <>
            <MenuItem sx={{px: 0}} onClick={() => navigate('/entities')}>
                <ListItemButton sx={styles.listItemBtn}>
                    <ListItemIcon sx={styles.color}>
                        <AccountBalance/>
                    </ListItemIcon>
                    <ListItemText primary={t`Entities`}/>
                    <ListItemIcon onClick={onToggle}>
                        {open
                            ? <ExpandLess sx={styles.color}/>
                            : <ExpandMore sx={styles.color}/>
                        }
                    </ListItemIcon>
                </ListItemButton>
            </MenuItem>
            <Collapse in={open} timeout={"auto"} unmountOnExit>
                <List>
                    <AuthorizationGuard
                        userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                        <>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => navigate('/add-entity')}>
                                <ListItemText primary={t`Add Entities`}/>
                            </ListItemButton>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => setShowAddServiceDialog(true)}>
                                <ListItemText primary={t`Add Services`}/>
                            </ListItemButton>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => setShowAddPpgrcdDialog(true)}>
                                <ListItemText primary={t`Add PPGRCDs`}/>
                            </ListItemButton>
                        </>
                    </AuthorizationGuard>
                    <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                    onClick={() => navigate('/entities')}>
                        <ListItemText primary={t`View Entities`}/>
                    </ListItemButton>
                </List>
            </Collapse>
            <CardDialog
                title={t`ADD SERVICE`}
                open={showAddServiceDialog}
                onClose={() => setShowAddServiceDialog(false)}
            >
                <EntityDepartmentSelectorForm onSubmit={() => setShowAddServiceDialog(false)}/>
            </CardDialog>
            <CardDialog
                title={t`ADD PPGRCD`}
                open={showAddPpgrcdDialog}
                onClose={() => setShowAddPpgrcdDialog(false)}
            >
                <EntityDepartmentSelectorPpgrcdForm/>
            </CardDialog>
        </>
    );
};

const styles = {
    listItemBtn: {
        color: 'common.white',
        px: 0
    },
    color: {
        color: 'common.white'
    },
    innerListItemBtn: {
        py: 0
    }
};

export default EntitiesMenuItem;