import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Stack,
    TextField,
    Typography
} from '@mui/material';
import commonStyles from '../../../../../../common/styles/commonStyles';
import {Help} from '@mui/icons-material';


export const AddCollaboratorFormDetails = () => {
    return (
        <Stack spacing={4}>
            <Grid spacing={4} py={1} container>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'email'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`E-mail` + '*'}
                                sx={commonStyles.textField}
                                variant={"outlined"}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'role'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={styles.inputLabel} id={'role-label'} required>{t`Role`}</InputLabel>
                                <Select
                                    {...field}
                                    label={t`Role`}
                                    labelId={'role-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem key={1} value={'MEMBER'}>{t`Member`}</MenuItem>
                                    <MenuItem key={2} value={'COORDINATOR'}>{t`Coordinator`}</MenuItem>
                                    <MenuItem key={3} value={'MANAGER'}>{t`Manager`}</MenuItem>
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    }
};

AddCollaboratorFormDetails.defaultProps = {
    editable: true
}

export default AddCollaboratorFormDetails;