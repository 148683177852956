import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import useDirectusMutation from '../../../custom-hooks/useDirectusMutation';
import {commonStyles} from '../../../common/styles/commonStyles';
import DirectusFeedback from '../../../common/DirectusFeedback';
import {columns} from './helpers/participants-list-helpers';
import ConfirmDialog from '../../../common/ConfirmDialog';
import {User} from '../../../../types';
import {dataGridComponents} from '../../../datagrid/dataGridComponents';

interface Props {
    users: User[]
    pattern?: string
}

export const ParticipantsList = ({users, pattern}: Props) => {
    const [participantToDelete, setParticipantToDelete] = useState<User>(null);

    const deleteHandler = (participant) => setParticipantToDelete(participant);

    const {
        actions: {DELETE: deleteDepartment},
        response: {
            error: errorDeleteDepartment,
            isLoading: isLoadingDeleteDepartment
        }
    } = useDirectusMutation({path: `/items/prevention_plans_directus_users/${participantToDelete?.id}`});

    return (
        <>
            <DataGrid
                rows={users || []}
                sx={commonStyles.datagrid}
                rowCount={users.length || 0}
                components={dataGridComponents}
                getRowId={(row) => row.id}
                columns={columns({deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(participantToDelete)}
                title={t`Delete Participant`}
                onCancel={() => setParticipantToDelete(null)}
                onConfirm={() => deleteDepartment(() => setParticipantToDelete(null))}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${participantToDelete?.first_name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorDeleteDepartment}
                isLoading={isLoadingDeleteDepartment}
            />
        </>
    );
};

export default ParticipantsList;