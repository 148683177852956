import * as Yup from 'yup';
import {t} from 'ttag';
import {validateNif} from '../../../../../../utils/forms/helpers';


export const WorkSpecificsFormSchema = Yup.object().shape(
    {
        work_description: Yup.string().required(t`Required field.`),
        conversion_factors: Yup.string(),
        targeted_demolition: Yup.string(),
        targeted_demolition_description: Yup.string().when('targeted_demolition', {
            is: (targetedDemolition) => targetedDemolition === 'true',
            then: Yup.string().typeError(t`Required Field.`).required(t`Required field.`)
        }),
    }
).required();
