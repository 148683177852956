import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import {useDirectusQuery} from '../custom-hooks/useDirectusQuery';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import {columns} from './helpers/wanted-residues-list-helpers';
import {DirectusFeedback} from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import ConfirmDialog from '../common/ConfirmDialog';
import {Residue, User} from '../../types';

export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    filterByLerCode?: string
    filterByEntityId?: number | string
}

export const WantedResiduesList = ({filterByEntityId, filterByLerCode}: Props) => {
    const [residueToDelete, setResidueToDelete] = useState(null);
    const [invalidatedResidues, setInvalidatedResidues] = useState<Residue[]>();

    const deleteHandler = (residue) => setResidueToDelete(residue);

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: 'rb17_api/wanted_residues',
        options: {
            refetchOnChange: invalidatedResidues
        }
    });

    const {
        actions: {DELETE: deleteResidue},
        response: {
            error: errorDeleteResidue,
            isLoading: isLoadingDeleteResidue
        }
    } = useDirectusMutation({path: `/items/wanted_residues/${residueToDelete?.id}`});

    if (!residues) return null;

    let filteredResidues = residues?.raw?.filter((residue) =>
        filterByEntityId
            ? residue.entity_id === filterByEntityId
            : residue
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByLerCode ? residue.ler_code.code.toString().startsWith(filterByLerCode) : residue
    );

    return (
        <>
            <DataGrid
                rows={filteredResidues || []}
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                loading={isLoadingGetResidues}
                components={dataGridComponents}
                rowCount={filteredResidues?.length || 0}
                columns={columns({deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(residueToDelete)}
                title={t`Delete Residue`}
                onCancel={() => setResidueToDelete(null)}
                onConfirm={() => deleteResidue(() => {
                    setResidueToDelete(null);
                    setInvalidatedResidues(residues);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${residueToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetResidues || errorDeleteResidue}
                isLoading={isLoadingGetResidues || isLoadingDeleteResidue}
            />
        </>
    );
};

export default WantedResiduesList;