import axios from 'axios';
import {useState} from 'react';
import {useDirectus} from 'react-directus';
import {arrayBufferToBase64} from '../../utils/forms/helpers';
import {DirectusFile, DirectusMutation} from '../../types';
import {useStore} from '../../store/valtio';

interface Args {
    path: string,
    options?: {
        params?: {
            fields?: string,
            search?: string,
            alias?: object
        },
        headers?: object,
        noAuth: boolean
    }
}

// eslint-disable-next-line
export const useDirectusMutation = <Type extends DirectusMutation>(args: Args) => {
    const {app: {configs}} = useStore();
    const {directus} = useDirectus();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const GET_ASSET = ({directus_files_id: file}: DirectusFile, callback = (data?: any) => null) => {
        setIsLoading(true);

        axios.get(`${configs.api_url}/assets/${file.id}`, {
            headers: {Authorization: `Bearer ${directus.storage.auth_token}`},
            responseType: 'arraybuffer'
        }).then((res) => {
            const base64 = arrayBufferToBase64(res.data);
            setData("data:image;base64," + base64);
            callback(base64);
        })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    const POST = (body: object, callback = (data?: any) => null) => {
        setIsLoading(true);

        const fetch = async () => await directus.collections.transport.post(`${args.path}` , body, {
            ...args?.options,
            headers: args.options?.noAuth ? { ...args?.options?.headers}
                : {
                    ...args?.options?.headers,
                    Authorization: `Bearer ${directus.storage.auth_token}`
                }
        });
        fetch().then(r => {
            setData(r.data);
            callback(r.data);
        }).catch(e => setError(e)).finally(() => {
            setIsLoading(false);
        });
    };

    const PATCH = (body: object, callback = (data?: any) => null, id = '') => {
        setIsLoading(true);
        const fetch = async () => await directus.collections.transport.patch(`${args.path}/${id}` , body, {
            ...args?.options,
            headers: args.options?.noAuth ? {...args?.options?.headers}
                : {
                    ...args?.options?.headers,
                    Authorization: `Bearer ${directus.storage.auth_token}`
                }
        })
        fetch().then(r => {
            setData(r.data);
            callback(r.data);
        }).catch(e => setError(e)).finally(() => {
            setIsLoading(false);
        });
    };

    const DELETE = (callback = (data?: any) => null, id = '') => {
        setIsLoading(true);
        const fetch = async () => await directus.collections.transport.delete(`${args.path}/${id}`, null, {
            ...args?.options,
            headers: args.options?.noAuth ? {...args?.options?.headers}
                : {
                    ...args?.options?.headers,
                    Authorization: `Bearer ${directus.storage.auth_token}`
                }
        });
        fetch().then(r => {
            setData(r.data);
            callback(r.data);
        }).catch(e => setError(e)).finally(() => {
            setIsLoading(false);
        });
    };

    return {actions: {POST, PATCH, DELETE, GET_ASSET}, response: {data: data || null, error: error || null, isLoading}};
};

useDirectusMutation.defaultProps = {
    args: {
        options: {
            noAuth: false
        }
    }
};

export default useDirectusMutation;