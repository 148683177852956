import {t} from 'ttag';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import commonStyles from '../../../../../components/common/styles/commonStyles';
import AddServiceForm from '../../../../../components/entities/entity/deparment/services/forms/AddServiceForm';


export const AddService = () => {
    const {entityName, entityId, departmentName, departmentId} = useParams();

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD SERVICE`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Entities`, link: '/entities'},
                    {name: `${entityName}`, link: `/entities/${entityName}/${entityId}`},
                    {name: `${departmentName}`, link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`},
                    {name: t`Add Service`, link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/services/add-service`}
                ]}
            />
            <AddServiceForm/>
        </Stack>
    );
};

export default AddService;