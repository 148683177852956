import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Grid, TextField} from '@mui/material';
import commonStyles from '../../../../../../common/styles/commonStyles';

interface Props {

}

export const AddServiceSuggestionFormDetails = ({}: Props) => {

    return (
        <>
            <Grid spacing={4} container>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'service_name'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Service name` + '*'}
                                variant={"outlined"}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'description'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                rows={3}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`Description` + '*'}
                                sx={commonStyles.textField}
                                variant={"outlined"}
                                multiline
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AddServiceSuggestionFormDetails;