import {t} from 'ttag';
import * as Yup from 'yup';


export const EntityDepartmentSelectorFormSchema = Yup.object().shape(
    {
        entity_id: Yup.string().typeError(t`Required Field.`).required(t`Required Field.`),
        department_id: Yup.string().typeError(t`Required Field.`).required(t`Required Field.`)
    }
).required();
