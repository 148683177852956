import {t} from 'ttag';
import * as React from 'react';
import {Stack, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddByProductForm from '../../components/residues/forms/AddByProductForm';
import commonStyles from '../../components/common/styles/commonStyles';
import {useStore} from '../../store/valtio';


export const AddByProduct = () => {
    const {app: {user}} = useStore();

    if (!user) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD BY-PRODUCT`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`By-Products`, link: '/by-products'},
                    {name: t`Add By-Product`, link: '/add-by-product'}
                ]}
            />
            <AddByProductForm user={user}/>
        </Stack>
    );
};

export default AddByProduct;