import {t} from 'ttag';
import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {KeyboardArrowRight} from '@mui/icons-material';
import commonStyles from '../../../../common/styles/commonStyles';

interface Props {
    onSelectType: (type: string) => any
}

export const ServiceTypes = ({onSelectType}: Props) => {
    return (
        <Stack spacing={2}>
            <Box
                sx={{...commonStyles.card(), ...styles.typeWrapper}}
                onClick={() => onSelectType('RESIDUAL OPERATOR')}
            >
                <Typography sx={styles.text}>{t`RESIDUAL OPERATOR`}</Typography>
                <KeyboardArrowRight/>
            </Box>
            <Box
                sx={{...commonStyles.card(), ...styles.typeWrapper}}
                onClick={() => onSelectType('TREATMENT SERVICES')}
            >
                <Typography sx={styles.text}>{t`TREATMENT SERVICES`}</Typography>
                <KeyboardArrowRight/>
            </Box>
            <Box sx={{...commonStyles.card(), ...styles.typeWrapper}}
                 onClick={() => onSelectType('ADVANCED SERVICES')}
            >
                <Typography sx={styles.text}>{t`ADVANCED SERVICES`}</Typography>
                <KeyboardArrowRight/>
            </Box>
            <Box sx={{...commonStyles.card(), ...styles.typeWrapper}}
                 onClick={() => onSelectType('TRANSPORT SERVICES')}
            >
                <Typography sx={styles.text}>{t`TRANSPORT SERVICES`}</Typography>
                <KeyboardArrowRight/>
            </Box>
            <Box sx={{...commonStyles.card(), ...styles.typeWrapper}}
                 onClick={() => onSelectType('CONSULTING SERVICES')}
            >
                <Typography sx={styles.text}>{t`CONSULTING SERVICES`}</Typography>
                <KeyboardArrowRight/>
            </Box>
        </Stack>
    );
};

const styles = {
    text: {
        color: 'text.secondary'
    },
    typeWrapper: {
        py: 1,
        px: 2,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '20rem',
        cursor: 'pointer'
    }
};


export default ServiceTypes;