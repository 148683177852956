import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

interface Props {
    value: string;
    children: React.ReactElement[];
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const CustomizedTabs = ({children, value, onChange}: Props) => {

    const clones = React.Children.map(children, (child) => {
        return React.cloneElement(child, {sx: styles.tab})
    });

    return (
        <Box>
            <Tabs
                value={value}
                sx={styles.tabs}
                onChange={onChange}
                TabIndicatorProps={{children: <span className={'MuiTabs-indicatorSpan'}/>}}
            >
                {clones}
            </Tabs>
        </Box>
    );
}

const styles = {
    tabs: {
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 50,
            width: '100%',
            backgroundColor: 'primary.main',
        },
    },
    tab: {
        fontSize: '1rem',
        textTransform: 'none',
        fontWeight: 'regular',
        color: 'text.primary',
        '&.MuiFocusVisible': {
            backgroundColor: 'primary.main',
        },
    }
};
