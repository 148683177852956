import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../common/grid/SubMenu';
import {Residue} from '../../../types';

interface Props {
    editHandler: (row: Residue) => any
    deleteHandler: (row: Residue) => any
}

export const columns = ({editHandler, deleteHandler}: Props) => [
    {
        field: 'ler_code.code',
        headerName: t`LER CODE`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => row.ler_code.code
    },
    {
        field: 'ler_code.description',
        headerName: t`DESIGNATION`,
        sortable: true,
        disableColumnMenu: true,
        flex: 5,
        renderCell: ({row}) => row.ler_code.description
    },
    {
        field: 'amount',
        headerName: t`AMOUNT`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => row.amount?.toLocaleString("pt-BR")
    },
    {
        field: 'amount_base_unit',
        headerName: t`BASE UNIT`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => (
            row.amount_base_unit === 'CUBIC_METER'
                ? 'm3'
                : row.amount_base_unit === 'TON'
                    ? 'tons' : '-'
        )
    },
    {
        field: 'user',
        headerName: t`USER`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) =>  `${row.user?.first_name} ${row.user?.last_name}`
    },
    {
        field: 'actions',
        headerName: '',
        type: 'number',
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => {
            return (
                <SubMenu
                    viewAction={false}
                    editAction={false}
                    onView={() => editHandler(row)}
                    onDelete={() => deleteHandler(row)}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
