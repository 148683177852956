import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack, Typography} from '@mui/material';
import EntityDepartmentSelectorPpgrcdForm from '../../components/ppgrcds/form-dialogs/EntityDepartmentSelectorPpgrcdForm';
import AllPPGRCDsList from '../../components/entities/entity/deparment/ppgrcds/AllPPGRCDsList';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import PatternSearch from '../../components/common/pattern-search/PatternSearch';
import commonStyles from '../../components/common/styles/commonStyles';
import BasicCard from '../../components/common/cards/BasicCard';
import CardDialog from '../../components/common/CardDialog';
import {useStore} from '../../store/valtio';


export const AllPPGRCDs = () => {
    const {app: {user}} = useStore();
    const [searchPattern, setSearchPattern] = useState(null);
    const [showSelectEntityDepartmentDialog, setShowSelectEntityDepartmentDialog] = useState(false);

    if (!user) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Box sx={styles.box}>
                    <Typography sx={commonStyles.pageTitle}>{t`PPGRCDs`}</Typography>
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: 'PPGRCDs', link: '/ppgrcds'}
                    ]}
                />
                <BasicCard headerContent={<></>}>
                    <Stack spacing={2}>
                        <Box sx={styles.box}>
                            <PatternSearch onChange={(searchPattern) => setSearchPattern(searchPattern)}/>
                            <Button variant={'contained'} onClick={() => setShowSelectEntityDepartmentDialog(true)}>
                                <Add sx={styles.headerAddIcon}/>
                                {t`Add PPGRCD`}
                            </Button>
                        </Box>
                        <AllPPGRCDsList user={user} pattern={searchPattern}/>
                    </Stack>
                </BasicCard>
            </Stack>
            <CardDialog
                title={t`ADD PPGRCD`}
                open={showSelectEntityDepartmentDialog}
                onClose={() => setShowSelectEntityDepartmentDialog(false)}
            >
                <EntityDepartmentSelectorPpgrcdForm/>
            </CardDialog>
        </>

    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export default AllPPGRCDs;