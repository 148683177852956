import {t} from 'ttag';
import React, {ReactElement} from 'react';
import {Box, Button, Stack} from '@mui/material';

interface Props {
    cancelBtnText?: string,
    submitBtnText?: string,
    onSubmit: React.MouseEventHandler<HTMLButtonElement>,
    onCancel: React.MouseEventHandler<HTMLButtonElement>,
    submitAction?: boolean,
    cancelAction?: boolean,
    submitActionDisabled?: boolean
    children?: ReactElement
}

export const FormActions = ({
    cancelBtnText,
    submitBtnText,
    onSubmit,
    onCancel,
    submitAction,
    cancelAction,
    submitActionDisabled,
    children
}: Props) => {
    return (
        <Stack direction={'row'} justifyContent={children ? 'space-between' : 'flex-end'} spacing={1}>
            {children}
            <Box display={'flex'} gap={1}>
                {cancelAction &&
                    <Button sx={{backgroundColor: 'primary.contrastText'}} variant={'outlined'} onClick={onCancel}>{cancelBtnText}</Button>
                }
                {submitAction && (<Button disabled={submitActionDisabled} onClick={onSubmit} variant={'contained'}>{submitBtnText}</Button>)}
            </Box>
        </Stack>
    );
};

FormActions.defaultProps = {
    cancelBtnText: t`Clear`,
    submitBtnText: t`Conclude`,
    submitActionDisabled: false,
    onSubmit: () => null,
    onCancel: () => null,
    submitAction: true,
    cancelAction: true
}

export default FormActions;
