import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {KeyboardArrowRight, LocationOn} from '@mui/icons-material';
import useDirectusAssets from '../../custom-hooks/useDirectusAssets';
import DirectusFeedback from '../../common/DirectusFeedback';
import commonStyles from '../../common/styles/commonStyles';
import GraphicCard from '../../common/cards/GraphicCard';
import {ImageApiResponse, Residue} from '../../../types';
import {t} from 'ttag';

interface Props {
    residue: Residue
}

export const ResidueCard = ({residue}: Props) => {

    const {
        data: residueImg,
        error: errorGetResidueImg,
        isLoading: isLoadingGetResidueImg
    } = useDirectusAssets<ImageApiResponse>({
        path: `/assets/${residue.image.id}`,
        options: {
            refetchOnChange: residue
        }
    });

    if (!residueImg) return null;

    return (
        <>
            <GraphicCard
                key={residue.id}
                backgroundImg={residueImg}
                bottomLabel={
                    <Stack sx={{width: '100%'}}>
                        <Box sx={styles.addressInfo} gap={1}>
                            <LocationOn height={16}/>
                            <Typography fontSize={'.6rem'}>
                                {`${residue.address_id.parish} - ${residue.address_id.county} - ${residue.address_id.island}`}
                            </Typography>
                        </Box>
                        <Box sx={{...commonStyles.spaceBetween, p: 2}}>
                            {residue?.is_derived && residue?.derived_designation
                                ? <Typography>{t`By-Product` + ` - ${residue.derived_designation}`}</Typography>
                                : <Typography>{residue.ler_code.code} - {residue.ler_code.description}</Typography>
                            }
                            <KeyboardArrowRight/>
                        </Box>
                    </Stack>
                }
            />
            <DirectusFeedback error={errorGetResidueImg} isLoading={isLoadingGetResidueImg}/>
        </>
    );
};

const styles = {
    addressInfo: {
        px: 2,
        py: .5,
        gap: 1,
        ...commonStyles.centerContentAndItems,
        backgroundColor: 'background.gray',
        justifyContent: 'flex-start'
    }
}

export default ResidueCard;