import React from 'react';
import {Box, LinearProgress, linearProgressClasses, Typography} from '@mui/material';

interface Props {
    currentStep: number,
    totalSteps: number
}

export const StepsLinearProgress = ({currentStep, totalSteps}: Props) => {

    return (
        <Box sx={styles.progressBarWrapper} gap={2}>
            <LinearProgress sx={styles.progressBar} variant={"determinate"} value={(currentStep/totalSteps)*100}/>
            <Typography sx={styles.progressBarLabel}>{`${currentStep}/${totalSteps}`}</Typography>
        </Box>
    );
};

const styles = {
    progressBarWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progressBarLabel: {
        color: 'text.secondary',
        fontWeight: 300,
        fontSize: '0.9rem'
    },
    progressBar: {
        width: '13rem',
        height: '1.1rem',
        borderRadius: 1,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'common.lightGray'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
            backgroundColor: 'primary.main'
        },
    }
};

export default StepsLinearProgress;