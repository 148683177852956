import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Link, Typography} from '@mui/material';
import {useStore} from '../../store/valtio';

const appLogo = require('../../assets/logos/eea-grants.jpg');
const graLogo = require('../../assets/icons/gra-logo-rgb.png');


export const Footer = () => {
    const navigate = useNavigate();
    const {app: {isDesktopLg}} = useStore();

    return (
        <Box sx={styles.wrapper(isDesktopLg)} pb={isDesktopLg ? 3 : 4}>
            <Box sx={styles.policies(isDesktopLg)} gap={1}>
                <Typography fontSize={'0.7rem'} color={'common.black'}>
                    <Link sx={styles.link} onClick={() => navigate('/terms-and-conditions')}>
                        {t`Terms and Conditions`}
                    </Link>
                </Typography>
                <Typography fontSize={'0.7rem'} color={'common.black'}>
                    <Link sx={styles.link} onClick={() => navigate('/privacy-policy')}>
                        {t`Privacy Policy`}
                    </Link>
                </Typography>
            </Box>
            <Box sx={styles.graContent(isDesktopLg)}>
                {!isDesktopLg && <img height={40} src={appLogo}/>}
                <Box>
                    <Typography sx={styles.graText(isDesktopLg)}>
                        Esta é uma presença online oficial do <Link sx={styles.link} href='https://portal.azores.gov.pt'>Governo dos Açores</Link>
                    </Typography>
                    <Typography sx={styles.graText(isDesktopLg)}>
                        Laboratório Regional de Engenharia Civil
                    </Typography>
                </Box>
                <Box sx={styles.logos(isDesktopLg)}>
                    {isDesktopLg && <img height={40} src={appLogo}/>}
                    <img height={30} src={graLogo}/>
                </Box>
            </Box>
        </Box>
    );
};

const styles = {
    graContent: (isDesktopLg = false) => ({
        display:'flex',
        flexDirection: isDesktopLg ? 'column-reverse' : 'row',
        justifyContent: 'center',
        alignItems:'center',
        gap:'20px'
    }),
    graText: (isDesktopLg) => ({
        textAlign: isDesktopLg ? 'center' : 'right',
        color: 'common.black',
        fontSize: '12px'
    }),
    wrapper: (isDesktopLg) => ({
        display: 'flex',
        flexDirection: isDesktopLg ? 'column' : 'row',
        justifyContent: isDesktopLg ? 'center' : 'space-between',
        alignItems: 'center',
        gap: 3
    }),
    policies: (isDesktopLg) => ({
        display: 'flex',
        flexDirection: isDesktopLg ? 'row' : 'column',
        justifyContent: isDesktopLg ? 'space-evenly' : 'center',
        alignItems: isDesktopLg ? 'center' : 'left'
    }),
    link: {
        cursor: 'pointer',
        color: 'common.black',
        underline: 'always'
    },
    logos: (isDesktopLg) => ({
        display: 'flex',
        flexDirection: isDesktopLg ? 'row' : 'column',
        justifyContent: isDesktopLg ? 'center' : 'space-between',
        alignItems: 'center',
        gap: 3
    }),
};


export default Footer;
