import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack} from '@mui/material';
import CreatePpgrcdForm from '../../../../../components/ppgrcds/ppgrcd/create-ppgrcd-form/CreatePpgrcdForm';
import PPGRCDsList from '../../../../../components/entities/entity/deparment/ppgrcds/PPGRCDsList';
import PatternSearch from '../../../../../components/common/pattern-search/PatternSearch';
import AuthorizationGuard from '../../../../common/guards/AuthorizationGuard';
import CardDialog from '../../../../../components/common/CardDialog';
import {ENTITY_ROLES} from '../../../../../utils/user/user-utils';
import {Department, User} from '../../../../../types';

interface Props {
    department: Department
    authenticatedUser: User
}

export const PPGRCDs = ({department, authenticatedUser}: Props) => {
    const [searchPattern, setSearchPattern] = useState(null);
    const [showCreatePpgrcdDialog, setShowCreatePpgrcdDialog] = useState(false);

    const entityUser = department?.entity_id?.users?.filter((user) => user.directus_users_id.id === authenticatedUser.id)[0];

    if(!entityUser) return null;

    return (
        <>
            <Stack spacing={2}>
                <Box sx={styles.box}>
                    <PatternSearch onChange={(searchPattern) => setSearchPattern(searchPattern)}/>
                    <AuthorizationGuard userHasSomeOf={[entityUser, [ENTITY_ROLES.MANAGER, ENTITY_ROLES.COORDINATOR]]}>
                        <Button variant={'contained'} onClick={() => setShowCreatePpgrcdDialog(true)}>
                            <Add sx={styles.headerAddIcon}/>
                            {t`Add PPGRCD`}
                        </Button>
                    </AuthorizationGuard>
                </Box>
                <PPGRCDsList pattern={searchPattern}/>
            </Stack>
            <CardDialog
                maxWidth={'sm'}
                title={t`ADD PPGRCD`}
                open={showCreatePpgrcdDialog}
                onClose={() => setShowCreatePpgrcdDialog(false)}
            >
                <CreatePpgrcdForm/>
            </CardDialog>
        </>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export default PPGRCDs;