import {Box} from '@mui/material';
import SideBar from '../components/layout/SideBar';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import {useStore} from '../store/valtio';
import NavBar from '../components/layout/NavBar';


export const MainLayout = ({children}) => {
    const {app: {isDesktop}} = useStore();

    return (
        <Box display={'flex'} minHeight={'100%'}>
            {isDesktop && <SideBar/>}
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} width={'100%'} px={isDesktop ? 8 : 0} gap={10}>
                <Box>
                    {isDesktop ? <Header/> : <NavBar/>}
                    <Box p={4}>
                        {children}
                    </Box>
                </Box>
                <Footer/>
            </Box>
        </Box>
    );
};

export default MainLayout;
