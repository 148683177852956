import {t} from 'ttag';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import commonStyles from '../../../../common/styles/commonStyles';
import JustifiedTypography from '../../../../common/JustifiedTypography';

interface Props {
    readOnly: boolean
}

export const WorkSpecificsFormDetails = ({readOnly}: Props) => {
    const {watch} = useFormContext();

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <Typography>{t`Project/work characterization and methods`}</Typography>
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'work_description'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                rows={3}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Description of the main activities of the work`+ '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <JustifiedTypography>
                    {t`In project and work, the CDW prevention and management options are respected, according to the following order of priorities: a) Prevention; b) Preparation for reuse; c) Recycling; d) Other types of recovery; e) Disposal. The CDW generated in the project/work are correctly sorted and stored in containers with the respective identification and forwarded to an adequate final destination, obeying the proximity criteria. 
Describe other methods, if applicable and briefly. Examples: prevention, reuse of materials and by-products; use of CDW in the project/work, and use of recycled materials or materials incorporating recycled materials.`}
                </JustifiedTypography>
            </Grid>
            <Grid xs={12} md={12} item>
                <Typography>{t`Conversion Factors`}</Typography>
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'conversion_factors'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                rows={3}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Explain conversion factors considered and rationale and references used`}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'targeted_demolition'}
                    render={({field}) => (
                        <Box sx={commonStyles.spaceBetween}>
                            <Typography>{t`Is there a targeted demolition plan?`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'true'} label={t`Yes`} control={<Radio/>} disabled={readOnly}/>
                                <FormControlLabel value={'false'} label={t`No`} control={<Radio/>} disabled={readOnly}/>
                            </RadioGroup>
                        </Box>
                    )}
                />
            </Grid>
            {watch('targeted_demolition') === 'true' &&
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'targeted_demolition_description'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                rows={3}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Targeted demolition procedures` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                multiline
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
            }
        </Grid>
    );
};

export default WorkSpecificsFormDetails;