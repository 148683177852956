import {t} from 'ttag';
import React, {useState} from 'react';
import {DateTime as dt} from 'luxon';
import {Stack, Typography} from '@mui/material';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {FieldValues, FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {ImportEgarsFormSchema} from './plan-closure-form/schemas/import-egars-form-schema';
import PlanClosureFormDetails from './plan-closure-form/PlanClosureFormDetails';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import useEgarsQuery from '../../custom-hooks/useEgarsQuery';
import DirectusFeedback from '../../common/DirectusFeedback';
import FormSkeleton from '../../common/forms/FormSkeleton';
import BasicCard from '../../common/cards/BasicCard';
import {Entity} from '../../../types';

interface NoEgarsError {
    message: string
}

interface Props {
    entity: Entity
    currentForm: string
    planClosureMethods: UseFormReturn<FieldValues | any>
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const PlanClosureForm = ({
    entity,
    currentForm,
    planClosureMethods,
    onSubmit,
    onReturn,
    readOnly
}: Props) => {
    const [importedEgars, setImportedEgars] = useState();
    const {resetField, setValue, handleSubmit} = planClosureMethods;

    const {fields: egars, append, replace} = useFieldArray({
        control: planClosureMethods.control,
        name: 'egars'
    });

    const {
        fields: addedImportedEgars,
        append: appendAddedImportedEgar,
        replace: replaceAddedImportedEgar
    } = useFieldArray({
        control: planClosureMethods.control,
        name: 'addedImportedEgars'
    });

    const ImportEgarsFormMethods = useForm({
        resolver: yupResolver(ImportEgarsFormSchema),
        defaultValues: {
            work_code: ''
        }
    });

    const {
        actions: {POST: importEgar},
        response: {
            isLoading: isLoadingImportEgar,
            error: errorImportEgar
        }
    } = useEgarsQuery();

    const handleAddEgar = handleSubmit((values) => {
        append({
            document_code: values.document_code,
            verification_code: values.verification_code,
            ler_code: values.ler_code,
            amount: values.amount,
            base_unit: values.base_unit,
            transport: values.transport,
            fiscal_number: values.fiscal_number,
            operator: values.operator,
            operation: values.operation,
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        });
        resetField('document_code');
        resetField('verification_code');
        resetField('ler_code');
        resetField('amount');
        resetField('base_unit');
        resetField('transport');
        resetField('fiscal_number');
        resetField('operator');
        resetField('operation');
        resetField('insertion_date');
    });

    const handleAddImportedEgar = (importedEgar) => {
        append({
            document_code: importedEgar.numeroGuia,
            verification_code: importedEgar.codigoVerificacao,
            ler_code: importedEgar.residuosTransportados.residuoTransportado.codigoResiduoLer,
            amount: parseInt(importedEgar.residuosTransportados.residuoTransportado.quantidade),
            base_unit: 'TON',
            transport: importedEgar.transportadores.transportador.entidade,
            fiscal_number: importedEgar.produtorDetentor.nif,
            operator: importedEgar.operadorGestaoResiduos.estabelecimento,
            operation: importedEgar.residuosTransportados.residuoTransportado.codigoOperacao,
            insertion_date: dt.now().toFormat(DATETIME_FORMAT)
        })
        appendAddedImportedEgar(importedEgar);
    };

    const handleEditEgar = (egarId) => {
        const wantedEgarToEdit = egars.filter((egar) => egar.id === egarId)[0];
        setValue('document_code', wantedEgarToEdit.document_code);
        setValue('verification_code', wantedEgarToEdit.verification_code);
        setValue('ler_code', wantedEgarToEdit.ler_code);
        setValue('amount', wantedEgarToEdit.amount);
        setValue('base_unit', wantedEgarToEdit.base_unit);
        setValue('transport', wantedEgarToEdit.transport);
        setValue('fiscal_number', wantedEgarToEdit.fiscal_number);
        setValue('operator', wantedEgarToEdit.operator);
        setValue('operation', wantedEgarToEdit.operation);
        setValue('insertion_date', wantedEgarToEdit.insertion_date);
        handleRemoveEgar(egarId);
    };

    const handleSaveEditEgar = (callback = () => null) => handleSubmit((values) => {
        append({
            document_code: values.document_code,
            verification_code: values.verification_code,
            ler_code: values.ler_code,
            amount: values.amount,
            base_unit: values.base_unit,
            transport: values.transport,
            fiscal_number: values.fiscal_number,
            operator: values.operator,
            operation: values.operation,
            insertion_date: values.insertion_date
        })
        callback();
    });

    const handleRemoveEgar = (egarId) => {
        const filteredEgars = egars.filter((egar) => egar.id !== egarId);
        const filteredImportedEgars = addedImportedEgars.filter((importedEgar) => importedEgar.numeroGuia !== documentCode);
        replace(filteredEgars);
        replaceAddedImportedEgar(filteredImportedEgars);
    }

    const handleImportEgars = ImportEgarsFormMethods.handleSubmit((values) => {
        importEgar({
            workCode: values.work_code,
            nif: entity.nif || entity.nipc,
        }, (egars) => setImportedEgars(egars))
    })

    return (
        <>
            {((currentForm === 'All') || (currentForm === 'PlanClosure')) &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`8. PLAN CLOSURE - E-GAR`}
                        </Typography>
                    }
                >
                    <FormProvider {...planClosureMethods}>
                        <Stack gap={3} py={2}>
                            <FormSkeleton
                                mainGroupContent={
                                    <PlanClosureFormDetails
                                        egars={egars}
                                        importedEgars={importedEgars}
                                        addedImportedEgars={addedImportedEgars}
                                        onAddEgar={handleAddEgar}
                                        onEditEgar={handleEditEgar}
                                        onSaveEditEgar={(callback) => handleSaveEditEgar(callback)()}
                                        onRemoveEgar={handleRemoveEgar}
                                        onImportEgars={handleImportEgars}
                                        onAddImportedEgar={handleAddImportedEgar}
                                        importEgarsFormMethods={ImportEgarsFormMethods}
                                        readOnly={readOnly}
                                    />
                                }
                                submitBtnText={t`Next`}
                                cancelBtnText={t`Return`}
                                onSubmit={onSubmit}
                                onCancel={onReturn}
                                actions={!readOnly}
                                innerForm
                            />
                        </Stack>
                    </FormProvider>
                </BasicCard>
            }
            <DirectusFeedback error={errorImportEgar} isLoading={isLoadingImportEgar}/>
        </>
    );
};

export default PlanClosureForm;