import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {Link, Stack, Typography} from '@mui/material';
import {columns} from './helpers/wanted-residues-others-list-helpers';
import {useDirectusQuery} from '../custom-hooks/useDirectusQuery';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import {DirectusFeedback} from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import AlertDialog from '../common/AlertDialog';
import {Residue, User} from '../../types';

export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    filterByLerCode?: string
    filterByEntityId?: number | string
}

export const WantedResiduesOthersList = ({filterByEntityId, filterByLerCode}: Props) => {
    const [userToContact, setUserToContact] = useState<User>();
    const [residueSelected, setResidueSelected] = useState<Residue>();

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: 'rb17_api/wanted_residues?own=true'
    });

    const contactHandler = (user, residue) => {
        setUserToContact(user);
        setResidueSelected(residue);
    };

    if (!residues) return null;

    let filteredResidues = residues?.raw?.filter((residue) =>
        filterByEntityId
            ? residue.department_id.entity_id.id === filterByEntityId
            : residue
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByLerCode ? residue.ler_code.code.toString().startsWith(filterByLerCode) : residue
    );

    return (
        <>
            <DataGrid
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                rows={filteredResidues || []}
                loading={isLoadingGetResidues}
                components={dataGridComponents}
                rowCount={filteredResidues?.length || 0}
                columns={columns({contactHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <AlertDialog
                title={t`Contact`}
                variant={'primary'}
                confirmText={t`Close`}
                open={Boolean(userToContact)}
                onConfirm={() => setUserToContact(null)}
            >
                <Stack sx={commonStyles.centerContentAndItems} spacing={1}>
                    <Typography textAlign={'center'}>{t`This residue is wanted by` + ` ${userToContact?.first_name} ${userToContact?.last_name} (${residueSelected?.entity?.name})`}</Typography>
                    <Typography textAlign={'center'}>{t`For more information contact the following email:`}</Typography>
                    <a href={`mailto:${userToContact?.email}?subject=${residueSelected?.ler_code?.code} - ${residueSelected?.ler_code?.description}`} target={'_blank'}>{userToContact?.email}</a>
                </Stack>
            </AlertDialog>
            <DirectusFeedback error={errorGetResidues} isLoading={isLoadingGetResidues}/>
        </>
    );
};

const styles = {
    link: {
        color:'primary.light',
        underline:'none'
    }
}

export default WantedResiduesOthersList;