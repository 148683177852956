import {t} from 'ttag';
import React, {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import data from "./form-selectors-raa/utils/islands-data.json";

interface Props {
    editable?: boolean
    fieldSize?: 'small' | 'medium'
}

export const FormSelectorsRAA = ({editable, fieldSize}: Props) => {
    const {islands} = data;
    const {watch, resetField} = useFormContext();

    const selectedIsland = useMemo(
        () => islands?.filter((island) => island.name === watch('address_id.island'))[0],
        [watch('address_id.island')]
    );
    const selectedCounty = useMemo(
        () => selectedIsland?.counties?.filter((county) => county.name === watch('address_id.county'))[0],
        [watch('address_id.county')]
    );

    return (
        <>
            <Grid xs={12} md={4} item>
                <Controller
                    name={'address_id.island'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl size={fieldSize} disabled={!editable} fullWidth>
                            <InputLabel sx={{color: 'text.primary'}} id={"island-label"}>{t`Island`}*</InputLabel>
                            <Select
                                {...field}
                                label={t`Island`}
                                labelId={"island-label"}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!editable}
                                onChange={(value) => {
                                    field.onChange(value);
                                    resetField('address_id.county');
                                    resetField('address_id.parish');
                                }}
                                variant={'outlined'}
                                size={fieldSize}
                                fullWidth
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {islands?.map((island, key) => (
                                    <MenuItem key={key} value={island.name}>{island.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>

            <Grid xs={12} md={4} item>
                <Controller
                    name={'address_id.county'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl
                            size={fieldSize}
                            disabled={!watch('address_id.island') || !editable}
                            fullWidth
                        >
                            <InputLabel sx={{color: 'text.primary'}} id={"county-label"}>{t`County`}*</InputLabel>
                            <Select
                                {...field}
                                label={t`County`}
                                labelId={'county-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                onChange={(value) => {
                                    field.onChange(value);
                                    resetField('address_id.parish');
                                }}
                                disabled={!watch('address_id.island') || !editable}
                                size={fieldSize}
                                fullWidth
                                required
                            >
                                <MenuItem value={''}></MenuItem>
                                {selectedIsland?.counties?.map((county, key) => (
                                    <MenuItem key={key} value={county.name}>{county.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={4} item>
                <Controller
                    name={'address_id.parish'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl
                            size={fieldSize}
                            disabled={!watch('address_id.county') || !editable}
                            fullWidth
                        >
                            <InputLabel sx={{color: 'text.primary'}} id={"parish-label"}>{t`Parish`}*</InputLabel>
                            <Select
                                {...field}
                                label={t`Parish`}
                                labelId={'parish-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={!watch('address_id.county') || !editable}
                                size={fieldSize}
                                fullWidth
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {selectedCounty?.parishes?.map((parish, key) => (
                                    <MenuItem key={key} value={parish}>{parish}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    }
};

FormSelectorsRAA.defaultProps = {
    editable: false,
    fieldSize: 'medium'
}

export default FormSelectorsRAA;