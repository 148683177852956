import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import ResidueComponent from '../../../components/residues/residue/ResidueComponent';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import {Residue as ResidueType} from '../../../types';

export const Residue = () => {
    const {residueId} = useParams();
    const [invalidatedResidue, setInvalidatedResidue] = useState<ResidueType>();

    const {data: residue, error: errorGetResidue, isLoading: isLoadingGetResidue} = useDirectusQuery({
        path: `/items/residues/${residueId}`,
        options: {
            params: {
                fields: '*.*.*.*.*'
            },
            refetchOnChange: invalidatedResidue
        }
    });

    const handleChangeInvalidateResidue = () => setInvalidatedResidue(residue);

    if (!residue) return null;

    return (
        <>
            <ResidueComponent
                residue={residue}
                onChangeInvalidateResidue={handleChangeInvalidateResidue}
            />
            <DirectusFeedback
                error={errorGetResidue}
                isLoading={isLoadingGetResidue}
            />
        </>
    );
};

export default Residue;