import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import commonStyles from '../../../../../../common/styles/commonStyles';
import FormActions from '../../../../../../common/forms/FormActions';

interface Props {
    services: Array<any>
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const CommonServiceFormDetails = ({services, onSubmit, onReturn}: Props) => {
    return (
        <>
            <Grid spacing={2} container>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'service_platform_id'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={styles.inputLabel} id={'type-label'} required>{t`Type`}</InputLabel>
                                <Select
                                    {...field}
                                    label={t`Type`}
                                    labelId={'type-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {services?.map((service, key) => (
                                        <MenuItem key={key} value={service.id}>
                                            {service.subtype_title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Controller
                        name={'description'}
                        render={({field, fieldState: {error}}) => (
                            <Grid xs={12} md={12} item>
                                <TextField
                                    {...field}
                                    rows={5}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`Description`+ '*'}
                                    error={Boolean(error)}
                                    variant={"outlined"}
                                    multiline
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                    />
                </Grid>
            </Grid>
            <FormActions
                submitBtnText={t`Add`}
                cancelBtnText={t`Return`}
                onSubmit={onSubmit}
                onCancel={onReturn}
            />
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    }
};

export default CommonServiceFormDetails;