import {t} from 'ttag';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {AddServiceSuggestionFormSchema} from '../schemas/add-service-suggestion-form-schema';
import AddServiceSuggestionFormDetails from './AddServiceSuggestionFormDetails';
import FormSkeleton from '../../../../../../common/forms/FormSkeleton';

interface Props {
    onSubmit: (body: object, callback?: (data?: any) => any) => void
}

export const AddServiceSuggestionForm = ({onSubmit}: Props) => {

    const methods = useForm({
        resolver: yupResolver(AddServiceSuggestionFormSchema),
        defaultValues: {
            service_name: '',
            description: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        onSubmit(values)
    })

    return (
        <FormProvider {...methods}>
            <FormSkeleton
                mainGroupContent={<AddServiceSuggestionFormDetails/>}
                submitBtnText={t`Send`}
                onSubmit={submitHandler}
                cancelAction={false}
                innerForm
            />
        </FormProvider>
    );
};

export default AddServiceSuggestionForm;