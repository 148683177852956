import {t} from 'ttag';
import * as Yup from 'yup';
import {validateNif} from '../../../../utils/forms/helpers';


export const AddEntityFormSchema = Yup.object().shape(
    {
        is_company: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        name: Yup.string().required(t`Required field.`),
        nif: Yup.number().when('is_company', {
            is: (isCompany) => isCompany === 'false',
            then: Yup.number().nullable().test({
                test: (value) => validateNif(value),
                message: t`Should be a valid fiscal number.`
            }).required(t`Required field.`).typeError(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        }),
        nipc: Yup.number().when('is_company', {
            is: (isCompany) => isCompany === 'true',
            then: Yup.number().nullable().test({
                test: (value) => validateNif(value),
                message: t`Should be a valid fiscal number.`
            }).required(t`Required field.`).typeError(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        }),
        license: Yup.string()
    }
).required();
