import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../common/grid/SubMenu';


export const columns = ({ editHandler, deleteHandler }) => [
  {
    field: 'name',
    headerName: t`NAME`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'address',
    headerName: t`ADDRESS`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id?.address
  },
  {
    field: 'county',
    headerName: t`COUNTY`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id?.county
  },
  {
    field: 'parish',
    headerName: t`PARISH`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id?.parish
  },
  {
    field: 'email',
    headerName: t`EMAIL`,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => row.address_id?.email
  },
  {
    field: 'actions',
    headerName: '',
    type: 'number',
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => {
      return (
          <SubMenu
              viewAction
              editAction={false}
              onView={() => editHandler(row)}
              onDelete={() => deleteHandler(row)}
          />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
