import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import AuthorizationGuard from '../guards/AuthorizationGuard';
import AuthenticationGuard from '../guards/AuthenticationGuard';
import {useStore} from '../../../store/valtio';
import RouteGuard from '../guards/RouteGuard';

interface Props {
    children: React.ReactElement,
    withMainLayout: boolean,
    withAuthentication: boolean,
    userHasSomeOf?: Array<any>
    userHasEveryOf: Array<any>
}

export const Page = ({children, userHasSomeOf, userHasEveryOf, withMainLayout, withAuthentication}: Props) => {
    const {app: {user}} = useStore();
    const Default = ({children}) => children;
    const LayoutMain = withMainLayout ? MainLayout : Default;
    const GuardAuthentication = withAuthentication ? AuthenticationGuard : Default;
    const GuardAuthorization = (userHasSomeOf.length || userHasEveryOf.length) ? AuthorizationGuard : Default;

    return (
        <RouteGuard>
            <GuardAuthentication>
                <GuardAuthorization
                    userHasSomeOf={[user, userHasSomeOf]}
                    userHasEveryOf={[user, userHasEveryOf]}
                    redirectToUnauthorized
                >
                    <LayoutMain>{children}</LayoutMain>
                </GuardAuthorization>
            </GuardAuthentication>
        </RouteGuard>
    );
};

Page.defaultProps = {
    children: null,
    withMainLayout: true,
    withAuthentication: true,
    userHasSomeOf: [],
    userHasEveryOf: []
};

export default Page;
