import {t} from 'ttag';
import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {EntityDepartmentSelectorFormSchema} from './schemas/entity-department-selector-form-schema';
import EntityDepartmentSelectorFormDetails from './EntityDepartmentSelectorFormDetails';
import FormSkeleton from '../../common/forms/FormSkeleton';
import AlertDialog from '../../common/AlertDialog';
import {useStore} from '../../../store/valtio';

interface Props {
    onSubmit?: () => void
}

export const EntityDepartmentSelectorForm = ({onSubmit}: Props) => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const [showNoPermissionDialog, setShowNoPermissionDialog] = useState(false);

    const methods = useForm({
        resolver: yupResolver(EntityDepartmentSelectorFormSchema),
        defaultValues: {
            entity_id: '',
            department_id: '',
            entity: {},
            department: {}
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        navigate(`/entities/${values.entity.name}/${values.entity_id}/departments/${values.department.name}/${values.department_id}/services/add-service`);
        onSubmit();
    });

    if (!user) return null;

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<EntityDepartmentSelectorFormDetails authenticatedUser={user}/>}
                    submitBtnText={t`Add Service`}
                    onSubmit={submitHandler}
                    cancelAction={false}
                    innerForm
                />
            </FormProvider>
            <AlertDialog
                open={showNoPermissionDialog}
                onConfirm={() => setShowNoPermissionDialog(false)}
            >
                <Typography color={'text.secondary'}>
                    {t`You don't have permissions on this entity to be able to create prevention plans. Please select other entity.`}
                </Typography>
            </AlertDialog>
        </>
    );
};

export default EntityDepartmentSelectorForm;