import {t} from 'ttag';
import Compressor from 'compressorjs';

export const validateNif = (value) => {
    if (value === 999999999) return true;
    value = value + '';
    // has 9 digits?
    if (!/^[0-9]{9}$/.test(value)) return false;
    // is from a person?
    if (!/^[123]|45|5|7/.test(value)) return false;
    // digit check
    let tot =
        value[0] * 9 +
        value[1] * 8 +
        value[2] * 7 +
        value[3] * 6 +
        value[4] * 5 +
        value[5] * 4 +
        value[6] * 3 +
        value[7] * 2;
    let mod = tot - parseInt(String(tot / 11)) * 11;
    let tst = mod === 1 || mod === 0 ? 0 : 11 - mod;
    return parseInt(value[8]) === tst;
};

export const emailIsValid = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    return emailPattern.test(email);
};
export const passwordIsValid = (password: string): boolean => {
    return password.length >= 8;
};

export const fileToBase64 = (file, callback = (result: string) => null) => {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = (e) => {
        callback(e.target.result.toString());
        return e.target.result;
    };
};

export const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const getFileFromBase64 = (string64: string, fileName: string) => {
    const trimmedString = string64.replace('data:image/jpeg;base64,', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }
    const blob = new Blob([buffer]);
    return new File([blob], fileName, {type: 'image/jpeg', lastModified: new Date().getTime()});
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

const compressFile = (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.8,
            success: (compressedResult) => resolve(compressedResult),
            error: (error) => reject(error)
        });
    });
};

export const createFileFormData = (files: File[], onSuccess: (formData: FormData) => void, onError: (error: Error) => void) => {
    const formData = new FormData();
    let filesCompressed = 0;

    files?.forEach((file, index, array) => {
        compressFile(file)
            .then((compressedFile) => {
                if (compressedFile.size >= 1000000) {
                    onError({message: t`The file(s) size should be less than 1mb.`})
                    return;
                }

                formData.append('title', compressedFile.name);
                formData.append('file', compressedFile);
                filesCompressed++;

                if (filesCompressed === array.length) {
                    onSuccess(formData);
                }
            })
            .catch((error) => {
                onError({message: error?.message})
                return;
            })
    });
}