import React, {useState} from 'react';
import {DateTime as dt} from 'luxon';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {PPGRCDsApiResponse} from '../../entities/entity/deparment/ppgrcds/PPGRCDsList';
import AddResidueFormDetails from './add-residue-form/AddResidueFormDetails';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import {AddResidueFormSchema} from './schemas/add-residue-form-schema';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import {createFileFormData} from '../../../utils/forms/helpers';
import DirectusFeedback from '../../common/DirectusFeedback';
import FormSkeleton from '../../common/forms/FormSkeleton';
import {ResiduesApiResponse} from '../ResiduesList';
import {LerCode, User} from '../../../types';

interface Props {
    user: User,
    lerCodes: LerCode[]
}

export const AddResidueForm = ({user, lerCodes}: Props) => {
    const navigate = useNavigate();
    const [errorFileSize, setErrorFileSize] = useState(null);

    const {
        actions: {POST: addResidue},
        response: {error: errorAddResidue, isLoading: isLoadingAddResidue}
    } = useDirectusMutation({path: '/items/residues'});

    const {
        data: departments,
        error: errorGetDepartments,
        isLoading: isLoadingGetDepartments
    } = useDirectusQuery({
        path: '/items/departments',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    "entity_id": {
                        "users": {
                            "directus_users_id": {"_eq": user.id}
                        }
                    }
                }
            }
        }
    });

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": user.id
                                }
                            }
                        }
                    }
                }
            }
        }
    });

    const {
        data: ppgrcds,
        error: errorGetPPGRCDs,
        isLoading: isLoadingGetPPGRCDs
    } = useDirectusQuery<PPGRCDsApiResponse>({
        path: '/items/prevention_plans',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": user.id
                                }
                            }
                        }
                    }
                }
            }
        }
    });

    const {
        actions: {POST: uploadFiles},
        response: {
            error: errorUploadPhoto,
            isLoading: isLoadingUploadPhoto
        }
    } = useDirectusMutation({path: '/files'});

    const methods = useForm({
        resolver: yupResolver(AddResidueFormSchema),
        defaultValues: {
            is_derived: 'false',
            holder: '',
            entity_id: '',
            department_id: '',
            ler_code: '',
            address_id: {
                island: '',
                parish: '',
                county: '',
            },
            format: '',
            amount: '',
            status: 'UNPUBLISHED',
            amount_base_unit: '',
            contamination: '',
            customAvailabilityDate: false,
            availability_date: dt.now().toFormat(DATETIME_FORMAT)
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        values.image ?
            createFileFormData([values.image], (formData) =>
                uploadFiles(formData,
                (uploadedFile) => {
                    addResidue({
                        ...values,
                        image: uploadedFile.id
                    }, () => navigate('/residues'));
                }
            ), (error) => setErrorFileSize(error))
            : addResidue((values), () => navigate('/residues'));
    });

    if (!residues || !ppgrcds || !departments) return null;

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={
                        <AddResidueFormDetails
                            residues={residues}
                            ppgrcds={ppgrcds}
                            lerCodes={lerCodes}
                        />
                    }
                    onSubmit={submitHandler}
                    onCancel={() => methods.reset()}
                />
            </FormProvider>
            <DirectusFeedback
                error={errorAddResidue || errorGetDepartments || errorGetPPGRCDs || errorGetResidues || errorUploadPhoto || errorFileSize}
                isLoading={isLoadingAddResidue || isLoadingGetDepartments || isLoadingGetPPGRCDs || isLoadingGetResidues || isLoadingUploadPhoto}
            />
        </>

    );
};

export default AddResidueForm;