import {t} from 'ttag';
import React, {ReactElement} from 'react';
import {Box, Card, CardActions, CardContent, CardHeader, Stack, Typography} from '@mui/material';
import FormActions from './FormActions';
import commonStyles from '../styles/commonStyles';

interface Props {
    mainGroupContent: ReactElement,
    subGroupContent?: ReactElement,
    subGroupTitle?: string,
    submitBtnText?: string,
    cancelBtnText?: string,
    onSubmit?: React.MouseEventHandler<HTMLButtonElement>,
    onCancel?: React.MouseEventHandler<HTMLButtonElement>,
    innerForm?: boolean,
    cancelAction?: boolean,
    actions?: boolean
}

export const FormSkeleton = ({
     mainGroupContent,
     subGroupContent,
     subGroupTitle,
     submitBtnText,
     cancelBtnText,
     onSubmit,
     onCancel,
     innerForm,
     cancelAction,
     actions,
}: Props) => {
    return (
        <Card sx={{...commonStyles.card(!innerForm), p: innerForm ? 0 : 1}}>
            <CardContent sx={{px: innerForm ? 0 : 1}}>
                <Stack spacing={4}>
                    {subGroupTitle &&
                        <Box sx={styles.subGroup}>
                            <Typography sx={styles.subGroupTilte}>{subGroupTitle}</Typography>
                            {subGroupContent || <></>}
                        </Box>
                    }
                    <Box>
                        {mainGroupContent}
                    </Box>
                    {actions &&
                        <FormActions
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            cancelAction={cancelAction}
                            submitBtnText={submitBtnText}
                            cancelBtnText={cancelBtnText}
                        >
                            <Typography sx={styles.caption}>{t`Required Fields*`}</Typography>
                        </FormActions>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
};

const styles = {
    subGroup: {
        py: 1,
        px: 2,
        gap: 4,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'background.gray'
    },
    subGroupTilte: {
        fontWeight: 600,
        fontSize: '1rem',
        color: 'text.primary'
    },
    caption: {
        color: 'common.gray',
        fontSize: '0.875rem'
    }
};

FormSkeleton.defaultProps = {
    innerForm: false,
    cancelAction: true,
    actions: true
};

export default FormSkeleton;