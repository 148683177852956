import {t} from 'ttag';
import React from 'react';
import {Stack, Typography} from '@mui/material';
import {FieldValues, FormProvider} from 'react-hook-form';
import BasicCard from '../../common/cards/BasicCard';
import ConditioningAndSortingFormDetails from './conditioning-and-sorting-form/ConditioningAndSortingFormDetails';
import FormSkeleton from '../../common/forms/FormSkeleton';
import {UseFormReturn} from 'react-hook-form/dist/types/form';

interface Props {
    currentForm: string
    conditioningAndSortingFormMethods: UseFormReturn<FieldValues | any>
    onSubmit: () => void
    onReturn: () => void
    readOnly: boolean
}

export const ConditioningAndSortingForm = ({
    currentForm,
    conditioningAndSortingFormMethods,
    onSubmit,
    onReturn,
    readOnly
}: Props) => {
    return (
        <>
            {(currentForm === 'All') || (currentForm === 'ConditioningAndSorting') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`5. CONDITIONING AND SORTING`}
                        </Typography>
                    }
                >
                    <FormProvider {...conditioningAndSortingFormMethods}>
                        <Stack gap={3} py={2}>
                            <FormSkeleton
                                mainGroupContent={<ConditioningAndSortingFormDetails readOnly={readOnly}/>}
                                submitBtnText={t`Next`}
                                cancelBtnText={t`Return`}
                                onSubmit={onSubmit}
                                onCancel={onReturn}
                                actions={!readOnly}
                                innerForm
                            />
                        </Stack>
                    </FormProvider>
                </BasicCard>
            }
        </>
    );
};

export default ConditioningAndSortingForm;