import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import {dataGridComponents} from '../../../../datagrid/dataGridComponents';
import useDirectusQuery from '../../../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import commonStyles from '../../../../common/styles/commonStyles';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import {columns} from './helpers/all-ppgrcds-list-helpers';
import {PPGRCD, User} from '../../../../../types';

interface AllPPGRCDsApiResponse {
    data?: PPGRCD[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    user: User
    pattern?: string
}

export const AllPPGRCDsList = ({user, pattern}: Props) => {
    const navigate = useNavigate();
    const [ppgrcdToDelete, setPpgrcdToDelete] = useState(null);
    const [invalidatedPpgrcds, setInvalidatedPpgrcds] = useState();
    const ppgrcdUrl = ({
       id: ppgrcdId,
       department_id: {
           id: departmentId,
           name: departmentName,
           entity_id: {
               id: entityId,
               name: entityName
           }
       }
   }) => `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`;

    const editHandler = (ppgrcd) => navigate(ppgrcdUrl(ppgrcd));
    const viewHandler = (ppgrcd) => navigate(ppgrcdUrl(ppgrcd));
    const deleteHandler = (ppgrcd) => setPpgrcdToDelete(ppgrcd);

    const {
        data: ppgrcds,
        error: errorGetPPGRCDs,
        isLoading: isLoadingGetPPGRCDs
    } = useDirectusQuery<AllPPGRCDsApiResponse>({
        path: '/items/prevention_plans',
        options: {
            params: {
                search: pattern,
                fields: '*.*.*.*',
                filter: {
                    'users': {
                        'directus_users_id': {
                            "_eq": user.id
                        }
                    }
                }
            },
            refetchOnChange: invalidatedPpgrcds || pattern
        }
    });

    const {
        actions: {DELETE: deletePPGRCD},
        response: {
            error: errorDeletePPGRCD,
            isLoading: isLoadingDeletePPGRCD
        }
    } = useDirectusMutation({path: `/items/prevention_plans/${ppgrcdToDelete?.id}`});

    return (
        <>
            <DataGrid
                rows={ppgrcds || []}
                getRowId={(row) => row.id}
                sx={commonStyles.datagrid}
                components={dataGridComponents}
                rowCount={ppgrcds?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(ppgrcdToDelete)}
                title={t`Delete PPGRCD`}
                onCancel={() => setPpgrcdToDelete(null)}
                onConfirm={() => deletePPGRCD(() => {
                    setInvalidatedPpgrcds(ppgrcds);
                    setPpgrcdToDelete(null);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete ppgrcd`+` ${ppgrcdToDelete?.id}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetPPGRCDs || errorDeletePPGRCD}
                isLoading={isLoadingGetPPGRCDs || isLoadingDeletePPGRCD}
            />
        </>
    );
};

export default AllPPGRCDsList;