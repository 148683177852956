import {t} from 'ttag';
import * as Yup from 'yup';
import {DateTime as datetime} from 'luxon';
import {createDatetime as dt} from '../../../../utils/datetime/datetime-utils';


export const UpdateByProductFormSchema = Yup.object().shape(
    {
        holder: Yup.string().required(t`Required field.`),
        derived_designation: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        address_id: Yup.object().shape({
            island: Yup.string().required(t`Required field.`),
            parish: Yup.string().required(t`Required field.`),
            county: Yup.string().required(t`Required field.`)
        }).required(t`Required field.`),
        amount: Yup.number().required(t`Required field.`).typeError(t`Must be a valid number.`),
        amount_base_unit: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        contamination: Yup.string().required(t`Required field.`),
        format: Yup.string().required(t`Required field.`),
        availability_date: Yup.string().test({
            test: (value) => value && dt(value).isValid && (dt(value) >= datetime.now()),
            message: t`Should not be a past date.`
        })
    }
).required();
