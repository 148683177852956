import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import {useDirectusQuery} from '../custom-hooks/useDirectusQuery';
import {DirectusFeedback} from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import {columns} from './helpers/residues-list-helpers';
import ConfirmDialog from '../common/ConfirmDialog';
import {Residue, User} from '../../types';

export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    user: User
    filterByLerCode?: string
    filterByEntityId?: number | string
    filterByStatus?: string
}

export const ResiduesList = ({user, filterByEntityId, filterByStatus, filterByLerCode}: Props) => {
    const navigate = useNavigate();
    const [residueToDelete, setResidueToDelete] = useState(null);
    const [invalidatedResidues, setInvalidatedResidues] = useState<Residue[]>();

    const editHandler = (residue) => navigate(residueUrl(residue));
    const viewHandler = (residue) => navigate(residueUrl(residue));
    const deleteHandler = (residue) => setResidueToDelete(residue);
    const residueUrl = (residue) => `/residues/${residue?.ler_code?.code}/${residue?.id}`;

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": user.id
                                }
                            }
                        }
                    },
                    'is_derived': {'_eq': false}
                }
            },
            refetchOnChange: invalidatedResidues
        }
    });

    const {
        actions: {DELETE: deleteResidue},
        response: {
            error: errorDeleteResidue,
            isLoading: isLoadingDeleteResidue
        }
    } = useDirectusMutation({path: `/items/residues/${residueToDelete?.id}`});

    if (!residues) return null;

    let filteredResidues = residues?.filter((residue) =>
        filterByEntityId === 'all' ? residue : residue.department_id.entity_id.id === filterByEntityId
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByStatus === 'all' ? residue : residue.status === filterByStatus
    );

    filteredResidues = filteredResidues?.filter((residue) =>
        filterByLerCode ? residue.ler_code.code.toString().startsWith(filterByLerCode) : residue
    );

    return (
        <>
            <DataGrid
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                rows={filteredResidues || []}
                loading={isLoadingGetResidues}
                components={dataGridComponents}
                rowCount={filteredResidues?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(residueToDelete)}
                title={t`Delete Residue`}
                onCancel={() => setResidueToDelete(null)}
                onConfirm={() => deleteResidue(() => {
                    setResidueToDelete(null);
                    setInvalidatedResidues(residues);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete`+` ${residueToDelete?.name}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetResidues || errorDeleteResidue}
                isLoading={isLoadingGetResidues || isLoadingDeleteResidue}
            />
        </>

    );
};

export default ResiduesList;