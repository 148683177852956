import * as Yup from 'yup';
import {t} from 'ttag';


export const WasteManagementFormSchema = Yup.object().shape(
    {
        description: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
        transportation: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`)
        }),
        operator: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`)
        }),
        deposition: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`)
        }),
        other_costs: Yup.string()
    }
).required();
