import {t} from 'ttag';


export const columns = () => [
  {
    field: 'title',
    headerName: t`TITLE`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'message',
    headerName: t`MESSAGE`,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'action_link',
    headerName: t`ACTION LINK`,
    disableColumnMenu: true,
    flex: 3
  }
];

export const helper = {
  columns
};

export default helper;
