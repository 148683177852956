import {t} from 'ttag';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import commonStyles from '../../../components/common/styles/commonStyles';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddCollaboratorForm from '../../../components/entities/entity/collaborators/collaborator/forms/AddCollaboratorForm';


export const AddCollaborator = () => {
    const {entityName, entityId} = useParams();

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD COLLABORATOR`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: t`Home`, link: '/'},
                    {name: t`Entities`, link: '/entities'},
                    {name: `${entityName}`, link: `/entities/${entityName}/${entityId}`},
                    {name: t`Add Collaborator`, link: `/entities/${entityName}/${entityId}/add-collaborator`}
                ]}
            />
            <AddCollaboratorForm/>
        </Stack>
    );
};

export default AddCollaborator;