import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './helpers/valorization-processes-list-helpers';
import commonStyles from '../common/styles/commonStyles';
import ConfirmDialog from '../common/ConfirmDialog';
import {ValorizationProcess} from '../../types';
import useDirectusMutation from '../custom-hooks/useDirectusMutation';
import DirectusFeedback from '../common/DirectusFeedback';
import {dataGridComponents} from '../datagrid/dataGridComponents';

interface Props {
    valorizationProcesses: ValorizationProcess[]
    onInvalidateValorizationProcesses: (valorizationPlans: ValorizationProcess[]) => void
}

export const ValorizationProcessesList = ({valorizationProcesses, onInvalidateValorizationProcesses}: Props) => {
    const [planToDelete, setPlanToDelete] = useState(null);

    const {
        actions: {PATCH: updateValorizationPlan},
        response: {
            error: errorUpdateValorizationPlan,
            isLoading: isLoadingUpdateValorizationPlan
        }
    } = useDirectusMutation({path: '/items/valorization_process'});

    const {
        actions: {DELETE: deleteValorizationPlan},
        response: {
            error: errorDeleteValorizationPlan,
            isLoading: isLoadingDeleteValorizationPlan
        }
    } = useDirectusMutation({path: `/items/valorization_process/${planToDelete?.id}`});

    const completeHandler = (plan) => updateValorizationPlan(
        {status: 'COMPLETED'},
        () => onInvalidateValorizationProcesses(valorizationProcesses),
        plan.id
    );

    const cancelHandler = (plan) => updateValorizationPlan(
        {status: 'CANCELED'},
        () => onInvalidateValorizationProcesses(valorizationProcesses),
        plan.id
    );

    const deleteHandler = (plan) => setPlanToDelete(plan);

    return (
        <>
            <DataGrid
                rows={valorizationProcesses || []}
                sx={commonStyles.datagrid}
                getRowId={(row) => row.id}
                components={dataGridComponents}
                rowCount={valorizationProcesses?.length || 0}
                columns={columns({completeHandler, cancelHandler, deleteHandler})}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(planToDelete)}
                title={t`Delete Valorization Plan`}
                onCancel={() => setPlanToDelete(null)}
                onConfirm={() => deleteValorizationPlan(() => {
                    onInvalidateValorizationProcesses(valorizationProcesses);
                    setPlanToDelete(null);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete valorization plan ` + ` ${planToDelete?.id}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorDeleteValorizationPlan || errorUpdateValorizationPlan}
                isLoading={isLoadingDeleteValorizationPlan || isLoadingUpdateValorizationPlan}
            />
        </>
    );
};

export default ValorizationProcessesList;