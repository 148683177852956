import {t} from 'ttag';
import * as Yup from 'yup';
import {validateNif} from '../../../../../utils/forms/helpers';


export const PlanClosureFormSchema = Yup.object().shape(
    {
        document_code: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().required(t`Required field.`)
        }),
        verification_code: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().required(t`Required field.`)
        }),
        ler_code: Yup.number().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val ? val : null)
        }),
        amount: Yup.number().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.number().typeError(t`Required field.`).required(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val ? parseInt(val) : null)
        }),
        base_unit: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
        }),
        transport: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().required(t`Required field.`)
        }),
        fiscal_number: Yup.number().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.number().nullable().test({
                test: (value) => validateNif(value),
                message: t`Should be a valid fiscal number.`
            }).required(t`Required field.`).typeError(t`Required field.`),
            otherwise: Yup.number().nullable(true).transform((_, val) => val ? parseInt(val) : null)
        }),
        operator: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().required(t`Required field.`)
        }),
        operation: Yup.string().when('egars', {
            is: (egars) => !egars.length,
            then: Yup.string().required(t`Required field.`)
        }),
    }
).required();
