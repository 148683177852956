import * as Yup from 'yup';
import {t} from 'ttag';


export const PreventionMethodologyFormSchema = Yup.object().shape(
    {
        methodology_description: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        name: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
        }),
        reuse_quantity: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().typeError(t`Must be a valid number.`).required(t`Required field.`)
        }),
        base_unit: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
        }),
        reuse_quantity_percentage: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().test({
                test: (amount_percentage) => parseInt(amount_percentage) > 0 && parseInt(amount_percentage) <= 100,
                message: t`Should be less or equal 100%.`
            }).required(t`Required field.`).typeError(t`Required field.`)
        })
    }
).required();
