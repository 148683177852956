import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import EntityFormDetails from './create-entity-form/EntityFormDetails';
import {AddEntityFormSchema} from './helpers/add-entity-form-schema';
import EntityFormType from './create-entity-form/EntityFormType';
import DirectusFeedback from '../../common/DirectusFeedback';
import FormSkeleton from '../../common/forms/FormSkeleton';


export const AddEntityForm = () => {
    const navigate = useNavigate();

    const {
        actions: {POST: addEntity},
        response: {error, isLoading}
    } = useDirectusMutation({path: '/items/entities'});

    const methods = useForm({
        resolver: yupResolver(AddEntityFormSchema),
        defaultValues: {
            is_company: '',
            nif: '',
            nipc: '',
            name: '',
            cae: '',
            license: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        addEntity({
            ...values,
            cae: parseInt(values.cae),
            nif: values.is_company === 'false' ? values.nif : null,
            nipc: values.is_company === 'true' ? values.nipc : null
        }, () => navigate('/entities'));
    });

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<EntityFormDetails/>}
                    subGroupTitle={t`COMPANY`}
                    subGroupContent={<EntityFormType/>}
                    onSubmit={submitHandler}
                    onCancel={() => methods.reset()}
                />
            </FormProvider>
            <DirectusFeedback error={error} isLoading={isLoading}/>
        </>

    );
};

export default AddEntityForm;