import {t} from 'ttag';
import React from 'react';
import {Add} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';


export const columns = ({addedImportedEgars, addHandler}) => [
    {
        field: 'numeroGuia',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`DOCUMENT`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`CODE`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => row.numeroGuia
    },
    {
        field: 'codigoVerificacao',
        headerName:
            <Stack textAlign={'center'}>
                <Typography fontSize={'0.875rem'}>{t`VERIFICATION`}</Typography>
                <Typography fontSize={'0.875rem'}>{t`CODE`}</Typography>
            </Stack>,
        disableColumnMenu: true,
        flex: 2.5,
        renderCell: ({row}) => row.codigoVerificacao
    },
    {
        field: 'codigoResiduoLer',
        headerName: t`LER`,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => row.residuosTransportados.residuoTransportado.codigoResiduoLer
    },
    {
        field: 'designacao',
        headerName: t`DESIGNATION`,
        disableColumnMenu: true,
        flex: 2.5,
        renderCell: ({row}) => row.residuosTransportados.residuoTransportado.designacao
    },
    {
        field: 'quantidade',
        headerName: t`QNT.`,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => row.residuosTransportados.residuoTransportado.quantidade
    },
    {
        field: 'transportador',
        headerName: t`TRANSPORT`,
        disableColumnMenu: true,
        flex: 2.5,
        renderCell: ({row}) => row.transportadores.transportador.entidade
    },
    {
        field: 'operador',
        headerName: t`OPERATOR`,
        disableColumnMenu: true,
        flex: 2.5,
        renderCell: ({row}) => row.operadorGestaoResiduos.estabelecimento
    },
    {
        field: 'estado',
        headerName: t`STATUS`,
        disableColumnMenu: true,
        flex: 1.5,
        renderCell: ({row}) => row.estado
    },
    {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        flex: .5,
        renderCell: ({row}) =>
            addedImportedEgars.some((egar) => egar.numeroGuia === row.numeroGuia)
                ? <></>
                : <Add sx={{cursor: 'pointer', color: 'primary.main'}} onClick={() => addHandler(row)}/>
    }
];

export const helper = {
    columns
};

export default helper;
