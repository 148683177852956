import {t} from 'ttag';
import React from 'react';
import {Controller} from 'react-hook-form';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {commonStyles} from '../../../common/styles/commonStyles';
import {contaminationLevels, formats} from '../../../../mocks';
import {LerCode, User} from '../../../../types';
import useDirectusQuery from '../../../custom-hooks/useDirectusQuery';
import {EntitiesApiResponse} from '../../../entities/EntitiesList';
import DirectusFeedback from '../../../common/DirectusFeedback';

interface Props {
    authenticatedUser: User
    lerCodes: LerCode[]
}

export const AddWantedResidueFormDetails = ({authenticatedUser, lerCodes}: Props) => {

    const {
        data: entities,
        error: errorGetEntities,
        isLoading: isLoadingGetEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    'users': {
                        "directus_users_id": {
                            "id": {
                                "_eq": authenticatedUser?.id
                            }
                        }
                    }
                }
            }
        }
    });

    return (
        <>
            <Box display={'flex'} flexDirection={'column'} gap={3}>
                <Grid spacing={4} container>
                    <Grid xs={12} md={12} item>
                        <Typography fontSize={'1.1rem'} fontWeight={'bold'}>{t`Wanted Residue Details`}</Typography>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'entity_id'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel sx={{color: 'text.primary'}}
                                                id={'entity-label'}>{t`Entity`}*</InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Entity`}
                                        labelId={'entity-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        variant={'outlined'}
                                        fullWidth
                                        required
                                    >
                                        {entities?.map((entity, key) => (
                                            <MenuItem key={key} value={entity.id}>
                                                {entity.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'ler_code'}
                            render={({field, fieldState: {error}}) => (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{color: 'text.primary'}}
                                            id={"lercode-label"}>{t`Ler Code`}*
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            label={t`Ler Code`}
                                            labelId={'lercode-label'}
                                            sx={styles.selectField}
                                            error={Boolean(error)}
                                            required
                                        >
                                            <MenuItem value={''} disabled></MenuItem>
                                            {lerCodes?.map((ler, key) => (
                                                ler.is_header
                                                    ?
                                                    <MenuItem
                                                        key={key}
                                                        value={ler.id}
                                                        sx={{
                                                            opacity: '1 !important',
                                                            backgroundColor: 'common.lightGray'
                                                        }}
                                                        disabled
                                                    >
                                                        {`${ler.code} - ${ler.description}`}
                                                    </MenuItem>
                                                    :
                                                    <MenuItem key={key} value={ler.id}>
                                                        {`${ler.code} - ${ler.description}`}
                                                    </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'contamination'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel id={'contamination-label'}>
                                        {t`Contamination`}
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Contamination`}
                                        labelId={'contamination-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {contaminationLevels.map((contamination, key) => (
                                            <MenuItem key={key} value={contamination.value}>
                                                {contamination.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'format'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel id={'format-label'}>{t`Format`}</InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Format`}
                                        labelId={'format-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {formats.map((format, key) => (
                                            <MenuItem key={key} value={format.value}>{format.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'amount'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type={'number'}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`Quantity(t)`}
                                    variant={"outlined"}
                                    onKeyDown={(event) => {
                                        if (event.key === ".") event.preventDefault();
                                    }}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'amount_base_unit'}
                            render={({field, fieldState: {error}}) => (
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                                    <Typography>{t`BASE UNIT`}</Typography>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                        <FormControlLabel value={'CUBIC_METER'} label={<Box
                                            display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                          control={<Radio/>}/>
                                    </RadioGroup>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
            <DirectusFeedback error={errorGetEntities} isLoading={isLoadingGetEntities}/>
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    }
};

export default AddWantedResidueFormDetails;