import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Stack} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import PatternSearch from '../../../common/pattern-search/PatternSearch';
import ParticipantsList from './ParticipantsList';
import {User} from '../../../../types';
import AuthorizationGuard from '../../../../pages/common/guards/AuthorizationGuard';
import {useStore} from '../../../../store/valtio';
import {APP_ROLES} from '../../../../utils/user/user-utils';

interface Props {
    users: User[]
}

export const Participants = ({users}: Props) => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [searchPattern, setSearchPattern] = useState(null);
    const {entityName, entityId, departmentName, departmentId, ppgrcdId} = useParams();
    const onAddParticipant = () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}/add-participant`);

    if(!user) return null;

    return (
        <Stack spacing={3}>
            <Box sx={styles.box}>
                <PatternSearch onChange={(searchPattern) => setSearchPattern(searchPattern)}/>
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                    <Button variant={'contained'} onClick={onAddParticipant}>
                        <Add sx={styles.headerAddIcon}/>
                        {t`Add Participant`}
                    </Button>
                </AuthorizationGuard>
            </Box>
            <ParticipantsList users={users} pattern={searchPattern}/>
        </Stack>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export default Participants;