import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box, FormControlLabel, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import {OneStepValorizationFormSchema} from './schemas/one-step-valorization-form-schema';
import AvailableDepartmentsList from '../entities/departments/AvailableDepartmentsList';
import commonStyles from '../common/styles/commonStyles';
import FormActions from '../common/forms/FormActions';
import {Entity, Service} from '../../types';


interface Props {
    selectedService: Service
    onSubmit: (values: object) => null,
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const OneStepValorizationForm = ({selectedService, onReturn, onSubmit}: Props) => {

    const methods = useForm({
        resolver: yupResolver(OneStepValorizationFormSchema),
        defaultValues: {
            selected_service: ''
        }
    });

    const handleOnSubmit = methods.handleSubmit((values) => {
        onSubmit(values);
    })

    const handleSelectService = (selectedService) => {
        methods.setValue('selected_service', selectedService);
    };

    return (
        <FormProvider {...methods}>
            <Stack spacing={4} py={3}>
                <Stack spacing={3}>
                    <Box sx={commonStyles.spaceBetween}>
                        <Typography color={'common.gray'}>{t`AVAILABLE ENTITIES LIST`}</Typography>
                        {(!methods.watch('selected_service')) &&
                            <Typography color={'error'}>{t`Please select one entity.`}</Typography>
                        }
                    </Box>
                    <AvailableDepartmentsList
                        selectedService={selectedService}
                        onSelectService={handleSelectService}
                    />
                </Stack>
            </Stack>
            <Box display={'flex'} justifyContent={'flex-end'} pt={3}>
                <FormActions
                    submitBtnText={t`SUBMIT`}
                    cancelBtnText={t`RETURN`}
                    onSubmit={handleOnSubmit}
                    onCancel={onReturn}
                />
            </Box>
        </FormProvider>
    );
};

export default OneStepValorizationForm;