import {t} from 'ttag';
import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import FormSelectorsRaaFilter from '../../components/common/forms/FormSelectorsRaaFilter';
import {SearchFormSchema} from '../../components/search/schemas/search-form-schema';
import ReservedResiduesGrid from '../../components/residues/ReservedResiduesGrid';
import commonStyles from '../../components/common/styles/commonStyles';
import {Entity, Residue} from '../../types';
import {useStore} from '../../store/valtio';
import LerCodeSelectorFilter from '../../components/common/filters/LerCodeSelectorFilter';
import DirectusFeedback from '../../components/common/DirectusFeedback';
import useDirectusQuery from '../../components/custom-hooks/useDirectusQuery';


export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

export interface EntitiesApiResponse {
    data?: Entity[],
    error?: Error,
    isLoading: boolean
}

interface AddressFilterType {
    island: string
    county: string
    parish: string
}

export const Search = () => {
    const {app: {user, lerCodes}} = useStore();
    const [residuesChecked, setResiduesChecked] = useState(true);
    const [byProductsChecked, setByProductsChecked] = useState(true);
    const [byProductsCheckboxDisabled, setByProductsCheckboxDisabled] = useState(false);
    const [addressFilter, setAddressFilter] = useState<AddressFilterType>();
    const [lerCodeFilter, setLerCodeFilter] = useState<number | string>();

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'status': {
                        "_eq": "PUBLISHED"
                    }
                }
            }
        }
    });

    const methods = useForm({
        resolver: yupResolver(SearchFormSchema),
        defaultValues: {
            address_id: {
                island: '',
                county: '',
                parish: ''
            },
            ler_code: ''
        }
    });

    useEffect(() => {
        if (methods.getValues('address_id.island')) {
            setAddressFilter({
                island: methods.getValues('address_id.island') === 'all' ? '' : methods.getValues('address_id.island'),
                county: methods.getValues('address_id.county') === 'all' ? '' : methods.getValues('address_id.county'),
                parish: methods.getValues('address_id.parish') === 'all' ? '' : methods.getValues('address_id.parish')
            })
        }
    }, [methods.watch('address_id.island'), methods.watch('address_id.county'), methods.watch('address_id.parish')]);

    useEffect(() => {
        if (methods.getValues('ler_code')) {
            if (methods.getValues('ler_code') !== ('all' || '')) {
                setByProductsCheckboxDisabled(true);
                setByProductsChecked(false);
            }
            if (methods.getValues('ler_code') === 'all') {
                setByProductsCheckboxDisabled(false);
            }
            setLerCodeFilter(methods.getValues('ler_code') === 'all' ? '' : methods.getValues('ler_code'))
        }
    }, [methods.watch('ler_code')]);

    if (!user || !lerCodes || !residues) return null;

    const filteredResidues = residues.filter((residue) => residue.is_derived === false);
    const filteredByProducts = residues.filter((residue) => residue.is_derived === true);

    return (
        <>
            <Box sx={commonStyles.pageWrapper}>
                <Box sx={styles.box}>
                    <Typography sx={commonStyles.pageTitle}>{t`SEARCH RESIDUES / BY-PRODUCTS`}</Typography>
                </Box>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Search Residues`, link: '/search'}
                    ]}
                />
                <Stack py={4} spacing={2}>
                    <Grid spacing={4} container>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormProvider {...methods}>
                                <Stack spacing={2}>
                                    <Box sx={styles.box} gap={4}>
                                        <Grid spacing={2} container>
                                            <Grid sx={commonStyles.centerItems} xs={12} md={1.5} item>
                                                <Typography>{t`LOCATION`}</Typography>
                                            </Grid>
                                            <FormSelectorsRaaFilter fieldSize={'small'} editable={true}/>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.box} gap={4}>
                                        <Grid spacing={2} container>
                                            <Grid sx={commonStyles.centerItems} xs={12} md={1.5} item>
                                                <Typography>{t`LER CODE`}</Typography>
                                            </Grid>
                                            <LerCodeSelectorFilter lerCodes={lerCodes}/>
                                            <Grid xs={12} md={3.5} item/>
                                            <Grid xs={12} md={3.5} item/>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.box} gap={4}>
                                        <Grid spacing={2} container>
                                            <Grid sx={commonStyles.centerItems} xs={12} md={1.5} item>
                                                <Typography>{t`TYPE`}</Typography>
                                            </Grid>
                                            <Grid sx={commonStyles.centerItems} xs={12} md={2} item>
                                                <Checkbox
                                                    checked={residuesChecked}
                                                    disabled={!byProductsChecked}
                                                    onChange={() => setResiduesChecked(!residuesChecked)}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                                <Typography>{t`Residues`}</Typography>
                                            </Grid>
                                            <Grid sx={commonStyles.centerItems} xs={12} md={2} item>
                                                <Checkbox
                                                    checked={byProductsChecked}
                                                    disabled={!residuesChecked || byProductsCheckboxDisabled}
                                                    onChange={() => setByProductsChecked(!byProductsChecked)}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                                <Typography>{t`By-Products`}</Typography>
                                            </Grid>
                                            <Grid xs={12} md={8} item/>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </FormProvider>
                        </Grid>
                    </Grid>
                </Stack>
                <Box sx={commonStyles.centerContentAndItems}>
                    <Grid spacing={2} container>
                        <ReservedResiduesGrid
                            residues={residuesChecked && byProductsChecked
                                ? residues
                                : !residuesChecked && byProductsChecked
                                    ? filteredByProducts
                                    : filteredResidues
                            }
                            addressFilter={addressFilter}
                            lerCodeFilter={lerCodeFilter}
                        />
                    </Grid>
                </Box>
            </Box>
            <DirectusFeedback error={errorGetResidues} isLoading={isLoadingGetResidues}/>
        </>
    );
};

const styles = {
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    addressInfo: {
        px: 2,
        py: .5,
        gap: 1,
        ...commonStyles.centerContentAndItems,
        backgroundColor: 'background.gray',
        justifyContent: 'flex-start'
    }
};

export default Search;