import {t} from 'ttag';
import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import {Delete, Visibility} from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Box, MenuList, Typography} from '@mui/material';
import AuthorizationGuard from '../../../pages/common/guards/AuthorizationGuard';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';

const EditIcon = require('../../../assets/icons/edit-icon.png');

interface Props {
    disabled: boolean
    onView?: React.MouseEventHandler<HTMLLIElement>
    onEdit?: React.MouseEventHandler<HTMLLIElement>
    onDelete?: React.MouseEventHandler<HTMLLIElement>
    viewAction?: boolean
    editAction?: boolean
    deleteAction?: boolean
}

export const SubMenu = ({disabled, viewAction, editAction, deleteAction, onView, onEdit, onDelete}: Props) => {
    const {app: {user}} = useStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
            <>
                <IconButton
                    id={"long-button"}
                    aria-label={"more"}
                    aria-haspopup={"true"}
                    aria-expanded={open ? 'true' : undefined}
                    aria-controls={open ? 'long-menu' : undefined}
                    onClick={handleClick}
                    disabled={disabled}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    open={open}
                    id={"long-menu"}
                    PaperProps={styles.paperProps}
                    MenuListProps={{'aria-labelledby': 'long-button'}}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <MenuList>
                        {viewAction &&
                            <MenuItem onClick={onView}>
                                <Box display={'flex'} gap={1}>
                                    <Visibility sx={{paddingLeft: '3px', width: 20}}/>
                                    <Typography color={'text.secondary'}>{t`View`}</Typography>
                                </Box>
                            </MenuItem>
                        }
                        {editAction &&
                            <MenuItem onClick={onEdit}>
                                <Box display={'flex'} gap={1}>
                                    <img style={{paddingLeft: 3}} alt={''} width={20} src={EditIcon}/>
                                    <Typography color={'text.secondary'}>{t`Edit`}</Typography>
                                </Box>
                            </MenuItem>
                        }
                        {deleteAction &&
                            <MenuItem onClick={onDelete}>
                                <Box display={'flex'} gap={1}>
                                    <Delete color={'error'}/>
                                    <Typography color={'error.main'}>{t`Delete`}</Typography>
                                </Box>
                            </MenuItem>
                        }
                    </MenuList>
                </Menu>
            </>
        </AuthorizationGuard>
    );
}

const styles = {
    paperProps: {
        style: {
            minWidth: '5rem',
            borderRadius: 10
        }
    },
}

SubMenu.defaultProps = {
    disabled: false,
    viewAction: false,
    editAction: true,
    deleteAction: true,
    onView: () => null,
    onEdit: () => null,
    onDelete: () => null
}

export default SubMenu;