import {t} from 'ttag';
import React from 'react';
import {Stack, Typography} from '@mui/material';
import EntityResponsibleFormDetails from './entity-responsible-form-schema/EntityResponsibleFormDetails';
import FormSkeleton from '../../../common/forms/FormSkeleton';

interface Props {
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    readOnly: boolean
}

export const EntityResponsibleForm = ({onSubmit, readOnly}: Props) => {

    return (
        <Stack spacing={1} py={2}>
            <Typography>{t`General data of the entity responsible for the work`}</Typography>
            <FormSkeleton
                mainGroupContent={<EntityResponsibleFormDetails readOnly={readOnly}/>}
                submitBtnText={t`Next`}
                onSubmit={onSubmit}
                cancelAction={false}
                actions={!readOnly}
                innerForm
            />
        </Stack>
    );
};

export default EntityResponsibleForm;