import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Grid, Stack, Typography} from '@mui/material';
import {KeyboardArrowRight, LocationOn} from '@mui/icons-material';
import ResidueCard from '../../residues/residue/ResidueCard';
import commonStyles from '../styles/commonStyles';
import GraphicCard from '../cards/GraphicCard';
import {Residue} from '../../../types';

const defaultPhoto = require('../../../assets/images/no-image.webp');

interface Props {
    residues: Residue[]
}

export const ResiduesGrid = ({residues}: Props) => {
    const navigate = useNavigate();

    const goToResiduePageHandler = (residue: Residue) => {
        navigate(
            residue?.is_derived && residue?.derived_designation
                    ? `/by-products/${residue?.derived_designation}/${residue?.id}`
                    : `/residues/${residue?.ler_code?.description}/${residue?.id}`
        )
    }

    return (
        <>
            {residues?.map((residue, key) => (
                <Grid xs={12} md={6} lg={3} key={key} onClick={() => goToResiduePageHandler(residue)} item>
                    {residue.image
                        ? <ResidueCard residue={residue}/>
                        : <GraphicCard
                            key={key}
                            backgroundImg={defaultPhoto}
                            bottomLabel={
                                <Stack sx={{width: '100%'}}>
                                    <Box sx={styles.addressInfo} gap={1}>
                                        <LocationOn height={16}/>
                                        <Typography fontSize={'.6rem'}>
                                            {`${residue.address_id.parish} - ${residue.address_id.county} - ${residue.address_id.island}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{...commonStyles.spaceBetween, p: 2}}>
                                        {residue?.is_derived && residue?.derived_designation
                                            ? <Typography>{t`By-Product` + ` - ${residue?.derived_designation}`}</Typography>
                                            : <Typography>{residue.ler_code?.code} - {residue.ler_code?.description}</Typography>
                                        }
                                        <KeyboardArrowRight/>
                                    </Box>
                                </Stack>
                            }
                        />
                    }
                </Grid>
            ))}
        </>
    );
};

const styles = {
    addressInfo: {
        px: 2,
        py: .5,
        gap: 1,
        ...commonStyles.centerContentAndItems,
        backgroundColor: 'background.gray',
        justifyContent: 'flex-start'
    }
}

export default ResiduesGrid;