import {t} from 'ttag';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {AddResidualTreatmentServiceFormSchema} from './schemas/add-residual-treatment-service-form-schema';
import commonStyles from '../../../../../../common/styles/commonStyles';
import FormActions from '../../../../../../common/forms/FormActions';
import {treatmentServiceTypes} from '../../../../../../../mocks';
import {useStore} from '../../../../../../../store/valtio';

interface Props {
    servicesPlatform: Array<any>
    onSubmit: (values: object) => any,
    onReturn: React.MouseEventHandler<HTMLButtonElement>
}

export const ResidualTreatmentServiceForm = ({servicesPlatform, onSubmit, onReturn}: Props) => {
    const {app: {lerCodes}} = useStore();

    const methods = useForm({
        resolver: yupResolver(AddResidualTreatmentServiceFormSchema),
        defaultValues: {
            service_platform_id: '',
            ler_code: '',
            equipment_type: '',
            capacity: '',
            receiving_conditions: '',
            granulometry_min: '',
            granulometry_max: '',
            granulometry_unit: ''
        }
    });

    const submitHandler = methods.handleSubmit((values) => onSubmit(values));

    if (!lerCodes) return null;

    return (
        <FormProvider {...methods}>
            <Grid spacing={4} container>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'service_platform_id'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={{color: 'text.primary'}} id={'treatment-operation-label'}>
                                    {t`Treatment Type`}*
                                </InputLabel>
                                <Select
                                    {...field}
                                    label={t`Treatment Type`}
                                    labelId={'treatment-operation-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {servicesPlatform?.map((servicePlatform, key) => (
                                        <MenuItem key={key} value={servicePlatform.id}>{servicePlatform.subtype_title}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                {methods.watch('type') === 'WASTE_SEPARATION_DECONTAMINATION' &&
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'ler_code'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel sx={styles.inputLabel} id={'lerCode-label'}
                                                required>{t`Ler Code`}</InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Ler Code`}
                                        labelId={'lerCode-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        required
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {lerCodes?.map((ler, key) => (
                                            ler.is_header
                                                ?
                                                <MenuItem
                                                    key={key}
                                                    value={ler.id}
                                                    sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                                    disabled
                                                >
                                                    {`${ler.code} - ${ler.description}`}
                                                </MenuItem>
                                                :
                                                <MenuItem key={key} value={ler.id}>
                                                    {`${ler.code} - ${ler.description}`}
                                                </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                }
                {methods.watch('type') !== 'WOOD_DRYING' &&
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'equipment_type'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`Equipment Type` + '*'}
                                    variant={"outlined"}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                }
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'capacity'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                type={'number'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Capacity (t/h)` + '*'}
                                variant={"outlined"}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'receiving_conditions'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Reception condition` + '*'}
                                variant={"outlined"}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} gap={3}>
                        <Typography sx={styles.label}>{t`GRAIN SIZE`}</Typography>
                        <Controller
                            name={'granulometry_min'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type={'number'}
                                    error={Boolean(error)}
                                    sx={{...commonStyles.textField, width: '5rem'}}
                                    variant={"outlined"}
                                    placeholder={'Min'}
                                    onKeyDown={(event) => {
                                        if (event.key === ".") event.preventDefault();
                                    }}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={'granulometry_max'}
                            render={({field, fieldState: {error}}) => (
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <TextField
                                        {...field}
                                        type={'number'}
                                        error={Boolean(error)}
                                        sx={{...commonStyles.textField, width: '5rem'}}
                                        variant={"outlined"}
                                        placeholder={'Max'}
                                        onKeyDown={(event) => {
                                            if (event.key === ".") event.preventDefault();
                                        }}
                                        fullWidth
                                        required
                                    />
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </Box>
                            )}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'granulometry_unit'}
                        render={({field, fieldState: {error}}) => (
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                                <Typography sx={styles.label}>{t`BASE UNIT`}</Typography>
                                <RadioGroup {...field} row>
                                    <FormControlLabel value={'mm'} label={'mm'} control={<Radio/>}/>
                                    <FormControlLabel value={'cms'} label={'cms'} control={<Radio/>}/>
                                </RadioGroup>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </Box>
                        )}
                    />
                </Grid>
            </Grid>
            <FormActions
                submitBtnText={t`Add`}
                cancelBtnText={t`Return`}
                onSubmit={submitHandler}
                onCancel={onReturn}
            />
        </FormProvider>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    subGroup: {
        py: 1,
        px: 3,
        gap: 7,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'background.gray'
    },
    subGroupTilte: {
        color: 'text.primary'
    },
    text: {
        color: 'text.secondary'
    },
    label: {
        minWidth: '6rem'
    },
    inputLabel: {
        fontWeight: 'bold'
    }
};

export default ResidualTreatmentServiceForm;