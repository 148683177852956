import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import commonStyles from '../../../../common/styles/commonStyles';
import {columns} from './helpers/residues-list-helpers';
import {useState} from 'react';
import {t} from 'ttag';
import {dataGridComponents} from '../../../../datagrid/dataGridComponents';

interface Props {
    residues?: any
    isEditing: boolean
    onDeleteResidue: (residueId) => void
    onEditResidue: (residueId) => void
    readOnly: boolean
}

export const ResiduesListForm = ({residues, isEditing, onEditResidue, onDeleteResidue, readOnly}: Props) => {
    const [pageSize, setPageSize] = useState(5);
    const deleteHandler = (residue) => onDeleteResidue(residue.id);
    const editHandler = (residue) => onEditResidue(residue.id);

    if (!residues) return null;

    return (
        <DataGrid
            rows={residues}
            pageSize={pageSize}
            sx={commonStyles.datagrid}
            components={dataGridComponents}
            getRowId={(row) => row.id}
            rowCount={residues?.length || 0}
            rowsPerPageOptions={[5, 15, 25]}
            columns={columns({isEditing, editHandler, deleteHandler, readOnly})}
            onPageSizeChange={(size) => setPageSize(size)}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: 'insertion_date',
                            sort: 'desc',
                        },
                    ],
                },
            }}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t`Rows per page`
                }
            }}
            disableExtendRowFullWidth
            autoHeight
        />
    );
};

export default ResiduesListForm;