import {t} from 'ttag';
import {DateTime as dt} from 'luxon';
import {useForm} from 'react-hook-form';
import {Stack, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import commonStyles from '../../../components/common/styles/commonStyles';
import SoilContaminationForm from '../../../components/ppgrcds/forms/SoilContaminationForm';
import GeneralEntityWorkForms from '../../../components/ppgrcds/forms/GeneralEntityWorkForms';
import NavigationBreadcrumbs from '../../../components/common/breadcrumbs/NavigationBreadcrumbs';
import ConditioningAndSortingForm from '../../../components/ppgrcds/forms/ConditioningAndSortingForm';
import ProductionAndOperationForm from '../../../components/ppgrcds/forms/ProductionAndOperationForm';
import WastePreventionAndReuseForms from '../../../components/ppgrcds/forms/WastePreventionAndReuseForms';
import IncorporationOfRecyclatesForm from '../../../components/ppgrcds/forms/IncorporationOfRecyclatesForm';
import {
    EntityResponsibleFormSchema
} from '../../../components/ppgrcds/forms/general-entity-work-forms/entity-responsible-form-schema/schemas/entity-responsible-form-schema';
import {
    WorkGeneralFormSchema
} from '../../../components/ppgrcds/forms/general-entity-work-forms/work-general-form/schemas/work-general-form-schema';
import {
    WorkSpecificsFormSchema
} from '../../../components/ppgrcds/forms/general-entity-work-forms/work-specifics-form/schemas/work-specifics-form-schema';
import {
    PreventionMethodologyFormSchema
} from '../../../components/ppgrcds/forms/waste-prevention-and-reuse-forms/prevention-methodology-form/schemas/prevention-methodology-form-schema';
import {
    SubstancesAndObjectsFormSchema
} from '../../../components/ppgrcds/forms/waste-prevention-and-reuse-forms/substances-and-objects-form/schemas/substances-and-objects-form-schema';
import {
    UsageMethodologyFormSchema
} from '../../../components/ppgrcds/forms/waste-prevention-and-reuse-forms/usage-methodology-form/schemas/usage-methodology-form-schema';
import {
    IncorportationMethodologyFormSchema
} from '../../../components/ppgrcds/forms/incorportation-of-recyclates-forms/schemas/incorportation-methodology-form-schema';
import {
    SoilContaminationFormSchema
} from '../../../components/ppgrcds/forms/soil-contamination-form/schemas/soil-contamination-form-schema';
import {
    ConditioningAndSortingFormSchema
} from '../../../components/ppgrcds/forms/conditioning-and-sorting-form/schemas/conditioning-and-sorting-form-schema';
import {
    ProductionAndOperationFormSchema
} from '../../../components/ppgrcds/forms/production-and-operation-form/schemas/production-and-operation-form-schema';
import {
    WasteManagementFormSchema
} from '../../../components/ppgrcds/forms/waste-management-form/schemas/waste-management-form-schema';
import WasteManagementForm from '../../../components/ppgrcds/forms/WasteManagementForm';
import {
    PlanClosureFormSchema
} from '../../../components/ppgrcds/forms/plan-closure-form/schemas/plan-closure-form-schema';
import {PPGRCDsApiResponse} from '../../../components/entities/entity/deparment/ppgrcds/PPGRCDsList';
import {compilleLocationFiles} from '../../../components/ppgrcds/ppgrcd/locations/helpers/utils';
import useDirectusMutation from '../../../components/custom-hooks/useDirectusMutation';
import {createFileFormData, getFileFromBase64} from '../../../utils/forms/helpers';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import PlanClosureForm from '../../../components/ppgrcds/forms/PlanClosureForm';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import {PPGRCD_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';
import {
    incorporationMethodologyFormMapper, planClosureFormMapper, productionAndOperationFormMapper,
    substancesAndObjectsFormMapper,
    usageMethodologyFormMapper, wasteManagementFormMapper
} from '../../../utils/mappers/ppgrcdFormsMappers';

//TODO REFACTOR; useEffect to useMemo with methods, title, subtitle and currentForm;

export const AddPPGRCD = () => {
    const {app: {user}} = useStore();
    const navigate = useNavigate();
    const [errorFileSize, setErrorFileSize] = useState(null);
    const {entityName, entityId, departmentName, departmentId, ppgrcdId, selectedForm} = useParams();
    const [currentForm, setCurrentForm] = useState(selectedForm || 'EntityResponsibleForm');

    const {
        data: ppgrcd,
        error: errorGetPpgrcd,
        isLoading: isLoadingGetPpgrcd
    } = useDirectusQuery({
            path: `/items/prevention_plans/${ppgrcdId}`,
            options: {
                params: {
                    fields: '*.*.*.*.*'
                }
            }
        }
    );

    const {
        data: preventionPlansUsers,
        error: errorGetPreventionPlansUsers,
        isLoading: isLoadingGetPreventionPlansUsers
    } = useDirectusQuery<PPGRCDsApiResponse>({
        path: '/items/prevention_plans_directus_users',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'directus_users_id': {
                        "_eq": user?.id
                    }
                }
            },
            refetchOnChange: user
        }
    });

    const entityResponsibleFormDefaultValues = useMemo(
        () => ({
            name: ppgrcd?.department_id?.entity_id?.name,
            fiscal_number: ppgrcd?.form_entity_responsible[0]?.fiscal_number || (ppgrcd?.department_id?.entity_id?.nif || ppgrcd?.department_id?.entity_id?.nipc),
            license: ppgrcd?.form_entity_responsible[0]?.license || ppgrcd?.department_id?.entity_id?.license,
            cae: ppgrcd?.form_entity_responsible[0]?.cae || ppgrcd?.department_id?.entity_id?.cae
        }),
        [ppgrcd]
    );

    const workGeneralFormDefaultValues = useMemo(
        () => ({
            name: ppgrcd?.name || '',
            cpv_code: ppgrcd?.form_work_general[0]?.cpv_code || '',
            environmental_impact: ppgrcd?.form_work_general[0]?.environmental_impact || '',
            estimated_start_date: ppgrcd?.form_work_general[0]?.estimated_start_date || '',
            estimated_duration: ppgrcd?.form_work_general[0]?.estimated_duration || '',
            estimated_duration_base_unit: ppgrcd?.form_work_general[0]?.estimated_duration_base_unit || 'months',
            address_id: {
                address: '',
                island: '',
                county: '',
                parish: '',
                insertion_date: dt.now().toFormat(DATETIME_FORMAT)
            },
            shapefiles: [],
            locations: ppgrcd?.form_work_general[0]?.locations || []
        }),
        [ppgrcd]
    );

    const workSpecificsFormDefaultValues = useMemo(
        () => ({
            work_description: ppgrcd?.form_work_specifics[0]?.work_description || '',
            conversion_factors: ppgrcd?.form_work_specifics[0]?.conversion_factors || '',
            targeted_demolition: ppgrcd?.form_work_specifics[0]?.targeted_demolition || 'false',
            targeted_demolition_description: ppgrcd?.form_work_specifics[0]?.targeted_demolition_description || ''
        }),
        [ppgrcd]
    );

    const preventionMethodologyFormDefaultValues = useMemo(
        () => ({
            methodology_description: ppgrcd?.form_prevention_methodology[0]?.methodology_description || '',
            name: '',
            reuse_quantity: '',
            base_unit: '',
            reuse_quantity_percentage: '',
            insertion_date: dt.now().toFormat(DATETIME_FORMAT),
            residues: ppgrcd?.form_prevention_methodology[0]?.residues || []
        }),
        [ppgrcd]
    );

    const substancesAndObjectsFormDefaultValues = useMemo(
        () => ({
            name: '',
            reuse_quantity: '',
            base_unit: '',
            reuse_quantity_percentage: '',
            destination: '',
            insertion_date: dt.now().toFormat(DATETIME_FORMAT),
            substances: substancesAndObjectsFormMapper(ppgrcd?.form_substances_objects[0]?.substances) || []
        }),
        [ppgrcd]
    );

    const UsageMethodologyFormDefaultValues = useMemo(
        () => ({
            methodology_description: ppgrcd?.form_usage_methodology[0]?.methodology_description || '',
            ler_code: {
                code: '',
                description: ''
            },
            reuse_quantity: '',
            base_unit: '',
            reuse_quantity_percentage: '',
            insertion_date: dt.now().toFormat(DATETIME_FORMAT),
            residues: usageMethodologyFormMapper(ppgrcd?.form_usage_methodology[0]?.residues) || []
        }),
        [ppgrcd]
    );

    const IncorporationMethodologyFormDefaultValues = useMemo(
        () => ({
            methodology_description: ppgrcd?.form_incorporation_methodology[0]?.methodology_description || '',
            name: '',
            amount: '',
            base_unit: '',
            amount_percentage: '',
            insertion_date: dt.now().toFormat(DATETIME_FORMAT),
            residues: incorporationMethodologyFormMapper(ppgrcd?.form_incorporation_methodology[0]?.residues) || []
        }),
        [ppgrcd]
    );

    const SoilContaminationFormDefaultValues = useMemo(
        () => ({
            historic_analysis: ppgrcd?.form_soil_contamination[0]?.historic_analysis || '',
            evaluation_description: ppgrcd?.form_soil_contamination[0]?.evaluation_description || '',
            potential_contamination: ppgrcd?.form_soil_contamination[0]?.potential_contamination || 'false',
            contaminated_soil_description: ppgrcd?.form_soil_contamination[0]?.contaminated_soil_description || ''
        }),
        [ppgrcd]
    );

    const ConditioningAndSortingFormDefaultValues = useMemo(
        () => ({
            condition_and_sorting: ppgrcd?.form_conditioning_sorting[0]?.condition_and_sorting || 'true',
            conditioning_description: ppgrcd?.form_conditioning_sorting[0]?.conditioning_description || '',
            justification: ppgrcd?.form_conditioning_sorting[0]?.justification || ''
        }),
        [ppgrcd]
    );

    const ProductionAndOperationFormDefaultValues = useMemo(
        () => ({
            ler_code: '',
            final_estimated_amount: '',
            base_unit: '',
            valorization_amount: '',
            valorization_operation: '',
            disposal_amount: '',
            disposal_operation: '',
            residues: productionAndOperationFormMapper(ppgrcd?.form_production_operation[0]?.residues) || []
        }),
        [ppgrcd]
    );

    const WasteManagementFormDefaultValues = useMemo(
        () => ({
            description: '',
            transportation: '',
            operator: '',
            deposition: '',
            other_costs: '',
            residues: wasteManagementFormMapper(ppgrcd?.form_waste_management[0]?.residues) || []
        }),
        [ppgrcd]
    );

    const PlanClosureFormDefaultValues = useMemo(
        () => ({
            document_code: '',
            verification_code: '',
            ler_code: '',
            amount: '',
            base_unit: '',
            transport: '',
            fiscal_number: '',
            operator: '',
            operation: '',
            egars: planClosureFormMapper(ppgrcd?.form_plan_closure[0]?.egars) || []
        }),
        [ppgrcd]
    );

    const EntityResponsibleFormMethods = useForm({
        resolver: yupResolver(EntityResponsibleFormSchema),
        defaultValues: entityResponsibleFormDefaultValues
    });

    const WorkGeneralFormMethods = useForm({
        resolver: yupResolver(WorkGeneralFormSchema),
        defaultValues: workGeneralFormDefaultValues
    });

    const WorkSpecificsFormMethods = useForm({
        resolver: yupResolver(WorkSpecificsFormSchema),
        defaultValues: workSpecificsFormDefaultValues
    });

    const PreventionMethodologyFormMethods = useForm({
        resolver: yupResolver(PreventionMethodologyFormSchema),
        defaultValues: preventionMethodologyFormDefaultValues
    });

    const SubstancesAndObjectsFormMethods = useForm({
        resolver: yupResolver(SubstancesAndObjectsFormSchema),
        defaultValues: substancesAndObjectsFormDefaultValues
    });

    const UsageMethodologyFormMethods = useForm({
        resolver: yupResolver(UsageMethodologyFormSchema),
        defaultValues: UsageMethodologyFormDefaultValues
    });

    const IncorporationMethodologyFormMethods = useForm({
        resolver: yupResolver(IncorportationMethodologyFormSchema),
        defaultValues: IncorporationMethodologyFormDefaultValues
    });

    const SoilContaminationFormMethods = useForm({
        resolver: yupResolver(SoilContaminationFormSchema),
        defaultValues: SoilContaminationFormDefaultValues
    });

    const ConditioningAndSortingFormMethods = useForm({
        resolver: yupResolver(ConditioningAndSortingFormSchema),
        defaultValues: ConditioningAndSortingFormDefaultValues
    });

    const ProductionAndOperationFormMethods = useForm({
        resolver: yupResolver(ProductionAndOperationFormSchema),
        defaultValues: ProductionAndOperationFormDefaultValues
    });

    const WasteManagementFormMethods = useForm({
        resolver: yupResolver(WasteManagementFormSchema),
        defaultValues: WasteManagementFormDefaultValues
    });

    const PlanClosureFormMethods = useForm({
        resolver: yupResolver(PlanClosureFormSchema),
        defaultValues: PlanClosureFormDefaultValues
    });

    useEffect(() => {
        if (ppgrcd) {
            EntityResponsibleFormMethods.reset(entityResponsibleFormDefaultValues)
            WorkGeneralFormMethods.reset(workGeneralFormDefaultValues)
            WorkSpecificsFormMethods.reset(workSpecificsFormDefaultValues)
            PreventionMethodologyFormMethods.reset(preventionMethodologyFormDefaultValues)
            SubstancesAndObjectsFormMethods.reset(substancesAndObjectsFormDefaultValues)
            UsageMethodologyFormMethods.reset(UsageMethodologyFormDefaultValues)
            IncorporationMethodologyFormMethods.reset(IncorporationMethodologyFormDefaultValues)
            SoilContaminationFormMethods.reset(SoilContaminationFormDefaultValues)
            ConditioningAndSortingFormMethods.reset(ConditioningAndSortingFormDefaultValues)
            ProductionAndOperationFormMethods.reset(ProductionAndOperationFormDefaultValues)
            WasteManagementFormMethods.reset(WasteManagementFormDefaultValues)
            PlanClosureFormMethods.reset(PlanClosureFormDefaultValues)
        }
    }, [
        ppgrcd,
        EntityResponsibleFormMethods,
        WorkGeneralFormMethods,
        WorkSpecificsFormMethods,
        PreventionMethodologyFormMethods,
        SubstancesAndObjectsFormMethods,
        UsageMethodologyFormMethods,
        IncorporationMethodologyFormMethods,
        SoilContaminationFormMethods,
        ConditioningAndSortingFormMethods,
        ProductionAndOperationFormMethods,
        WasteManagementFormMethods,
        PlanClosureFormMethods,
        entityResponsibleFormDefaultValues,
        workGeneralFormDefaultValues,
        workSpecificsFormDefaultValues,
        preventionMethodologyFormDefaultValues,
        substancesAndObjectsFormDefaultValues,
        UsageMethodologyFormDefaultValues,
        IncorporationMethodologyFormDefaultValues,
        SoilContaminationFormDefaultValues,
        ConditioningAndSortingFormDefaultValues,
        ProductionAndOperationFormDefaultValues,
        WasteManagementFormDefaultValues,
        PlanClosureFormDefaultValues
    ]);

    const {
        actions: {POST: uploadFiles},
        response: {
            error: errorUploadPhoto,
            isLoading: isLoadingUploadPhoto
        }
    } = useDirectusMutation({path: '/files'});

    const handleUploadLocationFiles = (location, attachments, callback = (locationWithShapefiles: {
        address_id: any;
        shapefiles: any[],
        insertion_date: string
    }) => null) => {
        const existingAttachments = attachments?.filter((attachment) => attachment.directus_files_id)
        const newAttachments = attachments?.filter((attachment) => attachment.content && attachment.name)

        if (newAttachments.length === 0) {
            callback({
                address_id: location.address_id,
                shapefiles: existingAttachments,
                insertion_date: location.insertion_date
            });
        }

        if (newAttachments.length === 1) {
            createFileFormData(newAttachments?.map(attachment => getFileFromBase64(attachment.content, attachment.name)), (formData) =>
                uploadFiles(formData,
                    (uploadedFile) => {
                        callback({
                            address_id: location.address_id,
                            shapefiles: compilleLocationFiles([uploadedFile, ...existingAttachments]),
                            insertion_date: location.insertion_date
                        })
                    }), (error) => setErrorFileSize(error))
        }

        if (newAttachments.length > 1) {
            createFileFormData(newAttachments?.map(attachment => getFileFromBase64(attachment.content, attachment.name)), (formData) => uploadFiles(formData,
                (uploadedFiles) => {
                    callback({
                        address_id: location.address_id,
                        shapefiles: compilleLocationFiles([...uploadedFiles, ...existingAttachments]),
                        insertion_date: location.insertion_date
                    })
                }), (error) => setErrorFileSize(error))
        }
    }

    const handleUploadAllLocationsFiles = (locations, callback = (allLocationsWithShapefiles: Array<any>) => null) => {
        let allLocationsWithShapefiles = [];
        let locationsVerified = 0;

        locations?.map((location) => {
            handleUploadLocationFiles(
                location,
                location.shapefiles,
                (locationWithShapefiles) => {
                    allLocationsWithShapefiles.push(locationWithShapefiles);
                    locationsVerified++;
                    if (locationsVerified === locations.length) callback(allLocationsWithShapefiles);
                }
            );
        })
    };

    const {
        actions: {POST: submitEntityResponsibleForm},
        response: {
            error: errorSubmitEntityResponsibleForm,
            isLoading: isLoadingSubmitEntityResponsibleForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_entity_responsible'});

    const {
        actions: {POST: submitWorkGeneralForm},
        response: {
            error: errorSubmitWorkGeneralForm,
            isLoading: isLoadingSubmitWorkGeneralForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_work_general'});

    const {
        actions: {POST: submitWorkSpecificsForm},
        response: {
            error: errorSubmitWorkSpecificsForm,
            isLoading: isLoadingSubmitWorkSpecificsForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_work_specifics'});

    const {
        actions: {POST: submitPreventionMethodologyForm},
        response: {
            error: errorSubmitPreventionMethodologyForm,
            isLoading: isLoadingSubmitPreventionMethodologyForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_prevention_methodology'});

    const {
        actions: {POST: submitSubstancesAndObjectsForm},
        response: {
            error: errorSubmitSubstancesAndObjectsForm,
            isLoading: isLoadingSubmitSubstancesAndObjectsForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_substances_objects'});

    const {
        actions: {POST: submitUsageMethodologyForm},
        response: {
            error: errorSubmitUsageMethodologyForm,
            isLoading: isLoadingSubmitUsageMethodologyForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_usage_methodology'});

    const {
        actions: {POST: submitIncorporationMethodologyForm},
        response: {
            error: errorSubmitIncorporationMethodologyForm,
            isLoading: isLoadingSubmitIncorporationMethodologyForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_incorporation_methodology'});

    const {
        actions: {POST: submitSoilContaminationForm},
        response: {
            error: errorSubmitSoilContaminationForm,
            isLoading: isLoadingSubmitSoilContaminationForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_soil_contamination'});

    const {
        actions: {POST: submitConditioningAndSortingForm},
        response: {
            error: errorSubmitConditioningAndSortingForm,
            isLoading: isLoadingSubmitConditioningAndSortingForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_conditioning_sorting'});

    const {
        actions: {POST: submitProductionAndOperationForm},
        response: {
            error: errorSubmitProductionAndOperationForm,
            isLoading: isLoadingSubmitProductionAndOperationForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_production_operation'});

    const {
        actions: {POST: submitWasteManagementForm},
        response: {
            error: errorSubmitWasteManagementForm,
            isLoading: isLoadingSubmitWasteManagementForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_waste_management'});

    const {
        actions: {POST: submitPlanClosureForm},
        response: {
            error: errorSubmitPlanClosureForm,
            isLoading: isLoadingSubmitPlanClosureForm
        }
    } = useDirectusMutation({path: '/items/ppgrcd_form_plan_closure'});

    const submitHandlerEntityResponsibleForm = EntityResponsibleFormMethods.handleSubmit((values) => {
        submitEntityResponsibleForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('WorkGeneralForm'));
    });

    const submitHandlerWorkGeneralForm = WorkGeneralFormMethods.handleSubmit((values) => {
        handleUploadAllLocationsFiles(values.locations, (allLocationsWithShapefiles) => {
            submitWorkGeneralForm({
                    ...values,
                    prevention_plan_id: ppgrcdId,
                    locations: allLocationsWithShapefiles
                }, () =>
                    setCurrentForm('WorkSpecificsForm')
            )
        })
    });

    const submitHandlerWorkSpecificsForm = WorkSpecificsFormMethods.handleSubmit((values) => {
        submitWorkSpecificsForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('PreventionMethodology'));
    });

    const submitHandlerPreventionMethodologyForm = PreventionMethodologyFormMethods.handleSubmit((values) => {
        submitPreventionMethodologyForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('SubstancesAndObjects'))
    });

    const submitHandlerSubstancesAndObjectsForm = SubstancesAndObjectsFormMethods.handleSubmit((values) => {
        submitSubstancesAndObjectsForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('UsageMethodology'));
    });

    const submitHandlerUsageMethodologyForm = UsageMethodologyFormMethods.handleSubmit((values) => {
        submitUsageMethodologyForm({
            ...values,
            methodology_description: values.methodology_description,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('IncorporationMethodology'))
    });

    const submitHandlerIncorporationMethodologyForm = IncorporationMethodologyFormMethods.handleSubmit((values) => {
        submitIncorporationMethodologyForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('SoilContamination'))
    });

    const submitHandlerSoilContaminationForm = SoilContaminationFormMethods.handleSubmit((values) => {
        submitSoilContaminationForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('ConditioningAndSorting'));
    });

    const submitHandlerConditioningAndSortingForm = ConditioningAndSortingFormMethods.handleSubmit((values) => {
        submitConditioningAndSortingForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('ProductionAndOperation'));
    });

    const submitHandlerProductionAndOperationForm = ProductionAndOperationFormMethods.handleSubmit((values) => {
        submitProductionAndOperationForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('WasteManagement'));
    });

    const submitHandlerWasteManagementForm = WasteManagementFormMethods.handleSubmit((values) => {
        submitWasteManagementForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => setCurrentForm('PlanClosure'));
    });

    const submitHandlerPlanClosureForm = PlanClosureFormMethods.handleSubmit((values) => {
        submitPlanClosureForm({
            ...values,
            prevention_plan_id: ppgrcdId
        }, () => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcdId}`));
    });

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    if (!ppgrcd || !preventionPlansUsers) return null;

    return (
        <>
            <Stack sx={commonStyles.pageWrapper}>
                <Typography sx={commonStyles.pageTitle}>{t`ADD PPGRCD`}</Typography>
                <NavigationBreadcrumbs
                    list={[
                        {name: t`Home`, link: '/'},
                        {name: t`Entities`, link: `/entities`},
                        {name: entityName, link: `/entities/${entityName}/${entityId}`},
                        {
                            name: departmentName,
                            link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`
                        },
                        {
                            name: t`Add PPGRCD`,
                            link: `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd`
                        },
                    ]}
                />
                <GeneralEntityWorkForms
                    currentForm={currentForm}
                    workGeneralFormMethods={WorkGeneralFormMethods}
                    workSpecificsFormMethods={WorkSpecificsFormMethods}
                    entityResponsibleFormMethods={EntityResponsibleFormMethods}
                    onSubmitEntityResponsibleForm={submitHandlerEntityResponsibleForm}
                    onSubmitWorkSpecificsForm={submitHandlerWorkSpecificsForm}
                    onSubmitWorkGeneralForm={submitHandlerWorkGeneralForm}
                    onReturnWorkGeneralForm={() => setCurrentForm('EntityResponsibleForm')}
                    onReturnWorkSpecificsForm={() => setCurrentForm('WorkGeneralForm')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <WastePreventionAndReuseForms
                    currentForm={currentForm}
                    usageMethodologyFormMethods={UsageMethodologyFormMethods}
                    substancesAndObjectsFormMethods={SubstancesAndObjectsFormMethods}
                    preventionMethodologyFormMethods={PreventionMethodologyFormMethods}
                    onSubmitPreventionMethodologyForm={submitHandlerPreventionMethodologyForm}
                    onSubmitSubstancesAndObjectsForm={submitHandlerSubstancesAndObjectsForm}
                    onSubmitUsageMethodologyForm={submitHandlerUsageMethodologyForm}
                    onReturnPreventionMethodologyForm={() => setCurrentForm('WorkSpecificsForm')}
                    onReturnSubstancesAndObjectsForm={() => setCurrentForm('PreventionMethodology')}
                    onReturnUsageMethodologyForm={() => setCurrentForm('SubstancesAndObjects')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <IncorporationOfRecyclatesForm
                    currentForm={currentForm}
                    incorporationMethodologyFormMethods={IncorporationMethodologyFormMethods}
                    onSubmit={submitHandlerIncorporationMethodologyForm}
                    onReturn={() => setCurrentForm('UsageMethodology')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <SoilContaminationForm
                    currentForm={currentForm}
                    soilContaminationFormMethods={SoilContaminationFormMethods}
                    onSubmit={submitHandlerSoilContaminationForm}
                    onReturn={() => setCurrentForm('IncorporationMethodology')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <ConditioningAndSortingForm
                    currentForm={currentForm}
                    conditioningAndSortingFormMethods={ConditioningAndSortingFormMethods}
                    onSubmit={submitHandlerConditioningAndSortingForm}
                    onReturn={() => setCurrentForm('SoilContamination')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <ProductionAndOperationForm
                    currentForm={currentForm}
                    productionAndOperationFormMethods={ProductionAndOperationFormMethods}
                    onSubmit={submitHandlerProductionAndOperationForm}
                    onReturn={() => setCurrentForm('ConditioningAndSorting')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <WasteManagementForm
                    currentForm={currentForm}
                    wasteManagementFormMethods={WasteManagementFormMethods}
                    onSubmit={submitHandlerWasteManagementForm}
                    onReturn={() => setCurrentForm('ProductionAndOperation')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
                <PlanClosureForm
                    entity={ppgrcd.department_id.entity_id}
                    currentForm={currentForm}
                    planClosureMethods={PlanClosureFormMethods}
                    onSubmit={submitHandlerPlanClosureForm}
                    onReturn={() => setCurrentForm('WasteManagement')}
                    readOnly={preventionPlansUsers[0]?.role === PPGRCD_ROLES.FISCAL}
                />
            </Stack>
            <DirectusFeedback
                error={
                    errorGetPpgrcd ||
                    errorSubmitEntityResponsibleForm ||
                    errorUploadPhoto ||
                    errorSubmitWorkGeneralForm ||
                    errorSubmitWorkSpecificsForm ||
                    errorSubmitSoilContaminationForm ||
                    errorSubmitConditioningAndSortingForm ||
                    errorSubmitPreventionMethodologyForm ||
                    errorSubmitSubstancesAndObjectsForm ||
                    errorSubmitUsageMethodologyForm ||
                    errorSubmitIncorporationMethodologyForm ||
                    errorSubmitProductionAndOperationForm ||
                    errorSubmitWasteManagementForm ||
                    errorSubmitPlanClosureForm ||
                    errorGetPreventionPlansUsers ||
                    errorFileSize
                }
                isLoading={
                    isLoadingGetPpgrcd ||
                    isLoadingSubmitEntityResponsibleForm ||
                    isLoadingUploadPhoto ||
                    isLoadingSubmitWorkGeneralForm ||
                    isLoadingSubmitWorkSpecificsForm ||
                    isLoadingSubmitSoilContaminationForm ||
                    isLoadingSubmitConditioningAndSortingForm ||
                    isLoadingSubmitPreventionMethodologyForm ||
                    isLoadingSubmitSubstancesAndObjectsForm ||
                    isLoadingSubmitUsageMethodologyForm ||
                    isLoadingSubmitIncorporationMethodologyForm ||
                    isLoadingSubmitProductionAndOperationForm ||
                    isLoadingSubmitWasteManagementForm ||
                    isLoadingSubmitPlanClosureForm ||
                    isLoadingGetPreventionPlansUsers
                }
            />
        </>
    );
};

export default AddPPGRCD;