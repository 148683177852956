import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Stack, Typography} from '@mui/material';
import AddServiceSuggestionForm from './add-service-suggestion-form/AddServiceSuggestionForm';
import ResidualConsultingServiceForm from './add-service-form/ResidualConsultingServiceForm';
import ResidualTransportServiceForm from './add-service-form/ResidualTransportServiceForm';
import ResidualTreatmentServiceForm from './add-service-form/ResidualTreatmentServiceForm';
import ResidualOperatorServiceForm from './add-service-form/ResidualOperatorServiceForm';
import ResidualAdvancedServiceForm from './add-service-form/ResidualAdvancedServiceForm';
import useDirectusMutation from '../../../../../custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../../../custom-hooks/useDirectusQuery';
import {EntitiesApiResponse} from '../../../../../../pages/search/Search';
import DirectusFeedback from '../../../../../common/DirectusFeedback';
import commonStyles from '../../../../../common/styles/commonStyles';
import FormSkeleton from '../../../../../common/forms/FormSkeleton';
import CardDialog from '../../../../../common/CardDialog';
import ServiceTypes from '../ServiceTypes';


export const AddServiceForm = ({}) => {
    const navigate = useNavigate();
    const [serviceType, setServiceType] = useState('');
    const {entityName, entityId, departmentName, departmentId} = useParams();
    const [showSuggestServiceForm, setShowSuggestServiceForm] = useState(false);

    const {
        data: servicesPlatform,
        error: errorGetServicesPlatform,
        isLoading: isLoadingGetServicesPlatform
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/services_platform',
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    const {
        actions: {POST: addServiceSuggestion},
        response: {
            error: errorAddServiceSuggestion,
            isLoading: isLoadingAddServiceSuggestion
        }
    } = useDirectusMutation({path: '/items/services_suggestions'});

    const {
        actions: {POST: addService},
        response: {
            error: errorAddService,
            isLoading: isLoadingAddService
        }
    } = useDirectusMutation({path: '/items/services'});

    const {
        actions: {POST: addResidueOperatorService},
        response: {
            error: errorAddResidueOperatorService,
            isLoading: isLoadingAddResidueOperatorService
        }
    } = useDirectusMutation({path: '/items/services_residue_operator'});

    const {
        actions: {POST: addPreTreatmentService},
        response: {
            error: errorAddPreTreatmentService,
            isLoading: isLoadingAddPreTreatmentService
        }
    } = useDirectusMutation({path: '/items/services_pre_treatment'});

    const {
        actions: {POST: addAdvancedService},
        response: {
            error: errorAddAdvancedService,
            isLoading: isLoadingAddAdvancedService
        }
    } = useDirectusMutation({path: '/items/services_advanced'});

    const {
        actions: {POST: addTransportationService},
        response: {
            error: errorAddTransportationService,
            isLoading: isLoadingAddTransportationService
        }
    } = useDirectusMutation({path: '/items/services_transportation'});

    const {
        actions: {POST: addConsultingService},
        response: {
            error: errorAddConsultingService,
            isLoading: isLoadingAddConsultingService
        }
    } = useDirectusMutation({path: '/items/services_transportation'});

    const addServiceHandler = (values) => {

        if (values.d_operations && values.r_operations){
            addService({
                department_id: departmentId,
                type: 'RESIDUE_OPERATOR'
            }, () => {
                addResidueOperatorService(values, () =>
                    navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)
                )
            })
        }

        if(values.service_platform_id){
            const servicePlatform = servicesPlatform.filter((service) => service.id === values.service_platform_id)[0];

            addService({
                department_id: departmentId,
                type: servicePlatform.type,
                services_platform_id: servicePlatform.id
            }, () => {
                servicePlatform.type === 'PRE_TREATMENT'
                    ? addPreTreatmentService(values, () =>
                        navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)
                    )
                    : servicePlatform.type === 'ADVANCED'
                        ? addAdvancedService(values, () =>
                            navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)
                        )
                        : servicePlatform.type === 'TRANSPORTATION'
                            ? addTransportationService(values, () =>
                                navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)
                            )
                            : servicePlatform.type === 'CONSULTING'
                            && addConsultingService(values, () =>
                                navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}`)
                            )
            })
        }
    };

    if(!servicesPlatform) return null;

    return (
        <>
            <FormSkeleton
                mainGroupContent={
                    <Stack spacing={3}>
                        {!serviceType && <ServiceTypes onSelectType={(type) => setServiceType(type)}/>}
                        {serviceType === 'RESIDUAL OPERATOR' &&
                            <ResidualOperatorServiceForm
                                onSubmit={(values) => addServiceHandler(values)}
                                onReturn={() => setServiceType('')}
                            />
                        }
                        {serviceType === 'TREATMENT SERVICES' &&
                            <ResidualTreatmentServiceForm
                                servicesPlatform={servicesPlatform.filter((service) => service.type === 'PRE_TREATMENT')}
                                onSubmit={(values) => addServiceHandler(values)}
                                onReturn={() => setServiceType('')}
                            />
                        }
                        {serviceType === 'ADVANCED SERVICES' &&
                            <ResidualAdvancedServiceForm
                                servicesPlatform={servicesPlatform.filter((service) => service.type === 'ADVANCED')}
                                onSubmit={(values) => addServiceHandler(values)}
                                onReturn={() => setServiceType('')}
                            />
                        }
                        {serviceType === 'TRANSPORT SERVICES' &&
                            <ResidualTransportServiceForm
                                servicesPlatform={servicesPlatform.filter((service) => service.type === 'TRANSPORTATION')}
                                onSubmit={(values) => addServiceHandler(values)}
                                onReturn={() => setServiceType('')}
                            />
                        }
                        {serviceType === 'CONSULTING SERVICES' &&
                            <ResidualConsultingServiceForm
                                servicesPlatform={servicesPlatform.filter((service) => service.type === 'CONSULTING')}
                                onSubmit={(values) => addServiceHandler(values)}
                                onReturn={() => setServiceType('')}
                            />
                        }
                    </Stack>
                }
                subGroupTitle={
                    serviceType === 'RESIDUAL OPERATOR' ? t`RESIDUAL OPERATOR` :
                        serviceType === 'TREATMENT SERVICES' ? t`TREATMENT SERVICES` :
                            serviceType === 'ADVANCED SERVICES' ? t`ADVANCED SERVICES` :
                                serviceType === 'TRANSPORT SERVICES' ? t`TRANSPORT SERVICES` :
                                    serviceType === 'CONSULTING SERVICES' ? t`CONSULTING SERVICES` : t`SERVICE TYPE`
                }
                actions={false}
            />
            {serviceType && serviceType !== 'RESIDUAL OPERATOR' &&
                <Stack sx={{...commonStyles.centerContentAndItems, width: '100%'}} py={5} gap={2}>
                    <Typography>{t`The service you want is not on the list?`}</Typography>
                    <Button variant={'outlined'} onClick={() => setShowSuggestServiceForm(true)}>
                        {t`Suggest new service`}
                    </Button>
                </Stack>
            }
            <CardDialog
                open={showSuggestServiceForm}
                title={t`ADD SERVICE SUGGESTION`}
                onClose={() => setShowSuggestServiceForm(false)}
            >
                <AddServiceSuggestionForm onSubmit={addServiceSuggestion}/>
            </CardDialog>
            <DirectusFeedback
                error={
                    errorAddService ||
                    errorAddServiceSuggestion ||
                    errorGetServicesPlatform ||
                    errorAddPreTreatmentService ||
                    errorAddAdvancedService ||
                    errorAddResidueOperatorService ||
                    errorAddTransportationService ||
                    errorAddConsultingService
                }
                isLoading={
                    isLoadingAddService ||
                    isLoadingAddServiceSuggestion ||
                    isLoadingGetServicesPlatform ||
                    isLoadingAddPreTreatmentService ||
                    isLoadingAddAdvancedService ||
                    isLoadingAddResidueOperatorService ||
                    isLoadingAddTransportationService ||
                    isLoadingAddConsultingService
                }
            />
        </>
    );
};

export default AddServiceForm;