import * as React from 'react';
import {useState} from 'react';
import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './helpers/residues-list-summarized-helpers';
import {useDirectusQuery} from '../custom-hooks/useDirectusQuery';
import {DirectusFeedback} from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import {Residue} from '../../types';
import {t} from 'ttag';
import {dataGridComponents} from '../datagrid/dataGridComponents';

export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    filterByUser: any,
    height?: number
}

export const ResiduesListSummarized = ({filterByUser, height}: Props) => {
    const [pageSize, setPageSize] = useState(4);

    const {
        data: residues,
        error: errorGetResidues,
        isLoading: isLoadingGetResidues
    } = useDirectusQuery<ResiduesApiResponse>({
        path: '/items/residues',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'department_id': {
                        'entity_id': {
                            'users': {
                                'directus_users_id': {
                                    "_eq": filterByUser.id
                                }
                            }
                        }
                    }
                }
            }
        }
    });

    return (
        <Box height={height}>
            <DataGrid
                columns={columns()}
                pageSize={pageSize}
                rows={residues || []}
                getRowId={(row) => row.id}
                loading={isLoadingGetResidues}
                components={dataGridComponents}
                rowsPerPageOptions={[5, 15, 25]}
                rowCount={residues?.length || 0}
                sx={{...commonStyles.datagridInner, ...commonStyles.dataGridHideHeader}}
                onPageSizeChange={(size) => setPageSize(size)}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                hideFooter
            />
            <DirectusFeedback
                error={errorGetResidues}
                isLoading={isLoadingGetResidues}
            />
        </Box>
    );
};

ResiduesListSummarized.defaultProps = {
    height: 300
}

export default ResiduesListSummarized;