import {t} from 'ttag';
import {useNavigate} from 'react-router-dom';
import {TabContext, TabPanel} from '@mui/lab';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Box, Button, Chip, FormControlLabel, Radio, RadioGroup, Stack, Tab, Typography} from '@mui/material';
import {Check, ContactMail, Delete, Publish} from '@mui/icons-material';
import {UpdateResidueFormDetails} from '../forms/update-residue-form/UpdateResidueFormDetails';
import {UpdateResidueFormSchema} from '../forms/schemas/update-residue-form-schema';
import NavigationBreadcrumbs from '../../common/breadcrumbs/NavigationBreadcrumbs';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import {CustomizedTabs} from '../../../pages/entities/CustomizedTabs';
import {createFileFormData} from '../../../utils/forms/helpers';
import ValorizationPlansTab from './tabs/ValorizationPlansTab';
import DirectusFeedback from '../../common/DirectusFeedback';
import commonStyles from '../../common/styles/commonStyles';
import FormSkeleton from '../../common/forms/FormSkeleton';
import ConfirmDialog from '../../common/ConfirmDialog';
import BasicCard from '../../common/cards/BasicCard';
import AlertDialog from '../../common/AlertDialog';
import {useStore} from '../../../store/valtio';
import {Residue} from '../../../types';

const defaultPhoto = require('../../../assets/images/no-image.webp');

interface Props {
    residue: Residue
    onChangeInvalidateResidue: () => void
}

export const ResidueComponent = ({residue, onChangeInvalidateResidue}: Props) => {
    const navigate = useNavigate();
    const {app: {user: authenticatedUser, lerCodes}} = useStore();
    const [tab, setTab] = useState('1');
    const [errorFileSize, setErrorFileSize] = useState(null);
    const [editable, setEditable] = useState(false);
    const [selectedResidue, setSelectedResidue] = useState<Residue>();
    const [showDeleteResidueDialog, setShowDeleteResidueDialog] = useState(false);
    const [showPublishResidueDialog, setShowPublishResidueDialog] = useState(false);
    const [showCompleteResidueDialog, setShowCompleteResidueDialog] = useState(false);

    const defaultValues = useMemo(
        () => ({
            ler_code: residue?.ler_code?.id || '',
            holder: residue?.holder || '',
            department_id: residue?.department_id?.id || '',
            entity_id: residue?.department_id?.entity_id?.id || '',
            entity: residue?.department_id?.entity_id || '',
            address_id: {
                island: residue?.address_id?.island || '',
                parish: residue?.address_id?.parish || '',
                county: residue?.address_id?.county || '',
            },
            contamination: residue?.contamination || '',
            format: residue?.format || '',
            amount: residue?.amount || '',
            amount_base_unit: residue?.amount_base_unit || '',
            availability_date: residue?.availability_date + ' 15:04:08' || '',
            current_photo: residue?.image,
            image: '',
            default_photo: defaultPhoto
        }),
        [residue]
    );

    const methods = useForm({
        resolver: yupResolver(UpdateResidueFormSchema),
        defaultValues
    });

    const {
        actions: {DELETE: deleteResidue},
        response: {
            error: errorDeleteResidue,
            isLoading: isLoadingDeleteResidue
        }
    } = useDirectusMutation({path: `/items/residues/${residue.id}`});

    const {
        actions: {PATCH: updateResidue},
        response: {
            error: errorUpdateResidue,
            isLoading: isLoadingUpdateResidue
        }
    } = useDirectusMutation({path: `/items/residues/${residue.id}`});

    const {
        actions: {POST: uploadPhoto},
        response: {error: errorUploadPhoto, isLoading: isLoadingUploadPhoto}
    } = useDirectusMutation({path: '/files'});

    const updateHandler = methods.handleSubmit((values) => {
            values.image
                ? createFileFormData([values.image], (formData) =>
                    uploadPhoto(formData, (photo) => {
                        updateResidue({
                            ...values,
                            image: photo.id
                        }, () => {
                            setEditable(false);
                            onChangeInvalidateResidue();
                        })
                    }), (error) => setErrorFileSize(error))
                : updateResidue({
                    ler_code: values.ler_code,
                    holder: values.holder,
                    department_id: values.department_id,
                    address_id: values.address_id,
                    contamination: values.contamination,
                    format: values.format,
                    amount: values.amount,
                    amount_base_unit: values.amount_base_unit,
                    availability_date: values.availability_date
                }, () => {
                    setEditable(false);
                    onChangeInvalidateResidue();
                })
        }
    );

    const deleteHandler = (callback = () => null) => deleteResidue(callback);

    const publishHandler = (callback = () => null) => updateResidue({status: 'PUBLISHED'}, callback);
    const completeHandler = (callback = () => null) => updateResidue({
        status: 'COMPLETED',
        residue_outcome: methods.getValues('residue_outcome')
    }, callback);

    useEffect(() => {
        if (residue) {
            methods.reset(defaultValues);
        }
    }, [residue, methods, defaultValues]);

    if (!lerCodes || !residue || !authenticatedUser) return null;

    const userCanEdit = residue.department_id.entity_id.users.filter((user) => user.directus_users_id.id === authenticatedUser.id).length > 0;

    return (
        <FormProvider {...methods}>
            {residue &&
                <Stack sx={commonStyles.pageWrapper}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            sx={commonStyles.pageTitle}>{residue?.ler_code?.code} ({residue?.ler_code?.description})</Typography>
                        {userCanEdit &&
                            <Box display={'flex'} gap={2}>
                                {
                                    residue.status === 'UNPUBLISHED' &&
                                    <Button
                                        size={'small'}
                                        variant={'contained'}
                                        onClick={() => setShowPublishResidueDialog(true)}
                                    >
                                        <Publish sx={styles.btnIcon}/>
                                        {t`Publish`}
                                    </Button>
                                }
                                {
                                    residue.status === 'PUBLISHED' &&
                                    <Button
                                        size={'small'}
                                        color={'success'}
                                        variant={'contained'}
                                        onClick={() => setShowCompleteResidueDialog(true)}
                                    >
                                        <Check sx={styles.btnIcon}/>
                                        {t`Complete`}
                                    </Button>
                                }
                                {
                                    residue.status !== 'COMPLETED' &&
                                    <Button
                                        size={'small'}
                                        sx={styles.removeBtn}
                                        variant={'contained'}
                                        onClick={() => setShowDeleteResidueDialog(true)}
                                    >
                                        <Delete sx={styles.btnIcon}/>
                                        {t`Delete`}
                                    </Button>
                                }
                            </Box>
                        }
                    </Box>
                    <Box sx={commonStyles.spaceBetween}>
                        <NavigationBreadcrumbs
                            list={[
                                {name: t`Home`, link: '/'},
                                {name: t`Residues`, link: `/residues`},
                                {name: residue?.ler_code?.code, link: `/residues/${residue?.format}/${residue?.id}`}
                            ]}
                        />
                        {userCanEdit ?
                            <Chip
                                variant={'outlined'}
                                sx={{fontSize: '0.95rem'}}
                                color={
                                    residue.status === 'PUBLISHED'
                                        ? 'primary'
                                        : residue.status === 'UNPUBLISHED'
                                            ? 'default'
                                            : residue.status === 'CANCELLED'
                                                ? 'error'
                                                : residue.status === 'COMPLETED'
                                                    ? 'success'
                                                    : residue.status === 'VALORISATION'
                                                        ? 'secondary'
                                                        : 'default'
                                }
                                label={
                                    residue.status === 'PUBLISHED'
                                        ? t`Published`
                                        : residue.status === 'UNPUBLISHED'
                                            ? t`Unpublished`
                                            : residue.status === 'CANCELLED'
                                                ? t`Cancelled`
                                                : residue.status === 'COMPLETED'
                                                    ? t`Completed`
                                                    : residue.status === 'VALORISATION'
                                                        ? t`Valorization`
                                                        : ''
                                }/>
                            : <Button
                                variant={'contained'}
                                onClick={() => setSelectedResidue(residue)}
                            >
                                <ContactMail sx={styles.btnIcon}/>
                                {t`Contact`}
                            </Button>
                        }
                    </Box>

                    {userCanEdit ?
                        <BasicCard
                            headerContent={
                                <CustomizedTabs value={tab} onChange={(_, value) => setTab(value)}>
                                    <Tab label={t`Info`} value={'1'} disableRipple/>
                                    <Tab label={t`Valorization Plan`} value={'2'} disableRipple/>
                                </CustomizedTabs>
                            }
                        >
                            <TabContext value={tab}>
                                <TabPanel sx={styles.tabPanel} value={'1'}>
                                    <FormSkeleton
                                        mainGroupContent={
                                            <UpdateResidueFormDetails
                                                residue={residue}
                                                lerCodes={lerCodes}
                                                editable={editable}
                                            />
                                        }
                                        onSubmit={editable ? updateHandler : () => setEditable(true)}
                                        submitBtnText={editable ? t`SAVE` : t`EDIT`}
                                        cancelAction={false}
                                        innerForm
                                    />
                                </TabPanel>
                                <TabPanel sx={styles.tabPanel} value={'2'}>
                                    <ValorizationPlansTab/>
                                </TabPanel>
                            </TabContext>
                        </BasicCard>
                        : <BasicCard>
                            <FormSkeleton
                                mainGroupContent={
                                    <UpdateResidueFormDetails
                                        residue={residue}
                                        lerCodes={lerCodes}
                                        editable={editable}
                                        readOnly
                                    />
                                }
                                actions={false}
                                innerForm
                            />
                        </BasicCard>
                    }
                </Stack>
            }
            <ConfirmDialog
                open={showPublishResidueDialog}
                variant={'primary'}
                title={t`Publish Residue`}
                onCancel={() => setShowPublishResidueDialog(false)}
                onConfirm={() => publishHandler(() => {
                    setShowPublishResidueDialog(false);
                    onChangeInvalidateResidue();
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to publish` + ` ${residue?.ler_code?.description}?`}
                </Typography>
            </ConfirmDialog>
            <ConfirmDialog
                maxWidth={'sm'}
                variant={'success'}
                title={t`Complete Residue`}
                open={showCompleteResidueDialog}
                confirmDisabled={!Boolean(methods.watch('residue_outcome'))}
                onCancel={() => setShowCompleteResidueDialog(false)}
                onConfirm={() => completeHandler(() => {
                    setShowCompleteResidueDialog(false);
                    onChangeInvalidateResidue();
                })}
            >
                <Stack spacing={2}>
                    <Typography color={'text.secondary'}>
                        {t`Are you sure you want to mark` + ` ${residue?.ler_code?.description} ` + t`as completed?`}
                    </Typography>
                    <Controller
                        name={'residue_outcome'}
                        render={({field}) => (
                            <RadioGroup {...field} row>
                                <Stack spacing={1}>
                                    <Typography color={'text.secondary'}>
                                        {t`Please select the outcome of this residue below:`}
                                    </Typography>
                                    <Stack>
                                        <FormControlLabel control={<Radio/>} value={'DEPOSIT_DISPOSAL'}
                                                          label={t`Deposit / Disposal`}/>
                                        <FormControlLabel control={<Radio/>} value={'RECYCLING'} label={t`Recycling`}/>
                                        <FormControlLabel control={<Radio/>} value={'REUSE'} label={t`Reuse`}/>
                                        <FormControlLabel control={<Radio/>} value={'OPERATOR'}
                                                          label={t`Licensed Operator / Recovery`}/>
                                    </Stack>
                                </Stack>
                            </RadioGroup>
                        )}
                    />
                </Stack>
            </ConfirmDialog>
            <ConfirmDialog
                open={showDeleteResidueDialog}
                title={t`Delete Residue`}
                onCancel={() => setShowDeleteResidueDialog(false)}
                onConfirm={() => deleteHandler(() => {
                    setShowDeleteResidueDialog(false);
                    navigate(residue.is_derived ? '/by-products' : '/residues');
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete` + ` ${residue?.ler_code?.description}?`}
                </Typography>
            </ConfirmDialog>
            <AlertDialog
                title={t`Contact`}
                variant={'primary'}
                confirmText={t`Close`}
                open={Boolean(selectedResidue)}
                onConfirm={() => setSelectedResidue(null)}
            >
                <Stack sx={commonStyles.centerContentAndItems} spacing={1}>
                    <Typography textAlign={'center'}>{t`This residue belongs to the entity` + ` ${residue?.department_id?.entity_id?.name}`}</Typography>
                    <Typography textAlign={'center'}>{t`For more information contact the following email:`}</Typography>
                    <a href={`mailto:${residue?.department_id?.address_id?.email}?subject=${residue?.ler_code?.code} - ${residue?.ler_code?.description}`} target={'_blank'}>{residue?.department_id?.address_id?.email}</a>
                </Stack>
            </AlertDialog>
            <DirectusFeedback
                error={errorDeleteResidue || errorUpdateResidue || errorUploadPhoto || errorFileSize}
                isLoading={isLoadingDeleteResidue || isLoadingUpdateResidue || isLoadingUploadPhoto}
            />
        </FormProvider>
    );
};

const styles = {
    removeBtn: {
        backgroundColor: 'error.main',
        "&.MuiButtonBase-root:hover": {
            bgcolor: "error.main"
        }
    },
    btnIcon: {
        pr: 1,
        width: 17
    },
    tabPanel: {
        p: 2
    }
};

export default ResidueComponent;