import {t} from 'ttag';
import * as Yup from 'yup';


export const UsageMethodologyFormSchema = Yup.object().shape(
    {
        methodology_description: Yup.string().required(t`Required field.`),
        ler_code: Yup.object().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.object().shape({
                code: Yup.string().required(t`Required field.`),
                description: Yup.string().required(t`Required field.`)
            }).typeError(t`Required Field.`).required(t`Required Field.`)
        }).nullable(),
        reuse_quantity: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.number().typeError(t`Must be a valid number.`).required(t`Required field.`)
        }),
        base_unit: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
        reuse_quantity_percentage: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().test({
                test: (amount_percentage) => parseInt(amount_percentage) > 0 && parseInt(amount_percentage) <= 100,
                message: t`Should be less or equal 100%.`
            }).required(t`Required field.`).typeError(t`Required field.`)
        }),
        insertion_date: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
    }
).required();
