import {t} from 'ttag';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Link, Stack, Typography} from '@mui/material';
import {NavigationBreadcrumbs} from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import commonStyles from '../../components/common/styles/commonStyles';
import {FiberManualRecord} from '@mui/icons-material';


export const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const contactText = `Encarregado de Proteção de Dados \n epd.pgra@azores.gov.pt \n Secretaria-geral da Presidência \n Palácio da Conceição,  Largo 2 de Março, \n 9500-152 Ponta Delgada `;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`PRIVACY POLICY`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: 'Home', link: '/'},
                    {name: t`Privacy Policy`, link: '/privacy-policy'}
                ]}
            />
            <Stack spacing={2}>
                <Typography>
                    A Plataforma ReBuild17 (ou plataforma-rebuild17.obraspublicas.azores.gov.pt) é fornecida pelo Governo dos Açores.
                </Typography>
                <Typography>
                    A Presidência do Governo é responsável pelo tratamento dos dados nas páginas genéricas da Plataforma ReBuild17, por exemplo, https://plataforma-rebuild17.obraspublicas.azores.gov.pt/. Sendo a Plataforma ReBuild17 um website que agrupa as diversas entidades, existem zonas da responsabilidade de edição de cada entidade. A responsabilidade pelo tratamento dos dados compete à entidade a que respeite cada site interno nesta plataforma.
                </Typography>
                <Typography>
                    Um responsável pelo tratamento de dados determina como e porquê são processados dados pessoais. Para mais informação, visite <Link sx={styles.link} href={'https://ec.europa.eu/info/law/law-topic/data-protection/reform/rules-business-and-organisations/obligations/controller-processor_pt'}> https://ec.europa.eu/info/law/law-topic/data-protection/reform/rules-business-and-organisations/obligations/controller-processor_pt. </Link>
                </Typography>
                <Typography sx={styles.title}>
                    Que dados recolhemos
                </Typography>
                <Typography>
                    Os dados pessoais que recolhemos nas páginas da Plataforma ReBuild17, que não estejam diretamente cometidas a outra entidade do Governo devidamente identificada, incluem:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> O seu nome, morada, contacto telefónico, sendo este opcional, e nif.</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> O seu endereço de correio eletrónico.</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> O endereço de Protocolo de Internet (IP) e detalhes sobre a versão de navegador de Internet que utiliza, através de cookies.</Typography>
                </Box>
                <Typography>
                    Não tentaremos, sob que forma for, identificá-lo através da informação recolhida e não combinaremos esta informação com quaisquer outros conjuntos de dados para efeitos de identificação pessoal.
                </Typography>
                <Typography>
                    Realizamos testes e monitorização continua aos nossos controlos de proteção de dados para assegurar que são eficazes e para detetar e atuar sobre quaisquer fragilidades.
                </Typography>
                <Typography sx={styles.title}>
                    Porque precisamos dos seus dados
                </Typography>
                <Typography>
                    Isto ajuda-nos a:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Assegurar que a Plataforma dá resposta às necessidades dos utilizadores;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Enviar emails para utilizadores a pedido de outros (convites);</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Permitir que aceda a informação e serviços da Plataforma ReBuild17;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Monitorizar o uso da Plataforma ReBuild17 e identificar ameaças de segurança.</Typography>
                </Box>
                <Typography sx={styles.title}>
                    A base legal para processarmos os seus dados
                </Typography>
                <Typography>
                    A Plataforma ReBuild17 cumpre com o Regulamento (EU) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (RGPD), assim como a sua execução, na ordem nacional, Lei n.º 58/2019, de 8 de agosto
                </Typography>
                <Typography>
                    A base legal para o processamento de dados pessoais em relação à segurança da Plataforma é o legítimo interesse, e o legítimo interesse dos nossos utilizadores, na garantia de segurança e integridade da Plataforma ReBuild17.
                </Typography>
                <Typography>
                    A base legal para o processamento restante de dados pessoais reside na necessidade de:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Realizar o interesse público; </Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> No exercício das nossas funções enquanto estrutura governamental.</Typography>
                </Box>
                <Typography sx={styles.title}>
                    O que fazemos com os seus dados
                </Typography>
                <Typography>
                    Os dados que recolhemos podem ser partilhados com outros utilizadores da plataforma e com outros departamentos do Governo. Podem ser também partilhados com fornecedores de tecnologia, por exemplo, o nosso fornecedor de alojamento ou de distribuição de notificações eletrónicas.
                </Typography>
                <Typography>
                    Nunca iremos:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Vender ou alugar os seus dados a terceiras partes;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Partilhar os seus dados para fins de marketing;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Usar os seus dados analiticamente para qualquer outro fim que não o da análise anonimizada da utilização da Plataforma ReBuild17.</Typography>
                </Box>
                <Typography>
                    Partilharemos os dados que tiver partilhado connosco se formos a isso obrigados por lei – por exemplo, por ordem judicial, ou para prevenir fraude ou outro crime.
                </Typography>
                <Typography sx={styles.title}>
                    Por quanto tempo mantemos os dados
                </Typography>
                <Typography>
                    Manteremos os dados enquanto:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Necessário para as finalidades descritas neste documento;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Requerido por lei.</Typography>
                </Box>
                <Typography sx={styles.title}>
                    Proteção da privacidade de menores
                </Typography>
                <Typography>
                    Os nossos serviços não são desenhados para - ou intencionalmente dirigidos a - crianças menores de 13 anos. Não recolhemos ou mantemos dados, intencionalmente, de crianças menores de 13 anos.
                </Typography>
                <Typography sx={styles.title}>
                    Onde processamos e armazenamos os dados
                </Typography>
                <Typography>
                    Os nossos sistemas são desenhados, construídos e administrados de maneira a assegurar que os dados que recolhemos estão tão seguros quanto possível a cada momento – tanto quando são processados, quanto durante o armazenamento.
                </Typography>
                <Typography>
                    Todos os dados pessoais são armazenados ou diretamente na base de dados da Plataforma ReBuild17 ou em serviços de subcontratantes que apresentam garantias suficientes para a aplicação de medidas técnicas e organizativas destinadas a assegurar que o tratamento cumprirá as normas do Regulamento Geral sobre a Proteção de Dados (RGPD) e a garantir a proteção dos direitos pessoais.
                </Typography>
                <Typography sx={styles.title}>
                    Como protegemos os seus dados e os mantemos seguros
                </Typography>
                <Typography>
                    Fazemos todos os esforços possíveis para mantermos a segurança dos dados que recolhemos. Temos sistemas e processos montados para prevenir acessos não autorizados ou divulgação dos dados – por exemplo, protegemos os dados usando diversos graus de encriptação.
                </Typography>
                <Typography>
                    Também tomamos diligências para assegurar que quaisquer subcontratantes mantêm seguros os dados que processam em nosso nome.
                </Typography>
                <Typography sx={styles.title}>
                    Os seus direitos
                </Typography>
                <Typography>
                    Como utilizador da Plataforma ReBuild17, tem o direito de solicitar:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Informação sobre como os seus dados pessoais são processados;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Uma cópia de tais dados pessoais;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Que qualquer incorreção nos seus dados pessoais seja corrigida imediatamente.</Typography>
                </Box>
                <Typography>
                    Pode também:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Objetar à forma como os seus dados pessoais são processados;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Requerer o apagamento dos seus dados pessoais no caso de não existir justificação para os mantermos; </Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Solicitar restrições circunstanciais ao processamento dos seus dados pessoais.</Typography>
                </Box>
                <Typography>
                    Caso deseja realizar algum destes pedidos, entre em contacto com o nosso Encarregado de Proteção de Dados através do email <Link sx={styles.link}>epd.pgra@azores.gov.pt.</Link>
                </Typography>
                <Typography sx={styles.title}>
                    Seguir um link de outro sítio web para a Plataforma ReBuild17
                </Typography>
                <Typography>
                    Caso chegue à Plataforma ReBuild17 a partir de outro sítio web, podemos receber informação sobre o sítio web a partir do qual navegou para a Plataforma.
                </Typography>
                <Typography sx={styles.title}>
                    Contacte-nos ou reclame
                </Typography>
                <Typography>
                    Contacte o Encarregado de Proteção de Dados se:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Tiver qualquer questão sobre esta política de privacidade;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> Acreditar que os seus dados pessoais foram abusados ou mal processados.</Typography>
                </Box>
                <Typography pt={3} align="left" sx={{color: 'secondary.main'}}>
                    {
                        contactText.split("\n").map((i, key) => {
                            return <p style={{margin: 0}} key={key}>{i}</p>;
                        }
                    )}
                </Typography>
                <Typography sx={styles.title}>
                    Alterações a esta política de privacidade
                </Typography>
                <Typography>
                    Podemos realizar alterações a esta política de privacidade. Sempre que ocorrerem alterações, ou, no inverso, a validação contínua desta política de privacidade, o dado “última atualização” na secção inferior desta página informará sobre a data. Quaisquer alterações serão imediatamente aplicáveis a si e aos seus dados.
                    Se tais alterações afetaram a forma como os seus dados são processados, tomaremos todos os passos razoáveis para o manter informado.
                </Typography>
                <Typography fontWeight={'bold'} pt={2}>Última atualização: 23 de março de 2023</Typography>
            </Stack>
        </Stack>
    );
};

const styles = {
    title: {
        fontWeight: 'bold',
        fontSize: '22px',
        paddingTop: 2
    },
    link: {
        color:'primary.light',
        underline:'none'
    }
}

export default PrivacyPolicy;