import {useEffect} from 'react';
import {useMediaQuery} from '@mui/material';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import SimulateValorization from './pages/valorization-processes/valorization-simulation/SimulateValorization';
import ValorizationProcesses from './pages/valorization-processes/ValorizationProcesses';
import AddService from './pages/entities/departments/department/services/AddService';
import Collaborator from './pages/entities/collaborators/collaborator/Collaborator';
import {AddCollaborator} from './pages/entities/collaborators/AddCollaborator';
import {Department} from './pages/entities/departments/department/Department';
import Collaborators from './pages/entities/collaborators/Collaborators';
import AddParticipant from './pages/ppgrcds/participants/AddParticipant';
import AddDepartment from './pages/entities/departments/AddDepartment';
import TermsAndConditions from './pages/policies/TermsAndConditions';
import CommentsPPGRCD from './pages/ppgrcds/ppgrcd/CommentsPPGRCD';
import AddWantedResidue from './pages/residues/AddWantedResidue';
import AddLocation from './pages/ppgrcds/locations/AddLocation';
import AppInitProvider from './utils/providers/AppInitProvider';
import ByProduct from './pages/residues/ByProduct/ByProduct';
import WantedResidues from './pages/residues/WantedResidues';
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import AddByProduct from './pages/residues/AddByProduct';
import AddPPGRCD from './pages/ppgrcds/ppgrcd/AddPPGRCD';
import Residue from './pages/residues/Residue/Residue';
import ByProducts from './pages/residues/ByProducts';
import AddResidue from './pages/residues/AddResidue';
import AllPPGRCDs from './pages/ppgrcds/AllPPGRCDs';
import PPGRCD from './pages/ppgrcds/ppgrcd/PPGRCD';
import AddEntity from './pages/entities/AddEntity';
import {APP_ROLES} from './utils/user/user-utils';
import Residues from './pages/residues/Residues';
import Entities from './pages/entities/Entities';
import LandingPage from './pages/LandingPage';
import Entity from './pages/entities/Entity';
import Page from './pages/common/page/Page';
import Search from './pages/search/Search';
import Profile from './pages/user/Profile';
import {actions} from './store/valtio';


export const App = () => {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isDesktopLg = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    useEffect(() => {
        actions.app.setIsDesktop(isDesktop);
    }, [isDesktop]);

    useEffect(() => {
        actions.app.setIsMobile(isMobile);
    }, [isMobile]);

    useEffect(() => {
        actions.app.setIsDesktopLg(isDesktopLg);
    }, [isDesktopLg]);

    return (
        <Router>
            <AppInitProvider/>
            <Routes>
                <Route path={'*'} element={<Navigate to='/'/>}/>
                <Route exact path={'/'}
                       element={<Page withAuthentication={false}><LandingPage/></Page>}/>
                <Route exact path={'/auth'}
                       element={<Page withMainLayout={false}/>}/>
                <Route exact path={'/auth/reset-password'}
                       element={<Page withMainLayout={false}/>}/>
                <Route exact path={'/terms-and-conditions'}
                       element={<Page withAuthentication={false}><TermsAndConditions/></Page>}/>
                <Route exact path={'/privacy-policy'}
                       element={<Page withAuthentication={false}><PrivacyPolicy/></Page>}/>
                <Route exact
                       path={'/search'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Search/></Page>}/>
                <Route exact
                       path={'/profile'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Profile/></Page>}/>
                <Route exact path={'/entities'}
                       element={<Page withAuthentication><Entities/></Page>}/>
                <Route exact path={'/entities/:entityName/:entityId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Entity/></Page>}/>
                <Route exact path={'/residues'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Residues/></Page>}/>
                <Route exact path={'/wanted-residues'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><WantedResidues/></Page>}/>
                <Route exact path={'/residues/:residueName/:residueId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Residue/></Page>}/>
                <Route exact path={'/by-products/:residueName/:residueId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><ByProduct/></Page>}/>
                <Route exact path={'/by-products'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><ByProducts/></Page>}/>
                <Route exact path={'/valorization-processes'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><ValorizationProcesses/></Page>}/>
                <Route exact path={'/residues/:residueName/:residueId/simulate-valorization'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><SimulateValorization/></Page>}/>
                <Route exact
                       path={'/residues/:residueName/:residueId/simulate-valorization/added-value/:valorizationProcessIdParam/:currentStepParam/'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><SimulateValorization/></Page>}/>
                <Route exact path={'/add-entity'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddEntity/></Page>}/>
                <Route exact path={'/add-residue'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddResidue/></Page>}/>
                <Route exact path={'/add-by-product'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddByProduct/></Page>}/>
                <Route exact path={'/add-wanted-residue'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddWantedResidue/></Page>}/>
                <Route exact path={'/entities/:entityName/:entityId/add-department'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddDepartment/></Page>}/>
                <Route exact path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Department/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/services/add-service'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddService/></Page>}/>
                <Route exact path={'/entities/:entityName/:entityId/add-collaborator'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddCollaborator/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/collaborators/:collaboratorName/:collaboratorId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Collaborator/></Page>}/>
                <Route exact path={'/entities/:entityName/:entityId/collaborators'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><Collaborators/></Page>}/>
                <Route exact path={'/ppgrcds'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AllPPGRCDs/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/add-ppgrcd/:ppgrcdId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddPPGRCD/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/add-ppgrcd/:ppgrcdId/:selectedForm'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddPPGRCD/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/ppgrcds/:ppgrcdId'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><PPGRCD/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/ppgrcds/:ppgrcdId/comments'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><CommentsPPGRCD/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/ppgrcds/:ppgrcdId/add-participant'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddParticipant/></Page>}/>
                <Route exact
                       path={'/entities/:entityName/:entityId/departments/:departmentName/:departmentId/ppgrcds/:ppgrcdId/add-location'}
                       element={<Page
                           userHasSomeOf={[APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]}
                           withAuthentication><AddLocation/></Page>}/>
            </Routes>
        </Router>
    );
}

export default App;
