import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {DirectusFeedback} from '../../common/DirectusFeedback';
import {useDirectusQuery} from '../../custom-hooks/useDirectusQuery';
import commonStyles from '../../common/styles/commonStyles';
import {columns} from './helpers/operations-list-helpers';
import {Operation} from '../../../types';
import {useState} from 'react';
import {Box} from '@mui/material';
import {t} from 'ttag';
import {dataGridComponents} from '../../datagrid/dataGridComponents';

export interface OperationsApiResponse {
    data?: Operation[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    userId: string,
    height?: number
}

export const OperationsList = ({userId, height}: Props) => {
    const [pageSize, setPageSize] = useState(5);
    const {data: operations, error, isLoading} = useDirectusQuery<OperationsApiResponse>({
        path: '/activity',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    "user" : {
                        "id" : {
                            "_eq": userId
                        }
                    }
                }
            }
        }
    });

    return (
        <Box height={height}>
            <DataGrid
                pageSize={pageSize}
                loading={isLoading}
                columns={columns()}
                rows={operations || []}
                components={dataGridComponents}
                sx={commonStyles.datagridInner}
                rowsPerPageOptions={[5, 15, 25]}
                getRowId={(row) => row.id}
                rowCount={operations?.length || 0}
                onPageSizeChange={(size) => setPageSize(size)}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
            />
            <DirectusFeedback error={error} isLoading={isLoading}/>
        </Box>

    );
};

OperationsList.defaultProps = {
    height: 500
};

export default OperationsList;