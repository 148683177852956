import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from '../entities/helpers/entities-list-summarized-helpers';
import useDirectusQuery from '../custom-hooks/useDirectusQuery';
import {commonStyles} from '../common/styles/commonStyles';
import DirectusFeedback from '../common/DirectusFeedback';
import {Entity} from '../../types';
import {Box} from '@mui/material';
import {useState} from 'react';
import {t} from 'ttag';
import {dataGridComponents} from '../datagrid/dataGridComponents';

export interface EntitiesApiResponse {
    data: Entity[],
    error: Error,
    isLoading: boolean
}

interface Props {
    filterByUser: any,
    height?: number
}

export const EntitiesListSummarized = ({filterByUser, height}: Props) => {
    const [pageSize, setPageSize] = useState(4);

    const {
        data: entities,
        error: errorGetEntity,
        isLoading: isLoadingGetEntity
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*',
                filter: {
                    'user_created': {
                        "_eq": filterByUser.id
                    }
                }
            }
        }
    });

    return (
        <Box height={height}>
            <DataGrid
                pageSize={pageSize}
                rows={entities || []}
                columns={columns()}
                components={dataGridComponents}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 15, 25]}
                rowCount={entities?.length || 0}
                onPageSizeChange={(size) => setPageSize(size)}
                sx={{...commonStyles.datagridInner, ...commonStyles.dataGridHideHeader}}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                hideFooter
            />
            <DirectusFeedback
                error={errorGetEntity}
                isLoading={isLoadingGetEntity}
            />
        </Box>
    );
};

EntitiesListSummarized.defaultProps = {
    height: 300
}

export default EntitiesListSummarized;