import {t} from 'ttag';
import React, {useState} from 'react';
import {Clear, FileUpload} from '@mui/icons-material';
import {Controller, useFormContext} from 'react-hook-form';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import EntityDepartmentSelectorResidueFormDetails from '../../residue/EntityDepartmentSelectorResidueFormDetails';
import DatetimePicker from '../../../common/date-time-picker/DatetimePicker';
import {contaminationLevels, formats, holders} from '../../../../mocks';
import FormSelectorsRAA from '../../../common/forms/FormSelectorsRAA';
import {commonStyles} from '../../../common/styles/commonStyles';
import {fileToBase64} from '../../../../utils/forms/helpers';
import {useStore} from '../../../../store/valtio';
import {PPGRCD, Residue} from '../../../../types';

interface Props {
    ppgrcds: PPGRCD[]
    residues: Residue[]
}

export const AddByProductFormDetails = ({residues, ppgrcds}: Props) => {
    const {app: {user}} = useStore();
    const [previewPhoto, setPreviewPhoto] = useState<string>();
    const {watch, resetField} = useFormContext();

    return (
        <>
            <Grid spacing={4} container>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'holder'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel sx={{color: 'text.primary'}}
                                            id={"holder-type-label"}>{t`Holder`}*</InputLabel>
                                <Select
                                    {...field}
                                    label={t`Holder`}
                                    labelId={'holder-type-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {holders.map((holder, key) => (
                                        <MenuItem key={key} value={holder.value}>{holder.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'derived_designation'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                error={Boolean(error)}
                                helperText={error?.message}
                                placeholder={t`By-Product Designation` + '*'}
                                sx={commonStyles.textField}
                                variant={'outlined'}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <EntityDepartmentSelectorResidueFormDetails authenticatedUser={user}/>
                </Grid>
                <>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'origin_derived_id'}
                            render={({field, fieldState: {error}}) => (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{color: 'text.primary'}}
                                            id={"origin-residue-label"}>{t`Origin residue id`}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            label={t`Origin residue id`}
                                            labelId={'origin-residue-label'}
                                            sx={styles.selectField}
                                            error={Boolean(error)}
                                        >
                                            <MenuItem value={''} disabled></MenuItem>
                                            {residues?.map((res, key) => (
                                                <MenuItem key={key} value={res.id}>
                                                    {`${res?.ler_code?.code} - ${res?.ler_code?.description}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'prevention_plan_id'}
                            render={({field, fieldState: {error}}) => (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{color: 'text.primary'}}
                                            id={"prevention-plan-label"}>{t`Prevention plan`}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            label={t`Prevention plan`}
                                            labelId={'prevention-plan-label'}
                                            sx={styles.selectField}
                                            error={Boolean(error)}
                                        >
                                            <MenuItem value={''} disabled></MenuItem>
                                            {ppgrcds?.map((plan, key) => (
                                                <MenuItem key={key} value={plan.id}>
                                                    {plan.form_work_general[0]?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                </>
                <Grid xs={12} pt={2} item>
                    <Typography sx={styles.inputLabel}>{t`BY-PRODUCT LOCATION ADDRESS`}</Typography>
                </Grid>
                <FormSelectorsRAA editable={true}/>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'contamination'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel id={'contamination-label'}>
                                    {t`Contamination` + '*'}
                                </InputLabel>
                                <Select
                                    {...field}
                                    label={t`Contamination`}
                                    labelId={'contamination-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {contaminationLevels.map((contamination, key) => (
                                        <MenuItem key={key} value={contamination.value}>
                                            {contamination.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'format'}
                        render={({field, fieldState: {error}}) => (
                            <FormControl fullWidth>
                                <InputLabel id={'format-label'}>{t`Format` + '*'}</InputLabel>
                                <Select
                                    {...field}
                                    label={t`Format`}
                                    labelId={'format-label'}
                                    sx={styles.selectField}
                                    error={Boolean(error)}
                                    required
                                >
                                    <MenuItem value={''} disabled></MenuItem>
                                    {formats.map((format, key) => (
                                        <MenuItem key={key} value={format.value}>{format.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'amount'}
                        render={({field, fieldState: {error}}) => (
                            <TextField
                                {...field}
                                type={'number'}
                                error={Boolean(error)}
                                helperText={error?.message}
                                sx={commonStyles.textField}
                                placeholder={t`Quantity(t)` + '*'}
                                variant={"outlined"}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Controller
                        name={'amount_base_unit'}
                        render={({field, fieldState: {error}}) => (
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                                <Typography>{t`BASE UNIT`}</Typography>
                                <RadioGroup {...field} row>
                                    <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                    <FormControlLabel value={'CUBIC_METER'} label={<Box
                                        display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                      control={<Radio/>}/>
                                </RadioGroup>
                                <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                            </Box>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Typography sx={styles.inputLabel}>{t`AVAILABILITY`}</Typography>
                </Grid>
                <Grid xs={12} item>
                    <Controller
                        name={'customAvailabilityDate'}
                        render={({field}) => (
                            <>
                                <RadioGroup {...field}>
                                    <FormControlLabel
                                        value={'false'}
                                        label={t`Immediate`}
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        value={'true'}
                                        label={t`Custom temporary date`}
                                        control={<Radio/>}
                                    />
                                </RadioGroup>
                                {(watch('customAvailabilityDate') === 'true') &&
                                    <Controller
                                        name={'availability_date'}
                                        render={({field, fieldState: {error}}) => (
                                            <Box pl={4} width={200}>
                                                <DatetimePicker
                                                    {...field}
                                                    size={'small'}
                                                    variant={'DATE_ONLY'}
                                                    error={Boolean(error)}
                                                    helperText={error?.message}
                                                />
                                            </Box>
                                        )}
                                    />
                                }
                            </>
                        )}
                    />
                </Grid>
                <Grid xs={12} md={12} item>
                    <Typography sx={styles.inputLabel}>{t`Photo Record`}</Typography>
                </Grid>
                <Grid xs={12} item>
                    <Box display={'flex'} alignItems={'center'}>
                        {previewPhoto &&
                            <IconButton
                                color={'error'}
                                size={'small'}
                                onClick={() => {
                                    setPreviewPhoto(null);
                                    resetField('image');
                                }}
                            >
                                <Clear/>
                            </IconButton>
                        }
                    </Box>
                    <Controller
                        name={'image'}
                        render={({field}) => (
                            <>
                                {previewPhoto ?
                                    <img alt={'preview-photo'} width={200} src={previewPhoto}/>
                                    :
                                    <>
                                        <input
                                            type={'file'}
                                            accept={'image/*'}
                                            id={'photo-upload'}
                                            onChange={({target: {files}}) => {
                                                field.onChange(files[0]);
                                                fileToBase64(files[0], (base64) =>
                                                    setPreviewPhoto(base64)
                                                )
                                            }}
                                            hidden
                                        />
                                        <label htmlFor={'photo-upload'}>
                                            <Button variant={"contained"} component={"span"}>
                                                {t`Upload File`}
                                                <FileUpload sx={{pl: 1}}/>
                                            </Button>
                                        </label>
                                    </>
                                }
                            </>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    }
};

export default AddByProductFormDetails;