import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../../common/grid/SubMenu';
import {createDatetime, DATE_FORMAT} from '../../../../../../utils/datetime/datetime-utils';
import {Stack, Typography} from '@mui/material';


export const columns = ({ isEditing, editHandler, deleteHandler, readOnly }) => [
  {
    field: 'name',
    headerName: t`BY-PRODUCT`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'reuse_quantity',
    headerName: t`QNT. TO REUSE`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'base_unit',
    headerName: t`BASE UNIT`,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({row}) => (
        row.base_unit === 'CUBIC_METER'
            ? 'm3'
            : row.base_unit === 'TON'
                ? 'tons' : '-'
    )
  },
  {
    field: 'reuse_quantity_percentage',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`% TO REUSE`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`FROM TOTAL`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'destination',
    headerName: t`DESTINATION`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'insertion_date',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    flex: .5,
    renderCell: ({ row }) => {
      return (
        <SubMenu
            disabled={isEditing || readOnly}
            onDelete={() => deleteHandler(row)}
            onEdit={() => editHandler(row)}
        />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
