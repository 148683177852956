import React from 'react';
import {DirectusProvider} from 'react-directus';
import {useStore} from './store/valtio';

interface Props {
    children: React.ReactElement
}

export const AuthProviders = ({children}: Props) => {
    const {app: {configs}} = useStore();

    if (!configs) return null;

    return (
        <>
            <DirectusProvider apiUrl={configs.api_url}>
                {children}
            </DirectusProvider>
        </>
    );
};

export default AuthProviders;