import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './helpers/ppgrcds-list-summarized-helpers';
import {useDirectusQuery} from '../custom-hooks/useDirectusQuery';
import {dataGridComponents} from '../datagrid/dataGridComponents';
import {DirectusFeedback} from '../common/DirectusFeedback';
import commonStyles from '../common/styles/commonStyles';
import {Residue} from '../../types';

export interface ResiduesApiResponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    filterByUser: any,
    height?: number
}

export const PPGRCDsListSummarized = ({filterByUser, height}: Props) => {
    const [pageSize, setPageSize] = useState(4);

    const {
        data: ppgrcds,
        error: errorGetPPGRCDs,
        isLoading: isLoadingGetPPGRCDs
    } = useDirectusQuery({
        path: '/items/prevention_plans',
        options: {
            params: {
                fields: '*.*.*.*',
                filter: {
                    'users': {
                        'directus_users_id': {
                            "_eq": filterByUser?.id
                        }
                    }
                }
            }
        }
    });

    return (
        <Box height={height}>
            <DataGrid
                columns={columns()}
                pageSize={pageSize}
                rows={ppgrcds || []}
                getRowId={(row) => row.id}
                loading={isLoadingGetPPGRCDs}
                components={dataGridComponents}
                rowsPerPageOptions={[5, 15, 25]}
                rowCount={ppgrcds?.length || 0}
                sx={{...commonStyles.datagridInner, ...commonStyles.dataGridHideHeader}}
                onPageSizeChange={(size) => setPageSize(size)}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                hideFooter
            />
            <DirectusFeedback
                error={errorGetPPGRCDs}
                isLoading={isLoadingGetPPGRCDs}
            />
        </Box>
    );
};

PPGRCDsListSummarized.defaultProps = {
    height: 300
}

export default PPGRCDsListSummarized;