import {t} from 'ttag';
import React, {useState} from 'react';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {UpdateCollaboratorFormSchema} from '../schemas/update-collaborator-form-schema';
import useDirectusMutation from '../../../../../../custom-hooks/useDirectusMutation';
import DirectusFeedback from '../../../../../../common/DirectusFeedback';
import commonStyles from '../../../../../../common/styles/commonStyles';
import FormActions from '../../../../../../common/forms/FormActions';
import {Collaborator} from '../../../../../../../types';

interface Props {
    collaborator: Collaborator
    updateCollaborator: (body: object, callback?: (data?: any) => any) => void
}

export const UpdateCollaboratorFormDetails = ({collaborator, updateCollaborator}: Props) => {
    const [editable, setEditable] = useState(false);

    const {
        actions: {PATCH: updateDirectusUser},
        response: {
            error: errorUpdateDirectusUser,
            isLoading: isLoadingUpdateDirectusUser
        }
    } = useDirectusMutation({path: `users/${collaborator.directus_users_id.id}`});

    const methods = useForm({
        resolver: yupResolver(UpdateCollaboratorFormSchema),
        defaultValues: {
            directus_users_id: collaborator.directus_users_id?.id || '',
            first_name: collaborator?.directus_users_id?.first_name || '',
            last_name: collaborator?.directus_users_id?.last_name || '',
            email: collaborator?.directus_users_id?.email || '',
            role: collaborator?.role || 'MANAGER'
        }
    });

    const updateHandler = methods.handleSubmit((values) =>
        updateDirectusUser({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email
        }, () => updateCollaborator(values))
    );

    return (
        <>
            <FormProvider {...methods}>
                <Grid spacing={4} container>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'first_name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`First Name` + '*'}
                                    variant={"outlined"}
                                    disabled={!editable}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'last_name'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`Last Name` + '*'}
                                    variant={"outlined"}
                                    disabled={!editable}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'email'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder={t`E-mail` + '*'}
                                    sx={commonStyles.textField}
                                    variant={"outlined"}
                                    disabled={!editable}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'role'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl fullWidth>
                                    <InputLabel sx={styles.inputLabel} id={'role-label'} disabled={!editable} required>
                                        {t`Role`}
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Role`}
                                        labelId={'role-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        disabled={!editable}
                                        required
                                    >
                                        <MenuItem key={1} value={'MANAGER'}>{t`Manager`}</MenuItem>
                                        <MenuItem key={2} value={'COORDINATOR'}>{t`Coordinator`}</MenuItem>
                                        <MenuItem key={3} value={'MEMBER'}>{t`Member`}</MenuItem>
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
                <FormActions
                    onSubmit={editable ? updateHandler : () => setEditable(true)}
                    submitBtnText={editable ? t`SAVE` : t`Edit`}
                    cancelAction={false}
                />
            </FormProvider>
            <DirectusFeedback error={errorUpdateDirectusUser} isLoading={isLoadingUpdateDirectusUser}/>
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        color: 'text.primary',
        fontSize: '1rem',
        fontWeight: 'bold'
    }
};

UpdateCollaboratorFormDetails.defaultProps
    = {
    editable: true
}

export default UpdateCollaboratorFormDetails;