import {t} from 'ttag';
import {useDirectus} from 'react-directus';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useDirectusMutation from '../../../components/custom-hooks/useDirectusMutation';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import Authentication from '../../authentication/Authentication';
import {useStore} from '../../../store/valtio';


export const AuthenticationGuard = ({children}) => {
    const {app: {user}} = useStore();
    const location = useLocation();
    const navigate = useNavigate();
    const {directus} = useDirectus();
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorRegister, setErrorRegister] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [isSuccessUserCreated, setIsSuccessUserCreated] = useState(false);
    const [isSuccessPasswordReset, setIsSuccessPasswordReset] = useState(false);
    const [isSuccessRequestPassword, setIsSuccessRequestPassword] = useState(false);

    useEffect(() => {
        if (!user && !location.pathname.includes('/auth')) navigate('/auth');
    }, [location]);

    const {
        actions: {POST: createUser},
        response: {
            error: errorCreateUser,
            isLoading: isLoadingCreateUser
        }
    } = useDirectusMutation({path: '/users', options: {noAuth: true}});

    const {
        actions: {POST: requestPasswordReset},
        response: {
            error: errorRequestPasswordReset,
            isLoading: isLoadingRequestPasswordReset
        }
    } = useDirectusMutation({path: '/auth/password/request', options: {noAuth: true}});

    const {
        actions: {POST: resetPassword},
        response: {
            error: errorResetPassword,
            isLoading: isLoadingResetPassword
        }
    } = useDirectusMutation({path: '/auth/password/reset', options: {noAuth: true}});

    useEffect(() => {
        if (errorCreateUser?.errors[0]?.extensions.code === 'RECORD_NOT_UNIQUE') {
            setErrorRegister(true);
        }
    }, [errorCreateUser]);

    const handleSubmitLoginForm = (values) => {
        setErrorLogin(false);
        setIsLoadingLogin(true);

        directus.auth.login({
            email: values.email,
            password: values.password
        })
            .then(() => navigate('/'))
            .then(() => window.location.reload())
            .catch(() => setErrorLogin(true))
            .finally(() => setIsLoadingLogin(false))
    };

    const handleSubmitRegisterForm = (body) => {
        setErrorRegister(false);
        createUser(body, () => {
            setShowRegisterForm(false);
            setIsSuccessUserCreated(true);
        })
    };

    const handleSubmitRequestPasswordResetForm = (email) => {
        requestPasswordReset({
            email,
            reset_url: 'https://plataforma-rebuild17.obraspublicas.azores.gov.pt/auth/reset-password'
        }, () => setIsSuccessRequestPassword(true));
    }

    const handleSubmitResetPasswordForm = (body) => {
        resetPassword(body, () => {
            setIsSuccessPasswordReset(true);
            navigate('/auth');
        });
    }

    if (!user) {
        return (
            <>
                <Authentication
                    onLogin={handleSubmitLoginForm}
                    onRegister={handleSubmitRegisterForm}
                    onSubmitRequest={handleSubmitRequestPasswordResetForm}
                    onSubmitResetPassword={handleSubmitResetPasswordForm}
                    onChangeShowRegisterForm={(value) => setShowRegisterForm(value)}
                    showRegisterForm={showRegisterForm}
                    errorRegister={errorRegister}
                    errorLogin={errorLogin}
                    isSuccessRequestPassword={isSuccessRequestPassword}
                />
                <DirectusFeedback
                    successMessage={isSuccessUserCreated ? t`User created` : isSuccessPasswordReset && t`Password was changed successfully`}
                    isSuccess={isSuccessUserCreated || isSuccessPasswordReset}
                    isLoading={isLoadingLogin
                        || isLoadingCreateUser
                        || isLoadingRequestPasswordReset
                        || isLoadingResetPassword
                    }
                    error={
                        ((!errorRegister && errorCreateUser) ? errorCreateUser : null)
                        || errorRequestPasswordReset
                        || errorResetPassword
                    }
                />
            </>
        );
    }

    if (location.pathname !== '/auth') return children;

    navigate('/');
};

export default AuthenticationGuard;
