import {t} from 'ttag';
import React, {SyntheticEvent, useState} from 'react';
import {Add, AddCircle, RemoveCircle} from '@mui/icons-material';
import {Controller, FormProvider, useFormContext} from 'react-hook-form';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import PlanClosureImportedEgarsListForm from './PlanClosureImportedEgarsListForm';
import PlanClosureEgarsListForm from './PlanClosureEgarsListForm';
import commonStyles from '../../../common/styles/commonStyles';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {useStore} from '../../../../store/valtio';
import Collapsable from '../../../Collapsable';
import {Egar} from '../../../../types';

interface EgarDetails {
    id?: string
    document_code: string,
    verification_code: string,
    ler_code: string,
    amount: number,
    base_unit: string,
    transport: string
    nif: string,
    operator: string,
    operation: string
}

interface Props {
    egars: EgarDetails[]
    importedEgars: Array<any>
    addedImportedEgars: Egar[]
    onEditEgar: (egarId: number) => void
    onSaveEditEgar: (callback: () => void) => void
    onRemoveEgar: (idx: number) => void
    onImportEgars: () => void
    onAddImportedEgar: (egar: Egar) => void
    importEgarsFormMethods: UseFormReturn
    readOnly: boolean
}

export const PlanClosureFormDetails = ({
   egars,
   importedEgars,
   addedImportedEgars,
   onAddEgar,
   onEditEgar,
   onSaveEditEgar,
   onRemoveEgar,
   onImportEgars,
   onAddImportedEgar,
   importEgarsFormMethods,
   readOnly
}: Props) => {
    const {app: {lerCodes}} = useStore();
    const {watch, resetField} = useFormContext();
    const [editingEgar, setEditingEgar] = useState(null);
    const [openManualForm, setOpenManualForm] = useState(false);

    const handleFinishEditing = () => {
        setEditingEgar(null);
        resetField('document_code');
        resetField('verification_code');
        resetField('ler_code');
        resetField('amount');
        resetField('base_unit');
        resetField('transport');
        resetField('fiscal_number');
        resetField('operator');
        resetField('operation');
    };

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    if (!lerCodes) return null;

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <Typography>{t`To close the plan insert manually or import from SRIR the e-Gars associated with the PPGRCD.`}</Typography>
            </Grid>
            <Grid xs={12} md={12} item>
                <PlanClosureEgarsListForm
                    egars={egars}
                    onEditEgar={(egarId) => {
                        setEditingEgar(egarId);
                        onEditEgar(egarId);
                    }}
                    onDeleteEgar={onRemoveEgar}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={12} mt={2} item>
                <Typography>{t`Import e-Gars`}</Typography>
            </Grid>
            {importedEgars
                ?
                <>
                    <Grid xs={12} md={12} item>
                        <PlanClosureImportedEgarsListForm
                            importedEgars={importedEgars}
                            addedImportedEgars={addedImportedEgars}
                            onAddEgar={onAddImportedEgar}
                        />
                    </Grid>
                </>
                : <FormProvider {...importEgarsFormMethods}>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'work_code'}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    helperText={error?.message}
                                    sx={commonStyles.textField}
                                    placeholder={t`Work Code`}
                                    error={Boolean(error)}
                                    variant={"outlined"}
                                    disabled={readOnly}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Button
                            variant={'outlined'}
                            sx={{width: '100%', height: '100%'}}
                            onClick={onImportEgars}
                        >
                            {t`Import e-Gars`}
                        </Button>
                    </Grid>
                </FormProvider>
            }
            <Grid xs={12} md={12} item>
                <Collapsable
                    open={openManualForm}
                    title={t`Or insert manually`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenManualForm(!openManualForm))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                >
                    <Grid spacing={3} pt={3} container>
                        <Grid xs={12} md={12} mt={2} item>
                            <Typography>{t`e-GAR Identification`}</Typography>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'document_code'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Document Code` + '*'}
                                        error={Boolean(error)}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'verification_code'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Verification Code` + '*'}
                                        error={Boolean(error)}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Typography>{t`Waste Characterization`}</Typography>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'ler_code'}
                                render={({field, fieldState: {error}}) => (
                                    <FormControl fullWidth>
                                        <InputLabel sx={styles.inputLabel} id={'lerCode-label'} disabled={readOnly}
                                                    required>{t`Ler Code`}</InputLabel>
                                        <Select
                                            {...field}
                                            label={t`Ler Code`}
                                            labelId={'lerCode-label'}
                                            sx={styles.selectField}
                                            error={Boolean(error)}
                                            disabled={readOnly}
                                            required
                                        >
                                            <MenuItem value={''} disabled></MenuItem>
                                            {lerCodes?.map((ler, key) => (
                                                ler.is_header
                                                    ?
                                                    <MenuItem
                                                        key={key}
                                                        value={ler.id}
                                                        sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                                        disabled
                                                    >
                                                        {`${ler.code} - ${ler.description}`}
                                                    </MenuItem>
                                                    :
                                                    <MenuItem key={key} value={ler.id}>
                                                        {`${ler.code} - ${ler.description}`}
                                                    </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={6}></Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'amount'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        type={'number'}
                                        error={Boolean(error)}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Quantity` + '*'}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        onKeyDown={(event) => {
                                            if (event.key === ".") event.preventDefault();
                                        }}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'base_unit'}
                                render={({field, fieldState: {error}}) => (
                                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                                        <Typography>{t`BASE UNIT`}</Typography>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                            <FormControlLabel value={'CUBIC_METER'} label={<Box
                                                display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                              control={<Radio/>}/>
                                        </RadioGroup>
                                        <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                    </Box>
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Typography>{t`Waste transporter`}</Typography>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'transport'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Transport` + '*'}
                                        error={Boolean(error)}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'fiscal_number'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        type={'number'}
                                        error={Boolean(error)}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Fiscal Number` + '*'}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Typography>{t`Final Destination`}</Typography>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'operator'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Operator` + '*'}
                                        error={Boolean(error)}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Controller
                                name={'operation'}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        helperText={error?.message}
                                        sx={commonStyles.textField}
                                        placeholder={t`Operation` + '*'}
                                        error={Boolean(error)}
                                        variant={"outlined"}
                                        disabled={readOnly}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Button
                                variant={'outlined'}
                                onClick={
                                    editingEgar
                                        ? () => onSaveEditEgar(handleFinishEditing)
                                        : () => onAddEgar()
                                }
                                disabled={
                                    readOnly || (
                                        !watch('document_code') ||
                                        !watch('verification_code') ||
                                        !watch('ler_code') ||
                                        !watch('amount') ||
                                        !watch('base_unit') ||
                                        !watch('transport') ||
                                        !watch('fiscal_number') ||
                                        !watch('operator') ||
                                        !watch('operation')
                                    )
                                }
                                fullWidth
                            >
                                {!editingEgar && <Add sx={styles.headerAddIcon}/>}
                                {editingEgar ? t`SAVE CHANGES` : t`Add Egar`}
                            </Button>
                        </Grid>
                    </Grid>
                </Collapsable>
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
}


export default PlanClosureFormDetails;