import {t} from 'ttag';
import * as Yup from 'yup';


export const SearchFormSchema = Yup.object().shape(
    {
        address_id: Yup.object().shape({
            island: Yup.string().required(t`Required field.`),
            parish: Yup.string(),
            county: Yup.string()
        }).required(t`Required field.`),
        ler_code: Yup.number().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
