import {t} from 'ttag';
import React from 'react';
import SubMenu from '../../../../common/grid/SubMenu';
import {toTitleCase} from '../../../../../utils/formatters/formatters';


export const columns = ({deleteHandler}) => [
    {
        field: 'name',
        headerName: t`NAME`,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({row}) => `${row.directus_users_id.first_name} ${row.directus_users_id.last_name}`
    },
    {
        field: 'role',
        headerName: t`ROLE`,
        disableColumnMenu: true,
        flex: 1.5,
        renderCell: ({row}) =>
            row.role === 'OWNER'
                ? t`Owner`
                : row.role === 'OWNER_REPRESENTATIVE'
                    ? t`Owner Representative`
                    : row.role === 'DRAFTSMAN'
                        ? t`Draftsman`
                        : row.role === 'CONTRACTOR'
                            ? t`Contractor`
                            : row.role === 'FISCAL'
                                ? t`Fiscal`
                                : '-'
    },
    {
        field: 'email',
        headerName: t`EMAIL`,
        disableColumnMenu: true,
        flex: 3,
        renderCell: ({row}) => row.directus_users_id.email
    },
    {
        field: 'status',
        headerName: t`STATUS`,
        disableColumnMenu: true,
        flex: 1.5,
        renderCell: ({row}) =>
            row.directus_users_id.status === 'active'
                ? t`Active`
                : t`Inactive`

    },
    {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        flex: .5,
        renderCell: ({row}) => {
            return (
                <SubMenu
                    onDelete={() => deleteHandler(row.directus_users_id)}
                    editAction={false}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
