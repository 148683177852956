import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Stack, Typography} from '@mui/material';
import ValorizationProcessesList from '../../components/valorization-processes/ValorizationProcessesList';
import PatternSearch from '../../components/common/pattern-search/PatternSearch';
import commonStyles from '../../components/common/styles/commonStyles';
import AuthorizationGuard from '../common/guards/AuthorizationGuard';
import BasicCard from '../../components/common/cards/BasicCard';
import {APP_ROLES} from '../../utils/user/user-utils';
import {useStore} from '../../store/valtio';


export const ValorizationProcesses = () => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();
    const [searchPattern, setSearchPattern] = useState(null);

    if(!user) return null;

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Box sx={styles.box}>
                <Typography sx={commonStyles.pageTitle}>{t`VALORIZATION PROCESSES`}</Typography>
                <AuthorizationGuard userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                <Button variant={'contained'} onClick={() => navigate('/add-valorization-process')}>
                    <Add sx={styles.headerAddIcon}/>
                    {t`Add Plan`}
                </Button>
                </AuthorizationGuard>
            </Box>
            <BasicCard>
                <Stack spacing={2}>
                    <PatternSearch onChange={(searchPattern) => setSearchPattern(searchPattern)}/>
                    <ValorizationProcessesList pattern={searchPattern}/>
                </Stack>
            </BasicCard>
        </Stack>
    );
};

const styles = {
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
}

export default ValorizationProcesses;