import {t} from 'ttag';
import {jsPDF} from 'jspdf';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Delete as DeleteIcon} from '@mui/icons-material';
import {Button, CircularProgress, IconButton} from '@mui/material';
import styles from './attachments.module.scss';

export const Attachments = ({
                                attachments,
                                onChange,
                                fileSizeLimit,
                                totalSizeLimit,
                                allowedFileTypes,
                                loadingHandlerFn,
                                multiple,
                                dropAttachmentsLabel,
                                allowedFileTypesLabel,
                                error,
                                disabled,
                                readOnly,
                                dropZoneProps,
                                onFilesRejected,
                                onSizeLimitReached,
                                onLoadFiles,
                                hideResetBtn,
                                hideFileElements,
                                hideSizeFeedback,
                                loading,
                                convertImagesToPdf,
                                children
                            }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [loadingFiles, setLoadingFiles] = useState(false);

    const localDataChangeHandler = (attachments) => {
        onChange([...attachments]);
    };

    const clearFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();

        localDataChangeHandler([]);
    };

    const clearFile = (e, attachmentIdx) => {
        e.preventDefault();
        e.stopPropagation();
        attachments.splice(attachmentIdx, 1);
        localDataChangeHandler([...attachments]);
    };

    const localLoadingHandler = (loading) => {
        setLoadingFiles(loading);
        loadingHandlerFn(loading);
    };

    const onDropRejected = (rejectedFiles) => {
        const rejectedFilesWithReasons = rejectedFiles.map((f) => {
            let reason = 'Unknown error';
            const extension = f.file.name.split('.').pop();
            if (f.file.size > fileSizeLimit) reason = byteToMB(fileSizeLimit) + 'MB ' + t`exceeded`;
            if (!allowedFileTypes.includes(extension)) reason = `.${extension}` + t`not allowed`;
            return {name: f.file.name, size: f.file.size, reason};
        });
        onFilesRejected(rejectedFilesWithReasons);
    };

    const validateDroppedFiles = (files) => {
        const totalAttachmentsSize = attachments.reduce((accumulator, currentFile) => accumulator + currentFile.size, 0);
        const totalFilesAndAttachmentsSize = files.reduce(
            (accumulator, currentFile) => accumulator + currentFile.size,
            totalAttachmentsSize
        );
        const isTotalFilesAndAttachmentsSizeAllowed = totalFilesAndAttachmentsSize <= totalSizeLimit;

        let allowedDroppedFiles = files;
        let rejectedDroppedFiles = [];

        if (!isTotalFilesAndAttachmentsSizeAllowed) {
            allowedDroppedFiles = [];
            let totalSize = totalAttachmentsSize;
            const sortedFiles = files.sort((fa, fb) => fa.size - fb.size);
            sortedFiles.forEach((file) => {
                totalSize += file.size;
                if (totalSize <= totalSizeLimit) {
                    allowedDroppedFiles.push(file);
                } else {
                    rejectedDroppedFiles.push(file);
                }
            });

            const availableSize = totalSizeLimit - allowedDroppedFiles.reduce((a, f) => a + f.size, totalAttachmentsSize);
            if (rejectedDroppedFiles.length) {
                const availableSizeInMB = byteToMB(availableSize);
                console.warn(`Total size limit was reached. (only ${availableSizeInMB}MB available)`);
                onSizeLimitReached(availableSizeInMB);
            }
        }

        return {allowedDroppedFiles, rejectedDroppedFiles};
    };

    const onDrop = (files) => {
        if (!files || !Array.isArray(files)) {
            return false;
        }

        const {allowedDroppedFiles} = validateDroppedFiles(files);

        localLoadingHandler(true);
        Promise.all(allowedDroppedFiles.map((f) => loadFile(f)))
            .then((loadedFiles) => {
                if (convertImagesToPdf && loadedFiles.filter(e => e.type.split('/')[1] !== 'pdf').length) {
                    const doc = new jsPDF();
                    doc.createAnnotation({
                        title: 'PDF'
                    });
                    loadedFiles.filter(e => e.type.split('/')[1] !== 'pdf').forEach((e, idx) => {
                        doc.addImage(`data:${e.type};base64,${e.content}`, e.type.split('/')[1].toUpperCase(), 1, 2);
                        if (idx + 1 !== loadedFiles.length) {
                            doc.addPage(idx, 'portrait');
                        }
                    });

                    loadFile(doc.output('blob')).then((e) => {
                        localDataChangeHandler([...attachments, {
                            ...e,
                            name: 'Title.pdf'
                        }, ...loadedFiles.filter(e => e.type.split('/')[1] === 'pdf')]);
                        onLoadFiles(loadedFiles);
                    });

                }
                if (!convertImagesToPdf || !loadedFiles.filter(e => e.type.split('/')[1] !== 'pdf').length) {
                    localDataChangeHandler([...attachments, ...loadedFiles]);
                    onLoadFiles(loadedFiles);
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.target?.error?.message || e.message || t`There was a problem loading your files`, {
                    variant: 'error'
                });
            })
            .finally(() => {
                localLoadingHandler(false);
            });
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        maxSize: fileSizeLimit,
        accept: allowedFileTypes,
        multiple,
        disabled,
        noClick: readOnly,
        noDrag: readOnly,
        ...dropZoneProps
    });

    const fileNamesJsx =
        !hideFileElements &&
        attachments.map((attach, idx) => (
            <span key={idx}>
        {!hideResetBtn && (
            <IconButton size={'small'} onClick={(e) => clearFile(e, idx, attach)}>
                <DeleteIcon/>
            </IconButton>
        )}
                <b>{attach.name || attach.directus_files_id.title}</b> ({byteToMB(attach.size || attach.directus_files_id.filesize)}MB)
        <br/>
      </span>
        ));

    const resetBtn =
        !hideResetBtn && attachments.length ? (
            <Button variant={'contained'} onClick={clearFiles} size={'small'} startIcon={<DeleteIcon/>}>
                {t`Reset Files`}
            </Button>
        ) : null;

    const totalFileSizeLoaded = attachments.reduce((accumulator, attach) => accumulator + (attach.size || attach.directus_files_id.filesize), 0);

    const sizeFeedback = !hideSizeFeedback && `(${byteToMB(totalFileSizeLoaded)}MB/${byteToMB(fileSizeLimit)}MB used)`;

    const containerClasses = [styles.dropzone];
    if (error) containerClasses.push(styles.error);
    if (disabled) containerClasses.push(styles.disabled);

    return (
        <>
            <div {...getRootProps({className: containerClasses.join(' ')})}>
                {children}
                <input {...getInputProps()} type={'file'}/>
                {loading || loadingFiles ? (
                    <CircularProgress color={'inherit'}/>
                ) : (
                    <>
                        <p style={{textAlign: 'center', margin: '10px 3px'}}>
                            {dropAttachmentsLabel}
                        </p>
                        <p style={{textAlign: 'center', margin: '10px'}}>
                            {sizeFeedback}
                        </p>
                    </>
                )}
                {/*<small>*/}
                {/*    <i>{`${allowedFileTypesLabel} ${allowedFileTypes}`}</i>*/}
                {/*</small>*/}
                <div style={{paddingTop: '1.8rem'}}>
                    {resetBtn}
                </div>
            </div>
            {fileNamesJsx}
        </>
    );
};

export const byteToMB = (bytes) => (bytes / 1000000).toFixed(1);
export const loadFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () =>
            resolve({
                content: btoa(reader.result),
                name: file.name,
                type: file.type,
                size: file.size
            });
        reader.onerror = (error) => reject(error);
    });
};

Attachments.propTypes = {
    dropAttachmentsLabel: PropTypes.string,
    allowedFileTypesLabel: PropTypes.string,
    attachments: PropTypes.array,
    allowedFileTypes: PropTypes.string,
    fileSizeLimit: PropTypes.number,
    totalSizeLimit: PropTypes.number,
    onChange: PropTypes.func,
    loadingHandlerFn: PropTypes.func,
    error: PropTypes.bool,
    multiple: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    dropZoneProps: PropTypes.object,
    onFilesRejected: PropTypes.func,
    onSizeLimitReached: PropTypes.func,
    onLoadFiles: PropTypes.func,
    hideResetBtn: PropTypes.bool,
    hideFileElements: PropTypes.bool,
    hideSizeFeedback: PropTypes.bool,
    loading: PropTypes.bool,
    convertImagesToPdf: PropTypes.bool,
};

Attachments.defaultProps = {
    dropAttachmentsLabel: t`Drop Attachments`,
    allowedFileTypesLabel: t`Allowed types:`,
    attachments: [],
    allowedFileTypes: '.jpg, .jpeg, .png',
    fileSizeLimit: 1000000,
    totalSizeLimit: 1000000,
    onChange: () => null,
    loadingHandlerFn: () => null,
    error: false,
    multiple: true,
    readOnly: false,
    disabled: false,
    dropZoneProps: {},
    onFilesRejected: () => null,
    onSizeLimitReached: () => null,
    onLoadFiles: () => null,
    hideResetBtn: false,
    hideFileElements: false,
    hideSizeFeedback: false,
    loading: false,
    convertImagesToPdf: false
};

export default Attachments;
