import {t} from 'ttag';
import * as Yup from 'yup';


export const LoginFormSchema = Yup.object().shape(
    {
        email: Yup.string()
            .required(t`Required field.`)
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, t`Should be a valid email.`),
        password: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
