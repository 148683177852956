import {t} from 'ttag';
import React from 'react';
import {baseUnitFormatter} from '../../../utils/formatters/formatters';
import SubMenu from '../../common/grid/SubMenu';


export const columns = ({editHandler, deleteHandler}) => [
    {
        field: 'derived_designation',
        headerName: t`DESIGNATION`,
        sortable: true,
        disableColumnMenu: true,
        flex: 3
    },
    {
        field: 'department',
        headerName: t`DEPARTMENT`,
        sortable: true,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({row}) => row.department_id?.name
    },
    {
        field: 'status',
        headerName: t`STATUS`,
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => (
            row.status === 'PUBLISHED'
                ? t`Published`
                : row.status === 'UNPUBLISHED'
                    ? t`Unpublished`
                    : row.status === 'CANCELLED'
                        ? t`Cancelled`
                        : row.status === 'COMPLETED'
                            ? t`Completed`
                            : row.status === 'VALORISATION'
                                ? t`Valorisation` : '-'
        )
    },
    {
        field: 'actions',
        headerName: '',
        type: 'number',
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row}) => {
            return (
                <SubMenu
                    viewAction
                    editAction={false}
                    onView={() => editHandler(row)}
                    onDelete={() => deleteHandler(row)}
                />
            );
        }
    }
];

export const helper = {
    columns
};

export default helper;
