import {t} from 'ttag';
import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {KeyboardArrowRight} from '@mui/icons-material';
import {useNavigate, useParams} from 'react-router-dom';
import OneStepValorizationForm from '../../../components/valorization-processes/OneStepValorizationForm';
import useDirectusMutation from '../../../components/custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../components/custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../components/common/DirectusFeedback';
import GraphicCard from '../../../components/common/cards/GraphicCard';
import CircularStatus from '../../../components/common/CircularStatus';
import BasicCard from '../../../components/common/cards/BasicCard';
import ValorizationSuggestions from './ValorizationSuggestions';
import RouteValorization from './RouteValorization';

const treatmentImg = require('../../../assets/images/valorization-processes/treatment.png');
const residueOperatorImg = require('../../../assets/images/valorization-processes/residue-operator.png');


export const ValorizationType = () => {
    const navigate = useNavigate();
    const {residueName, residueId} = useParams();
    const [selectedService, setSelectedService] = useState();
    const [valorizationRoutePlatformTitle, setValorizationRoutePlatformTitle] = useState();
    const [step, setStep] = useState('chooseValorizationType');

    const {
        data: residue,
        error: errorGetResidue,
        isLoading: isLoadingGetResidue
    } = useDirectusQuery({
        path: `/items/residues/${residueId}`,
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    const {
        data: servicesPlatform,
        error: errorGetServicesPlatform,
        isLoading: isLoadingGetServicesPlatform
    } = useDirectusQuery({
        path: '/items/services_platform',
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    const {
        data: valorizationMatrixResults,
        error: errorGetValorizationMatrixResults,
        isLoading: isLoadingGetValorizationMatrixResults
    } = useDirectusQuery({
        path: `rb17_api/valorization_matrix?residue_id=${residueId}`
    });

    const {
        data: valorizationRoutesResults,
        error: errorGetValorizationRoutesResults,
        isLoading: isLoadingGetValorizationRoutesResults
    } = useDirectusQuery({
        path: `rb17_api/valorization_routes?residue_id=${residueId}`
    });

    const {
        actions: {POST: createService},
        response: {
            error: errorCreateService,
            isLoading: isLoadingCreateService
        }
    } = useDirectusMutation({path: '/items/services'});

    const {
        actions: {POST: createValorizationProcess},
        response: {
            error: errorCreateValorizationProcess,
            isLoading: isLoadingCreateValorizationProcess
        }
    } = useDirectusMutation({path: '/items/valorization_process'});

    const {
        actions: {POST: addValorizationRoute},
        response: {
            error: errorAddValorizationRoute,
            isLoading: isLoadingAddValorizationRoute
        }
    } = useDirectusMutation({path: '/items/valorization_routes'});

    const handleOnSubmitOneStepValorizationForm = (values) => {
        createValorizationProcess({
            residue_id: residueId,
            selected_service_id: values.selected_service.id,
            status: 'ONGOING'
        }, () => navigate(`/residues/${residueName}/${residueId}`))
    }

    const handleAddValorizationRoute = (values) => {
        if(!values.step0_pickup_transportation_service){
            const transportationService = servicesPlatform?.filter(service => service.id === values.step0_pickup_transportation_service_to_create?.id)[0];

            createService({
                department_id: residue?.department_id.id,
                type: 'TRANSPORTATION',
                services_platform_id: transportationService.id
            }, (service) => {
                addValorizationRoute({
                    residue_id: residueId,
                    status: 'ONGOING',
                    step0_pickup_transportation_service: service.id,
                    step1_pretreatment_service: values.step1_pretreatment_service.id,
                    step1_2_treatment_service: values.step1_2_treatment_service.id,
                    step2_processing_service: values.step2_processing_service.id,
                    step3_product_service: values.step3_product_service.id,
                    valorization_routes_platform_id: values.valorization_routes_platform_id
                }, () => navigate(`/residues/${residueName}/${residueId}`))
            })

            return;
        }

        addValorizationRoute({
            residue_id: residueId,
            status: 'ONGOING',
            step0_pickup_transportation_service: values.step0_pickup_transportation_service.id,
            step1_pretreatment_service: values.step1_pretreatment_service.id,
            step1_2_treatment_service: values.step1_2_treatment_service.id,
            step2_processing_service: values.step2_processing_service.id,
            step3_product_service: values.step3_product_service.id,
            valorization_routes_platform_id: values.valorization_routes_platform_id
        }, () => navigate(`/residues/${residueName}/${residueId}`))
    };

    return (
        <>
            <BasicCard headerContent={
                <Box px={2}>
                    <Typography>
                        {
                            step === 'chooseValorizationType'
                                ? t`VALORIZATION TYPE`
                                : step === 'oneStepValorization'
                                    ? t`SERVICE TYPE`
                                    : step === 'chooseEntity'
                                        ? t`ENTITY`
                                        : step === 'routeValorization' && !valorizationRoutePlatformTitle
                                            ? t`VALORIZATION SOLUTION`
                                            : step === 'routeValorization' && valorizationRoutePlatformTitle
                                                ? valorizationRoutePlatformTitle + ' (Etapas)' : ''
                        }
                    </Typography>
                </Box>
            }>
                {step === 'chooseValorizationType'
                    ?
                    <>
                        <Box display={'flex'} gap={7} p={8}>
                            <GraphicCard
                                onClick={() => setStep('oneStepValorization')}
                                backgroundImg={residueOperatorImg}
                                bottomLabel={
                                    <Box sx={{...styles.box, p: 2}}>
                                        <Typography>{t`ONE STEP VALORIZATION`}</Typography>
                                        <KeyboardArrowRight/>
                                    </Box>
                                }
                            >
                                <Box p={1}>
                                    <CircularStatus color={'success.main'} char={'1'}/>
                                </Box>
                            </GraphicCard>
                            <GraphicCard
                                onClick={() => setStep('routeValorization')}
                                backgroundImg={treatmentImg}
                                bottomLabel={
                                    <Box sx={{...styles.box, p: 2}}>
                                        <Typography>{t`ROUTE VALORIZATION`}</Typography>
                                        <KeyboardArrowRight/>
                                    </Box>
                                }
                            >
                                <Box p={1}>
                                    <CircularStatus color={'warning.main'} char={'2'}/>
                                </Box>
                            </GraphicCard>
                        </Box>
                    </>
                    : step === 'oneStepValorization'
                        ? <ValorizationSuggestions
                            valorizationMatrixResults={valorizationMatrixResults?.raw?.DO}
                            onSelectService={(service) => {
                                setSelectedService(service);
                                setStep('chooseEntity');
                            }}
                            onReturn={() => setStep('chooseValorizationType')}
                        />
                        : step === 'chooseEntity'
                            ? <OneStepValorizationForm
                                selectedService={selectedService}
                                onSubmit={handleOnSubmitOneStepValorizationForm}
                                onReturn={() => setStep('oneStepValorization')}
                            />
                            : step === 'routeValorization'
                            && <RouteValorization
                                residue={residue}
                                valorizationRoutesResults={valorizationRoutesResults?.raw}
                                onSelectValorizationRoutePlatform={(route) => setValorizationRoutePlatformTitle(route.name)}
                                onSubmit={handleAddValorizationRoute}
                                onReturn={() => {
                                    setStep('chooseValorizationType');
                                    setValorizationRoutePlatformTitle(null);
                                }}
                            />
                }
            </BasicCard>
            <DirectusFeedback
                error={errorGetValorizationMatrixResults || errorCreateValorizationProcess || errorGetValorizationRoutesResults || errorAddValorizationRoute || errorCreateService || errorGetServicesPlatform || errorGetResidue}
                isLoading={isLoadingGetValorizationMatrixResults || isLoadingCreateValorizationProcess || isLoadingGetValorizationRoutesResults || isLoadingAddValorizationRoute || isLoadingCreateService || isLoadingGetServicesPlatform || isLoadingGetResidue}
            />
        </>
    );
};

const styles = {
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        whiteSpace: 'nowrap'
    }
};

export default ValorizationType;