import {t} from 'ttag';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import useDirectusMutation from '../../../custom-hooks/useDirectusMutation';
import {CreatePpgrcdFormSchema} from './schemas/create-ppgrcd-form-schema';
import CreatePpgrcdFormDetails from './CreatePpgrcdFormDetails';
import DirectusFeedback from '../../../common/DirectusFeedback';
import FormSkeleton from '../../../common/forms/FormSkeleton';

export const CreatePpgrcdForm = () => {
    const navigate = useNavigate();
    const {entityName, entityId, departmentName, departmentId} = useParams();

    const {
        actions: {POST: createPPGRCD},
        response: {
            error: errorCreatePPGRCD,
            isLoading: isLoadingCreatePPGRCD
        }
    } = useDirectusMutation({path: '/items/prevention_plans'});

    const methods = useForm({
        resolver: yupResolver(CreatePpgrcdFormSchema),
        defaultValues: {
            name: ''
        }
    });

    const onAddPPGRCD = methods.handleSubmit((values) => {
        createPPGRCD({
            name: values.name,
            department_id: departmentId,
            status: "DRAFT"
        }, ({id: ppgrcdId}) => navigate(`/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/add-ppgrcd/${ppgrcdId}`));
    });

    return (
        <>
            <FormProvider {...methods}>
                <FormSkeleton
                    mainGroupContent={<CreatePpgrcdFormDetails/>}
                    submitBtnText={t`Add PPGRCD`}
                    onSubmit={onAddPPGRCD}
                    cancelAction={false}
                    innerForm
                />
            </FormProvider>
            <DirectusFeedback error={errorCreatePPGRCD} isLoading={isLoadingCreatePPGRCD}/>
        </>
    );
};

export default CreatePpgrcdForm;