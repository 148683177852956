import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {dataGridComponents} from '../../datagrid/dataGridComponents';
import {columns} from './helpers/notifications-list-helpers';
import commonStyles from '../../common/styles/commonStyles';
import {Notification, Residue} from '../../../types';

export interface NotificationsAPIresponse {
    data?: Residue[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    notifications: Notification[],
    height?: number
}

export const NotificationsList = ({notifications, height, hideFooter}: Props) => {
    const [pageSize, setPageSize] = useState(5);

    return (
        <Box height={height}>
            <DataGrid
                pageSize={pageSize}
                columns={columns()}
                rows={notifications || []}
                components={dataGridComponents}
                sx={commonStyles.datagridInner}
                rowsPerPageOptions={[5, 15, 25]}
                getRowId={(row) => row.id}
                rowCount={notifications?.length || 0}
                onPageSizeChange={(size) => setPageSize(size)}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`
                    }
                }}
                disableExtendRowFullWidth
                hideFooter={hideFooter}
            />
        </Box>
    );
};

NotificationsList.defaultProps = {
    height: 300
};

export default NotificationsList;