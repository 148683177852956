import React from 'react';
import {FieldValues, FormProvider} from 'react-hook-form';
import PreventionMethodologyForm from './waste-prevention-and-reuse-forms/PreventionMethodologyForm';
import SubstancesAndObjectsForm from './waste-prevention-and-reuse-forms/SubstancesAndObjectsForm';
import UsageMethodologyForm from './waste-prevention-and-reuse-forms/UsageMethodologyForm';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import BasicCard from '../../common/cards/BasicCard';
import {Typography} from '@mui/material';
import {t} from 'ttag';

interface Props {
    currentForm: string
    preventionMethodologyFormMethods: UseFormReturn<FieldValues | any>
    substancesAndObjectsFormMethods: UseFormReturn<FieldValues | any>
    usageMethodologyFormMethods: UseFormReturn<FieldValues | any>
    onSubmitPreventionMethodologyForm: () => void
    onSubmitSubstancesAndObjectsForm: () => void
    onSubmitUsageMethodologyForm: () => void
    onReturnPreventionMethodologyForm: () => void
    onReturnSubstancesAndObjectsForm: () => void
    onReturnUsageMethodologyForm: () => void
    readOnly: boolean
}

export const WastePreventionAndReuseForms = ({
     currentForm,
     preventionMethodologyFormMethods,
     substancesAndObjectsFormMethods,
     usageMethodologyFormMethods,
     onSubmitPreventionMethodologyForm,
     onSubmitSubstancesAndObjectsForm,
     onSubmitUsageMethodologyForm,
     onReturnPreventionMethodologyForm,
     onReturnSubstancesAndObjectsForm,
     onReturnUsageMethodologyForm,
     readOnly
}: Props) => {

    return (
        <>
            {(currentForm === 'All') || (currentForm === 'PreventionMethodology') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`2.1 WASTE PREVENTION AND REUSE`} ({t`PROJECT DATA`})
                        </Typography>
                    }
                >
                    <FormProvider {...preventionMethodologyFormMethods}>
                        <PreventionMethodologyForm
                            onSubmit={onSubmitPreventionMethodologyForm}
                            onReturn={onReturnPreventionMethodologyForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
            {(currentForm === 'All') || (currentForm === 'SubstancesAndObjects') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`2.2 WASTE PREVENTION AND REUSE`} ({ t`SUBSTANCES DATA`})
                        </Typography>
                    }
                >
                    <FormProvider {...substancesAndObjectsFormMethods}>
                        <SubstancesAndObjectsForm
                            onSubmit={onSubmitSubstancesAndObjectsForm}
                            onReturn={onReturnSubstancesAndObjectsForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
            {(currentForm === 'All') || (currentForm === 'UsageMethodology') &&
                <BasicCard
                    headerContent={
                        <Typography px={3}>
                            {t`2.3 WASTE PREVENTION AND REUSE`} ({t`USAGE DATA`})
                        </Typography>
                    }
                >
                    <FormProvider {...usageMethodologyFormMethods}>
                        <UsageMethodologyForm
                            onSubmit={onSubmitUsageMethodologyForm}
                            onReturn={onReturnUsageMethodologyForm}
                            readOnly={readOnly}
                        />
                    </FormProvider>
                </BasicCard>
            }
        </>
    );
};

export default WastePreventionAndReuseForms;