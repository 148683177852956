import {t} from 'ttag';
import {Add} from '@mui/icons-material';
import {Controller, useFormContext} from 'react-hook-form';
import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import ProductionResiduesListForm from './ProductionResiduesListForm';
import commonStyles from '../../../common/styles/commonStyles';
import {useStore} from '../../../../store/valtio';
import {LerCode} from '../../../../types';

interface ResidueDetails {
    id?: string
    ler_code: LerCode
    final_estimated_amount: string
    base_unit: string
    valorization_amount: string
    valorization_operation: string
    disposal_amount: string
    disposal_operation: string
}

interface Props {
    residues: ResidueDetails[]
    onAddResidue: () => void
    onEditResidue: (residueId: number) => void
    onSaveEditResidue: (callback: () => void) => void
    onRemoveResidue: (idx: number) => void
    readOnly: boolean
}

export const ProductionAndOperationFormDetails = ({
                                                      residues,
                                                      onAddResidue,
                                                      onEditResidue,
                                                      onSaveEditResidue,
                                                      onRemoveResidue,
                                                      readOnly
                                                  }: Props) => {
    const {app: {lerCodes, valorizationOperations, eliminationOperations}} = useStore();
    const {watch, getValues, resetField} = useFormContext();
    const [editingResidue, setEditingResidue] = useState(null);
    const percentagesLessThan100Combined = (watch('valorization_amount') && watch('disposal_amount')) &&
        (parseInt(getValues('valorization_amount')) + parseInt(getValues('disposal_amount'))) <= 100;


    const handleFinishEditing = () => {
        setEditingResidue(null);
        resetField('ler_code');
        resetField('final_estimated_amount');
        resetField('base_unit');
        resetField('valorization_amount');
        resetField('valorization_operation');
        resetField('disposal_amount');
        resetField('disposal_operation');
    };

    if (!lerCodes || !valorizationOperations || !eliminationOperations) return null;

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <Typography>{t`Contaminated soil and rock classified as non-hazardous waste may not be sent to landfills for inert waste or to quarries, as per Guidance Document - Remediation operations for soil - Management of uncontaminated soil (2021)`}</Typography>
            </Grid>
            <Grid xs={12} md={12} item>
                <ProductionResiduesListForm
                    residues={residues}
                    onEditResidue={(residueId) => {
                        setEditingResidue(residueId);
                        onEditResidue(residueId);
                    }}
                    onDeleteResidue={onRemoveResidue}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'ler_code'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel sx={styles.inputLabel} id={'lerCode-label'} disabled={readOnly}
                                        required>{t`Ler Code`}</InputLabel>
                            <Select
                                {...field}
                                label={t`Ler Code`}
                                labelId={'lerCode-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={readOnly}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {lerCodes?.map((ler, key) => (
                                    ler.is_header
                                        ?
                                        <MenuItem
                                            key={key}
                                            value={ler.id}
                                            sx={{opacity: '1 !important', backgroundColor: 'common.lightGray'}}
                                            disabled
                                        >
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                        :
                                        <MenuItem key={key} value={ler.id}>
                                            {`${ler.code} - ${ler.description}`}
                                        </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item></Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'final_estimated_amount'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'number'}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Estimated/Final output quantity` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            onKeyDown={(event) => {
                                if (event.key === ".") event.preventDefault();
                            }}
                            disabled={readOnly}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                <FormControlLabel value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'valorization_amount'}
                    render={({field, fieldState: {error}}) => (
                        <>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                placeholder={t`Quantity for recovery (%)` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                            {!percentagesLessThan100Combined &&
                                <FormHelperText error={!percentagesLessThan100Combined}>
                                    {t`The sum of quantity for recovery and disposal must be less or equal 100% combined`}
                                </FormHelperText>
                            }
                        </>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'valorization_operation'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel
                                sx={styles.inputLabel}
                                id={'valorization-operation-label'}
                                disabled={readOnly}
                                required
                            >
                                {t`Valorization Operation`}
                            </InputLabel>
                            <Select
                                {...field}
                                label={t`Valorization Operation`}
                                labelId={'valorization-operation-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={readOnly}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {valorizationOperations?.map((op, key) => (
                                    <MenuItem key={key} value={op.id}>
                                        {op.code} - {op.description}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'disposal_amount'}
                    render={({field, fieldState: {error}}) => (
                        <>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                placeholder={t`Quantity for disposal (%)` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                            {!percentagesLessThan100Combined &&
                                <FormHelperText error={!percentagesLessThan100Combined}>
                                    {t`The sum of quantity for recovery and disposal must be less or equal 100% combined`}
                                </FormHelperText>
                            }
                        </>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'disposal_operation'}
                    render={({field, fieldState: {error}}) => (
                        <FormControl fullWidth>
                            <InputLabel
                                sx={styles.inputLabel}
                                id={'disposal-operation-label'}
                                disabled={readOnly}
                                required
                            >
                                {t`Disposal Operation`}
                            </InputLabel>
                            <Select
                                {...field}
                                label={t`Disposal Operation`}
                                labelId={'disposal-operation-label'}
                                sx={styles.selectField}
                                error={Boolean(error)}
                                disabled={readOnly}
                                required
                            >
                                <MenuItem value={''} disabled></MenuItem>
                                {eliminationOperations?.map((op, key) => (
                                    <MenuItem key={key} value={op.id}>
                                        {`${op.code} - ${op.description}`}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingResidue
                            ? () => onSaveEditResidue(handleFinishEditing)
                            : () => onAddResidue()
                    }
                    disabled={
                        readOnly || (
                            !watch('ler_code') ||
                            !watch('final_estimated_amount') ||
                            !watch('base_unit') ||
                            (!watch('valorization_amount') ||
                                parseInt(getValues('valorization_amount')) > 100 ||
                                parseInt(getValues('valorization_amount')) <= 0
                            ) ||
                            !watch('valorization_operation') ||
                            (!watch('disposal_amount') ||
                                parseInt(getValues('disposal_amount')) > 100 ||
                                parseInt(getValues('disposal_amount')) <= 0
                            ) ||
                            !percentagesLessThan100Combined ||
                            !watch('disposal_operation')
                        )
                    }
                    fullWidth
                >
                    {!editingResidue && <Add sx={styles.headerAddIcon}/>}
                    {editingResidue ? t`SAVE CHANGES` : t`Add Residue`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default ProductionAndOperationFormDetails;