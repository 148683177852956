import {t} from 'ttag';
import React, {SyntheticEvent, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Add, AddCircle, RemoveCircle} from '@mui/icons-material';
import {
    Box,
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import commonStyles from '../../../../common/styles/commonStyles';
import SubstancesListForm from './helpers/SubstancesListForm';
import TextCollapsable from '../../../../common/TextCollapsable';

interface SubstanceDetails {
    id?: string
    substance_id: string,
    reuse_quantity: string,
    base_unit: string,
    reuse_quantity_percentage: string
}

interface Props {
    substances: SubstanceDetails[]
    onAddSubstance: () => void
    onEditSubstance: (substanceId: number) => void
    onSaveEditSubstance: (callback: () => void) => void,
    onRemoveSubstance: (idx: number) => void
    readOnly: boolean
}

export const SubstancesAndObjectsFormDetails = ({
                                                    substances,
                                                    onAddSubstance,
                                                    onEditSubstance,
                                                    onSaveEditSubstance,
                                                    onRemoveSubstance,
                                                    readOnly
                                                }: Props) => {
    const {watch, getValues, resetField} = useFormContext();
    const [editingSubstance, setEditingSubstance] = useState(null);
    const [openSubstancesOrObjects, setOpenSubstancesOrObjects] = useState(true);
    const reuseQuantityPercentageCondition = watch('reuse_quantity_percentage') && (parseInt(getValues('reuse_quantity_percentage')) <= 100);

    const handleFinishEditing = () => {
        setEditingSubstance(null);
        resetField('name');
        resetField('reuse_quantity');
        resetField('base_unit');
        resetField('reuse_quantity_percentage');
        resetField('destination');
        resetField('insertion_date');
    }

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    }

    return (
        <Grid spacing={4} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openSubstancesOrObjects}
                    title={t`Substances or objects classified as by-products`}
                    content={t`This item should include excavated soil and other uncontaminated materials used in locations other than the site where they were excavated - in other works, in compliance with the Technical Note published on the APA website "Classification of soil and rock as by-product", among others.
Refer to the destination of the by-products and respective quantities to be used in other projects or other works, whether from the same or different owners and/or contractors.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenSubstancesOrObjects(!openSubstancesOrObjects))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <SubstancesListForm
                    substances={substances}
                    isEditing={editingSubstance}
                    onEditSubstance={(substanceId) => {
                        setEditingSubstance(substanceId);
                        onEditSubstance(substanceId);
                    }}
                    onDeleteSubstance={onRemoveSubstance}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Identification of substances/objects used as by-products` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'reuse_quantity'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Quantity to be reused` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                <FormControlLabel value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'reuse_quantity_percentage'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Quantity to be reused in relation to the total (%)` + '*'}
                                InputProps={{inputProps: {min: 0}}}
                                error={Boolean(error)}
                                variant={'outlined'}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                            {!reuseQuantityPercentageCondition &&
                                <FormHelperText error={!reuseQuantityPercentageCondition}>
                                    {t`The quantity to be reused must be 100% or less`}
                                </FormHelperText>
                            }
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={`destination`}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Destination` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingSubstance
                            ? () => onSaveEditSubstance(handleFinishEditing)
                            : () => onAddSubstance()
                    }
                    disabled={
                        readOnly || (
                            !watch('name') ||
                            !watch('reuse_quantity') ||
                            !watch('base_unit') ||
                            (!watch('reuse_quantity_percentage') ||
                                parseInt(getValues('reuse_quantity_percentage')) > 100 ||
                                parseInt(getValues('reuse_quantity_percentage')) <= 0
                            ) ||
                            !watch('destination')
                        )
                    }
                    fullWidth
                >
                    {!editingSubstance && <Add sx={styles.headerAddIcon}/>}
                    {editingSubstance ? t`SAVE CHANGES` : t`Add By-product`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default SubstancesAndObjectsFormDetails;