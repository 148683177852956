import {configureStore} from '@reduxjs/toolkit';
import sliceTemplate from './slices/sliceTemplate';
import api from '../../../api';

const store = configureStore({
  reducer: {
    sliceTemplate,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)
});

export default store;
