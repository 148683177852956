import {t} from 'ttag';
import * as React from 'react';
import {useState} from 'react';
import {Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate, useParams} from 'react-router-dom';
import useDirectusMutation from '../../../../custom-hooks/useDirectusMutation';
import useDirectusQuery from '../../../../custom-hooks/useDirectusQuery';
import DirectusFeedback from '../../../../common/DirectusFeedback';
import commonStyles from '../../../../common/styles/commonStyles';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import {columns} from './helpers/ppgrcds-list-helpers';
import {PPGRCD} from '../../../../../types';
import {dataGridComponents} from '../../../../datagrid/dataGridComponents';

export interface PPGRCDsApiResponse {
    data?: PPGRCD[],
    error?: Error,
    isLoading: boolean
}

interface Props {
    pattern?: string
}

export const PPGRCDsList = ({pattern}: Props) => {
    const navigate = useNavigate();
    const [invalidatedPpgrcd, setInvalidatedPpgrcd] = useState<PPGRCD>();
    const {entityName, entityId, departmentName, departmentId} = useParams();
    const [ppgrcdToDelete, setPpgrcdToDelete] = useState(null);

    const ppgrcdUrl = (ppgrcd) =>
        `/entities/${entityName}/${entityId}/departments/${departmentName}/${departmentId}/ppgrcds/${ppgrcd?.id}`;

    const editHandler = (ppgrcd) => navigate(ppgrcdUrl(ppgrcd));
    const viewHandler = (ppgrcd) => navigate(ppgrcdUrl(ppgrcd));
    const deleteHandler = (ppgrcd) => setPpgrcdToDelete(ppgrcd);

    const {
        data: ppgrcds,
        error: errorGetPPGRCD,
        isLoading: isLoadingGetPPGRCDs
    } = useDirectusQuery<PPGRCDsApiResponse>({
        path: '/items/prevention_plans',
        options: {
            params: {
                search: pattern,
                fields: '*.*.*.*',
                filter: {
                    'department_id': {
                        "_eq": departmentId
                    }
                }
            },
            refetchOnChange: invalidatedPpgrcd || pattern
        }
    });

    const {
        actions: {DELETE: deletePPGRCD},
        response: {
            error: errorDeletePPGRCD,
            isLoading: isLoadingDeletePPGRCD
        }
    } = useDirectusMutation({path: `/items/prevention_plans/${ppgrcdToDelete?.id}`});

    return (
        <>
            <DataGrid
                rows={ppgrcds || []}
                sx={commonStyles.datagrid}
                components={dataGridComponents}
                getRowId={(row) => row.id}
                rowCount={ppgrcds?.length || 0}
                columns={columns({editHandler, deleteHandler})}
                onCellClick={({row, field}) => {
                    if (field !== 'actions') {
                        viewHandler(row);
                    }
                }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t`Rows per page`,
                    }
                }}
                disableExtendRowFullWidth
                autoHeight
            />
            <ConfirmDialog
                open={Boolean(ppgrcdToDelete)}
                title={t`Delete PPGRCD`}
                onCancel={() => setPpgrcdToDelete(null)}
                onConfirm={() => deletePPGRCD(() => {
                    setInvalidatedPpgrcd(ppgrcdToDelete);
                    setPpgrcdToDelete(null);
                })}
            >
                <Typography color={'text.secondary'}>
                    {t`Are you sure you want to delete ppgrcd`+` ${ppgrcdToDelete?.id}?`}
                </Typography>
            </ConfirmDialog>
            <DirectusFeedback
                error={errorGetPPGRCD || errorDeletePPGRCD}
                isLoading={isLoadingGetPPGRCDs || isLoadingDeletePPGRCD}
            />
        </>
    );
};

export default PPGRCDsList;