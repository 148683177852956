import * as Yup from 'yup';
import {t} from 'ttag';
import {createDatetime as dt} from '../../../../../utils/datetime/datetime-utils';


export const SoilContaminationFormSchema = Yup.object().shape(
    {
        potential_contamination: Yup.string().required(t`Required field.`),
        historic_analysis: Yup.string().required(t`Required field.`),
        evaluation_description: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        contaminated_soil_description: Yup.string().when('potential_contamination', {
            is: (potentialContamination) => potentialContamination === 'true',
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
        })
    }
).required();
