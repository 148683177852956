import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: "https://api.votespot.tetrapi.org/api"
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => { return Promise.reject(error); }
)

export {axiosInstance};

export default axiosInstance;


