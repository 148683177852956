import * as Yup from 'yup';
import {t} from 'ttag';


export const ProductionAndOperationFormSchema = Yup.object().shape(
    {
        ler_code: Yup.object().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.object().shape({
                code: Yup.string().required(t`Required field.`),
                description: Yup.string().required(t`Required field.`)
            }).typeError(t`Required Field.`).required(t`Required Field.`)
        }).nullable(),
        final_estimated_amount: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
        base_unit: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
        valorization_amount: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().test({
                test: (amount_percentage) => parseInt(amount_percentage) > 0 && parseInt(amount_percentage) <= 100,
                message: t`Should be less or equal 100%.`
            }).required(t`Required field.`).typeError(t`Required field.`)
        }),
        valorization_operation: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        }),
        disposal_amount: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().test({
                test: (amount_percentage) => parseInt(amount_percentage) > 0 && parseInt(amount_percentage) <= 100,
                message: t`Should be less or equal 100%.`
            }).required(t`Required field.`).typeError(t`Required field.`)
        }),
        disposal_operation: Yup.string().when('residues', {
            is: (residues) => !residues.length,
            then: Yup.string().required(t`Required field.`)
        })
    }
).required();
