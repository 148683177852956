import {t} from 'ttag';
import React from 'react';
import {DateTime as dt} from 'luxon';
import {createDatetime, DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import SubMenu from '../../../../common/grid/SubMenu';
import {Stack, Typography} from '@mui/material';


export const columns = ({ isEditing, editHandler, deleteHandler, readOnly }) => [
  {
    field: 'description',
    headerName: t`DESCRIPTION`,
    disableColumnMenu: true,
    flex: 1.5
  },
  {
    field: 'transportation',
    headerName: t`TRANSPORTATION (€)`,
    disableColumnMenu: true,
    flex: 2.5
  },
  {
    field: 'operator',
    headerName: t`OPERATOR (€)`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'deposition',
    headerName: t`DEPOSITION (€)`,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'other_costs',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`OTHER`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`COSTS (€)`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 1.5
  },
  {
    headerName: t`TOTAL (€)`,
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: ({row}) => parseInt(row.transportation) + parseInt(row.deposition) + parseInt(row.operator) + (parseInt(row.other_costs) || 0)
  },
  {
    field: 'insertion_date',
    headerName:
        <Stack textAlign={'center'}>
          <Typography fontSize={'0.875rem'}>{t`INSERTION`}</Typography>
          <Typography fontSize={'0.875rem'}>{t`DATE`}</Typography>
        </Stack>,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ row }) => createDatetime(row.insertion_date).toFormat(DATE_FORMAT)
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    flex: .5,
    renderCell: ({ row }) => {
      return (
          <SubMenu
              disabled={isEditing || readOnly}
              onDelete={() => deleteHandler(row)}
              onEdit={() => editHandler(row)}
          />
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
