import {t} from 'ttag';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Link, Stack, Typography} from '@mui/material';
import {NavigationBreadcrumbs} from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import commonStyles from '../../components/common/styles/commonStyles';
import {FiberManualRecord} from '@mui/icons-material';


export const TermsAndConditions = () => {
    const navigate = useNavigate();

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`TERMS AND CONDITIONS`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: 'Home', link: '/'},
                    {name: t`Terms and Conditions`, link: '/terms-and-conditions'}
                ]}
            />
            <Stack spacing={2}>
                <Typography fontSize={'24px'} color={'primary.main'}>
                    Esta página explica os termos e condições de uso da plataforma ReBuild17. Ao utilizar o plataforma-rebuild17.obraspublicas.azores.gov.pt está a aceitar estes termos.
                </Typography>
                <Typography sx={styles.title}>
                    Uso
                </Typography>
                <Typography>
                    O ReBuild17 (ou plataforma-rebuild17.obraspublicas.azores.gov.pt) é fornecido pelo Governo Regional dos Açores através da Presidência do Governo Regional (PGR).
                </Typography>
                <Typography>
                    Ao navegar nesta Plataforma, o Utilizador concorda os presentes termos e condições de uso.
                </Typography>
                <Typography>
                    Utilizará o plataforma-rebuild17.obraspublicas.azores.gov.pt apenas para fins legais e de forma que não colida com os direitos de utilização deste sítio web por terceiros.
                </Typography>
                <Typography>
                    A Plataforma ReBuild17 é atualizada continuamente. Reservamos o direito de modificar o conteúdo existente, incluindo alterar ou remover conteúdo, a qualquer momento.
                </Typography>
                <Typography>
                    O material disponível neste site tem por finalidade fornecer informação generalista e gratuita a terceiros, cujo uso é da inteira responsabilidade do utilizador.
                </Typography>
                <Typography>
                    A Plataforma ReBuild17 não exige qualquer informação ao cidadão quando este interage com a Plataforma para consulta de conteúdos. O utilizador pode optar por realizar registo e autenticação, sendo que os dados pessoais que transmita estão protegidos nos termos da lei e nos termos da nossa Política de Privacidade e Política de cookies. Essa informação está disponível em permanência ao próprio utilizador, o qual pode alterar os seus dados ou solicitar a eliminação da sua conta na Plataforma. A Plataforma ReBuild17 utiliza a informação transmitida pelo utilizador unicamente para as finalidades de suporte à utilização personalizada da Plataforma, prestação de serviço público ao utilizador e analítica Web.
                </Typography>
                <Typography>
                    Não somos responsáveis por quaisquer danos ou perdas resultantes da utilização do plataforma-rebuild17.obraspublicas.azores.gov.pt, incluindo:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> perdas diretas, indiretas ou conexas;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> utilização do plataforma-rebuild17.obraspublicas.azores.gov.pt ou qualquer sítio web hiperligado a este ou ao qual este hiperligue;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> incapacidade de utilizar o plataforma-rebuild17.obraspublicas.azores.gov.pt ou quaisquer sítios web hiperligados a este ou ao qual este hiperligue.</Typography>
                </Box>
                <Typography sx={styles.title}>
                    Uso de conteúdos do plataforma-rebuild17.obraspublicas.azores.gov.pt
                </Typography>
                <Typography>
                    O utilizador pode copiar, importar ou utilizar gratuitamente informações existentes nesta plataforma para uso pessoal ou público e para fins lucrativos, desde que não tenha finalidades ofensivas. O utilizador deve contudo referir a fonte de informação.
                </Typography>
                <Typography>
                    Quando algum conteúdo seja de utilização restrita, mencionamos usualmente o autor ou o detentor dos direitos autorais. Caso não esteja seguro sobre se algum conteúdo é de uso livre ou está sujeito a direitos, <a href="mailto: rebuild17@azores.gov.pt" target="_blank">contacte-nos</a>
                </Typography>
                <Typography>
                    A Plataforma ReBuild17 ao disponibilizar formas de tratamento de resíduos e casos de estudo, está a aconselhar o utilizador a como tratar o seu resíduo.
                </Typography>
                <Typography sx={styles.title}>
                    Pedidos de remoção ou atualização de conteúdo
                </Typography>
                <Typography>
                    Poderá solicitar a remoção ou atualização de conteúdo da Plataforma ReBuild17.
                </Typography>
                <Typography>
                    Removeremos conteúdo sob pedido dos utilizadores nas seguintes situações:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> no cumprimento da legislação sobre proteção de dados;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> quando o conteúdo infrinja direitos autorais, contenha dados pessoais sensíveis ou material que possa ser considerado difamatório ou ofensivo.</Typography>
                </Box>
                <Typography>
                    Atualizaremos conteúdo sob pedido dos utilizadores quando ocorra imprecisão comprovável.
                </Typography>
                <Typography sx={styles.title}>
                    Serviços e transações
                </Typography>
                <Typography>
                    Pode utilizar a Plataforma ReBuild17 para aceder a serviços online prestados por entidades do Governo dos Açores.
                </Typography>
                <Typography>
                    Tais serviços podem ser geridos pelo GSRAPAP ou por outro departamento ou serviço governamental.
                </Typography>
                <Typography>
                    Alguns serviços dispõem dos seus próprios termos e condições que se aplicam - leia a informação aplicável, caso a caso, antes de utilizar o serviço.
                </Typography>
                <Typography sx={styles.title}>
                    Informação sobre si e sobre as suas visitas ao ReBuild17
                </Typography>
                <Typography>
                    Recolhemos informação de acordo com a <Link sx={styles.link} onClick={() => navigate('/privacy-policy')}>Política de Privacidade</Link> e a legislação portuguesa vigente. Ao utilizar o plataforma-rebuild17.obraspublicas.azores.gov.pt, está a concordar com a recolha de tal informação e a confirmar que quaisquer dados que nos transmita são verdadeiros e corretos.
                </Typography>
                <Typography sx={styles.title}>
                    Proteção contra Vírus informáticos
                </Typography>
                <Typography>
                    Fazemos todos os possíveis para assegurar que o plataforma-rebuild17.obraspublicas.azores.gov.pt está livre de vírus em todas as fases de produção. Compete ao utilizador assegurar que utiliza a Plataforma de maneira a não se expor aos risco de vírus, código malicioso ou outras formas de interferência que possam danificar o seu computador.
                </Typography>
                <Typography sx={styles.title}>
                    Vírus, pirataria e outras ofensas
                </Typography>
                <Typography>
                    Ao usar plataforma-rebuild17.obraspublicas.azores.gov.pt, compromete-se a:
                </Typography>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> não introduzir vírus, trojans, worms, bombas lógicas ou qualquer outro material ou código informático que seja malicioso ou tecnologicamente danoso;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> não tentar obter acesso não autorizado ao plataforma-rebuild17.obraspublicas.azores.gov.pt, o servidor no qual este está armazenado ou quaisquer outros servidores, bases de dados ou aplicativos informáticos conectados à Plataforma ReBuild17;</Typography>
                </Box>
                <Box display={'flex'} gap={1} pl={2}>
                    <FiberManualRecord sx={{width: 10}}/>
                    <Typography> não atacar o plataforma-rebuild17.obraspublicas.azores.gov.pt de nenhuma maneira, incluindo ataques DoS e DDoS.</Typography>
                </Box>
                <Typography>
                    Reportaremos às autoridades toda e qualquer tentativa de ataque informático e partilharemos com as autoridades toda e qualquer informação que tenhamos recolhido por potenciais atacantes.
                </Typography>
                <Typography sx={styles.title}>
                    Geral
                </Typography>
                <Typography>
                    Estes termos e condições respeitam e encontram-se obrigados à lei da República de Portugal. Toda e qualquer disputa em relação a estes termos e condições ou à sua utilização pessoal do plataforma-rebuild17.obraspublicas.azores.gov.pt será sujeita à jurisdição exclusiva dos tribunais portugueses.
                </Typography>
                <Typography sx={styles.title}>
                    Lei
                </Typography>
                <Typography>
                    A presente Plataforma rege-se pela Lei portuguesa.
                </Typography>
                <Typography sx={styles.title}>
                    Alterações aos termos e condições
                </Typography>
                <Typography>
                    Podemos realizar alterações a estes termos e condições. Sempre que ocorrerem alterações, ou, no inverso, a validação contínua deste conteúdo, o dado “última atualização” na secção inferior desta página informará sobre a data. Quaisquer alterações serão imediatamente aplicáveis a si e aos seus dados.
                </Typography>
                <Typography>
                    Ao continuar a visitar o plataforma-rebuild17.obraspublicas.azores.gov.pt, estará a concordar com os termos e condições conforme aplicáveis no momento da sua visita.
                </Typography>
                <Typography fontWeight={'bold'} pt={2}>Última atualização: 23 de março de 2023</Typography>
            </Stack>
        </Stack>
    );
};

const styles = {
    title: {
        fontWeight: 'bold',
        fontSize: '22px',
        paddingTop: 2
    },
    link: {
        color:'primary.light',
        underline:'none',
        cursor: 'pointer'
    }
}

export default TermsAndConditions;