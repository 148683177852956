import {t} from 'ttag';
import React, {SyntheticEvent, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Add, AddCircle, RemoveCircle} from '@mui/icons-material';
import {
    Box,
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import commonStyles from '../../../common/styles/commonStyles';
import TextCollapsable from '../../../common/TextCollapsable';
import {LerCode} from '../../../../types';
import IncorporationResiduesListForm from './IncorporationResiduesListForm';

interface ResidueDetails {
    id?: string
    amount: LerCode
    base_unit: string,
    amount_percentage: string
}

interface Props {
    residues: ResidueDetails[]
    onAddResidue: () => void
    onEditResidue: (residueId: number) => void
    onSaveEditResidue: (callback: () => void) => void
    onRemoveResidue: (idx: number) => void
    readOnly: boolean
}

export const IncorporationMethodologyFormDetails = ({
                                                        residues,
                                                        onAddResidue,
                                                        onEditResidue,
                                                        onSaveEditResidue,
                                                        onRemoveResidue,
                                                        readOnly
                                                    }: Props) => {
    const {watch, getValues, resetField} = useFormContext();
    const [editingResidue, setEditingResidue] = useState(null);
    const [openUsedWaste, setOpenUsedWaste] = useState(true);
    const [openMethodologyUsage, setOpenMethodologyUsage] = useState(true);
    const amountPercentageCondition = watch('amount_percentage') && (parseInt(getValues('amount_percentage')) <= 100);

    const handleFinishEditing = () => {
        setEditingResidue(null);
        resetField('name');
        resetField('amount');
        resetField('base_unit');
        resetField('amount_percentage');
        resetField('insertion_date');
    };

    const handleMenuItemClick = (e: SyntheticEvent, callback = () => null) => {
        e.stopPropagation();
        callback();
    };

    return (
        <Grid spacing={3} container>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openMethodologyUsage}
                    title={t`Methodology for incorporating recycled CDW`}
                    content={t`Describe the methodology used for incorporating recycled materials or products incorporating recycled materials to be used in design/work`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenMethodologyUsage(!openMethodologyUsage))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'methodology_description'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            rows={3}
                            helperText={error?.message}
                            sx={commonStyles.textField}
                            placeholder={t`Description of the methodology for incorporating recyclates` + '*'}
                            error={Boolean(error)}
                            variant={"outlined"}
                            disabled={readOnly}
                            multiline
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <TextCollapsable
                    open={openUsedWaste}
                    title={t`Recycled CDW integrated into project/works `}
                    content={t`Identify only recycled materials and products that incorporate recycled materials used in the project/work. These must have a Certificate of Factory Production Control issued by a notified body and a Declaration of Conformity, since they are materials and products. Note: This point is not to identify the waste applicable to the general rules, since these are not recycled materials. This is where the requirement to use 10% recycled materials, only in public works, or materials incorporating recycled materials relative to the total amount of raw materials used in design/work is required.

Examples: recycled aggregates, plastic pipes made from recycled plastics, bituminous paving mixtures incorporating rubber granules from the recovery of used tires, insulation materials made from recycled wood, furniture, etc.

The ratio for measuring the % of recycled materials or materials incorporating recycled materials in relation to the total quantity of raw materials used in the construction site shall be made in one of the following ways, using a simple example: 
A - % recyclates obtained = (% incorporating recyclates in material1) + (% incorporating recyclates in material2) + (....) / (total materials applied) 
B - % recyclates obtained = (% recyclates incorporated in material1) + (% recyclates incorporated in material2) + (....) / (total raw materials applied) 
The difference from A to B is in the raw materials, that is, if we consider raw materials, as the DL mentions (iron, aluminum, copper, clay, sand, limestone, wood, aggregates, stone, etc.) we exclude from the denominator materials whose manufacture comes from one or more raw materials.`}
                    onToggle={e => handleMenuItemClick(e, () => setOpenUsedWaste(!openUsedWaste))}
                    customIconLess={<RemoveCircle sx={{color: 'primary.main'}}/>}
                    customIconMore={<AddCircle sx={{color: 'primary.main'}}/>}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <IncorporationResiduesListForm
                    residues={residues}
                    onEditResidue={(residueId) => {
                        setEditingResidue(residueId);
                        onEditResidue(residueId);
                    }}
                    onDeleteResidue={onRemoveResidue}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'name'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Recycled materials` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'amount'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <TextField
                                {...field}
                                type={'number'}
                                sx={commonStyles.textField}
                                helperText={error?.message}
                                placeholder={t`Quantity to be reused` + '*'}
                                error={Boolean(error)}
                                variant={"outlined"}
                                onKeyDown={(event) => {
                                    if (event.key === ".") event.preventDefault();
                                }}
                                disabled={readOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <Controller
                    name={'base_unit'}
                    render={({field, fieldState: {error}}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography>{t`BASE UNIT`}</Typography>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={'TON'} label={t`Tons`} control={<Radio/>}/>
                                <FormControlLabel value={'CUBIC_METER'} label={<Box
                                    display={'flex'}><Typography>{t`m`}</Typography><sup>3</sup></Box>}
                                                  control={<Radio/>}/>
                            </RadioGroup>
                            <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                        </Box>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Controller
                    name={'amount_percentage'}
                    render={({field, fieldState: {error}}) => (
                        <Grid xs={12} md={12} item>
                            <>
                                <TextField
                                    {...field}
                                    type={'number'}
                                    sx={commonStyles.textField}
                                    placeholder={t`Quantity to be reused in relation to the total (%)` + '*'}
                                    error={Boolean(error)}
                                    variant={'outlined'}
                                    disabled={readOnly}
                                    onKeyDown={(event) => {
                                        if (event.key === ".") event.preventDefault();
                                    }}
                                    fullWidth
                                    required
                                />
                                {!amountPercentageCondition &&
                                    <FormHelperText error={!amountPercentageCondition}>
                                        {t`The quantity to be reused must be 100% or less`}
                                    </FormHelperText>
                                }
                            </>
                        </Grid>
                    )}
                />
            </Grid>
            <Grid xs={12} md={12} item>
                <Button
                    variant={'outlined'}
                    onClick={
                        editingResidue
                            ? () => onSaveEditResidue(handleFinishEditing)
                            : () => onAddResidue()
                    }
                    disabled={
                        readOnly || (
                            !watch('name') ||
                            !watch('amount') ||
                            !watch('base_unit') ||
                            (!watch('amount_percentage') ||
                                parseInt(getValues('amount_percentage')) > 100 ||
                                parseInt(getValues('amount_percentage')) < 0
                            )
                        )
                    }
                    fullWidth
                >
                    {!editingResidue && <Add sx={styles.headerAddIcon}/>}
                    {editingResidue ? t`SAVE CHANGES` : t`Add Recycled`}
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    },
    inputLabel: {
        fontWeight: 'bold'
    },
    headerAddIcon: {
        pr: 1,
        width: 15
    }
};

export default IncorporationMethodologyFormDetails;