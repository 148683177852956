import {t} from 'ttag';
import * as Yup from 'yup';


export const ResetPasswordFormSchema = Yup.object().shape(
    {
        password: Yup.string().typeError(t`Required field.`).required(t`Required field.`),
        passwordConfirmation: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
    }
).required();
