import React from 'react';
import useDirectusAssets from '../../../custom-hooks/useDirectusAssets';
import DirectusFeedback from '../../../common/DirectusFeedback';
import {ImageApiResponse} from '../../../../types';

const defaultPhoto = require('../../../../assets/images/no-image.webp');

interface Props {
    image: object
}


export const UpdateByProductFormImage = ({image}: Props) => {

    const {
        data: residueImg,
        error: errorGetResidueImg,
        isLoading: isLoadingGetResidueImg
    } = useDirectusAssets<ImageApiResponse>({
        path: `/assets/${image.id}`
    });

    return (
        <>
            <img
                src={residueImg || defaultPhoto}
                alt={'current-photo'}
                style={{maxWidth: 400, maxHeight: 200, borderRadius: 10}}
            />
            <DirectusFeedback error={errorGetResidueImg} isLoading={isLoadingGetResidueImg}/>
        </>
    );
};

export default UpdateByProductFormImage;