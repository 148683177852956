import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Container, Link, Stack, Typography} from '@mui/material';
import RequestResetPasswordForm from '../../components/authentication/request-reset-password/RequestResetPasswordForm';
import ResetPasswordForm from '../../components/authentication/reset-password/ResetPasswordForm';
import RegisterForm from '../../components/authentication/register/RegisterForm';
import LoginForm from '../../components/authentication/login/LoginForm';
import commonStyles from '../../components/common/styles/commonStyles';
import {useStore} from '../../store/valtio';

interface Props {
    onLogin: ({email: string, password: string}) => void
    onRegister: ({email: string, password: string}) => void
    onSubmitRequest: ({email: string}) => void
    onSubmitResetPassword: ({resetPasswordToken: string, password: string}) => void
    showRegisterForm: boolean
    onChangeShowRegisterForm: (value: boolean) => void
    errorLogin: boolean
    errorRegister: boolean
    isSuccessRequestPassword: boolean
}

export const Authentication = ({
   onLogin,
   onRegister,
   onSubmitRequest,
   onSubmitResetPassword,
   showRegisterForm,
   onChangeShowRegisterForm,
   errorLogin,
   errorRegister,
   isSuccessRequestPassword
}: Props) => {
    const {app: {resetPasswordToken}} = useStore();
    const navigate = useNavigate();
    const [showRequestResetPasswordForm, setShowRequestResetPasswordForm] = useState(false);

    if (resetPasswordToken) {
        return (
            <Box sx={styles.wrapper}>
                <Container component={'main'} maxWidth={'sm'} sx={styles.container}>
                    <Typography variant={'h4'} color={'common.white'} fontWeight={'bold'}>LREC REBUILD 17</Typography>
                    <Box sx={styles.formWrapper}>
                        <Typography variant={'h5'}>
                            {t`Reset Password`}
                        </Typography>
                        <ResetPasswordForm
                            onSubmit={(password) => onSubmitResetPassword({
                                token: resetPasswordToken,
                                password
                            })}
                        />
                        <Box sx={commonStyles.flexEndContent}>
                            <Link onClick={() => {
                                onChangeShowRegisterForm(false);
                                navigate('/auth');
                            }}
                                  sx={{cursor: 'pointer'}}
                                  variant={'body2'}
                            >
                                {t`Return to login`}
                            </Link>
                        </Box>
                    </Box>
                    <Typography
                        sx={styles.backToPlatform}
                        onClick={() => navigate('/')}
                    >
                        {t`Return home`}
                    </Typography>
                </Container>
            </Box>
        )
    }

    return (
        <Box sx={styles.wrapper}>
            <Container component={'main'} maxWidth={'sm'} sx={styles.container}>
                <Typography variant={'h4'} color={'common.white'} fontWeight={'bold'}>LREC REBUILD 17</Typography>
                <Box sx={styles.formWrapper}>
                    <Typography variant={'h5'}>
                        {showRegisterForm
                            ? t`Sign Up`
                            : showRequestResetPasswordForm
                                ? t`Password Reset`
                                : t`Sign In`
                        }
                    </Typography>
                    <>
                        {isSuccessRequestPassword
                            ?
                            <Stack spacing={3}>
                                <Typography py={2} fontSize={'0.9rem'}>
                                    {t`If there is an account associated with this email, you will receive an email with a link to reset your password.`}
                                </Typography>
                            </Stack>
                            :
                            <>
                                {showRegisterForm
                                    ? <RegisterForm onSubmitRegister={onRegister} errorRegister={errorRegister}/>
                                    :
                                    <>
                                        {showRequestResetPasswordForm
                                            ? <RequestResetPasswordForm onSubmitRequest={onSubmitRequest}/>
                                            : <LoginForm
                                                onSubmitLogin={onLogin}
                                                onShowRequestResetPasswordForm={() => setShowRequestResetPasswordForm(true)}
                                                errorLogin={errorLogin}
                                            />
                                        }
                                    </>
                                }
                                <Box sx={commonStyles.flexEndContent}>
                                    {showRequestResetPasswordForm
                                        ? <Link onClick={() => setShowRequestResetPasswordForm(false)} variant={'body2'}
                                                sx={{cursor: 'pointer'}}>
                                            {t`Return to login`}
                                        </Link>
                                        : <Link onClick={() => onChangeShowRegisterForm(!showRegisterForm)} variant={'body2'}
                                                sx={{cursor: 'pointer'}}>
                                            {showRegisterForm ? t`Have an account already? Sign In` : t`Don't have an account? Sign Up`}
                                        </Link>
                                    }
                                </Box>
                            </>
                        }
                    </>
                </Box>
                <Typography
                    sx={styles.backToPlatform}
                    onClick={() => navigate('/')}
                >
                    {t`Return home`}
                </Typography>
            </Container>
        </Box>
    );
};

const styles = {
    wrapper: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#283593'
    },
    container: {
        textAlign: 'center',
        marginTop: '5rem'
    },
    formWrapper: {
        px: 4,
        py: 6,
        gap: 4,
        marginTop: 8,
        boxShadow: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'common.black',
        backgroundColor: 'common.white'
    },
    backToPlatform: {
        color: 'common.white',
        paddingTop: '2rem',
        cursor: 'pointer',
        textDecoration: 'underline'
    }
};

export default Authentication;