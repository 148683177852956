import React from 'react';
import {Box, Typography} from '@mui/material';

interface Props {
    color: string,
    char: string,
    size?: ['small' | 'medium'],
}

export const CircularStatus = ({color, char, size}: Props) => {
    return (
        <Box sx={styles.marker(color, size)}>
            <Typography>{char?.substring(0, 1)}</Typography>
        </Box>
    );
};

CircularStatus.defaultProps = {
    color: 'gray',
    char: '',
    size: 'medium',
};

const styles = {
    marker: (color, size) => {
        return {
            p: .2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'common.white',
            borderRadius: '50%',
            backgroundColor: color,
            fontSize: size === 'medium' ? '14px' : '12px',
            width: size === 'medium' ? '20px' : '16px',
            height: size === 'medium' ? '20px' : '16px',
            lineHeight: size === 'medium' ? '20px' : '16px'
        };
    }
};

export default CircularStatus;
