import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Cloud, ExpandLess, ExpandMore} from '@mui/icons-material';
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import AuthorizationGuard from '../../../pages/common/guards/AuthorizationGuard';
import {APP_ROLES} from '../../../utils/user/user-utils';
import {useStore} from '../../../store/valtio';

interface Props {
    open: boolean,
    onToggle: React.MouseEventHandler<HTMLDivElement>
}

export const ResiduesMenuItem = ({open, onToggle}: Props) => {
    const navigate = useNavigate();
    const {app: {user}} = useStore();

    if (!user) return null;

    return (
        <>
            <MenuItem sx={{px: 0}} onClick={() => navigate('/residues')}>
                <ListItemButton sx={styles.listItemBtn}>
                    <ListItemIcon sx={styles.color}>
                        <Cloud/>
                    </ListItemIcon>
                    <ListItemText primary={t`Residues`}/>
                    <ListItemIcon onClick={onToggle}
                    >
                        {open
                            ? <ExpandLess sx={styles.color}/>
                            : <ExpandMore sx={styles.color}/>
                        }
                    </ListItemIcon>
                </ListItemButton>
            </MenuItem>
            <Collapse in={open} timeout={"auto"} unmountOnExit>
                <List>
                    <AuthorizationGuard
                        userHasSomeOf={[user, [APP_ROLES.ADMIN_GOLD, APP_ROLES.ADMIN_GENERAL, APP_ROLES.RESERVED]]}>
                        <>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => navigate('/add-residue')}>
                                <ListItemText primary={t`Add Residue`}/>
                            </ListItemButton>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => navigate('/add-by-product')}>
                                <ListItemText primary={t`Add By-Product`}/>
                            </ListItemButton>
                            <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                            onClick={() => navigate('/add-wanted-residue')}>
                                <ListItemText primary={t`Add Wanted Residue`}/>
                            </ListItemButton>
                        </>
                    </AuthorizationGuard>
                    <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                    onClick={() => navigate('/residues')}>
                        <ListItemText primary={t`View Residues`}/>
                    </ListItemButton>
                    <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                    onClick={() => navigate('/by-products')}>
                        <ListItemText primary={t`View By-Products`}/>
                    </ListItemButton>
                    <ListItemButton sx={{...styles.innerListItemBtn, ...styles.color}}
                                    onClick={() => navigate('/wanted-residues')}>
                        <ListItemText primary={t`View Wanted Residues`}/>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
};

const styles = {
    listItemBtn: {
        color: 'common.white',
        px: 0
    },
    color: {
        color: 'common.white'
    },
    innerListItemBtn: {
        py: 0
    }
};

export default ResiduesMenuItem;