import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import useDirectusQuery from '../../custom-hooks/useDirectusQuery';
import {EntitiesApiResponse} from '../../entities/EntitiesList';
import DirectusFeedback from '../../common/DirectusFeedback';
import {User} from '../../../types';

interface Props {
    authenticatedUser: User
    editable?: boolean
    readOnly?: boolean
}

export const EntityDepartmentSelectorFormDetails = ({authenticatedUser, editable, readOnly}: Props) => {
    const {watch, setValue, getValues, resetField} = useFormContext();

    const {
        data: entities,
        error: errorGetEntities,
        isLoading: isLoadingGetEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*.*',
                filter: {
                    'users': {
                        "directus_users_id": {
                            "id": {
                                "_eq": authenticatedUser?.id
                            }
                        }
                    }
                }
            }
        }
    });

    const {
        data: allEntities,
        error: errorGetAllEntities,
        isLoading: isLoadingGetAllEntities
    } = useDirectusQuery<EntitiesApiResponse>({
        path: '/items/entities',
        options: {
            params: {
                fields: '*.*.*'
            }
        }
    });

    useEffect(() => {
        const entitiesToFilter = readOnly ? allEntities : entities
        const entity = entitiesToFilter?.filter((entity) => entity.id === watch('entity_id'))[0];

        if (entity) {
            setValue('entity', {
                ...entity,
                user: entity.users.filter((user) => user.directus_users_id.id === authenticatedUser.id)[0]
            })
        }
    }, [entities, allEntities, watch('entity_id')]);

    if (!entities || !allEntities) return null;

    const filteredEntities = readOnly ? allEntities : entities;

    return (
        <>
            <Stack spacing={3}>
                <Grid spacing={4} container>
                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'entity_id'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl disabled={!editable} fullWidth>
                                    <InputLabel sx={{color: 'text.primary'}} id={'entity-label'} disabled={!editable}>
                                        {t`Entity`}*
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Entity`}
                                        labelId={'entity-label'}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            resetField('department_id');
                                        }}
                                        variant={'outlined'}
                                        disabled={!editable}
                                        fullWidth
                                        required
                                    >
                                        {filteredEntities?.map((entity, key) => (
                                            <MenuItem key={key} value={entity.id}>
                                                {entity.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>

                    <Grid xs={12} md={6} item>
                        <Controller
                            name={'department_id'}
                            render={({field, fieldState: {error}}) => (
                                <FormControl disabled={!watch('entity_id') || !editable} fullWidth>
                                    <InputLabel sx={{color: 'text.primary'}} id={'department-label'}>
                                        {t`Department`}*
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        label={t`Department`}
                                        labelId={'department-label'}
                                        disabled={!watch('entity_id') || !editable}
                                        sx={styles.selectField}
                                        error={Boolean(error)}
                                        fullWidth
                                        required
                                    >
                                        {watch('entity')?.departments?.map((department, key) => (
                                            <MenuItem key={key} value={department.id}>
                                                {department.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText
                                        error={Boolean(error)}>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <DirectusFeedback
                error={errorGetEntities || errorGetAllEntities}
                isLoading={isLoadingGetEntities || isLoadingGetAllEntities}
            />
        </>
    );
};

const styles = {
    selectField: {
        borderRadius: 2
    }
    ,
    inputLabel: {
        fontWeight: 'bold'
    }
};

EntityDepartmentSelectorFormDetails.defaultProps = {
    editable: true,
    readOnly: false
};

export default EntityDepartmentSelectorFormDetails;