import {t} from 'ttag';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography} from '@mui/material';
import {LoginFormSchema} from './helpers/login-form-schema';
import commonStyles from '../../common/styles/commonStyles';
import {emailIsValid} from '../../../utils/forms/helpers';

interface Props {
    onSubmitLogin: ({email: string, password: string}) => void
    errorLogin: boolean
    onShowRequestResetPasswordForm: () => void
}

export const LoginForm = ({onSubmitLogin, errorLogin, onShowRequestResetPasswordForm}: Props) => {
    const navigate = useNavigate();

    const methods = useForm({
        resolver: yupResolver(LoginFormSchema),
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const handleSubmitLoginForm = methods.handleSubmit((values) => {
        onSubmitLogin(values)
    });

    return (
        <FormProvider {...methods}>
            <Stack width={'100%'} spacing={3}>
                <Controller
                    name={'email'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Email` + ' *'}
                            sx={commonStyles.textField}
                            variant={'outlined'}
                            fullWidth
                            required
                        />
                    )}
                />
                <Controller
                    name={'password'}
                    render={({field, fieldState: {error}}) => (
                        <TextField
                            {...field}
                            type={'password'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            placeholder={t`Password` + ' *'}
                            sx={commonStyles.textField}
                            variant={'outlined'}
                            fullWidth
                            required
                        />
                    )}
                />
                {errorLogin &&
                    <Box sx={commonStyles.flexStartContent}>
                        <Typography color={'error'}>{t`Invalid email or password.`}</Typography>
                    </Box>
                }
                <Box sx={commonStyles.spaceBetween}>
                    <FormControlLabel
                        control={<Checkbox value='remember' color='primary'/>}
                        label={t`Remember me`}
                    />
                    <Typography
                        sx={styles.forgotPassword}
                        onClick={onShowRequestResetPasswordForm}
                    >
                        {t`Forgot Password?`}
                    </Typography>
                </Box>
                <Button
                    variant={'contained'}
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmitLoginForm}
                    disabled={!emailIsValid(methods.watch('email')) || !methods.watch('password')}
                    fullWidth
                >
                    {t`Login`}
                </Button>
            </Stack>
        </FormProvider>
    );
};

const styles = {
    forgotPassword: {
        cursor: 'pointer',
        textDecoration: 'underline'
    }
}

export default LoginForm;