import {t} from 'ttag';
import * as Yup from 'yup';


export const ConditioningAndSortingFormSchema = Yup.object().shape(
    {
        condition_and_sorting: Yup.string().required(t`Required field.`),
        conditioning_description: Yup.string().required(t`Required field.`),
        justification: Yup.string().when('condition_and_sorting', {
            is: (conditionAndSorting) => conditionAndSorting === 'false',
            then: Yup.string().typeError(t`Required field.`).required(t`Required field.`)
        })
    }
).required();
