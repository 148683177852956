import {t} from 'ttag';
import React from 'react';
import {Box, Card, LinearProgress, linearProgressClasses, Stack, Typography} from '@mui/material';
import commonStyles from '../styles/commonStyles';

interface Props {
    title: string,
    infoText: string,
    progressPercentage: number
}

export const ProgressCard = ({title, infoText, progressPercentage}: Props) => {
    return (
        <Card sx={commonStyles.card()}>
            <Stack spacing={3} p={5}>
                <Stack display={'flex'} justifyContent={'flex-start'} spacing={2}>
                    <Typography sx={styles.title}>{title}</Typography>
                    <Typography sx={styles.infoText}>{infoText}</Typography>
                </Stack>
                <Box sx={styles.progressBarWrapper} gap={2}>
                    <LinearProgress sx={styles.progressBar} variant={"determinate"} value={progressPercentage}/>
                    <Typography sx={styles.progressBarLabel}>{`${Math.round(progressPercentage)}%`}</Typography>
                </Box>
            </Stack>
        </Card>
    );
};

const styles = {
    title: {
        fontWeight: 'bold',
        color: 'text.secondary'
    },
    infoText: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: 'text.info'
    },
    progressBarWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progressBarLabel: {
        color: 'text.secondary',
        fontWeight: 300,
        fontSize: '0.9rem'
    },
    progressBar: {
        width: '100%',
        height: '1.1rem',
        borderRadius: 10,
        borderWidth: .8,
        borderStyle: 'solid',
        borderColor: 'text.secondary',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'background.default'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: 'success.main'
        },
    }
};

ProgressCard.defaultProps = {
    buttonText: t`SEE MORE`
}

export default ProgressCard;