import {t} from 'ttag';
import * as React from 'react';
import {Stack, Typography} from '@mui/material';
import NavigationBreadcrumbs from '../../components/common/breadcrumbs/NavigationBreadcrumbs';
import AddEntityForm from '../../components/entities/forms/AddEntityForm';
import commonStyles from '../../components/common/styles/commonStyles';


export const AddEntity = () => {

    return (
        <Stack sx={commonStyles.pageWrapper}>
            <Typography sx={commonStyles.pageTitle}>{t`ADD ENTITY`}</Typography>
            <NavigationBreadcrumbs
                list={[
                    {name: 'Home', link: '/'},
                    {name: t`Add Entity`, link: '/add-entity'}
                ]}
            />
            <AddEntityForm/>
        </Stack>
    );
};

export default AddEntity;