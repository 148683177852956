import React from 'react';
import {DateTime as dt} from 'luxon';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {AddWantedResidueFormSchema} from './add-wanted-residue-form/schemas/add-wanted-residue-form-schema';
import AddWantedResidueFormDetails from './add-wanted-residue-form/AddWantedResidueFormDetails';
import useDirectusMutation from '../../custom-hooks/useDirectusMutation';
import {DATETIME_FORMAT} from '../../../utils/datetime/datetime-utils';
import DirectusFeedback from '../../common/DirectusFeedback';
import FormSkeleton from '../../common/forms/FormSkeleton';
import BasicCard from '../../common/cards/BasicCard';
import {useStore} from '../../../store/valtio';


export const AddWantedResidueForm = () => {
    const navigate = useNavigate();
    const {app: {user, lerCodes}} = useStore();

    const {
        actions: {POST: addWantedResidue},
        response: {error: errorAddWantedResidue, isLoading: isLoadingAddWantedResidue}
    } = useDirectusMutation({path: '/items/wanted_residues'});

    const methods = useForm({
        resolver: yupResolver(AddWantedResidueFormSchema),
        defaultValues: {
            ler_code: '',
            format: '',
            amount: '',
            entity_id: '',
            amount_base_unit: '',
            contamination: '',
            alert_expiration_date: dt.now().plus({days: 90}).toFormat(DATETIME_FORMAT),
            user_id: user.id
        }
    });

    const submitHandler = methods.handleSubmit((values) => {
        addWantedResidue((values), () => navigate('/wanted-residues'));
    });

    if(!user || !lerCodes) return null;

    return (
        <>
            <BasicCard headerContent={<></>}>
                <FormProvider {...methods}>
                    <FormSkeleton
                        mainGroupContent={<AddWantedResidueFormDetails authenticatedUser={user} lerCodes={lerCodes}/>}
                        onSubmit={submitHandler}
                        onCancel={() => methods.reset()}
                        innerForm
                    />
                </FormProvider>
            </BasicCard>
            <DirectusFeedback error={errorAddWantedResidue} isLoading={isLoadingAddWantedResidue}/>
        </>
    );
};

export default AddWantedResidueForm;