import React from 'react';
import useDirectusAssets from '../../components/custom-hooks/useDirectusAssets';
import {ImageApiResponse} from '../../types';
import DirectusFeedback from '../../components/common/DirectusFeedback';
import {Avatar} from '@mui/material';


interface Props {
    avatar: object
}

export const ProfileUserAvatar = ({avatar}: Props) => {

    const {
        data: userAvatar,
        error: errorGetUserAvatar,
        isLoading: isLoadingGetUserAvatar
    } = useDirectusAssets<ImageApiResponse>({
        path: `/assets/${avatar?.id}`,
    });

    if(!userAvatar) return null;

    return (
        <>
            <Avatar alt={"avatar"} src={userAvatar} sx={styles.avatar}/>
            <DirectusFeedback error={errorGetUserAvatar} isLoading={isLoadingGetUserAvatar}/>
        </>
    );
};

const styles = {
    avatar: {
        width: 110,
        height: 110
    },
}

export default ProfileUserAvatar;